export default function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Bytes';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  const value = Number(parseFloat(bytes / 1024 ** i).toFixed(2)).toLocaleString(
    'en',
    {
      minimumFractionDigits: 2,
    }
  );
  return `${value} ${sizes[i]}`;
}
