import * as actions from './StaffActions';
import { UPDATE_STAFF_CONTACT } from '../Contact/ContactActions';
import { ROUTE_CHANGED } from '../App/AppActions';

const initialState = {
  detail: {},
  loading: false,
  error: false,
};

export default (state = initialState, action) => {
  if (action.type === ROUTE_CHANGED) {
    return { ...state, detail: {} };
  }
  switch (action.type) {
    case actions.STAFF_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actions.LOADING_STAFF:
      return { ...state, loading: action.loading };
    case actions.DISPLAY_STAFF:
      return {
        ...state,
        detail: action.staff,
      };
    case UPDATE_STAFF_CONTACT:
      if (action.detail) {
        return {
          ...state,
          detail: {
            ...state.detail,
            contact: {
              ...state.detail.contact,
              ...action.body,
            },
          },
        };
      }
      return state;
    case actions.UPDATE_STAFF:
      return {
        ...state,
        list: state.list.map(l => {
          if (l._id === action.staff) {
            return { ...l, ...action.body };
          }
          return l;
        }),
        detail: {
          ...state.detail,
          ...action.body,
        },
      };
    case actions.GET_CASES:
      return {
        ...state,
        detail: {
          ...state.detail,
          caseLoad: action.cases,
        },
      };
    default:
      return state;
  }
};
