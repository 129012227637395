import React, { Component } from 'react';
import { NavLink as RNavLink } from 'react-router-dom';

class NavLink extends Component {
  render() {
    return <RNavLink {...this.props} />;
  }
}

export default NavLink;
