/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import OauthCallback from './modules/User/pages/OauthCallback';
import AuthError from './modules/User/pages/AuthError';

import Dashboard from './modules/App/pages/Dashboard';
import CarerDetail from './modules/Carer/pages/CarerDetail';
import CarerList from './modules/Carer/pages/CarerList';
import CarerPrint from './modules/Carer/pages/CarerPrint';
import ProspectDetail from './modules/Carer/pages/ProspectDetail';
import ProspectList from './modules/Carer/pages/ProspectList';
import HouseholdList from './modules/Household/pages/HouseholdList';
import HouseholdDetail from './modules/Household/pages/HouseholdDetail';
import MemberList from './modules/OtherIndividual/pages/MemberList';
import HouseholdPrint from './modules/Household/pages/HouseholdPrint';
import YoungPersonList from './modules/YoungPerson/pages/YoungPersonList';
import YoungPersonDetail from './modules/YoungPerson/pages/YoungPersonDetail';
import YoungPersonPrint from './modules/YoungPerson/pages/YoungPersonPrint';
import ReferralList from './modules/Referral/pages/ReferralList';
import ReferralDetail from './modules/Referral/pages/ReferralDetail';
import PlacementList from './modules/Placement/pages/PlacementList';
import PlacementDetail from './modules/Placement/pages/PlacementDetail';
import StaffList from './modules/Staff/pages/StaffList';
import StaffDetail from './modules/Staff/pages/StaffDetail';
import StaffPrint from './modules/Staff/pages/StaffPrint';
import AllLogs from './modules/Log/pages/AllLogs';
import Keywords from './modules/App/pages/Keywords';

import AccessDB from './modules/AccessDB/pages/AccessDB';

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/home" exact component={Dashboard} />
        <Route path="/login/:error" exact component={AuthError} />
        <Route path="/me" exact component={StaffDetail} />

        <Route path="/auth/:provider/callback" component={OauthCallback} />
        <Route
          path="/auth/:provider/:externalId/callback"
          component={OauthCallback}
        />

        <Route path="/prospect" exact component={ProspectList} />
        <Route path="/prospect/:id" exact component={ProspectDetail} />

        <Route path="/carer" exact component={CarerList} />
        <Route path="/carer/:id" exact component={CarerDetail} />

        <Route path="/household" exact component={HouseholdList} />
        <Route path="/household/list/:type" exact component={HouseholdList} />
        <Route path="/household/:id" exact component={HouseholdDetail} />

        <Route path="/member" exact component={MemberList} />

        <Route path="/young" exact component={YoungPersonList} />
        <Route path="/young/:id" exact component={YoungPersonDetail} />

        <Route path="/referral" exact component={ReferralList} />
        <Route path="/referral/:id" exact component={ReferralDetail} />

        <Route path="/case" exact component={PlacementList} />
        <Route path="/case/:id" exact component={PlacementDetail} />

        <Route path="/staff" exact component={StaffList} />
        <Route path="/staff/:id" exact component={StaffDetail} />

        <Route path="/keywords" exact component={Keywords} />
        <Route path="/logs" exact component={AllLogs} />

        {/*printpreview*/}
        <Route
          path="/printpreview/household/:id"
          exact
          component={HouseholdPrint}
        />
        <Route
          path="/printpreview/young/:id"
          exact
          component={YoungPersonPrint}
        />
        <Route path="/printpreview/carer/:id" exact component={CarerPrint} />
        <Route path="/printpreview/staff/:id" exact component={StaffPrint} />

        {/*accessdb*/}
        <Route path="/accessdb" exact component={AccessDB} />
      </Switch>
    );
  }
}

export default Routes;
