import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import indexOf from 'lodash/indexOf';
import clone from 'lodash/clone';

import MenuItem from 'material-ui/MenuItem';
import { List, ListItem } from 'material-ui/List';
import IconButton from 'material-ui/IconButton';

import Remove from 'material-ui/svg-icons/content/remove-circle';
import ArrowDown from 'material-ui/svg-icons/navigation/arrow-drop-down';
import ArrowUp from 'material-ui/svg-icons/navigation/arrow-drop-up';

import keywordsModel from '../KeywordModel';
import TextField from '../../../components/TextField';
import SelectField from '../../../components/SelectField';
import { updateKeywords } from '../AppActions';

import './Keywords.css';

class Keywords extends Component {
  constructor() {
    super();
    this.state = {
      keyword: '',
      subword: '',
      subfield: '',
      subtitle: '',
      newvalue: '',
      hassub: false,
    };
  }
  static renderMenuItems(level) {
    return keywordsModel.map(key => (
      <MenuItem value={key.field} key={key.field} primaryText={key.name} />
    ));
  }
  selectMenuItem(value) {
    const keyword = keywordsModel.filter(key => key.field === value)[0];
    keyword.subfield
      ? this.setState({
          keyword: value,
          subfield: keyword.subfield.field,
          subtitle: keyword.subfield.name,
          hassub: true,
          subword: '',
        })
      : this.setState({
          keyword: value,
          subfield: '',
          subtitle: '',
          hassub: false,
          subword: '',
        });
  }
  renderSubMenuItems(level) {
    const keywords = this.props.keywords.filter(
      key => key.field === this.state.keyword
    );
    const values = ['', ...keywords[0].values];

    return values.map(key => (
      <MenuItem value={key} key={key} primaryText={key} />
    ));
  }
  selectSubMenuItem(value) {
    this.setState({
      subword: value,
    });
  }
  getKeyword() {
    const keyword = this.state.subword
      ? {
          field: this.state.subfield,
          parent: this.state.subword,
        }
      : {
          field: this.state.keyword,
          parent: '',
        };
    return keyword;
  }
  getKeyvalues() {
    const keywords = this.state.subword
      ? this.props.keywords.filter(
          key =>
            key.field === this.state.subfield &&
            key.parent === this.state.subword
        )
      : this.props.keywords.filter(key => key.field === this.state.keyword);

    return keywords[0] ? keywords[0].values : [];
  }
  move(value, index, up = true) {
    const keyword = this.getKeyword();
    const oldvalues = clone(this.getKeyvalues());
    const keyvalues = clone(this.getKeyvalues());
    const newIndex = up ? index - 1 : index + 1;

    keyvalues[newIndex] = oldvalues[index];
    keyvalues[index] = oldvalues[newIndex];

    const body = {
      field: keyword.field,
      parent: keyword.parent,
      values: keyvalues,
    };

    this.props.dispatch(updateKeywords(body));
  }
  renderEditArea() {
    if (!this.state.keyword && this.state.subword) return null;
    if (!this.state.keyword && !this.state.subword) return null;

    const keyvalues = this.getKeyvalues();

    const chips = keyvalues.map(value => {
      const index = indexOf(keyvalues, value);
      return (
        <div key={value} className="keywordsListItem">
          <div className="arrows">
            {index !== 0 ? (
              <ArrowUp onClick={() => this.move(value, index)} />
            ) : (
              <svg className="svgDisabled" />
            )}
            {index !== keyvalues.length - 1 ? (
              <ArrowDown onClick={() => this.move(value, index, false)} />
            ) : (
              <svg className="svgDisabled" />
            )}
          </div>
          <ListItem
            rightIconButton={
              <IconButton
                onClick={() => {
                  const body = this.getKeyword();
                  body.values = [...keyvalues.filter(item => item !== value)];
                  this.props.dispatch(updateKeywords(body));
                }}
              >
                <Remove className="iconDanger" />
              </IconButton>
            }
          >
            {value}
          </ListItem>
        </div>
      );
    });
    return (
      <List className="smallList floating body">
        {chips.length ? (
          chips
        ) : (
          <ListItem disabled>
            No key values defined. <br />
            Add one or more comma separated values to display them here.
          </ListItem>
        )}
      </List>
    );
  }
  render() {
    // const isMobile = this.context.isMobile();
    // const isPortrait = this.context.isPortrait();
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Keywords Config - HEART1869</title>
          <meta
            name="description"
            content="Kennerley HEART1869 Keywords Config"
          />
        </Helmet>
        <div className="content">
          <form className="keyword-form">
            <SelectField
              // style={{ width: isMobile && isPortrait ? '99%' : '49%' }}
              style={{ width: '99%' }}
              param={{ name: 'Keyword', editable: true }}
              value={this.state.keyword}
              edit
              options={this.constructor.renderMenuItems('main')}
              onUpdated={value => this.selectMenuItem(value)}
            />
            {!!this.state.hassub && (
              <SelectField
                // style={{ width: isMobile && isPortrait ? '99%' : '49%' }}
                style={{ width: '99%' }}
                param={{ name: this.state.subtitle }}
                value={this.state.subword}
                edit
                options={this.renderSubMenuItems('sub')}
                onUpdated={value => this.selectSubMenuItem(value)}
              />
            )}
            <TextField
              // style={{ width: isMobile && isPortrait ? '99%' : '49%' }}
              style={{ width: '99%' }}
              param={{ name: 'Value (press enter to submit)', editable: true }}
              value={this.state.newvalue}
              edit={this.state.keyword !== ''}
              onUpdated={value => this.setState({ newvalue: value })}
              onKeyDown={e => {
                switch (e.key) {
                  case 'Enter':
                    e.preventDefault();
                    if (this.state.newvalue !== '') {
                      const newvalues = this.state.newvalue
                        .split(',')
                        .filter(value => !!value);
                      const body = this.getKeyword();
                      body.values = [...newvalues, ...this.getKeyvalues()];
                      this.props.dispatch(updateKeywords(body));
                      this.setState({ newvalue: '' });
                    }
                    break;
                  default:
                }
              }}
            />
          </form>
          {this.renderEditArea()}
        </div>
      </div>
    );
  }
}

// Keywords.contextTypes = {
//   isMobile: PropTypes.func.isRequired,
//   isPortrait: PropTypes.func.isRequired,
// };

function mapStateToProps(state) {
  return {
    keywords: state.app.keywords,
  };
}

export default connect(mapStateToProps)(Keywords);
