import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar';

import { colours, icons } from '../../../theme';

import './ReferralCard.css';

class ReferralCard extends Component {
  renderStatus() {
    const { referral } = this.props;
    if (
      (referral.closed || referral.closedDate || referral.closedReason) &&
      (!referral.closed ||
        !referral.closedDate ||
        !referral.closedReason ||
        (referral.placementType !== 'Moving On Program' &&
          isEmpty(referral.household)))
    )
      return colours.kRed;
    else if (!isEmpty(referral.case)) return colours.kGrey;
    return colours.skyBlue;
  }
  renderSubtitle() {
    const { referral } = this.props;
    let message = '';
    if (referral.closed) {
      const { closedDate, closedReason } = referral;
      message = `${moment(closedDate).format('D MMMM YYYY')} - ${closedReason}`;
    } else {
      message = `Open - ${referral.placementType}`;
    }
    return message;
  }
  render() {
    const { referral } = this.props;
    const colour = this.renderStatus();
    return (
      <div className="person-card">
        <icons.ReferralIcon className="person-card-back" color={colour} />
        <Link to={`/referral/${referral._id}`}>
          <ListItem
            primaryText={`Referral for ${referral.youngPerson?.contact
              .fullName || 'Unknown'}`}
            secondaryText={this.renderSubtitle()}
            leftAvatar={<Avatar backgroundColor={colours.kGrey}>R</Avatar>}
            style={{ borderLeft: `5px solid ${colour}` }}
          />
        </Link>
      </div>
    );
  }
}

export default ReferralCard;
