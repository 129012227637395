import callApi, { callDownloadApi } from '../../util/apiCaller';

export function searchYoungPeople(text, start, count, type) {
  return callApi(`young?start=${start}&count=${count}`, 'POST', { text, type });
}

export function fetchYoungPerson(_id) {
  return callApi(`young/${_id}`);
}

export function deleteYoungPerson(_id) {
  return callApi(`young/${_id}`, 'DELETE');
}

export function updateYoungPerson(_id, body) {
  return callApi(`young/${_id}`, 'POST', body);
}

export function addYoungPerson(body) {
  return callApi('young', 'PUT', body);
}

export function getAccommodation(id) {
  return callApi(`young/${id}/accommodation`);
}

export function getPlacements(id) {
  return callApi(`young/${id}/cases`);
}

export function getYPNotes(id) {
  return callApi(`young/${id}/note`);
}

export function filterYPNotes(id, body) {
  return callApi(`young/${id}/note`, 'POST', body);
}

export function addDoctor(_id, body) {
  return callApi(`young/${_id}/doctors`, 'PUT', body);
}

export function updateDoctor(yP, doctor, body) {
  return callApi(`young/${yP}/doctors/${doctor}`, 'POST', body);
}

export function addParent(_id, body) {
  return callApi(`young/${_id}/parents`, 'PUT', body);
}

export function updateParent(yP, parent, body) {
  return callApi(`young/${yP}/parents/${parent}`, 'POST', body);
}

export function addSibling(_id, body) {
  return callApi(`young/${_id}/siblings`, 'PUT', body);
}

export function updateSibling(yP, sibling, body) {
  return callApi(`young/${yP}/siblings/${sibling}`, 'POST', body);
}

export function addViewAndWish(_id, body) {
  return callApi(`young/${_id}/viewsandwishes`, 'PUT', body);
}

export function getViewsAndWishes(yPID, vWID) {
  if (vWID) {
    return callApi(`young/${yPID}/viewsandwishes/${vWID}`);
  }
  return callApi(`young/${yPID}/viewsandwishes`);
}

export function updateViewAndWish(yP, vW, body) {
  return callApi(`young/${yP}/viewsandwishes/${vW}`, 'POST', body);
}

export function deleteViewAndWish(yP, vW) {
  return callApi(`young/${yP}/viewsandwishes/${vW}`, 'DELETE');
}

export function exportYoungPerson(_id, body) {
  return callDownloadApi(`young/${_id}/export`, 'POST', body);
}
