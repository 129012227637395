import React, { Component } from 'react';
import moment from 'moment';

import { ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar';

import { colours, icons } from '../../../theme';

class TrainingCard extends Component {
  render() {
    const { training, readOnly, showDialog } = this.props;
    const randColour = colours.skyBlue;

    return (
      <div className="person-card">
        <icons.TrainingIcon className="person-card-back" color={randColour} />
        <ListItem
          primaryText={training.module}
          secondaryText={
            <span>
              Start Date: {moment(training.startDate).format('DD MMM YYYY')}
            </span>
          }
          leftAvatar={
            <Avatar
              icon={<icons.TrainingIcon />}
              backgroundColor={colours.kGrey}
            />
          }
          style={{ borderLeft: `5px solid ${randColour}` }}
          disabled={readOnly}
          onClick={() => showDialog()}
        />
      </div>
    );
  }
}

export default TrainingCard;
