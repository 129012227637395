import keys from 'lodash/keys';
import find from 'lodash/find';

import ContactModel from './ContactModel';

import { update } from './ContactAPI';

import { notification } from '../App/AppActions';

export const UPDATE_CARER_CONTACT = 'carer/UPDATE_CARER_CONTACT';
export const UPDATE_PROSPECT_CONTACT = 'prospect/UPDATE_PROSPECT_CONTACT';
export const UPDATE_YOUNG_PERSON_CONTACT = 'young/UPDATE_YOUNG_PERSON_CONTACT';
export const UPDATE_STAFF_CONTACT = 'staff/UPDATE_STAFF_CONTACT';

export function updateContact(_id, body, type, detail) {
  return {
    type,
    _id,
    body,
    detail,
  };
}

export function modifyContact(
  _id,
  body,
  type,
  prevVal,
  undo = false,
  detail = true
) {
  return dispatch =>
    update(_id, body).then(() => {
      let action = '';
      switch (type) {
        case 'carer':
          action = UPDATE_CARER_CONTACT;
          break;
        case 'prospect':
          action = UPDATE_PROSPECT_CONTACT;
          break;
        case 'young':
          action = UPDATE_YOUNG_PERSON_CONTACT;
          break;
        case 'staff':
          action = UPDATE_STAFF_CONTACT;
          break;
        default:
      }
      dispatch(updateContact(_id, body, action, detail));
      if (!undo) {
        dispatch(
          notification(
            `${find(ContactModel, { field: keys(body)[0] }).name} updated.`,
            'UNDO',
            modifyContact(_id, prevVal, type, '', true)
          )
        );
      } else {
        dispatch(
          notification(
            `Changes to ${
              find(ContactModel, { field: keys(body)[0] }).name
            } undone.`
          )
        );
      }
    });
}
