import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroller';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { Tabs, Tab } from 'material-ui/Tabs';
import { ListItem } from 'material-ui/List';

import FloatingActionButton from 'material-ui/FloatingActionButton';

import SearchBox from '../../../components/SearchBox';
import ReferralCard from '../components/ReferralCard';

import ReferralAdd from '../components/ReferralAdd';
import { changeTab, clearSearch, searchReferrals } from '../ReferralActions';
import { icons } from '../../../theme';

class ReferralList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdd: false,
    };
    this.doSearch = debounce(this.doSearch.bind(this), 800);
  }

  fetchMore() {
    const { count, start, tab, loading, dispatch, text } = this.props;
    if (!loading) {
      const newStart = start + count;
      dispatch(searchReferrals(text, newStart, count, tab));
    }
  }

  doSearch(val) {
    const { count, tab, loading, dispatch } = this.props;
    if (!loading) {
      dispatch(searchReferrals(val, 0, count, tab));
    }
  }

  handleTabOnActive(tab, val) {
    const { dispatch } = this.props;
    dispatch(changeTab(tab));
    const { count, loading } = this.props;
    if (!loading) {
      dispatch(searchReferrals(val, 0, count, tab, 'tabChanged'));
    }
  }

  handleSearchButtonOnClick(val) {
    this.doSearch(val);
  }

  handleSearchBoxOnChange(val) {
    this.doSearch(val);
  }

  handleClearButtonOnClick() {
    this.props.dispatch(clearSearch());
    this.doSearch(null);
  }

  render() {
    const {
      tab,
      search,
      start,
      referrals,
      hasMore,
      error,
      /* location, */
    } = this.props;
    const list = search.text ? search.result : referrals;
    let hint = '';
    // switch (location.pathname) {
    //   case '/referral':
    //     hint = 'Search referrals';
    //     break;
    //   default:
    //     hint = 'Search';
    // }
    if (tab === 5) {
      hint = 'Search external referrals';
    } else if (tab === 4) {
      hint = 'Search family referrals';
    } else if (tab === 3) {
      hint = 'Search community referrals';
    } else if (tab === 2) {
      hint = 'Search kennerley referrals';
    } else {
      hint = 'Search referrals';
    }

    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Referrals - HEART1869</title>
          <meta name="description" content="Kennerley HEART1869 Referrals" />
        </Helmet>
        <div className="content">
          <div className="list-tabs" style={{ overflowY: 'scroll' }}>
            <Tabs
              style={{
                maxWidth: '900px',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingBottom: '5px',
              }}
              value={tab}
              onChange={index => {
                this.setState({ tab: index });
              }}
            >
              <Tab
                label="Active"
                value="open"
                onActive={() => {
                  this.handleTabOnActive('open', search.text);
                }}
              />
              <Tab
                label="Inactive"
                value="closed"
                onActive={() => {
                  this.handleTabOnActive('closed', search.text);
                }}
              />
              <Tab
                label="Kennerley"
                value={2}
                onActive={() => {
                  this.handleTabOnActive(2, search.text);
                }}
              />
              <Tab
                label="Community"
                value={3}
                onActive={() => {
                  this.handleTabOnActive(3, search.text);
                }}
              />
              <Tab
                label="FCP"
                value={4}
                onActive={() => {
                  this.handleTabOnActive(4, search.text);
                }}
              />
              <Tab
                label="External"
                value={5}
                onActive={() => {
                  this.handleTabOnActive(5, search.text);
                }}
              />
              <Tab
                label="All"
                value={1}
                onActive={() => {
                  this.handleTabOnActive(1, search.text);
                }}
              />
            </Tabs>
          </div>
          <div className="list-head">
            <SearchBox
              hintText={hint}
              onClickSearch={val => this.handleSearchButtonOnClick(val)}
              onChangeSearch={val => this.handleSearchBoxOnChange(val)}
              onClickClear={() => this.handleClearButtonOnClick()}
            />
          </div>
          <div className="list-body" style={{ height: '87.05%' }}>
            <InfiniteScroll
              className="list-section"
              useWindow={false}
              pageStart={start}
              loadMore={() => this.fetchMore(start)}
              hasMore={!error && hasMore}
              initialLoad
            >
              {!isEmpty(list) ? (
                list.map(r => (
                  <ReferralCard referral={r} rel="referral" key={r._id} />
                ))
              ) : (
                <ListItem disabled>
                  {error
                    ? 'An error occurred, please refresh the app.'
                    : 'No results.'}
                </ListItem>
              )}
            </InfiniteScroll>
          </div>
          <FloatingActionButton
            className="fab"
            onClick={() => this.setState({ showAdd: true })}
          >
            <icons.AddIcon />
          </FloatingActionButton>
          <ReferralAdd
            closeDialog={() => this.setState({ showAdd: false })}
            open={this.state.showAdd}
          />
        </div>
      </div>
    );
  }
}

ReferralList.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tab: state.referral.tab,
  search: state.referral.search,
  start: state.referral.info.start,
  count: state.referral.info.count,
  hasMore: state.referral.info.hasMore,
  loading: state.referral.info.loading,
  referrals: state.referral.list,
  error: state.referral.info.error,
});

export default connect(mapStateToProps)(ReferralList);
