import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroller';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { Tabs, Tab } from 'material-ui/Tabs';
import { ListItem } from 'material-ui/List';

import SearchBox from '../../../components/SearchBox';

import ClientCard from '../components/ClientCard';
import ClientReport from '../components/ClientReport';

import {
  clearSearch,
  searchClients,
  fetchFullIndividualClientReport,
} from '../AccessDBActions';

class AccessDB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClientReport: false,
    };
    this.doSearch = debounce(this.doSearch.bind(this), 800);
  }

  fetchMore() {
    const { count, start, tab, loading, dispatch } = this.props;
    if (!loading) {
      const newStart = start + count;
      dispatch(searchClients(null, newStart, count, tab));
    }
  }

  doSearch(val) {
    const { count, tab, loading, dispatch } = this.props;
    if (!loading) {
      dispatch(searchClients(val, 0, count, tab));
    }
  }

  handleTabOnActive(tab, val) {
    const { count, loading, dispatch } = this.props;
    // dispatch(changeTab(tab));
    if (!loading) {
      dispatch(searchClients(val, 0, count, tab, 'tabChanged'));
    }
  }

  handleSearchButtonOnClick(val) {
    this.doSearch(val);
  }

  handleSearchBoxOnChange(val) {
    this.doSearch(val);
  }

  handleClearButtonOnClick() {
    this.props.dispatch(clearSearch());
    this.doSearch(null);
  }

  handleClientClick(client) {
    const { dispatch } = this.props;
    this.setState({
      showClientReport: true,
    });
    dispatch(fetchFullIndividualClientReport(client)).then(report => {
      this.setState({
        client,
        report,
      });
    });
  }

  render() {
    const { tab, search, start, clients, hasMore, error } = this.props;
    const list = search.text ? search.result : clients;
    let hint = '';
    // switch (location.pathname) {
    //   case '/client':
    //     hint = 'Search client people';
    //     break;
    //   default:
    //     hint = 'Search';
    // }
    hint = 'Search clients';
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clients - HEART1869</title>
          <meta name="description" content="Kennerley HEART1869 clients" />
        </Helmet>
        <div className="content">
          <div className="list-tabs" style={{ overflowY: 'scroll' }}>
            <Tabs
              style={{
                maxWidth: '900px',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingBottom: '5px',
              }}
              value={tab}
              onChange={index => {
                this.setState({ tab: index });
              }}
            >
              <Tab
                label="Full Individual Client Report "
                value={1}
                onActive={() => {
                  this.handleTabOnActive(1, search.text);
                }}
              />
            </Tabs>
          </div>
          <div className="list-head">
            <SearchBox
              hintText={hint}
              searchText={search.text}
              onClickSearch={val => this.handleSearchButtonOnClick(val)}
              onChangeSearch={val => this.handleSearchBoxOnChange(val)}
              onClickClear={() => this.handleClearButtonOnClick()}
            />
          </div>
          <div className="list-body" style={{ height: '87.05%' }}>
            <InfiniteScroll
              className="list-section"
              useWindow={false}
              pageStart={start}
              loadMore={() => this.fetchMore(start)}
              hasMore={!error && hasMore}
              initialLoad
            >
              {!isEmpty(list) ? (
                list.map(c => (
                  <ClientCard
                    client={c}
                    rel="client"
                    key={c._id}
                    onClickClient={obj => this.handleClientClick(obj)}
                  />
                ))
              ) : (
                <ListItem disabled>
                  {error
                    ? 'An error occurred, please refresh the app.'
                    : 'No results.'}
                </ListItem>
              )}
            </InfiniteScroll>
          </div>
          <ClientReport
            client={this.state.client}
            report={this.state.report}
            closeDialog={() =>
              this.setState({ client: {}, report: {}, showClientReport: false })
            }
            open={this.state.showClientReport}
          />
        </div>
      </div>
    );
  }
}

AccessDB.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tab: state.accessdb.tab,
  search: state.accessdb.search,
  start: state.accessdb.info.start,
  count: state.accessdb.info.count,
  hasMore: state.accessdb.info.hasMore,
  loading: state.accessdb.info.loading,
  clients: state.accessdb.list,
  error: state.accessdb.info.error,
});

export default connect(mapStateToProps)(AccessDB);
