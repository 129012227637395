import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import { ListItem } from 'material-ui/List';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

import Dialog from '../../../components/Dialog';

import { getAvailableHouseholds } from '../HouseholdAPI';
import HouseholdCard from '../components/HouseholdCard';
import { addReferralHousehold } from '../../Referral/ReferralActions';

class AttachHousehold extends Component {
  constructor() {
    super();
    this.state = { households: [], household: {} };
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.open && isEmpty(this.state.households)) {
      const options = {
        placementType: props.referral.placementType,
        servicesProvided: props.referral.servicesProvided,
      };
      getAvailableHouseholds(options).then(households => {
        this.setState({ households });
      });
    }
  }
  onClose() {
    this.setState({ household: {}, households: [] });
    this.props.closeDialog();
  }
  submit() {
    const { referral } = this.props; // NOTE just id
    const { household } = this.state;
    this.props
      .dispatch(
        addReferralHousehold(
          { _id: referral._id, household: household._id },
          household
        )
      )
      .then(() => {
        this.onClose();
      });
  }
  render() {
    const { household, households } = this.state;
    const { open } = this.props;
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onClose()}
        title="Select Household"
        actions={[
          <small>{household.name ? `Selected ${household.name}` : ''}</small>,
          <FlatButton label="Cancel" primary onClick={() => this.onClose()} />,
          <RaisedButton
            label="Select"
            primary
            disabled={isEmpty(household)}
            onClick={() => this.submit()}
          />,
        ]}
      >
        <ListItem>Selected {household.name}</ListItem>
        {households.map(h => (
          <HouseholdCard
            household={h}
            key={h._id}
            selected={household._id === h._id}
            attach={() => {
              this.setState({ household: h });
            }}
          />
        ))}
      </Dialog>
    );
  }
}

AttachHousehold.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(AttachHousehold);
