import moment from 'moment';

const editable = true;
const filterable = true;

const months = moment.months();

function getYears() {
  const nextYear = new Date().getFullYear() + 1;
  const years = [];
  let yearToAdd = nextYear;
  while (yearToAdd >= 2000) {
    years.push(yearToAdd);
    // eslint-disable-next-line no-plusplus
    yearToAdd--;
  }
  return years;
}

export default [
  {
    field: 'dateRecorded',
    name: 'Date Recorded',
    type: 'date',
    section: 'info',
  },
  {
    field: 'title',
    name: 'Title',
    type: 'text',
    section: 'info',
    required: true,
    editable,
  },
  {
    field: 'category',
    name: 'Category',
    type: 'select',
    useKeywords: true,
    section: 'info',
    required: true,
    default: 'General',
    editable,
    filterable,
  },
  {
    field: 'communication',
    name: 'Communication',
    type: 'select',
    useKeywords: true,
    section: 'info',
    editable,
    filterable,
  },
  {
    field: 'homeVisitMonth',
    name: 'Home Visit Month',
    type: 'select',
    options: months,
    default: months[new Date().getMonth()],
    section: 'info',
    required: true,
  },
  {
    field: 'homeVisitYear',
    name: 'Home Visit Year',
    type: 'select',
    options: getYears(),
    default: new Date().getFullYear(),
    section: 'info',
    required: true,
  },
  {
    field: 'incidentReporting',
    name: 'Incident Reporting',
    type: 'select',
    useKeywords: true,
    section: 'info',
    editable,
    filterable,
  },
  {
    field: 'concernInCare',
    name: 'Concern In Care',
    type: 'check',
    section: 'info',
    editable,
  },
  {},
  {
    field: 'qualityOfCare',
    name: 'Quality of Care',
    type: 'check',
    section: 'info',
    editable,
  },
  {
    field: 'sensitive',
    name: 'Sensitive Record',
    type: 'check',
    section: 'content',
    editable,
  },
  {
    field: 'note',
    name: 'Note',
    type: 'text',
    multiLine: true,
    section: 'content',
    required: true,
    editable,
  },
];
