import callApi from '../../util/apiCaller';
import { addReadLog } from '../Log/LogAPI';

export const DEMO_MESSAGE = 'app/DEMO_MESSAGE';
export const NOTIFICATION = 'app/NOTIFICATION';
export const ROUTE_CHANGED = 'app/ROUTE_CHANGED';
export const PARAMS = 'app/PARAMS';
export const LAST_CHANGE = 'app/LAST_CHANGE';
export const LOADING = 'app/LOADING';
export const ADD_KEYWORDS = 'app/ADD_KEYWORDS';
export const UPDATE_RECENT = 'app/UPDATE_RECENT';
export const REPORT_PROGRESS = 'app/REPORT_PROGRESS';

export function toggleLoading() {
  return {
    type: LOADING,
  };
}

export function updateRecent(path, initial = false) {
  return {
    type: UPDATE_RECENT,
    path,
    initial,
  };
}

export function notification(message, label, action) {
  return {
    type: NOTIFICATION,
    message,
    messageDuration: 10000,
    messageAction: label || '',
    messageOnActionClick: action || '',
  };
}

export function routeChanged(path) {
  const splitPath = path.split('/');
  if (splitPath.length > 2 && splitPath[2].length === 24) {
    let recordType = `${splitPath[1]
      .charAt(0)
      .toUpperCase()}${splitPath[1].slice(1)}`;
    if (recordType === 'Prospect') recordType = 'Carer';
    if (recordType === 'Young') recordType = 'YoungPerson';
    addReadLog(splitPath[2], recordType);
  }
  return {
    type: ROUTE_CHANGED,
    path,
  };
}

export function addKeywords(keywords) {
  return {
    type: ADD_KEYWORDS,
    keywords,
  };
}

export function getDemoMessage() {
  return dispatch => {
    callApi('demo').then(message => {
      dispatch(notification(message.text));
    });
  };
}

export function getTestError() {
  return dispatch => {
    callApi('demo/error').then(message => {
      if (message) dispatch(notification(message.text));
      else dispatch(notification("Can't load request", 'Dismiss'));
    });
  };
}

export function addLog(_id, action, body) {
  return () => {
    callApi(`log/${action}/${_id}`, 'PUT', body);
  };
}
export function setAppParams(params) {
  return {
    type: PARAMS,
    params,
  };
}

export function setReportProgress(percent) {
  return {
    type: REPORT_PROGRESS,
    percent,
  };
}

export function getStats(from, to) {
  return () =>
    callApi(
      `dashboard/stats?from=${encodeURIComponent(from)}&to=${encodeURIComponent(
        to
      )}`
    );
}

export function getCounts() {
  return () => callApi('dashboard/counts');
}

export function getConcerns() {
  return () => callApi('dashboard/concerns');
}

export function refreshReports() {
  return dispatch => {
    dispatch(toggleLoading());
    return callApi('report/all').then(() => {
      dispatch(notification('Reports are being updated.'));
      dispatch(toggleLoading());
    });
  };
}

export function getCIC(from, to) {
  return () =>
    callApi(
      `dashboard/concerns/cic?from=${encodeURIComponent(
        from
      )}&to=${encodeURIComponent(to)}`
    );
}

export function fetchKeywords(body) {
  return dispatch =>
    callApi('keyword').then(keywords => {
      dispatch(addKeywords(keywords));
    });
}

export function updateKeywords(body) {
  return dispatch =>
    callApi('keyword', 'POST', body).then(() => dispatch(fetchKeywords(body)));
}
export function onRemoteAction(data, dispatch) {
  switch (data.type) {
    case 'UPDATE_REPORTS':
      dispatch(setReportProgress(data.percent));
      break;
    default:
      dispatch(data);
  }
}
