import React, { Component } from 'react';
import moment from 'moment';

import { ListItem } from 'material-ui/List';
import DatePicker from 'material-ui/DatePicker';

import NoteCard from '../../Placement/components/NoteCard';

class CIC extends Component {
  render() {
    const datePickerSettings = {
      fullWidth: true,
      formatDate: d => moment(d).format('D MMMM YYYY'),
      autoOk: true,
      firstDayOfWeek: 1,
      style: { display: 'inline-block', width: '50%', padding: 5 },
      openToYearSelection: true,
    };
    const { cic, from, to, fromUpdate, toUpdate } = this.props;
    return cic ? (
      <div className="dash-col">
        <ListItem
          style={{ backgroundColor: 'white' }}
          primaryText={`${cic.length} Concerns in Care`}
          disabled
        />
        <div style={{ backgroundColor: 'white' }}>
          <DatePicker
            {...datePickerSettings}
            id="concernFrom"
            floatingLabelText="From"
            value={from}
            onChange={(e, concernFrom) => {
              fromUpdate(concernFrom);
              this.concernTo.openDialog();
            }}
          />
          <DatePicker
            ref={e => {
              this.concernTo = e;
            }}
            {...datePickerSettings}
            id="concernTo"
            floatingLabelText="To"
            value={to}
            onChange={(e, concernTo) => {
              toUpdate(concernTo);
            }}
          />
        </div>
        {cic.length ? (
          cic.map(n => (
            <NoteCard
              key={n._id}
              note={n}
              caseLoad={n.case}
              household={n.household}
              readOnly
              goToPlacement
            />
          ))
        ) : (
          <ListItem
            disabled
            style={{ backgroundColor: 'white' }}
            primaryText="No Concerns in Care"
          />
        )}
      </div>
    ) : (
      <div className="dash-col">
        <ListItem
          disabled
          style={{ backgroundColor: 'white' }}
          primaryText="No Concerns in Care"
        />
      </div>
    );
  }
}

export default CIC;
