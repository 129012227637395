import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import isDate from 'lodash/isDate';
import compact from 'lodash/compact';
import findIndex from 'lodash/findIndex';
import xor from 'lodash/xor';
import filter from 'lodash/filter';
import find from 'lodash/find';

import Avatar from 'material-ui/Avatar';
import IconButton from 'material-ui/IconButton';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import MenuItem from 'material-ui/MenuItem';
import { List, ListItem } from 'material-ui/List';

import Dialog from '../../../components/Dialog';
import Attachment from '../../../components/Attachment';
import Form from '../../../components/Form';
import SelectField from '../../../components/SelectField';

import noteModel from '../NoteModel';
import { addPlacementNote } from '../PlacementActions';
import { getAccommodation } from '../PlacementAPI';
import { addHouseholdNote } from '../../Household/HouseholdActions';
import { uploadFile } from '../../../util/UtilAPI';

import { icons, colours } from '../../../theme';

function filterAccom(dateRecorded, accommodation) {
  return filter(accommodation, a => {
    const dateStart = new Date(a.dateStart);
    const dateEnd = a.dateEnd ? new Date(a.dateEnd) : dateRecorded;
    return dateStart <= dateRecorded && dateEnd >= dateRecorded;
  });
}

class NoteAdd extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.model = noteModel;
  }
  getInitialState() {
    const {
      caseLoad,
      accommodation,
      youngPerson,
      primaryCarer,
      secondaryCarer,
      householdMembers,
      staff,
    } = this.props;
    const dateRecorded = new Date().setHours(0, 0, 0, 0);
    return {
      dateRecorded,
      title: '',
      category: 'General',
      communication: '',
      incidentReporting: '',
      note: '',
      sensitive: false,
      valid: false,
      concernInCare: false,
      homeVisitMonth: '',
      homeVisitYear: null,
      caseLoad,
      people: {
        youngPerson,
        primaryCarer,
        secondaryCarer,
        householdMembers,
        staff,
      },
      accommodation: filterAccom(dateRecorded, accommodation),
      selected: youngPerson ? [youngPerson] : [],
      attachments: [],
      loading: false,
      error: false,
    };
  }
  onChange(val) {
    const key = Object.keys(val).join();
    const { homeVisitMonth, homeVisitYear } = this.state;
    let { accommodation } = this.state;
    const homeVisit = { homeVisitYear, homeVisitMonth };
    if (key === 'dateRecorded') {
      const dateRecorded = new Date(val.dateRecorded);
      accommodation = filterAccom(dateRecorded, this.props.accommodation);
    }
    if (key === 'communication') {
      if (val.communication === 'Home Visit') {
        this.model = noteModel.map(m => {
          if (['homeVisitYear', 'homeVisitMonth'].includes(m.field)) {
            if (!homeVisit[m.field]) homeVisit[m.field] = m.default;
            return { ...m, editable: true };
          }
          return m;
        });
      } else {
        homeVisit.homeVisitMonth = null;
        homeVisit.homeVisitYear = null;
        this.model = [...noteModel];
      }
    }
    this.setState({ accommodation, ...homeVisit, ...val }, () =>
      this.validate()
    );
  }
  onSubmit() {
    const {
      caseLoad,
      youngPerson,
      primaryCarer,
      secondaryCarer,
      householdMembers,
      household,
      dispatch,
    } = this.props;
    let { accommodation } = this.props;
    const {
      parents,
      siblings,
      doctors,
      siblingsInCare,
      emergencyContacts,
    } = youngPerson || {
      parents: [],
      siblings: [],
      doctors: [],
      siblingsInCare: [],
      emergencyContacts: [],
    };
    const { selected, attachments } = this.state;
    function personSelected(_id) {
      return findIndex(selected, s => s._id === _id) > -1;
    }
    const body = {
      carers: [],
      otherIndividuals: [],
      otherYoungPeople: [],
    };

    each(noteModel, n => {
      body[n.field] = this.state[n.field];
    });
    if (youngPerson && personSelected(youngPerson._id))
      body.youngPerson = youngPerson._id;
    if (primaryCarer && personSelected(primaryCarer._id))
      body.carers.push(primaryCarer._id);
    if (secondaryCarer && personSelected(secondaryCarer._id))
      body.carers.push(secondaryCarer._id);
    each(householdMembers, h => {
      if (personSelected(h._id)) body.otherIndividuals.push(h._id);
    });
    each([...parents, ...siblings, ...doctors, ...emergencyContacts], o => {
      if (personSelected(o._id)) body.otherIndividuals.push(o._id);
    });
    each(siblingsInCare, s => {
      if (personSelected(s._id)) body.otherYoungPeople.push(s._id);
    });
    this.setState({ loading: true, error: false });
    if (caseLoad) {
      const checkAccom = accommodation
        ? Promise.resolve()
        : getAccommodation(caseLoad._id).then(accom => {
            accommodation = accom;
            return;
          });
      Promise.resolve(checkAccom).then(() => {
        body.accommodation = accommodation[0]._id;
        dispatch(addPlacementNote(caseLoad._id, body)).then(
          note => {
            if (note) {
              if (!isEmpty(attachments)) {
                each(attachments, attachment => {
                  const pathToFix = attachment.path;
                  const path = pathToFix.replace('undefined', note._id);
                  const {
                    toUpload,
                    description,
                    recordType,
                    dateRecorded,
                    category,
                  } = attachment;
                  uploadFile(
                    toUpload,
                    path,
                    description,
                    recordType,
                    note._id,
                    dateRecorded,
                    category
                  );
                });
              }
              this.onDialogClose();
            } else this.setState({ loading: false, error: true });
          },
          () => this.setState({ loading: false, error: true })
        );
      });
    } else if (household) {
      body.household = household;
      dispatch(addHouseholdNote(household._id, body)).then(
        note => {
          if (note) {
            if (!isEmpty(attachments)) {
              each(attachments, attachment => {
                const pathToFix = attachment.path;
                const path = pathToFix.replace('undefined', note._id);
                const {
                  toUpload,
                  description,
                  recordType,
                  dateRecorded,
                  category,
                } = attachment;
                uploadFile(
                  toUpload,
                  path,
                  description,
                  recordType,
                  note._id,
                  dateRecorded,
                  category
                );
              });
            }
            this.onDialogClose();
          } else this.setState({ loading: true, error: false });
        },
        () => this.setState({ loading: false, error: true })
      );
    }
  }
  onDialogClose() {
    this.setState(this.getInitialState());
    this.props.closeDialog();
  }
  validate() {
    let valid = true;
    const { state } = this;
    each(noteModel, n => {
      if (n.required && n.editable && valid) {
        valid = !isEmpty(state[n.field]) || isDate(state[n.field]);
      }
    });
    if (state.accommodation.length > 1) {
      valid = false;
    }
    this.setState({ valid });
  }
  renderAccomSelect() {
    const { accommodation } = this.state;
    const tooManyAccom = accommodation.length > 1;
    return (
      <List>
        {accommodation.map(a => (
          <ListItem
            leftAvatar={
              <Avatar
                backgroundColor={tooManyAccom ? colours.kRed : ''}
                icon={<icons.HomeIcon />}
              />
            }
            primaryText={a.household ? a.household.name : a.alternate}
            secondaryText={
              !tooManyAccom
                ? `${moment(a.dateStart).format('D MMMM YYYY')} - ${
                    a.dateEnd
                      ? moment(a.dateEnd).format('D MMMM YYYY')
                      : 'Present'
                  }`
                : 'Remove One Household'
            }
            key={a._id}
            disabled
            rightIconButton={
              tooManyAccom ? (
                <IconButton
                  onClick={() =>
                    this.setState(
                      { accommodation: xor(accommodation, [a]) },
                      () => this.validate()
                    )
                  }
                >
                  <icons.CloseIcon color={colours.kRed} />
                </IconButton>
              ) : (
                <IconButton disabled />
              )
            }
          />
        ))}
      </List>
    );
  }
  renderPeopleSelect() {
    const {
      youngPerson,
      primaryCarer,
      secondaryCarer,
      householdMembers,
    } = this.props;
    const { parents, siblings, doctors, siblingsInCare, emergencyContacts } =
      youngPerson || {};
    const { selected } = this.state;
    const people = compact([
      ...([youngPerson] || []),
      primaryCarer,
      secondaryCarer,
      ...(householdMembers || []),
      ...(parents || []),
      ...(siblings || []),
      ...(siblingsInCare || []),
      ...(emergencyContacts || []),
      ...(doctors || []),
    ]).sort(p => p.contact.fullName);
    return (
      <SelectField
        create
        param={{
          field: 'people',
          name: 'People',
          editiable: true,
          required: true,
          multiLine: true,
          multiSelect: true,
        }}
        value={selected}
        options={people.map(p => (
          <MenuItem
            key={p._id}
            value={p}
            primaryText={p.contact.fullName}
            checked={!!find(selected, select => select._id === p._id)}
            insetChildren
          />
        ))}
        onUpdated={val => this.setState({ selected: val })}
      />
    );
  }
  render() {
    const { valid, attachments, caseLoad, loading, error } = this.state;
    const { open } = this.props;
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title="Add Note"
        style={{
          width: '90%',
          maxWidth: '1200px',
        }}
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <FlatButton
            label="Cancel"
            primary
            onClick={() => this.onDialogClose()}
          />,
          <RaisedButton
            label="Submit"
            primary
            disabled={loading || !valid}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() => this.onSubmit()}
          />,
        ]}
      >
        <Form
          caseLoad={caseLoad}
          create
          edit
          model={this.model}
          object={this.state}
          section="info"
          onUpdated={val => this.onChange(val)}
        />
        <Form
          create
          edit
          model={this.model}
          object={this.state}
          section="content"
          onUpdated={val => this.onChange(val)}
        />
        <Attachment
          newRecord
          recordType="Note"
          onFileSubmit={attachment =>
            this.setState({
              attachments: [...attachments, attachment],
            })
          }
          filesToUpload={attachments}
        />
        {this.renderPeopleSelect()}
        {this.renderAccomSelect()}
      </Dialog>
    );
  }
}

NoteAdd.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(NoteAdd);
