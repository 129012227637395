import keys from 'lodash/keys';
import find from 'lodash/find';

import CarerModel from './CarerModel';

import * as ProspectAPI from './ProspectAPI';

import { notification } from '../App/AppActions';
import { addCarerHousehold } from '../Household/HouseholdAPI';

export const RENDER_PROSPECTS = 'prospect/RENDER_PROSPECTS';
export const LOADING_PROSPECTS = 'prospect/LOADING_PROSPECTS';
export const DISPLAY_PROSPECT = 'prospect/DISPLAY_PROSPECT';
export const UPDATE_PROSPECT = 'prospect/UPDATE_PROSPECT';
export const CLEAR_SEARCH = 'prospect/CLEAR_SEARCH';
export const ADD_PROSPECT_HOUSEHOLD = 'prospect/ADD_PROSPECT_HOUSEHOLD';
export const DELETE_PROSPECT = 'prospect/DELETE_PROSPECT';
export const CHANGE_TAB = 'prospect/CHANGE_TAB';
export const PROSPECTS_ERROR = 'prospect/PROSPECTS_ERROR';

export function changeTab(tab) {
  return {
    type: CHANGE_TAB,
    tab,
  };
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

export function renderProspects(text, tab, tabChanged, prospects) {
  return {
    type: RENDER_PROSPECTS,
    text,
    tab,
    tabChanged,
    prospects,
  };
}

export function getProspect(prospect) {
  return {
    type: DISPLAY_PROSPECT,
    prospect,
  };
}

export function updateProspect(prospect, body, detail) {
  return {
    type: UPDATE_PROSPECT,
    prospect,
    body,
    detail,
  };
}

export function loadMore(start) {
  return {
    type: LOADING_PROSPECTS,
    start,
  };
}

export function addHousehold(prospect, household) {
  return {
    type: ADD_PROSPECT_HOUSEHOLD,
    prospect,
    household,
  };
}

export function _deleteProspect(prospect) {
  return {
    type: DELETE_PROSPECT,
    prospect,
  };
}

export function setError(error = false) {
  return {
    type: PROSPECTS_ERROR,
    error,
  };
}

export function searchProspects(text, start, count, type, tabChanged) {
  return dispatch => {
    dispatch(loadMore(start));
    return ProspectAPI.searchProspects(text, start, count, type).then(
      prospects => dispatch(renderProspects(text, type, tabChanged, prospects)),
      err => dispatch(setError(err || true))
    );
  };
}

export function fetchProspect(_id) {
  return dispatch =>
    ProspectAPI.fetchProspect(_id).then(
      prospect => {
        if (prospect) {
          return dispatch(getProspect(prospect));
        }
        return dispatch(getProspect({ deleted: true }));
      },
      () => Promise.reject()
    );
}

export function modifyProspect(
  _id,
  body,
  prevVal,
  undo = false,
  detail = true
) {
  return dispatch =>
    ProspectAPI.updateProspect(_id, body).then(() => {
      dispatch(updateProspect(_id, body, detail));
      if (!undo) {
        dispatch(
          notification(
            `${find(CarerModel, { field: keys(body)[0] }).name} updated.`,
            'UNDO',
            modifyProspect(_id, prevVal, '', true)
          )
        );
      } else {
        dispatch(
          notification(
            `Changes to ${
              find(CarerModel, { field: keys(body)[0] }).name
            } undone.`
          )
        );
      }
    });
}

export function submitProspect(body) {
  return dispatch =>
    ProspectAPI.addProspect(body).then(prospect => {
      dispatch(getProspect(prospect));
      return prospect;
    });
}

export function createProspectHousehold(prospect) {
  return dispatch =>
    addCarerHousehold(prospect).then(household => {
      dispatch(addHousehold(prospect, household));
    });
}

export function deleteProspect(prospect) {
  return dispatch =>
    ProspectAPI.deleteProspect(prospect).then(() =>
      dispatch(_deleteProspect(prospect))
    );
}
