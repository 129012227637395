import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import get from 'lodash/get';

import { ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar';

import { colours, icons } from '../../../theme';

import SvgIcon from 'material-ui/SvgIcon';

import SVGIcons from '../SVGIcons.js';

function getIcon(pathname, position, className, color) {
  const i = {
    kinship_carer: (
      <SvgIcon className={className} color={color}>
        <path d={SVGIcons.kinship_carer} />
      </SvgIcon>
    ),
    foster_carer: (
      <SvgIcon className={className} color={color}>
        <path d={SVGIcons.foster_carer} />
      </SvgIcon>
    ),
    respite_carer: (
      <SvgIcon className={className} color={color}>
        <path d={SVGIcons.respite_carer} />
      </SvgIcon>
    ),
    respite_foster_carer: (
      <SvgIcon className={className} color={color}>
        <path d={SVGIcons.respite_foster_carer} />
      </SvgIcon>
    ),
  };

  return i[pathname] || <icons.HomeIcon className={className} color={color} />;
}

class HouseholdCard extends Component {
  static filterPlacements(cases) {
    return (
      filter(
        cases,
        c =>
          new Date(c.kennerleyEntryDate || c.created) <= new Date() &&
          (!c.leftCareDate || new Date(c.leftCareDate) >= new Date())
      ) || []
    );
  }
  static getVacancies(props) {
    const { fosterCapacity, respiteCapacity, cases } = props.household;
    // let vacancies = 'None';
    const casesGrouped = groupBy(cases, 'careType');
    const foster = HouseholdCard.filterPlacements(casesGrouped['Foster Care'])
      .length;
    const respite = HouseholdCard.filterPlacements(casesGrouped['Respite Care'])
      .length;
    const vacancies = `Vacancies Filled Foster: ${foster}/${fosterCapacity}; Respite: ${respite}/${respiteCapacity}`;
    const placements = `Placements: ${cases ? cases.length : 0}`;
    const full =
      foster > fosterCapacity ||
      respite > respiteCapacity ||
      (foster === fosterCapacity && respite === respiteCapacity);
    return { full, placements, vacancies };
  }
  constructor(props) {
    super(props);
    this.state = HouseholdCard.getVacancies(props);
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ ...HouseholdCard.getVacancies(props) });
  }
  // renderColour() {
  //   const { household } = this.props;
  //   const { primaryCarer, secondaryCarer } = household;
  //   const colour = colours.skyBlue;

  //   console.log(colour);
  //   return colour || primaryCarer || secondaryCarer;
  // }

  assessPerson(person, currentColour, secondary = false, oI = false) {
    const now = new Date();
    const next2Month = new Date();
    next2Month.setMonth(next2Month.getMonth() + 2);
    const { household } = this.props;
    const {
      wwvpCheck,
      pcResults,
      cscResults,
      approvalDate,
      status,
      dateClosed,
      dateInactive,
      wwvpcExpiry,
      pcReviewDate,
      reviewDue,
      scocExpiry,
    } = person;
    if (currentColour !== colours.kGrey) {
      if (
        (dateClosed || dateInactive) &&
        !secondary &&
        !oI &&
        (status === 'Temporarily Inactive' ||
          status === 'Withdrawn' ||
          status === 'Not Suitable' ||
          status === 'Transferred' ||
          status === 'Deregistered' ||
          status === 'Closed')
      )
        return colours.kGrey;
      if (currentColour !== colours.kRed) {
        if (
          get(household, 'careType', []).includes('Family Connections Program')
        ) {
          return colours.brightGreen;
        }
        if (
          !wwvpCheck ||
          !pcResults ||
          !cscResults ||
          !wwvpcExpiry ||
          (!reviewDue && !oI) ||
          (!scocExpiry && !oI) ||
          new Date(pcReviewDate) <= now ||
          new Date(wwvpcExpiry) <= now ||
          (new Date(reviewDue) <= now && !oI) ||
          (new Date(scocExpiry) <= now && !oI)
        ) {
          return colours.kRed;
        }
        if (currentColour !== colours.orange) {
          if (
            new Date(pcReviewDate) <= next2Month ||
            new Date(wwvpcExpiry) <= next2Month ||
            (new Date(reviewDue) <= next2Month && !oI)
          ) {
            return colours.orange;
          }
          if (!oI && approvalDate) return colours.brightGreen;
        }
      }
    }
    return currentColour;
  }
  renderColour() {
    const { household } = this.props;
    let colour = colours.skyBlue;
    if (household.primaryCarer)
      colour = this.assessPerson(household.primaryCarer, colour);
    if (household.secondaryCarer)
      colour = this.assessPerson(household.secondaryCarer, colour, true);
    household.otherIndividuals.forEach(oi => {
      colour = this.assessPerson(oi, colour, false, true);
    });
    return colour;
  }

  renderIcon(position, className, colour) {
    const { household } = this.props;
    let icon;

    if (household.kinship === true) {
      icon = getIcon('kinship_carer', position, className, colour);
    } else if (
      household.careType.includes('Respite Care') &&
      household.careType.includes('Foster Care')
    ) {
      icon = getIcon('respite_foster_carer', position, className, colour);
    } else if (household.careType.includes('Respite Care')) {
      icon = getIcon('respite_carer', position, className, colour);
    } else if (household.careType.includes('Foster Care')) {
      icon = getIcon('foster_carer', position, className, colour);
    } else {
      icon = getIcon('fubar', position, className, colour);
    }

    return icon;
  }

  render() {
    const colour = this.renderColour();

    const {
      household,
      attach,
      selected,
      caller,
      rightIconButton,
      onClick,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { full, placements, vacancies } = this.state;
    const communityHousehold =
      household.careType.length === 1 &&
      household.careType.includes('Community Respite Care');
    const backIcon = this.renderIcon('right', 'person-card-back', colour);
    const icon = this.renderIcon('left');

    const secondaryText =
      caller === 'dashboard' ? (
        household.staff ? (
          `Coordinator: ${get(household, 'staff.contact.fullName')} `
        ) : (
          'Coordinator: None'
        )
      ) : household.householdType === 'Community' ? (
        <span style={{}}> {placements} </span>
      ) : (
        <span style={full ? { color: colours.kRed } : {}}> {vacancies} </span>
      );

    return (
      <div className="person-card">
        {backIcon}
        <ListItem
          primaryText={`${household.name}${
            communityHousehold ? ' (Community Respite)' : ''
          }`}
          secondaryText={secondaryText}
          leftAvatar={
            <Avatar
              icon={selected ? <icons.SelectedIcon /> : icon}
              backgroundColor={colours.kGrey}
            />
          }
          style={{ borderLeft: `5px solid ${colour}` }}
          containerElement={
            !attach && !rightIconButton ? (
              <Link to={`/household/${household._id}`} />
            ) : (
              <span />
            )
          }
          onClick={attach || onClick || null}
          rightIconButton={rightIconButton}
        />
      </div>
    );
  }
}

export default HouseholdCard;
