import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import moment from 'moment';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';

import Dialog from '../../../components/Dialog';
import Form from '../../../components/Form';
// import DatePicker from '../../../components/DatePicker';

import {
  fetchViewAndWish,
  updateViewAndWish,
  deleteViewAndWish,
  exitViewAndWish,
} from '../YoungPersonActions';
import viewandwishModel from '../ViewAndWishModel';
import Attachment from '../../../components/Attachment';
import DeletePrompt from '../../../components/DeletePrompt';

import { /*icons,*/ colours } from '../../../theme';
import deepCompare from '../../../util/deepCompare';

class ViewAndWishDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { viewandwish: '' };
    this.onChange = debounce(this.onChange.bind(this), 800);
  }
  shouldComponentUpdate(props, state) {
    const { viewandwishDetail, open } = this.props;
    if (!deepCompare(state, this.state)) return true;
    if (!deepCompare(props.viewandwishDetail, viewandwishDetail)) return true;
    if (props.open !== open) return true;
    return false;
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (!isEmpty(props.viewandwishDetail))
      this.setState({ viewandwish: props.viewandwishDetail });
    else if (props.open && !this.state.viewandwish) {
      this.props.dispatch(
        fetchViewAndWish(props.viewandwish.youngPerson, props.viewandwish._id)
      );
    } else if (!props.open) this.setState({ viewandwish: '' });
  }
  onChange(val) {
    const { viewandwish } = this.state;
    const key = Object.keys(val).join();
    const prevVal = { [key]: viewandwish[key] };
    return this.props.dispatch(
      updateViewAndWish(viewandwish.youngPerson, viewandwish._id, val, prevVal)
    );
  }
  onDialogClose() {
    const { dispatch, closeDialog } = this.props;
    dispatch(exitViewAndWish());
    closeDialog();
  }
  onDelete() {
    const { viewandwish } = this.state;
    return this.props
      .dispatch(deleteViewAndWish(viewandwish.youngPerson, viewandwish._id))
      .then(() => this.onDialogClose());
  }

  render() {
    const { open, user /*readOnly*/ } = this.props;
    const { viewandwish, showDelPro } = this.state;
    let dialogBody = '';
    let title = 'Loading...';
    if (viewandwish) {
      title = viewandwish.title; // eslint-disable-line prefer-destructuring
      dialogBody = (
        <div>
          <Form
            edit
            model={viewandwishModel}
            object={viewandwish}
            section="info"
            onUpdated={val => this.onChange(val)}
          />
          <Form
            edit
            model={viewandwishModel}
            object={viewandwish}
            section="content"
            onUpdated={val => this.onChange(val)}
          />
          <Attachment
            admin={
              user.roles.includes('admin') || user.roles.includes('moderator')
            }
            // readOnly={readOnly}
            record={viewandwish._id}
            recordType="ViewAndWish"
            category="Views and Wishes"
          />
        </div>
      );
    }
    const style = {
      marginLeft: 12,
    };
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title={title}
        actions={[
          (user.roles.includes('admin') || user.roles.includes('moderator')) &&
            viewandwish && (
              <FlatButton
                label="Delete"
                primary
                style={{
                  ...style,
                  backgroundColor: colours.kRed,
                  color: 'white',
                }}
                onClick={() => this.setState({ showDelPro: true })}
              />
            ),
          <RaisedButton
            label="Close"
            primary
            style={style}
            onClick={() => this.onDialogClose()}
          />,
        ]}
      >
        {viewandwish ? dialogBody : ''}
        <DeletePrompt
          closeDialog={() => this.setState({ showDelPro: false })}
          open={!!showDelPro}
          delete={() => this.onDelete()}
        />
      </Dialog>
    );
  }
}

ViewAndWishDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  viewandwishDetail: state.young.viewandwishDetail,
  user: state.user.profile,
});

export default connect(mapStateToProps)(ViewAndWishDetail);
