import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import extend from 'lodash/extend';
import debounce from 'lodash/debounce';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import isDate from 'lodash/isDate';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import { List, ListItem } from 'material-ui/List';
import { Step, Stepper, StepLabel } from 'material-ui/Stepper';

import carerModel from '../CarerModel';
import { submitProspect } from '../ProspectActions';
import { createSecondaryCarer } from '../../Household/HouseholdActions';
import { contactOptDOBModel as contactModel } from '../../Contact/ContactModel';
import { findContacts } from '../../Contact/ContactAPI';
import ContactCard from '../../Contact/components/ContactCard';
import Form from '../../../components/Form';
import Dialog from '../../../components/Dialog';

import { colours } from '../../../theme';

class CarerAdd extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(!!props.prospect);
    this.findExistingContacts = debounce(
      this.findExistingContacts.bind(this),
      800
    );
  }
  getInitialState(prospect) {
    const { household } = this.props;
    const carer = {};
    const contact = { userType: 'carer', prospect };
    each(carerModel, c => {
      carer[c.field] = '';
      if (c.multiSelect) carer[c.field] = [];
      if (c.type === 'check') carer[c.field] = false;
    });
    each(contactModel, c => {
      contact[c.field] = '';
      if (c.multiSelect) contact[c.field] = [];
      if (c.field === 'gender') contact[c.field] = 'Not Specified';
      if (c.type === 'address') {
        if (household) {
          contact[c.field] = household.address;
        } else contact[c.field] = {};
      }
    });
    return {
      carer,
      contact,
      sections: {},
      stepIndex: 0,
      redirect: '',
      suggestions: [],
      doNotCheckSemiRequired: !!household,
      valid: false,
      loading: false,
      error: false,
    };
  }
  onChange(val, object) {
    this.setState({ [object]: extend(this.state[object], val) });
    if (object === 'contact') {
      this.findExistingContacts();
    }
    this.validate();
  }
  onDialogClose() {
    const { prospect, closeDialog } = this.props;
    this.setState(this.getInitialState(!!prospect));
    closeDialog();
  }
  getStepContent() {
    const { suggestions, stepIndex, contact, carer } = this.state;
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <List>
              <ListItem
                primaryTogglesNestedList
                nestedItems={suggestions.map(s => (
                  <ListItem
                    key={s._id}
                    primaryTogglesNestedList
                    nestedItems={[
                      <ListItem disabled key={`${s._id}-card`}>
                        <ContactCard
                          contact={s}
                          readOnly
                          redirectTo={`/prospect/${s.carer}`}
                        />
                      </ListItem>,
                    ]}
                  >
                    {s.fullName}
                  </ListItem>
                ))}
                style={{
                  backgroundColor:
                    !isEmpty(suggestions) && colours.paleLimeGreen,
                }}
              >
                Suggestions{!isEmpty(suggestions)
                  ? ` (${suggestions.length})`
                  : ''}
              </ListItem>
            </List>
            <Form
              create
              model={contactModel}
              object={contact}
              onUpdated={val => this.onChange(val, 'contact')}
              carer
            />
          </div>
        );
      case 1:
        return (
          <div>
            <Form
              create
              model={carerModel}
              object={carer}
              section="enquiry"
              onUpdated={val => this.onChange(val, 'carer')}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <Form
              create
              model={carerModel}
              object={carer}
              section="assessment"
              onUpdated={val => this.onChange(val, 'carer')}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <Form
              create
              model={carerModel}
              object={carer}
              section="checks"
              onUpdated={val => this.onChange(val, 'carer')}
            />
          </div>
        );
      default:
        return null;
    }
  }
  validate() {
    const { stepIndex, contact } = this.state;
    let valid = true;
    if (stepIndex === 0) {
      let doNotCheckSemiRequired = false;
      each(contactModel, c => {
        if (c.semiRequired && !doNotCheckSemiRequired) {
          if (c.type === 'address' && !isEmpty(contact[c.field])) {
            const address = contact[c.field];
            doNotCheckSemiRequired =
              address.street_number &&
              address.route &&
              address.locality &&
              address.administrative_area_level_1 &&
              address.country &&
              address.postal_code;
          } else
            doNotCheckSemiRequired =
              !isEmpty(contact[c.field]) || isDate(contact[c.field]);
        }
        if (c.required && valid) {
          valid = !isEmpty(contact[c.field]) || isDate(contact[c.field]);
          if (c.type === 'address' && valid) {
            const address = contact[c.field];
            valid =
              address.street_number &&
              address.route &&
              address.locality &&
              address.administrative_area_level_1 &&
              address.country &&
              address.postal_code;
          }
        }
      });
      this.setState({ doNotCheckSemiRequired });
      if (!doNotCheckSemiRequired) valid = false;
    }
    this.setState({ valid });
  }
  handleNext(lastPage) {
    const { stepIndex, contact, carer } = this.state;
    const { prospect, household } = this.props;
    if (stepIndex === lastPage) {
      this.setState({ loading: true, error: false });
      if (household) {
        this.props
          .dispatch(createSecondaryCarer(household._id, { contact, carer }))
          .then(
            () => {
              this.setState({
                loading: false,
              });
              this.onDialogClose();
            },
            () => this.setState({ loading: false, error: true })
          );
      } else {
        this.props.dispatch(submitProspect({ ...carer, contact })).then(
          newCarer => {
            if (newCarer) {
              this.setState({
                loading: false,
                redirect: (
                  <Redirect
                    to={`/${prospect ? 'prospect' : 'carer'}/${newCarer._id}`}
                  />
                ),
              });
              this.onDialogClose();
            }
            this.setState({ loading: false, error: true });
          },
          () => this.setState({ loading: false, error: true })
        );
      }
    } else {
      this.setState({ stepIndex: stepIndex + 1 }, () => this.validate());
    }
  }
  handlePrev() {
    const { stepIndex } = this.state;
    if (stepIndex === 0) {
      this.onDialogClose();
      return;
    }
    this.setState({ stepIndex: stepIndex - 1 });
  }
  findExistingContacts() {
    const { givenName, surname, aka, email } = this.state.contact;
    const contact = {};
    if (givenName) contact.givenName = givenName;
    if (surname) contact.surname = surname;
    if (aka) contact.aka = aka;
    if (email) contact.email = email;
    if (!isEmpty(contact)) {
      findContacts(contact, 'carer').then(suggestions => {
        this.setState({ suggestions });
      });
    }
  }
  render() {
    const {
      stepIndex,
      valid,
      redirect,
      doNotCheckSemiRequired,
      loading,
      error,
    } = this.state;
    const { prospect, open } = this.props;
    const lastPage = prospect ? 1 : 3;
    if (redirect) return redirect;
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title={`Add ${prospect ? 'Prospect' : 'Carer'}`}
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <small
            style={{
              color: !doNotCheckSemiRequired ? colours.kRed : colours.kGrey,
            }}
          >
            Email, Mobile, Phone or Residential Address required
          </small>,
          <FlatButton
            label={stepIndex === 0 ? 'Cancel' : 'Back'}
            primary
            onClick={() => this.handlePrev()}
          />,
          <RaisedButton
            label={stepIndex === lastPage ? 'Submit' : 'Next'}
            primary
            disabled={loading || !valid}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() => this.handleNext(lastPage)}
          />,
        ]}
      >
        <Stepper activeStep={stepIndex}>
          <Step>
            <StepLabel>Contact</StepLabel>
          </Step>
          <Step>
            <StepLabel>Enquiry</StepLabel>
          </Step>
          {!prospect && (
            <Step>
              <StepLabel>Assessments</StepLabel>
            </Step>
          )}
          {!prospect && (
            <Step>
              <StepLabel>Checks</StepLabel>
            </Step>
          )}
        </Stepper>
        {this.getStepContent()}
      </Dialog>
    );
  }
}

CarerAdd.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(CarerAdd);
