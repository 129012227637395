import callApi from '../../util/apiCaller';

// eslint-disable-next-line import/prefer-default-export
export function searchClients(text, start, count, type) {
  return callApi(`accessdb/client?start=${start}&count=${count}`, 'POST', {
    text,
    type,
  });
}

export function fetchFullIndividualClientReport(client) {
  return callApi(`accessdb/report/ficr?`, 'POST', client);
}
