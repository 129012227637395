const editable = true;

export default [
  {
    field: 'contact',
    name: 'Contact Details',
    type: 'check',
    default: true,
    section: 'options',
    editable: false,
  },
  {
    field: 'details',
    name: 'Details',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'regulationOfCare',
    name: 'Regulation of Care',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'initialEnquiry',
    name: 'Initial Enquiry',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'assessmentAndTraining',
    name: 'Assessments and Training',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'family',
    name: 'Family',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'placements',
    name: 'Placements',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'support',
    name: 'Support',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'notes',
    name: 'Notes',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'attachments',
    name: 'Attachments',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
];
