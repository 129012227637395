import callApi, { callDownloadApi } from '../../util/apiCaller';

export function searchHousehold(text, start, count, type) {
  return callApi(`household?start=${start}&count=${count}`, 'POST', {
    text,
    type,
  });
}

export function fetchHousehold(_id) {
  return callApi(`household/${_id}`);
}

export function addCarerHousehold(carer) {
  return callApi(`household/add/carer/${carer}`);
}

export function getHousehold(household) {
  return callApi(`household/${household}`);
}

export function getHouseholds() {
  return callApi('household');
}

export function addHouseholdMember(household, member) {
  return callApi(`household/${household}/members`, 'PUT', member);
}

export function updateHouseholdMember(household, member, body) {
  return callApi(`household/${household}/members/${member}`, 'POST', body);
}

export function deleteHouseholdMember(household, member) {
  return callApi(`household/${household}/members/${member}`, 'DELETE');
}

export function addHouseholdSecondaryCarer(household, carer) {
  return callApi(`household/${household}/secondary`, 'PUT', carer);
}

export function getAvailableHouseholds(body) {
  // return callApi(`household/availability/${placementType}`);
  return callApi(`household/availability`, 'POST', body);
}

export function getAccommodation(id) {
  return callApi(`household/${id}/accommodation`);
}

export function getPlacements(id) {
  return callApi(`household/${id}/cases`);
}

export function updateHousehold(_id, body) {
  return callApi(`household/${_id}`, 'POST', body);
}

export function assignCoordinator(id, body) {
  return callApi(`household/${id}/coordinator`, 'POST', body);
}

export function deleteHousehold(_id) {
  return callApi(`household/${_id}`, 'DELETE');
}

export function createHouseholdNote(id, body) {
  return callApi(`household/${id}/note`, 'PUT', body);
}

export function getNotes(id, print) {
  return callApi(`household/${id}/note?print=${print}`);
}

export function filterHouseholdNotes(id, body) {
  return callApi(`household/${id}/note`, 'POST', body);
}

export function getNote(caseId, noteId) {
  return callApi(`household/${caseId}/note/${noteId}`);
}

export function updateNote(caseId, noteId, body) {
  return callApi(`household/${caseId}/note/${noteId}`, 'POST', body);
}

export function deleteNote(_id, noteId) {
  return callApi(`household/${_id}/note/${noteId}`, 'DELETE');
}

export function removeCarerFromHousehold(_id, removePrimary) {
  return callApi(
    `household/${_id}/removecarer?removePrimary=${removePrimary}`,
    'DELETE'
  );
}

export function exportHousehold(_id, body) {
  return callDownloadApi(`household/${_id}/export`, 'POST', body);
}
