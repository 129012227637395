import React, { Component } from 'react';
import { ListItem } from 'material-ui/List';

import PersonCard from '../../../components/PersonCard';

class Review extends Component {
  render() {
    const { review } = this.props;
    return review ? (
      <div className="dash-col">
        <div>
          {review.carers.map(s => (
            <PersonCard person={s} rel="carer" key={s._id} caller="dashboard" />
          ))}
        </div>
      </div>
    ) : (
      <div className="dash-col">
        <ListItem
          disabled
          style={{ backgroundColor: 'white' }}
          primaryText="No Reviews Due"
        />
      </div>
    );
  }
}

export default Review;
