import callApi from '../../util/apiCaller';

export function searchReferrals(text, start, count, type) {
  return callApi(`referral?start=${start}&count=${count}`, 'POST', {
    text,
    type,
  });
}

export function fetchReferral(_id) {
  return callApi(`referral/${_id}`);
}

export function updateReferral(_id, body) {
  return callApi(`referral/${_id}`, 'POST', body);
}

export function addReferral(body) {
  return callApi('referral', 'PUT', body);
}

export function addReferralHousehold(body) {
  return callApi('referral/household', 'POST', body);
}

export function fetchYoungPersonReferrals(_id) {
  return callApi(`referral/youngperson/${_id}`);
}

export function deleteReferral(_id) {
  return callApi(`referral/${_id}`, 'DELETE');
}
