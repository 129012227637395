import React, { Component } from 'react';

import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import { ListItem } from 'material-ui/List';
import MenuItem from 'material-ui/MenuItem';

import Dialog from '../../../components/Dialog';
import PlacementCard from '../../Placement/components/PlacementCard';
import SelectField from '../../../components/SelectField';
import { getCoordinators } from '../StaffAPI';
import { changeCoordinator } from '../../Placement/PlacementActions';
import { changeHouseholdCoordinator } from '../../Household/HouseholdActions';

class Assign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinators: [],
      staff: props.prevStaff || {},
      loading: false,
      error: false,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.open && isEmpty(this.state.coordinators)) {
      getCoordinators().then(coordinators => {
        this.setState({
          coordinators,
          staff:
            find(coordinators, c => c._id === (props.prevStaff || {})._id) ||
            {},
        });
      });
    }
  }
  onDialogClose(staff) {
    const { prevStaff, closeDialog } = this.props;
    this.setState({
      coordinators: [],
      staff: staff || { ...prevStaff },
    });
    closeDialog();
  }
  onSubmit() {
    const { staff } = this.state;
    const { caseLoad, dispatch, household } = this.props;
    const prevStaff = this.props.prevStaff || {};
    this.setState({ loading: true, error: false });
    if (household) {
      dispatch(
        changeHouseholdCoordinator(
          household._id,
          { staff: staff._id, prevStaff: prevStaff._id },
          staff
        )
      ).then(
        () => {
          this.onDialogClose(staff);
        },
        () => this.setState({ loading: false, error: true })
      );
    } else {
      dispatch(
        changeCoordinator(
          caseLoad._id,
          { staff: staff._id, prevStaff: prevStaff._id },
          staff
        )
      ).then(
        () => {
          this.onDialogClose(staff);
        },
        () => this.setState({ loading: false, error: true })
      );
    }
  }
  render() {
    const { coordinators, staff, error, loading } = this.state;
    const { open, prevStaff } = this.props;
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title="Assign Coordinator"
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <FlatButton
            label="Cancel"
            primary
            onClick={() => this.onDialogClose()}
          />,
          <RaisedButton
            label="Submit"
            primary
            disabled={
              loading || (prevStaff ? staff._id === prevStaff._id : false)
            }
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() => this.onSubmit()}
          />,
        ]}
      >
        <SelectField
          param={{
            field: 'staff',
            name: 'Coordinator',
            editable: true,
            required: true,
          }}
          value={staff._id}
          options={coordinators.map(c => (
            <MenuItem
              key={c._id}
              value={c._id}
              primaryText={c.contact.fullName}
            />
          ))}
          onUpdated={val =>
            this.setState({ staff: find(coordinators, c => c._id === val) })
          }
        />
        <ListItem
          primaryText="Current Placements"
          nestedItems={(staff.caseLoad || []).map(
            c =>
              c._id ? <PlacementCard key={c._id || c} caseLoad={c} /> : null
          )}
          disabled
          initiallyOpen
        />
      </Dialog>
    );
  }
}

export default Assign;
