import React, { Component } from 'react';

import RefreshIndicator from 'material-ui/RefreshIndicator';

import { colours } from '../../../theme';

import './LoadingIndicator.css';

class LoadingIndicator extends Component {
  render() {
    if (!this.props.loading) return null;
    return (
      <div className="loading-indicator">
        <RefreshIndicator
          size={50}
          left={0}
          top={0}
          loadingColor="white"
          status="loading"
          style={{ backgroundColor: colours.skyBlue, boxShadow: 'none' }}
        />
      </div>
    );
  }
}

export default LoadingIndicator;
