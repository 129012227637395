import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import isDate from 'lodash/isDate';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

import Dialog from '../../../components/Dialog';
import Attachment from '../../../components/Attachment';
import Form from '../../../components/Form';

import viewAndWishModel from '../ViewAndWishModel';
import { addViewAndWish } from '../YoungPersonActions';
import { uploadFile } from '../../../util/UtilAPI';

class ViewAndWishAdd extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }
  getInitialState() {
    const { youngPerson } = this.props;
    const dateRecorded = moment(new Date().setHours(0, 0, 0, 0)).toDate();
    return {
      dateRecorded,
      title: '',
      detail: '',
      attachments: [],
      youngPerson,
      category: 'Views and Wishes',
      loading: false,
      error: false,
    };
  }
  onChange(val) {
    this.setState({ ...val }, () => this.validate());
  }
  onSubmit() {
    const { youngPerson } = this.props;
    const { attachments, category } = this.state;
    const body = {};

    each(viewAndWishModel, n => {
      body[n.field] = this.state[n.field];
    });

    if (youngPerson) {
      this.setState({ loading: true, error: false });
      body.category = category;
      this.props.dispatch(addViewAndWish(youngPerson._id, body)).then(
        viewandwish => {
          if (viewandwish) {
            if (!isEmpty(attachments)) {
              each(attachments, attachment => {
                const pathToFix = attachment.path;
                const path = pathToFix.replace('undefined', viewandwish._id);
                const {
                  toUpload,
                  description,
                  recordType,
                  dateRecorded,
                  category,
                } = attachment;
                uploadFile(
                  toUpload,
                  path,
                  description,
                  recordType,
                  viewandwish._id,
                  dateRecorded,
                  category
                );
              });
            }
            this.onDialogClose();
          } else this.setState({ loading: false, error: true });
        },
        () => this.setState({ loading: false, error: true })
      );
    }
  }
  onDialogClose() {
    this.setState(this.getInitialState());
    this.props.closeDialog();
  }
  validate() {
    let valid = true;
    const { state } = this;
    each(viewAndWishModel, n => {
      if (n.required && valid) {
        valid = !isEmpty(state[n.field]) || isDate(state[n.field]);
      }
    });
    this.setState({ valid });
  }
  render() {
    const { valid, attachments, category, error, loading } = this.state;
    const { open } = this.props;
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title="Add Views and Wishes"
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <FlatButton
            label="Cancel"
            primary
            onClick={() => this.onDialogClose()}
          />,
          <RaisedButton
            label="Submit"
            primary
            disabled={loading || !valid}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() => this.onSubmit()}
          />,
        ]}
      >
        <Form
          create
          model={viewAndWishModel}
          object={this.state}
          section="info"
          onUpdated={val => this.onChange(val)}
        />
        <Form
          create
          model={viewAndWishModel}
          object={this.state}
          section="content"
          onUpdated={val => this.onChange(val)}
        />
        <Attachment
          newRecord
          recordType="ViewAndWish"
          category={category}
          onFileSubmit={attachment =>
            this.setState({
              attachments: [...attachments, attachment],
            })
          }
          filesToUpload={attachments}
        />
      </Dialog>
    );
  }
}

ViewAndWishAdd.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ViewAndWishAdd);
