import React, { Component } from 'react';
import MTimePicker from 'material-ui/TimePicker';

import { colours } from '../theme';

class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { param, value, onUpdated, create, readOnly } = this.props;
    let floatingLabelText = param.name;
    if (create) {
      if (param.required) {
        floatingLabelText = `${param.name} (required)`;
      }
    }
    return (
      <MTimePicker
        id={param.field}
        fullWidth
        floatingLabelText={floatingLabelText}
        floatingLabelStyle={{
          color: create && param.required && !value && colours.kRed,
        }}
        autoOk
        disabled={readOnly || (!create && !param.editable)}
        onChange={(e, val) => onUpdated(val)}
        value={value ? new Date(value) : null}
        underlineDisabledStyle={{ borderBottom: '1px solid #e0e0e0' }}
        minutesStep={15}
        style={{ display: 'inline-block', width: '50%' }}
      />
    );
  }
}

export default TimePicker;
