import React, { Component } from 'react';
import moment from 'moment';

import { ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar';

import NoteDetail from './NoteDetail';

import { colours, icons } from '../../../theme';

class PlacementCard extends Component {
  constructor(props) {
    super(props);
    this.state = { sensitive: props.note.sensitive, openNote: false };
  }
  render() {
    const { note, readOnly, goToPlacement, caseLoad, household } = this.props;
    const { sensitive, openNote } = this.state;
    const statusColour = sensitive ? colours.kRed : colours.skyBlue;
    const iconCommons = {
      className: 'person-card-back',
      color: statusColour,
    };
    const noteIcons = {
      General: <icons.NoteIcon />,
      Family: <icons.FamilyIcon />,
      Education: <icons.EducationIcon />,
      Medical: <icons.MedicalIcon />,
      Employment: <icons.WorkIcon />,
      Legal: <icons.LegalIcon />,
      Incidents: <icons.IncidentIcon />,
      Interests: <icons.InterestsIcon />,
      'Therapeutic Support': <icons.TherapeuticSupportIcon />,
    };
    const backIcons = {
      General: <icons.NoteIcon {...iconCommons} />,
      Family: <icons.FamilyIcon {...iconCommons} />,
      Education: <icons.EducationIcon {...iconCommons} />,
      Medical: <icons.MedicalIcon {...iconCommons} />,
      Employment: <icons.WorkIcon {...iconCommons} />,
      Legal: <icons.LegalIcon {...iconCommons} />,
      Incidents: <icons.IncidentIcon {...iconCommons} />,
      Interests: <icons.InterestsIcon {...iconCommons} />,
      'Therapeutic Support': <icons.TherapeuticSupportIcon {...iconCommons} />,
    };
    return (
      <div className="person-card">
        {sensitive ? (
          <icons.LockIcon {...iconCommons} />
        ) : (
          backIcons[note.category]
        )}
        <ListItem
          primaryText={sensitive ? 'Sensitive' : note.title}
          secondaryText={moment(note.dateRecorded).format('D MMMM YYYY')}
          leftAvatar={
            <Avatar
              icon={sensitive ? <icons.LockIcon /> : noteIcons[note.category]}
              backgroundColor={colours.kGrey}
            />
          }
          style={{ borderLeft: `5px solid ${statusColour}` }}
          onClick={() => {
            this.setState({ openNote: true });
          }}
          rightIcon={
            note.hasAtt ? (
              <icons.AttachmentIcon color={colours.darkBlack} />
            ) : (
              <span />
            )
          }
        />
        <NoteDetail
          readOnly={readOnly}
          open={openNote}
          closeDialog={() => this.setState({ openNote: false })}
          note={note}
          goToPlacement={goToPlacement}
          caseLoad={caseLoad}
          household={household}
        />
      </div>
    );
  }
}

export default PlacementCard;
