import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import moment from 'moment';

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

import Dialog from '../../../components/Dialog';

// import { colours } from '../../../theme';

class ClientReport extends Component {
  onDialogClose() {
    const { closeDialog } = this.props;
    closeDialog();
  }

  // eslint-disable-next-line class-methods-use-this
  renderResults(report) {
    let totalNights = 0;

    const rows = report.map(r => {
      totalNights += r.nights ? Math.abs(parseInt(r.nights, 10)) : 0;

      return (
        <TableRow>
          <TableRowColumn style={{ width: '200px' }}>{`${r.p1_onames}  ${
            r.p2_onames ? '& ' + r.p2_onames : ''
          } ${r.famname}`}</TableRowColumn>
          <TableRowColumn style={{ width: '120px' }}>
            {r.start_date ? moment(r.start_date).format('DD/MM/YYYY') : ''}
          </TableRowColumn>
          <TableRowColumn style={{ width: '120px' }}>
            {r.end_date ? moment(r.end_date).format('DD/MM/YYYY') : ''}
          </TableRowColumn>
          <TableRowColumn>{r.placement_type}</TableRowColumn>
          <TableRowColumn style={{ width: '80px', textAlign: 'right' }}>
            {r.nights ? Math.abs(parseInt(r.nights, 10)) : ''}
          </TableRowColumn>
        </TableRow>
      );
    });

    rows.push(
      <TableRow>
        <TableRowColumn style={{ width: '200px' }} />
        <TableRowColumn style={{ width: '120px' }} />
        <TableRowColumn style={{ width: '120px' }} />
        <TableRowColumn>Total Nights</TableRowColumn>
        <TableRowColumn style={{ width: '80px', textAlign: 'right' }}>
          {totalNights}
        </TableRowColumn>
      </TableRow>
    );

    return rows;
  }

  render() {
    const { client, report, open } = this.props;
    const clientName =
      client && client.onames && client.sname
        ? '- ' + client.onames + ' ' + client.sname
        : '';
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.props.closeDialog()}
        title={`Client Report ${clientName}`}
        style={{
          width: '90%',
          maxWidth: '900px',
        }}
      >
        <Table
          //className={styles.body}
          stickyHeader
          aria-label="sticky table"
          // height={'100%'}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          >
            <TableRow>
              <TableHeaderColumn style={{ width: '200px' }}>
                Carers' Name
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: '120px' }}>
                Start Date
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: '120px' }}>
                End Date
              </TableHeaderColumn>
              <TableHeaderColumn>Placement Type</TableHeaderColumn>
              <TableHeaderColumn style={{ width: '80px', textAlign: 'right' }}>
                Nights
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={false}
            deselectOnClickaway={false}
            preScanRows={false}
          >
            {!isEmpty(report) ? (
              this.renderResults(report)
            ) : (
              <TableRow>
                <TableRowColumn
                  colSpan="3"
                  style={{
                    textAlign: 'center',
                    cursor: 'default',
                  }}
                >
                  No available records found.
                </TableRowColumn>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Dialog>
    );
  }
}

ClientReport.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(ClientReport);
