// import keys from 'lodash/keys';
// import find from 'lodash/find';

// import AccessDBModel from './AccessDBModel';

import * as AccessDBAPI from './AccessDBAPI';

export const CLEAR_SEARCH = 'accessdb/CLEAR_SEARCH';
export const LOADING_CLIENTS = 'accessdb/LOADING_CLIENTS';
export const RENDER_CLIENTS = 'accessdb/RENDER_CLIENTS';
export const RENDER_FULL_INDIVIDUAL_CLIENT_REPORT =
  'accessdb/RENDER_FULL_INDIVIDUAL_CLIENT_REPORT';
export const ACCESSDB_ERROR = 'accessdb/ACCESSDB_ERROR';

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

export function loadMore(start) {
  return {
    type: LOADING_CLIENTS,
    start,
  };
}

export function renderClients(text, tab, tabChanged, clients) {
  return {
    type: RENDER_CLIENTS,
    text,
    tab,
    tabChanged,
    clients,
  };
}

export function renderFullIndividualClientReport(client, report) {
  return {
    type: RENDER_FULL_INDIVIDUAL_CLIENT_REPORT,
    client,
    report,
  };
}

export function setError(error = false) {
  return {
    type: ACCESSDB_ERROR,
    error,
  };
}

export function searchClients(text, start, count, type, tabChanged) {
  return dispatch => {
    dispatch(loadMore(start));
    return AccessDBAPI.searchClients(text, start, count, type).then(
      clients => {
        dispatch(renderClients(text, type, tabChanged, clients));
      },
      err => dispatch(setError(err || true))
    );
  };
}

export function fetchFullIndividualClientReport(client) {
  return dispatch =>
    AccessDBAPI.fetchFullIndividualClientReport(client).then(report => report);
}
