const editable = true;

export default [
  {
    field: 'description',
    name: 'Description',
    type: 'text',
    multiLine: true,
    section: 'description',
    editable,
  },
  {
    field: 'wwvpCheck',
    name: 'Working with Vulnerable People Check',
    type: 'text',
    section: 'regulation',
    editable,
  },
  {
    field: 'wwvpcExpiry',
    name: 'Expiry of WWVP Check',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'pcLodged',
    name: 'Police Check Lodged',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'pcResults',
    name: 'Police Check Results',
    type: 'text',
    section: 'regulation',
    editable,
  },
  {
    field: 'pcResultsReceived',
    name: 'PC Results Received',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'pcReviewDate',
    name: 'Next PC Due',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'cscSent',
    name: 'Child Safety Check Sent',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'cscResults',
    name: 'CSC Results',
    type: 'text',
    section: 'regulation',
    editable,
  },
  {
    field: 'cscResultsReceived',
    name: 'CSC Results Received',
    type: 'date',
    section: 'regulation',
    editable,
  },
];
