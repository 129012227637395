import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import { ListItem } from 'material-ui/List';
import MenuItem from 'material-ui/MenuItem';

import Dialog from '../../../components/Dialog';
import PlacementCard from '../../Placement/components/PlacementCard';
import SelectField from '../../../components/SelectField';
import TextField from '../../../components/TextField';
import { submitPlacement } from '../PlacementActions';
import { getCoordinators } from '../../Staff/StaffAPI';

class PlacementAdd extends Component {
  constructor() {
    super();
    this.state = {
      information: '',
      redirect: '',
      coordinators: [],
      staff: {},
      loading: false,
      error: false,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.open && isEmpty(this.state.coordinators)) {
      getCoordinators().then(coordinators => {
        this.setState({ coordinators });
      });
    }
  }
  onSubmit() {
    const { staff, information } = this.state;
    const referral = this.props.referral._id;
    const body = {
      referral,
      information,
    };
    if (!isEmpty(staff)) {
      body.staff = staff._id;
    }
    this.setState({ loading: true, error: false });
    this.props.dispatch(submitPlacement(body)).then(
      newCase => {
        if (newCase) {
          this.setState({ redirect: <Redirect to={`/case/${newCase._id}`} /> });
          this.onDialogClose();
        } else this.setState({ loading: false, error: true });
      },
      () => this.setState({ loading: false, error: true })
    );
  }
  onDialogClose() {
    this.setState({ information: '' });
    this.props.closeDialog();
  }
  render() {
    const { open } = this.props;
    const {
      coordinators,
      staff,
      redirect,
      information,
      loading,
      error,
    } = this.state;
    if (redirect) return redirect;
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title="Create Placement"
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <FlatButton
            label="Cancel"
            primary
            onClick={() => this.onDialogClose()}
          />,
          <RaisedButton
            label="Submit"
            primary
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() => this.onSubmit()}
            disabled={loading || isEmpty(staff)}
          />,
        ]}
      >
        <SelectField
          param={{
            field: 'staff',
            name: 'Coordinator',
            editable: true,
            required: true,
          }}
          value={staff._id}
          options={coordinators.map(c => (
            <MenuItem
              key={c._id}
              value={c._id}
              primaryText={c.contact.fullName}
            />
          ))}
          onUpdated={val =>
            this.setState({ staff: find(coordinators, c => c._id === val) })
          }
        />
        <ListItem
          primaryText="Current Placements"
          nestedItems={
            !isEmpty(staff)
              ? staff.caseLoad.map(c => (
                  <PlacementCard key={c._id || c} caseLoad={c} />
                ))
              : []
          }
          disabled
          initiallyOpen
        />
        <TextField
          param={{
            name: 'Additional Placement Information',
            multiLine: true,
            editable: true,
          }}
          onUpdated={information => this.setState({ information })}
          value={information}
        />
      </Dialog>
    );
  }
}

PlacementAdd.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(PlacementAdd);
