import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar';

import { colours, icons } from '../../../theme';

class PlacementCard extends Component {
  render() {
    const { caseLoad, rightIconButton, onClick } = this.props;
    const randColour =
      caseLoad.leftCareDate &&
      moment(new Date(caseLoad.leftCareDate)) < moment()
        ? colours.kGrey
        : colours.skyBlue;
    return (
      <div className="person-card">
        <icons.PlacementIcon className="person-card-back" color={randColour} />
        <ListItem
          primaryText={`Placement for ${caseLoad.youngPerson.contact.fullName}`}
          secondaryText={`${
            caseLoad.household ? caseLoad.household.name : 'Moving On Program'
          } from ${moment(
            caseLoad.kennerleyEntryDate || caseLoad.created
          ).format('D MMMM YYYY')}`}
          leftAvatar={
            <Avatar
              icon={<icons.PlacementIcon />}
              backgroundColor={colours.kGrey}
            />
          }
          style={{ borderLeft: `5px solid ${randColour}` }}
          containerElement={
            !rightIconButton ? <Link to={`/case/${caseLoad._id}`} /> : <span />
          }
          rightIconButton={rightIconButton}
          onClick={onClick}
        />
      </div>
    );
  }
}

export default PlacementCard;
