import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroller';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { ListItem } from 'material-ui/List';
import { Tabs, Tab } from 'material-ui/Tabs';

import FloatingActionButton from 'material-ui/FloatingActionButton';

import SearchBox from '../../../components/SearchBox';
import PersonCard from '../../../components/PersonCard';

import CarerAdd from '../components/CarerAdd';
import { clearSearch, searchProspects, changeTab } from '../ProspectActions';
import { icons } from '../../../theme';

class ProspectList extends Component {
  constructor() {
    super();
    this.state = {
      showAdd: false,
    };
    this.doSearch = debounce(this.doSearch.bind(this), 800);
  }

  fetchMore() {
    const { count, start, loading, dispatch, text, tab } = this.props;

    if (!loading) {
      const newStart = start + count;
      dispatch(searchProspects(text, newStart, count, tab));
    }
  }

  doSearch(val) {
    const { count, loading, dispatch, tab } = this.props;

    if (!loading) {
      dispatch(searchProspects(val, 0, count, tab));
    }
  }

  handleTabOnActive(tab, val) {
    const { dispatch } = this.props;
    dispatch(changeTab(tab));
    const { count, loading } = this.props;
    if (!loading) {
      dispatch(searchProspects(val, 0, count, tab, 'tabChanged'));
    }
  }

  handleSearchButtonOnClick(val) {
    this.doSearch(val);
  }

  handleSearchBoxOnChange(val) {
    this.doSearch(val);
  }

  handleClearButtonOnClick() {
    this.props.dispatch(clearSearch());
  }

  render() {
    const {
      search,
      prospects,
      start,
      location,
      hasMore,
      error,
      tab,
    } = this.props;
    const list = search.text ? search.result : prospects;
    let hint = '';
    switch (location.pathname) {
      case '/carer':
        hint = 'Search carers';
        break;
      case '/prospect':
        hint = 'Search prospects';
        break;
      default:
        hint = 'Search';
    }
    switch (tab) {
      case 'open':
        hint = 'Search open prospects';
        break;
      case 'closed':
        hint = 'Search closed/inactive prospects';
        break;
      case 2:
        hint = 'Search kinship prospects';
        break;
      default:
        hint = 'Search prospects';
    }

    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Prospects - HEART1869</title>
          <meta name="description" content="Kennerley HEART1869 Prospects" />
        </Helmet>
        <div className="content">
          <div className="list-tabs" style={{ overflowY: 'scroll' }}>
            <Tabs
              style={{
                maxWidth: '900px',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingBottom: '5px',
              }}
              value={tab}
            >
              <Tab
                label="Active"
                value="open"
                onActive={() => {
                  this.handleTabOnActive('open', search.text);
                }}
              />
              <Tab
                label="Inactive"
                value="closed"
                onActive={() => {
                  this.handleTabOnActive('closed', search.text);
                }}
              />
              <Tab
                label="Kinship"
                value={2}
                onActive={() => {
                  this.handleTabOnActive(2, search.text);
                }}
              />
              <Tab
                label="All"
                value={1}
                onActive={() => {
                  this.handleTabOnActive(1, search.text);
                }}
              />
            </Tabs>
          </div>
          <div className="list-head">
            <SearchBox
              hintText={hint}
              onClickSearch={val => this.handleSearchButtonOnClick(val)}
              onChangeSearch={val => this.handleSearchBoxOnChange(val)}
              onClickClear={() => this.handleClearButtonOnClick()}
            />
          </div>
          <div className="list-body">
            <InfiniteScroll
              className="list-section"
              useWindow={false}
              pageStart={start}
              loadMore={() => this.fetchMore(start)}
              hasMore={!error && hasMore}
              initialLoad
            >
              {!isEmpty(list) ? (
                list.map(c => (
                  <PersonCard person={c} rel="prospect" key={c._id} />
                ))
              ) : (
                <ListItem disabled>
                  {error
                    ? 'An error occurred, please refresh the app.'
                    : 'No results.'}
                </ListItem>
              )}
            </InfiniteScroll>
          </div>
          <FloatingActionButton
            className="fab"
            onClick={() => this.setState({ showAdd: true })}
          >
            <icons.AddIcon />
          </FloatingActionButton>
          <CarerAdd
            prospect={location.pathname === '/prospect'}
            closeDialog={() => this.setState({ showAdd: false })}
            open={this.state.showAdd}
          />
        </div>
      </div>
    );
  }
}

ProspectList.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tab: state.prospect.tab,
  prospects: state.prospect.list,
  search: state.prospect.search,
  loading: state.prospect.info.loading,
  start: state.prospect.info.start,
  count: state.prospect.info.count,
  hasMore: state.prospect.info.hasMore,
  error: state.prospect.info.error,
});

export default connect(mapStateToProps)(ProspectList);
