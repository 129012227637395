import keys from 'lodash/keys';
import find from 'lodash/find';

import YoungPersonModel from './YoungPersonModel';
import ViewAndWishModel from './ViewAndWishModel';

import * as YoungPersonAPI from './YoungPersonAPI';
import * as ReferralAPI from '../Referral/ReferralAPI';
import { notification } from '../App/AppActions';

export const RENDER_YOUNG_PEOPLE = 'young/RENDER_YOUNG_PEOPLE';
export const LOADING_YOUNG_PEOPLE = 'young/LOADING_YOUNG_PEOPLE';
export const DISPLAY_YOUNG_PERSON = 'young/DISPLAY_YOUNG_PERSON';
export const UPDATE_YOUNG_PERSON = 'young/UPDATE_YOUNG_PERSON';
export const CHANGE_TAB = 'young/CHANGE_TAB';
export const CLEAR_SEARCH = 'young/CLEAR_SEARCH';
export const GET_REFERRALS = 'young/GET_REFERRALS';
export const ADD_DOCTOR = 'young/ADD_DOCTOR';
export const UPDATE_DOCTOR = 'young/UPDATE_DOCTOR';
export const ADD_PARENT = 'young/ADD_PARENT';
export const UPDATE_PARENT = 'young/UPDATE_PARENT';
export const ADD_SIBLING = 'young/ADD_SIBLING';
export const UPDATE_SIBLING = 'young/UPDATE_SIBLING';
export const DELETE_YOUNG_PERSON = 'young/DELETE_YOUNG_PERSON';
export const ADD_VIEW_AND_WISH = 'young/ADD_VIEW_AND_WISH';
export const UPDATE_VIEW_AND_WISH = 'young/UPDATE_VIEW_AND_WISH';
export const GET_VIEW_AND_WISH = 'young/GET_VIEW_AND_WISH';
export const GET_VIEWS_AND_WISHES = 'young/GET_VIEWS_AND_WISHES';
export const DELETE_VIEW_AND_WISH = 'young/DELETE_VIEW_AND_WISH';
export const EXIT_VIEW_AND_WISH = 'young/EXIT_VIEW_AND_WISH';
export const GET_NOTES = 'young/GET_NOTES';
export const GET_MEETINGS = 'young/GET_MEETINGS';
export const YOUNG_PEOPLE_ERROR = 'young/YOUNG_PEOPLE_ERROR';

export function changeTab(tab) {
  return {
    type: CHANGE_TAB,
    tab,
  };
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

export function renderYoungPeople(text, tab, tabChanged, youngPeople) {
  return {
    type: RENDER_YOUNG_PEOPLE,
    text,
    tab,
    tabChanged,
    youngPeople,
  };
}

export function getYoungPerson(youngPerson) {
  return {
    type: DISPLAY_YOUNG_PERSON,
    youngPerson,
  };
}

export function _deleteYoungPerson(youngPerson) {
  return {
    type: DELETE_YOUNG_PERSON,
    youngPerson,
  };
}

export function updateYoungPerson(youngPerson, body, detail) {
  return {
    type: UPDATE_YOUNG_PERSON,
    youngPerson,
    body,
    detail,
  };
}

export function loadMore(start) {
  return {
    type: LOADING_YOUNG_PEOPLE,
    start,
  };
}

export function _fetchReferrals(referrals) {
  return {
    type: GET_REFERRALS,
    referrals,
  };
}

export function addDoctor(doctor) {
  return {
    type: ADD_DOCTOR,
    doctor,
  };
}

export function addParent(parent) {
  return {
    type: ADD_PARENT,
    parent,
  };
}

export function addSibling(sibling) {
  return {
    type: ADD_SIBLING,
    sibling,
  };
}

export function updateDoctor(youngPerson, doctor, body, contact) {
  return {
    type: UPDATE_DOCTOR,
    youngPerson,
    doctor,
    body,
    contact,
  };
}

export function updateParent(youngPerson, parent, body, contact) {
  return {
    type: UPDATE_PARENT,
    youngPerson,
    parent,
    body,
    contact,
  };
}

export function updateSibling(youngPerson, sibling, body, contact) {
  return {
    type: UPDATE_SIBLING,
    youngPerson,
    sibling,
    body,
    contact,
  };
}

export function setError(error = false) {
  return {
    type: YOUNG_PEOPLE_ERROR,
    error,
  };
}

export function searchYoungPeople(text, start, count, type, tabChanged) {
  return dispatch => {
    dispatch(loadMore(start));
    return YoungPersonAPI.searchYoungPeople(text, start, count, type).then(
      youngPeople => {
        dispatch(renderYoungPeople(text, type, tabChanged, youngPeople));
      },
      err => dispatch(setError(err || true))
    );
  };
}

export function fetchYoungPerson(_id) {
  return dispatch =>
    YoungPersonAPI.fetchYoungPerson(_id).then(
      youngPerson => {
        if (youngPerson) {
          return YoungPersonAPI.getAccommodation(_id).then(accommodation =>
            ReferralAPI.fetchYoungPersonReferrals(_id).then(referrals =>
              YoungPersonAPI.getViewsAndWishes(_id).then(viewsandwishes => {
                const ypWithAcc = {
                  ...youngPerson,
                  accommodation,
                  referrals,
                  viewsandwishes,
                };
                return dispatch(getYoungPerson(ypWithAcc));
              })
            )
          );
        }
        return dispatch(getYoungPerson({ deleted: true }));
      },
      () => Promise.reject()
    );
}

export function modifyYoungPerson(
  _id,
  body,
  prevVal,
  undo = false,
  detail = true
) {
  return dispatch =>
    YoungPersonAPI.updateYoungPerson(_id, body).then(() => {
      dispatch(updateYoungPerson(_id, body, detail));
      if (!undo) {
        dispatch(
          notification(
            `${find(YoungPersonModel, { field: keys(body)[0] }).name} updated.`,
            'UNDO',
            modifyYoungPerson(_id, prevVal, '', true)
          )
        );
      } else {
        dispatch(
          notification(
            `Changes to ${
              find(YoungPersonModel, { field: keys(body)[0] }).name
            } undone.`
          )
        );
      }
    });
}

export function submitYoungPerson(body) {
  return dispatch =>
    YoungPersonAPI.addYoungPerson(body).then(youngPerson => {
      dispatch(getYoungPerson(youngPerson));
      return youngPerson;
    });
}

export function fetchReferrals(_id) {
  return dispatch =>
    ReferralAPI.fetchYoungPersonReferrals(_id).then(referrals => {
      dispatch(_fetchReferrals(referrals));
      return referrals;
    });
}

export function createDoctor(_id, body) {
  return dispatch =>
    YoungPersonAPI.addDoctor(_id, body).then(doctor => {
      dispatch(addDoctor(doctor));
      return doctor;
    });
}
export function createParent(_id, body) {
  return dispatch =>
    YoungPersonAPI.addParent(_id, body).then(parent => {
      dispatch(addParent(parent));
      return parent;
    });
}
export function createSibling(_id, body) {
  const { youngPerson } = body;
  return dispatch =>
    YoungPersonAPI.addSibling(_id, youngPerson ? { youngPerson } : body).then(
      sibling => {
        dispatch(addSibling(youngPerson ? body : sibling));
        return sibling;
      }
    );
}

export function editDoctor(yP, doctor, body) {
  return dispatch =>
    YoungPersonAPI.updateDoctor(yP, doctor, body).then(() => {
      dispatch(updateDoctor(yP, doctor, body, body.contact));
    });
}

export function editParent(yP, parent, body) {
  return dispatch =>
    YoungPersonAPI.updateParent(yP, parent, body).then(() => {
      dispatch(updateParent(yP, parent, body, body.contact));
    });
}

export function editSibling(yP, sibling, body) {
  return dispatch =>
    YoungPersonAPI.updateSibling(yP, sibling, body).then(() => {
      dispatch(updateSibling(yP, sibling, body, body.contact));
    });
}

export function deleteYoungPerson(yP) {
  return dispatch =>
    YoungPersonAPI.deleteYoungPerson(yP).then(() =>
      dispatch(_deleteYoungPerson(yP))
    );
}

export function _getYPNotes(yP, notes) {
  return {
    type: GET_NOTES,
    yP,
    notes,
  };
}

export function getYPNotes(yP) {
  return dispatch =>
    YoungPersonAPI.getYPNotes(yP).then(notes => {
      dispatch(_getYPNotes(yP, notes));
      return notes;
    });
}

export function _addViewAndWish(yP, vW, _vW) {
  return {
    type: ADD_VIEW_AND_WISH,
    yP,
    vW,
    _vW,
  };
}

export function addViewAndWish(yP, vW) {
  return dispatch =>
    YoungPersonAPI.addViewAndWish(yP, vW).then(_vW => {
      dispatch(_addViewAndWish(yP, vW, _vW));
      return _vW;
    });
}

export function _updateViewAndWish(yP, vW, body) {
  return {
    type: UPDATE_VIEW_AND_WISH,
    yP,
    vW,
    body,
  };
}

export function updateViewAndWish(yP, vW, body, prevVal, undo = false) {
  return dispatch =>
    YoungPersonAPI.updateViewAndWish(yP, vW, body).then(_vW => {
      dispatch(_updateViewAndWish(yP, vW, body));
      dispatch(fetchViewsAndWishes(yP));
      if (!undo) {
        dispatch(
          notification(
            `${find(ViewAndWishModel, { field: keys(body)[0] }).name} updated.`,
            'UNDO',
            updateViewAndWish(yP, vW, prevVal, '', true)
          )
        );
      } else {
        dispatch(
          notification(
            `Changes to ${
              find(ViewAndWishModel, { field: keys(body)[0] }).name
            } undone.`
          )
        );
      }
    });
}

export function _fetchViewAndWish(yP, vW, _vW) {
  return {
    type: GET_VIEW_AND_WISH,
    yP,
    vW,
    _vW,
  };
}

export function fetchViewAndWish(yP, vW) {
  return dispatch =>
    YoungPersonAPI.getViewsAndWishes(yP, vW).then(_vW => {
      dispatch(_fetchViewAndWish(yP, vW, _vW));
    });
}

export function _fetchViewsAndWishes(yP, vWs) {
  return {
    type: GET_VIEWS_AND_WISHES,
    yP,
    vWs,
  };
}

export function fetchViewsAndWishes(yP) {
  return dispatch =>
    YoungPersonAPI.getViewsAndWishes(yP).then(vWs => {
      dispatch(_fetchViewsAndWishes(yP, vWs));
    });
}

export function _exitViewAndWish() {
  return { type: EXIT_VIEW_AND_WISH };
}

export function exitViewAndWish() {
  return dispatch => {
    dispatch(_exitViewAndWish());
  };
}

export function _deleteViewAndWish(yP, vW) {
  return {
    type: DELETE_VIEW_AND_WISH,
    yP,
    vW,
  };
}

export function deleteViewAndWish(yP, vW) {
  return dispatch =>
    YoungPersonAPI.deleteViewAndWish(yP, vW).then(() => {
      dispatch(_deleteViewAndWish(yP, vW));
      return vW;
    });
}
