import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { SpeedDial, BubbleList, BubbleListItem } from 'react-speed-dial';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import { Card, CardTitle } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';

import ObjectLogs from '../../Log/components/ObjectLogs';
import { getObjectLogs } from '../../Log/LogAPI';

import referralModel from '../ReferralModel';
import Form from '../../../components/Form';
import Attachment from '../../../components/Attachment';
import {
  fetchReferral,
  modifyReferral,
  deleteReferral,
} from '../ReferralActions';
import PersonCard from '../../../components/PersonCard';
import GlobalLoading from '../../../components/GlobalLoading';
import GlobalError from '../../../components/GlobalError';
import AttachHousehold from '../../Household/components/AttachHousehold';
import HouseholdCard from '../../Household/components/HouseholdCard';
import PlacementAdd from '../../Placement/components/PlacementAdd';
import PlacementCard from '../../Placement/components/PlacementCard';
import BackLink from '../../../components/BackLink';
import DeletePrompt from '../../../components/DeletePrompt';
import Title from '../../../components/Title';

import { icons, colours } from '../../../theme';
import deepCompare from '../../../util/deepCompare';
import { updateRecent } from '../../App/AppActions';

class ReferralDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachmentsExpanded: false,
      speedDialOpen: false,
      showAttachHousehold: false,
      showPlacementAdd: false,
      requestExpanded: true,
      closedExpanded: true,
      referral: {},
      showDelPro: false,
      error: false,
    };
    this.onChange = debounce(this.onChange.bind(this), 800);
    props
      .dispatch(fetchReferral(props.match.params.id))
      .then(({ referral }) => {
        if (referral.youngPerson && referral.youngPerson.contact.fullName) {
          props.dispatch(
            updateRecent({
              type: 'referral',
              id: props.match.params.id,
              title: `Referral: ${referral.youngPerson.contact.fullName}`,
            })
          );
        }
      })
      .catch(() => this.setState({ error: true }));
  }
  shouldComponentUpdate(props, state) {
    const { user, referral } = this.props;
    return (
      !deepCompare(state, this.state) ||
      !deepCompare(props.user, user) ||
      !deepCompare(props.referral, referral)
    );
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ referral: props.referral });
  }
  onChange(val) {
    const { match, dispatch } = this.props;
    const { referral } = this.state;
    const { id } = match.params;
    const key = Object.keys(val).join();
    const prevVal = { [key]: referral[key] };
    const nextVal = val;
    if (val.legalOrderStatus === 'Not Applicable') {
      nextVal.placementType = 'Community Respite Care';
      nextVal.household = null;
    }
    if (
      val.placementType &&
      val.placementType !== 'Community Respite Care' &&
      referral.legalOrderStatus === 'Not Applicable'
    ) {
      nextVal.legalOrderStatus = '';
      nextVal.household = null;
    }
    if (val.placementType === 'Community Respite Care') {
      nextVal.legalOrderStatus = 'Not Applicable';
      nextVal.household = null;
    }
    dispatch(modifyReferral(id, nextVal, prevVal));
  }
  getLogs() {
    getObjectLogs(this.props.referral._id).then(logs =>
      this.setState({ logs })
    );
  }
  renderYP() {
    const { youngPerson } = this.state.referral;
    return (
      <Card className="content-box">
        <CardTitle subtitle="Young Person" />
        <PersonCard person={youngPerson} rel="young" key={youngPerson._id} />
      </Card>
    );
  }
  renderHousehold() {
    const { household } = this.state.referral;
    return (
      <Card className="content-box">
        <CardTitle subtitle="Proposed Household" />
        {household && <HouseholdCard household={household} />}
      </Card>
    );
  }
  renderPlacement() {
    const assignedPlacement = this.state.referral.case;
    return (
      <Card className="content-box">
        <CardTitle subtitle="Assigned Placement" />
        {assignedPlacement && <PlacementCard caseLoad={assignedPlacement} />}
      </Card>
    );
  }
  renderRequest() {
    const { referral, requestExpanded } = this.state;
    return (
      <Card
        className="content-box"
        expanded={requestExpanded}
        onExpandChange={() =>
          this.setState({ requestExpanded: !requestExpanded })
        }
      >
        <CardTitle
          subtitle={`Placement Request (${moment(referral.created).format(
            'Do MMMM YYYY'
          )})`}
          titleStyle={{ fontSize: '1em' }}
          actAsExpander
          showExpandableButton
        />
        <Form
          model={referralModel}
          section="request"
          expandable
          object={referral}
          onUpdated={val => this.onChange(val)}
        />
      </Card>
    );
  }
  renderClosure() {
    const { referral, closedExpanded } = this.state;
    return (
      <Card
        className="content-box"
        expanded={closedExpanded}
        onExpandChange={() =>
          this.setState({ closedExpanded: !closedExpanded })
        }
      >
        <CardTitle
          subtitle="Closure Status"
          actAsExpander
          showExpandableButton
        />
        <Form
          model={referralModel}
          section="closed"
          expandable
          object={referral}
          onUpdated={val => this.onChange(val)}
        />
      </Card>
    );
  }
  renderLogs() {
    const { logs, logExpanded } = this.state;
    if (isEmpty(logs) && logExpanded) this.getLogs();
    return (
      <Card
        className="content-box"
        expanded={logExpanded}
        onExpandChange={() => this.setState({ logExpanded: !logExpanded })}
      >
        <CardTitle subtitle="Logs" actAsExpander showExpandableButton />
        {!isEmpty(logs) && <ObjectLogs logs={logs} expandable />}
      </Card>
    );
  }
  renderAttachments() {
    const { user } = this.props;
    const { attachmentsExpanded, referral } = this.state;
    return (
      <Card
        className="content-box"
        expanded={attachmentsExpanded}
        onExpandChange={() =>
          this.setState({ attachmentsExpanded: !attachmentsExpanded })
        }
      >
        <CardTitle subtitle="Attachments" actAsExpander showExpandableButton />
        <Attachment
          admin={
            user.roles.includes('admin') || user.roles.includes('moderator')
          }
          record={referral._id}
          recordType="Referral"
          expandable
        />
      </Card>
    );
  }
  render() {
    const {
      referral,
      showAttachHousehold,
      showPlacementAdd,
      speedDialOpen,
      showDelPro,
      error,
    } = this.state;
    const { user, dispatch } = this.props;
    if (error) return <GlobalError />;
    if (isEmpty(referral) || isEmpty(user.roles)) return <GlobalLoading />;
    if (referral.deleted)
      return (
        <div className="container">
          <div className="content">
            <div>
              <div
                className="list-search"
                style={{
                  margin: 'auto',
                  width: '50%',
                  textAlign: 'center',
                  padding: '5px',
                }}
              >
                This referral can not be found or has been deleted.
                <BackLink />
              </div>
            </div>
          </div>
        </div>
      );
    const mop = referral.placementType === 'Moving On Program';
    const canMakePlacement =
      (user.roles.includes('admin') ||
        user.roles.includes('moderator') ||
        user.roles.includes('coordinator')) &&
      (!!referral.household || mop) &&
      referral.closed &&
      referral.closedDate &&
      referral.closedReason ===
        'Placement Identified and Approved By Kennerley';
    const deletable =
      user.roles.includes('admin') || user.roles.includes('moderator');
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Referral for {referral.youngPerson.contact.fullName}- HEART1869
          </title>
          <meta
            name="description"
            content="Kennerley HEART1869 Referral Detail"
          />
        </Helmet>
        <div className="content">
          <div className="content-body">
            <div className="content-column">
              <Card className="content-box">
                <Title
                  title={`Referral for ${
                    referral.youngPerson.contact.fullName
                  }`}
                />
              </Card>
              {this.renderRequest()}
              {this.renderYP()}
              {this.renderHousehold()}
              {this.renderPlacement()}
            </div>
            <div className="content-column">
              {this.renderClosure()}
              {this.renderAttachments()}
              {this.renderLogs()}
            </div>
          </div>
          {!referral.case &&
            !(mop && !canMakePlacement) && (
              <SpeedDial
                styleBackdrop={{ opacity: 0.01 }}
                className="speed-dial"
                isOpen={speedDialOpen}
                onChange={change =>
                  this.setState({ speedDialOpen: change.isOpen })
                }
                floatingActionButtonProps={
                  canMakePlacement ? { backgroundColor: colours.kRed } : {}
                }
              >
                <BubbleList className="bubble-list">
                  {!mop && (
                    <BubbleListItem
                      primaryText="Household"
                      rightAvatar={
                        <Avatar
                          backgroundColor={colours.limeGreen}
                          color={colours.skyBlue}
                          icon={<icons.HomeIcon />}
                        />
                      }
                      onClick={() => {
                        this.setState({
                          speedDialOpen: false,
                          showAttachHousehold: true,
                        });
                      }}
                    />
                  )}
                  {canMakePlacement && (
                    <BubbleListItem
                      primaryText="Placement"
                      rightAvatar={
                        <Avatar
                          backgroundColor={colours.limeGreen}
                          color={colours.skyBlue}
                          icon={<icons.PlacementIcon />}
                        />
                      }
                      onClick={() => {
                        this.setState({
                          speedDialOpen: false,
                          showPlacementAdd: true,
                        });
                      }}
                    />
                  )}
                  {deletable && (
                    <BubbleListItem
                      primaryText="Delete"
                      rightAvatar={
                        <Avatar
                          backgroundColor={colours.kRed}
                          color="white"
                          icon={<icons.DeleteIcon />}
                        />
                      }
                      onClick={() => {
                        this.setState({
                          speedDialOpen: false,
                          showDelPro: true,
                        });
                      }}
                    />
                  )}
                </BubbleList>
              </SpeedDial>
            )}
          <AttachHousehold
            closeDialog={() => this.setState({ showAttachHousehold: false })}
            open={showAttachHousehold}
            referral={referral}
          />
          <PlacementAdd
            closeDialog={() => this.setState({ showPlacementAdd: false })}
            open={showPlacementAdd}
            referral={referral}
          />
          <DeletePrompt
            closeDialog={() => this.setState({ showDelPro: false })}
            open={showDelPro}
            delete={() => dispatch(deleteReferral(referral._id))}
            parentRoute={'/referral'}
          />
        </div>
      </div>
    );
  }
}

ReferralDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  referral: state.referral.detail,
  user: state.user.profile,
});

export default connect(mapStateToProps)(ReferralDetail);
