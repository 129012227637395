/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';

class GlobalError extends Component {
  render() {
    return (
      <div className="container">
        <div className="content">
          <div>
            <div
              className="list-search"
              style={{
                margin: 'auto',
                width: '50%',
                textAlign: 'center',
                padding: '5px',
              }}
            >
              An error occurred, please refresh the app.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GlobalError;
