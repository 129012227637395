import unionBy from 'lodash/unionBy';
import filter from 'lodash/filter';

import * as actions from './ReferralActions';
import { ROUTE_CHANGED } from '../App/AppActions';

const initialState = {
  list: [],
  detail: {},
  tab: 'open',
  search: {
    text: '',
    result: [],
  },
  info: {
    loading: false,
    start: -30,
    count: 30,
    hasMore: true,
    error: false,
  },
};

export default (state = initialState, action) => {
  const _referrals =
    action.start === 0 || action.tabChanged
      ? action.referrals
      : unionBy(state.list, action.referrals, '_id');

  const _list = state.list;

  if (action.type === ROUTE_CHANGED) {
    return { ...state, detail: {} };
  }
  switch (action.type) {
    case actions.REFERRALS_ERROR:
      return {
        ...state,
        info: { ...state.info, loading: false, error: action.error },
      };
    case actions.CHANGE_TAB:
      return {
        ...state,
        list: [],
        search: {
          text: '',
          result: [],
        },
        tab: action.tab,
      };
    case actions.CLEAR_SEARCH:
      return {
        ...state,
        search: {
          text: '',
          result: [],
        },
      };
    case actions.RENDER_REFERRALS:
      return {
        ...state,
        list: action.text ? _list : _referrals,
        search: {
          text: action.text,
          result: !action.text ? state.search.result : action.referrals,
        },
        info: {
          ...state.info,
          loading: false,
          hasMore: action.referrals.length === state.info.count,
        },
      };
    case actions.LOADING_REFERRALS:
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
          start: action.start,
        },
      };
    case actions.DISPLAY_REFERRAL:
      return {
        ...state,
        detail: action.referral,
      };
    case actions.UPDATE_REFERRAL:
      if (action.detail) {
        return {
          ...state,
          list: state.list.map(l => {
            if (l._id === action.referral) {
              return { ...l, ...action.body };
            }
            return l;
          }),
          detail: {
            ...state.detail,
            ...action.body,
          },
        };
      }
      return state;
    case actions.DELETE_REFERRAL:
      return {
        ...state,
        list: [...filter(state.list, l => l._id !== action.referral)],
        search: {
          ...state.search,
          result: [
            ...filter(state.search.result, r => r._id !== action.referral),
          ],
        },
      };
    default:
      return state;
  }
};
