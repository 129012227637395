import callApi from '../../util/apiCaller';

export function searchProspects(text, start, count, type) {
  return callApi(`prospect?start=${start}&count=${count}`, 'POST', {
    text,
    type,
  });
}

export function fetchProspect(_id) {
  return callApi(`prospect/${_id}`);
}

export function updateProspect(_id, body) {
  return callApi(`prospect/${_id}`, 'POST', body);
}

export function addProspect(body) {
  return callApi('prospect', 'PUT', body);
}

export function getProspectNotes(id) {
  return callApi(`prospect/${id}/note`);
}

export function filterProspectNotes(id, body) {
  return callApi(`prospect/${id}/note`, 'POST', body);
}

export function deleteProspect(_id) {
  return callApi(`prospect/${_id}`, 'DELETE');
}
