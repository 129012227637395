import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroller';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { Tabs, Tab } from 'material-ui/Tabs';
import { ListItem } from 'material-ui/List';

import SearchBox from '../../../components/SearchBox';
import HouseholdCard from '../components/HouseholdCard';

import { changeTab, clearSearch, searchHousehold } from '../HouseholdActions';

class HouseholdList extends Component {
  constructor(props) {
    super(props);
    this.doSearch = debounce(this.doSearch.bind(this), 800);
  }

  fetchMore() {
    const { count, start, tab, loading, dispatch, text } = this.props;
    if (!loading) {
      const newStart = start + count;
      dispatch(searchHousehold(text, newStart, count, tab));
    }
  }

  doSearch(val) {
    const { count, tab, loading, dispatch } = this.props;
    if (!loading) {
      dispatch(searchHousehold(val, 0, count, tab));
    }
  }

  handleTabOnActive(tab, val) {
    const { dispatch } = this.props;
    dispatch(changeTab(tab));
    const { count, loading } = this.props;
    if (!loading) {
      dispatch(searchHousehold(val, 0, count, tab, 'tabChanged'));
    }
  }

  handleSearchButtonOnClick(val) {
    this.doSearch(val);
  }

  handleSearchBoxOnChange(val) {
    this.doSearch(val);
  }

  handleClearButtonOnClick() {
    this.props.dispatch(clearSearch());
    this.doSearch(null);
  }

  render() {
    const {
      tab,
      search,
      households,
      start,
      hasMore,
      error,
      /* location, */
    } = this.props;
    const list = search.text ? search.result : households;
    let hint = '';
    // switch (location.pathname) {
    //   case '/household':
    //     hint = 'Search households';
    //     break;
    //   default:
    //     hint = 'Search';
    // }
    switch (tab) {
      case 'open':
        hint = 'Search open households';
        break;
      case 'closed':
        hint = 'Search closed/inactive households';
        break;
      case 5:
        hint = 'Search external households';
        break;
      case 4:
        hint = 'Search family households';
        break;
      case 3:
        hint = 'Search community households';
        break;
      case 2:
        hint = 'Search kennerley households';
        break;
      default:
        hint = 'Search households';
    }
    if (tab === 5) {
      hint = 'Search external households';
    } else if (tab === 4) {
      hint = 'Search family households';
    } else if (tab === 3) {
      hint = 'Search community households';
    } else if (tab === 2) {
      hint = 'Search kennerley households';
    } else {
      hint = 'Search households';
    }

    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Households - HEART1869</title>
          <meta name="description" content="Kennerley HEART1869 Households" />
        </Helmet>
        <div className="content">
          <div className="list-tabs" style={{ overflowY: 'scroll' }}>
            <Tabs
              style={{
                maxWidth: '900px',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingBottom: '5px',
              }}
              value={tab}
            >
              <Tab
                label="Active"
                value="open"
                onActive={() => {
                  this.handleTabOnActive('open', search.text);
                }}
              />
              <Tab
                label="Inactive"
                value="closed"
                onActive={() => {
                  this.handleTabOnActive('closed', search.text);
                }}
              />
              <Tab
                label="Kennerley"
                value={2}
                onActive={() => {
                  this.handleTabOnActive(2, search.text);
                }}
              />
              <Tab
                label="Community"
                value={3}
                onActive={() => {
                  this.handleTabOnActive(3, search.text);
                }}
              />
              <Tab
                label="FCP"
                value={4}
                onActive={() => {
                  this.handleTabOnActive(4, search.text);
                }}
              />
              <Tab
                label="External"
                value={5}
                onActive={() => {
                  this.handleTabOnActive(5, search.text);
                }}
              />
              <Tab
                label="All"
                value={1}
                onActive={() => {
                  this.handleTabOnActive(1, search.text);
                }}
              />
            </Tabs>
          </div>
          <div className="list-head">
            <SearchBox
              hintText={hint}
              searchText={search.text}
              onClickSearch={val => {
                this.handleSearchButtonOnClick(val);
              }}
              onChangeSearch={val => {
                this.handleSearchBoxOnChange(val);
              }}
              onClickClear={() => {
                this.handleClearButtonOnClick();
              }}
            />
          </div>
          <div className="list-body" style={{ height: '87.05%' }}>
            <InfiniteScroll
              className="list-section"
              useWindow={false}
              pageStart={start}
              loadMore={() => this.fetchMore(start)}
              hasMore={!error && hasMore}
              initialLoad
            >
              {!isEmpty(list) ? (
                list.map(h => (
                  <HouseholdCard
                    household={h}
                    caller="households"
                    rel="household"
                    key={h._id}
                  />
                ))
              ) : (
                <ListItem disabled>
                  {error
                    ? 'An error occurred, please refresh the app.'
                    : 'No results.'}
                </ListItem>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

HouseholdList.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tab: state.household.tab,
  search: state.household.search,
  households: state.household.list,
  start: state.household.info.start,
  count: state.household.info.count,
  loading: state.household.info.loading,
  hasMore: state.household.info.hasMore,
  error: state.household.info.error,
});

export default connect(mapStateToProps)(HouseholdList);
