const editable = true;
const required = true;

export default [
  {
    field: 'classification',
    name: 'Payment Classification',
    type: 'select',
    section: 'payments',
    editable,
    required,
    useKeywords: true,
  },
  {
    field: 'dateStart',
    name: 'Payment Start',
    type: 'date',
    section: 'payments',
    editable,
    required,
  },
  {
    field: 'dateEnd',
    name: 'Payment End',
    type: 'date',
    section: 'payments',
    editable,
  },
];
