import unionBy from 'lodash/unionBy';
import each from 'lodash/each';
import filter from 'lodash/filter';
// import find from 'lodash/find';
// import merge from 'lodash/merge';

import * as actions from './PlacementActions';
import { ROUTE_CHANGED } from '../App/AppActions';
import { isEmpty } from 'lodash';

const initialState = {
  list: [],
  detail: {},
  noteDetail: {},
  tab: 'open',
  search: {
    text: '',
    result: [],
  },
  info: {
    loading: false,
    start: -30,
    count: 30,
    hasMore: true,
    error: false,
  },
};

export default (state = initialState, action) => {
  const _cases =
    action.start === 0 || action.tabChanged
      ? action.cases
      : unionBy(state.list, action.cases, '_id');

  const _list = state.list;

  if (action.type === ROUTE_CHANGED) {
    return { ...state, detail: {} };
  }
  switch (action.type) {
    case actions.CASES_ERROR:
      return {
        ...state,
        info: { ...state.info, loading: false, error: action.error },
      };
    case actions.CHANGE_TAB:
      return {
        ...state,
        list: [],
        search: {
          text: '',
          result: [],
        },
        tab: action.tab,
      };
    case actions.CLEAR_SEARCH:
      return {
        ...state,
        search: {
          text: '',
          result: [],
        },
      };
    case actions.RENDER_CASES:
      return {
        ...state,
        list: action.text ? _list : _cases,
        search: {
          text: action.text,
          result: !action.text ? state.search.result : action.cases,
        },
        info: {
          ...state.info,
          loading: false,
          hasMore: action.cases.length === state.info.count,
        },
      };
    case actions.LOADING_CASES:
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
          start: action.start,
        },
      };
    case actions.DISPLAY_CASE:
      return {
        ...state,
        detail: action.caseLoad,
      };
    case actions.UPDATE_CASE:
      if (action.detail) {
        return {
          ...state,
          list: state.list.map(l => {
            if (l._id === action.caseLoad) {
              return { ...l, ...action.body };
            }
            return l;
          }),
          detail: {
            ...state.detail,
            ...action.body,
          },
        };
      }
      return state;
    case actions.UPDATE_NOTE:
      return {
        ...state,
        noteDetail: {
          ...state.noteDetail,
          ...action.body,
        },
      };
    case actions.ADD_NOTE:
      if (!isEmpty(state.detail)) {
        return {
          ...state,
          detail: {
            ...state.detail,
            notes: [action.note, ...state.detail.notes],
          },
        };
      }
      return state;
    case actions.GET_NOTES:
      return {
        ...state,
        detail: {
          ...state.detail,
          notes: action.notes,
        },
      };
    case actions.GET_NOTE:
      return {
        ...state,
        noteDetail: action.note,
      };
    case actions.EXIT_NOTE:
      return {
        ...state,
        noteDetail: {},
      };
    case actions.UPDATE_ACCOMMODATION:
      return {
        ...state,
        detail: {
          ...state.detail,
          accommodation: each(state.detail.accommodation, (value, key) => {
            if (value._id === action.accId) {
              state.detail.accommodation[key] = action.accommodation;
            }
          }),
        },
      };
    case actions.DELETE_CASE:
      return {
        ...state,
        list: [...filter(state.list, l => l._id !== action.caseLoad)],
        search: {
          ...state.search,
          result: [
            ...filter(state.search.result, r => r._id !== action.caseLoad),
          ],
        },
      };
    default:
      return state;
  }
};
