import React, { Component } from 'react';
import moment from 'moment';

import { ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar';

import { colours, icons } from '../../../theme';

class AccommodationCard extends Component {
  render() {
    const { accommodation, showDialog } = this.props;
    const {
      dateStart,
      dateEnd,
      household,
      youngPerson,
      alternate,
    } = accommodation;
    const now = new Date();
    const { readOnly } = this.props;
    let colour = colours.kGrey;
    if (!dateEnd || new Date(dateEnd) > now) {
      if (new Date(dateStart) < now) colour = colours.skyBlue;
      else colour = colours.paleBlue;
    }
    return (
      <div className="person-card">
        <icons.AccommodationIcon className="person-card-back" color={colour} />
        <ListItem
          primaryText={
            alternate || household.name || youngPerson.contact.fullName
          }
          secondaryText={`${moment(dateStart).format('ll')} - ${
            dateEnd ? moment(dateEnd).format('ll') : 'Present'
          }`}
          leftAvatar={
            <Avatar
              icon={<icons.AccommodationIcon />}
              backgroundColor={colours.kGrey}
            />
          }
          style={{ borderLeft: `5px solid ${colour}` }}
          onClick={() => showDialog()}
          disabled={readOnly}
        />
      </div>
    );
  }
}

export default AccommodationCard;
