import unionBy from 'lodash/unionBy';
// import extend from 'lodash/extend';
// import filter from 'lodash/filter';
// import isEmpty from 'lodash/isEmpty';

import * as actions from './AccessDBActions';
import { ROUTE_CHANGED } from '../App/AppActions';

const initialState = {
  list: [],
  detail: {},
  search: {
    text: '',
    result: [],
  },
  info: {
    loading: false,
    start: -30,
    count: 30,
    hasMore: true,
    error: false,
  },
};

export default (state = initialState, action) => {
  const _clients =
    action.start === 0 || action.tabChanged
      ? action.clients
      : unionBy(state.list, action.clients);

  const _list = state.list;

  if (action.type === ROUTE_CHANGED) {
    return { ...state, detail: {} };
  }

  switch (action.type) {
    case actions.ACCESSDB_ERROR:
      return {
        ...state,
        info: { ...state.info, loading: false, error: action.error },
      };
    case actions.CLEAR_SEARCH:
      return {
        ...state,
        search: {
          text: '',
          result: [],
        },
      };

    case actions.LOADING_CLIENTS:
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
          start: action.start,
        },
      };

    case actions.RENDER_CLIENTS:
      return {
        ...state,
        list: action.text ? _list : _clients,
        search: {
          text: action.text,
          result: !action.text ? state.search.result : action.clients,
        },
        info: {
          ...state.info,
          loading: false,
          hasMore: action.clients.length === state.info.count,
        },
      };

    case actions.RENDER_FULL_INDIVIDUAL_CLIENT_REPORT:
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
};
