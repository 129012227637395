import config from '../config';

export default function callApi(
  endpoint,
  method = 'get',
  body,
  fetchParams = {}
) {
  /* eslint no-param-reassign: off */
  const token = localStorage.getItem('token');
  const headers = {
    'content-type': 'application/json',
  };
  if (token) {
    headers.token = token;
  }
  if (typeof body === 'object') {
    body = JSON.stringify(body);
  }
  return fetch(`${config.server}/${endpoint}`, {
    headers,
    method,
    body,
    ...fetchParams,
  }).then(
    response => {
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          localStorage.removeItem('token');
          return Promise.reject();
        }
        return Promise.reject();
      }
      /* no content */
      if (response.status === 204) {
        return {};
      }
      return response.json().catch(() => ({}));
    },
    () => Promise.reject()
  );
}

export function callDownloadApi(
  endpoint,
  method = 'get',
  body,
  fetchParams = {}
) {
  /* eslint no-param-reassign: off */
  const token = localStorage.getItem('token');
  const headers = {
    'content-type': 'application/json',
  };
  if (token) {
    headers.token = token;
  }
  if (typeof body === 'object') {
    body = JSON.stringify(body);
  }
  return fetch(`${config.server}/${endpoint}`, {
    headers,
    method,
    body,
    ...fetchParams,
  }).then(
    response => {
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          localStorage.removeItem('token');
          return Promise.reject();
        }
        return Promise.reject();
      }
      return response.blob().catch(() => ({}));
    },
    () => Promise.reject()
  );
}

export function withCredentials(endpoint, method, body) {
  return callApi(endpoint, method, body, { credentials: 'include' });
}
