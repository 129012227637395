import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ListItem } from 'material-ui/List';

import PersonCard from '../../../components/PersonCard';

class PC extends Component {
  render() {
    const { pc } = this.props;
    return pc ? (
      <div className="dash-col">
        <ListItem
          primaryTogglesNestedList
          style={{ backgroundColor: 'white' }}
          nestedItems={pc.staff.map(s => (
            <PersonCard person={s} rel="staff" key={s._id} />
          ))}
          primaryText={`Staff (${pc.staff.length})`}
        />
        <ListItem
          primaryTogglesNestedList
          style={{ backgroundColor: 'white' }}
          nestedItems={pc.carers.map(s => (
            <PersonCard
              person={s}
              rel={s.approvalDate ? 'carer' : 'prospect'}
              key={s._id}
              caller="dashboard"
            />
          ))}
          primaryText={`Carers (${pc.carers.length})`}
        />
        <ListItem
          primaryTogglesNestedList
          style={{ backgroundColor: 'white' }}
          nestedItems={pc.members.map(s => (
            <PersonCard
              person={s}
              rel="other"
              key={s._id}
              householdLink={<Link to={`/household/${s.household._id}`} />}
              caller="dashboard"
            />
          ))}
          primaryText={`Household Members (${pc.members.length})`}
        />
      </div>
    ) : (
      <div className="dash-col">
        <ListItem
          disabled
          style={{ backgroundColor: 'white' }}
          primaryText="No Reviews Due"
        />
      </div>
    );
  }
}

export default PC;
