const editable = true;

export default [
  { field: 'category', name: 'Category', editable },
  { field: 'communication', name: 'Communication', editable },
  { field: 'incidentReporting', name: 'Incident Reporting', editable },
  {
    field: 'howDidTheyHear',
    name: 'How did they hear about Kennerley?',
    editable,
  },
  { field: 'enquiryCareType', name: 'Suggested Care Type', editable },
  { field: 'servicesProvided', name: 'Services Requested', editable },
  { field: 'leftCareReason', name: 'Reason Left Care', editable },
  { field: 'gender', name: 'Gender', editable },
  { field: 'severity', name: 'Health Severity', editable },
  { field: 'aboriginal', name: 'First Nations Connection', editable },
  { field: 'school', name: 'School', editable },
  { field: 'tertiary', name: 'Undertaking Tertiary Education', editable },
  { field: 'source', name: 'Source of Referral', editable },
  { field: 'notPlacingReasons', name: 'Reasons for Not Placing', editable },
  { field: 'classification', name: 'Payment Classification', editable },
];
