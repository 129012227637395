import keys from 'lodash/keys';
import find from 'lodash/find';

import ReferralModel from './ReferralModel';

import * as ReferralAPI from './ReferralAPI';

import { notification } from '../App/AppActions';

export const RENDER_REFERRALS = 'referral/RENDER_REFERRALS';
export const LOADING_REFERRALS = 'referral/LOADING_REFERRALS';
export const DISPLAY_REFERRAL = 'referral/DISPLAY_REFERRAL';
export const UPDATE_REFERRAL = 'referral/UPDATE_REFERRAL';
export const CHANGE_TAB = 'referral/CHANGE_TAB';
export const CLEAR_SEARCH = 'referral/CLEAR_SEARCH';
export const DELETE_REFERRAL = 'referral/DELETE_REFERRAL';
export const REFERRALS_ERROR = 'referral/REFERRALS_ERROR';

export function changeTab(tab) {
  return {
    type: CHANGE_TAB,
    tab,
  };
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

export function renderReferrals(text, tab, tabChanged, referrals) {
  return {
    type: RENDER_REFERRALS,
    text,
    tab,
    tabChanged,
    referrals,
  };
}

export function getReferral(referral) {
  return {
    type: DISPLAY_REFERRAL,
    referral,
  };
}

export function updateReferral(referral, body, detail) {
  return {
    type: UPDATE_REFERRAL,
    referral,
    body,
    detail,
  };
}

export function loadMore(start) {
  return {
    type: LOADING_REFERRALS,
    start,
  };
}

export function _deleteReferral(referral) {
  return {
    type: DELETE_REFERRAL,
    referral,
  };
}

export function setError(error = false) {
  return {
    type: REFERRALS_ERROR,
    error,
  };
}

export function searchReferrals(text, start, count, type, tabChanged) {
  return dispatch => {
    dispatch(loadMore(start));
    return ReferralAPI.searchReferrals(text, start, count, type).then(
      referrals => {
        dispatch(renderReferrals(text, type, tabChanged, referrals));
      },
      err => dispatch(setError(err || true))
    );
  };
}

export function fetchReferral(_id) {
  return dispatch =>
    ReferralAPI.fetchReferral(_id).then(
      referral => {
        if (referral) {
          return dispatch(getReferral(referral));
        }
        return dispatch(getReferral({ deleted: true }));
      },
      () => Promise.reject()
    );
}

export function modifyReferral(
  _id,
  body,
  prevVal,
  undo = false,
  detail = true
) {
  return dispatch =>
    ReferralAPI.updateReferral(_id, body).then(() => {
      dispatch(updateReferral(_id, body, detail));
      if (!undo) {
        dispatch(
          notification(
            `${find(ReferralModel, { field: keys(body)[0] }).name} updated.`,
            'UNDO',
            modifyReferral(_id, prevVal, '', true)
          )
        );
      } else {
        dispatch(
          notification(
            `Changes to ${
              find(ReferralModel, { field: keys(body)[0] }).name
            } undone.`
          )
        );
      }
    });
}

export function submitReferral(body) {
  return dispatch =>
    ReferralAPI.addReferral(body).then(referral => {
      dispatch(getReferral(referral));
      return referral;
    });
}

export function addReferralHousehold(body, household) {
  return dispatch =>
    ReferralAPI.addReferralHousehold(body).then(() => {
      dispatch(updateReferral(body._id, { household }, true));
      dispatch(notification('Household added.'));
    });
}

export function deleteReferral(ref) {
  return dispatch =>
    ReferralAPI.deleteReferral(ref).then(() => dispatch(_deleteReferral(ref)));
}
