import React, { Component } from 'react';
import LinearProgress from 'material-ui/LinearProgress';

import { colours } from '../theme';

class GlobalLoading extends Component {
  render() {
    const { isDialog } = this.props;
    const loader = (
      <LinearProgress
        color={colours.limeGreen}
        mode="indeterminate"
        style={{ marginBottom: '15px' }}
      />
    );
    return !isDialog ? <div className="container">{loader}</div> : loader;
  }
}

export default GlobalLoading;
