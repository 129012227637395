import React, { Component } from 'react';
import { ListItem } from 'material-ui/List';

import PersonCard from '../../../components/PersonCard';

class SCOC extends Component {
  render() {
    const { scoc } = this.props;
    return scoc ? (
      <div className="dash-col">
        <ListItem
          primaryTogglesNestedList
          style={{ backgroundColor: 'white' }}
          nestedItems={scoc.staff.map(s => (
            <PersonCard person={s} rel="staff" key={s._id} />
          ))}
          primaryText={`Staff (${scoc.staff.length})`}
        />
        <ListItem
          primaryTogglesNestedList
          style={{ backgroundColor: 'white' }}
          nestedItems={scoc.carers.map(s => (
            <PersonCard person={s} rel="carer" key={s._id} caller="dashboard" />
          ))}
          primaryText={`Carers (${scoc.carers.length})`}
        />
      </div>
    ) : (
      <div className="dash-col">
        <ListItem
          disabled
          style={{ backgroundColor: 'white' }}
          primaryText="No Safeguarding Children Online Certificates Due"
        />
      </div>
    );
  }
}

export default SCOC;
