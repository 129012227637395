import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Card } from 'material-ui/Card';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { ListItem } from 'material-ui/List';

import { getStaff, searchStaff } from '../StaffAPI';
import { loadStaff, setError } from '../StaffActions';

import Personcard from '../../../components/PersonCard';
import SearchBox from '../../../components/SearchBox';

// TODO REDUX
class StaffList extends Component {
  constructor() {
    super();
    this.state = { staff: [] };
    this.doSearch = debounce(this.doSearch.bind(this), 800);
  }
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadStaff(true));
    getStaff().then(
      staff => {
        this.setState({ staff, fullStaff: staff });
        dispatch(loadStaff(false));
      },
      err => dispatch(setError(err || true))
    );
  }
  doSearch(val) {
    if (val) {
      const { dispatch } = this.props;
      dispatch(loadStaff(true));
      searchStaff(val).then(
        staff => {
          this.setState({ staff });
          dispatch(loadStaff(false));
        },
        err => dispatch(setError(err || true))
      );
    } else {
      this.setState({ staff: this.state.fullStaff });
    }
  }
  searchOnClick(val) {
    this.doSearch(val);
  }
  searchOnChange(val) {
    this.doSearch(val);
  }
  clearOnClick() {
    // TODO dispatch clearsearch
    this.setState({ staff: this.state.fullStaff });
  }
  render() {
    const { staff } = this.state;
    const { error } = this.props;
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Staff - HEART1869</title>
          <meta name="description" content="Kennerley HEART1869 Staff" />
        </Helmet>
        <div className="content">
          <div className="list-head">
            <SearchBox
              hintText="Search staff"
              onClickSearch={val => this.searchOnClick(val)}
              onChangeSearch={val => this.searchOnChange(val)}
              onClickClear={() => this.clearOnClick()}
            />
          </div>
          <div className="list-body">
            <Card className="list-section">
              {!isEmpty(staff) ? (
                staff.map(s => (
                  <Personcard person={s} rel="staff" key={s._id} />
                ))
              ) : (
                <ListItem disabled>
                  {error
                    ? 'An error occurred, please refresh the app.'
                    : 'No results.'}
                </ListItem>
              )}
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.staff.loading,
  error: state.staff.error,
});

export default connect(mapStateToProps)(StaffList);
