import React, { Component } from 'react';
import moment from 'moment';

import { Donut, Legend } from 'britecharts-react';

import { ListItem } from 'material-ui/List';
import DatePicker from 'material-ui/DatePicker';

import NoteCard from '../../Placement/components/NoteCard';
import ResponsiveContainer from '../../../util/ResponsiveContainer';

class Incidents extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const datePickerSettings = {
      fullWidth: true,
      formatDate: d => moment(d).format('D MMMM YYYY'),
      autoOk: true,
      firstDayOfWeek: 1,
      style: { display: 'inline-block', width: '50%', padding: 5 },
      openToYearSelection: true,
    };
    const {
      incidents,
      from,
      to,
      colorSchema,
      fromUpdate,
      toUpdate,
    } = this.props;
    return incidents ? (
      <div className="dash-col">
        <ListItem
          style={{ backgroundColor: 'white' }}
          primaryText={`${incidents.length} Concerns in Care`}
          disabled
        />
        <div style={{ backgroundColor: 'white' }}>
          <DatePicker
            {...datePickerSettings}
            id="concernFrom"
            floatingLabelText="From"
            value={from}
            onChange={(e, concernFrom) => {
              fromUpdate(concernFrom);
              this.concernTo.openDialog();
            }}
          />
          <DatePicker
            ref={e => {
              this.concernTo = e;
            }}
            {...datePickerSettings}
            id="concernTo"
            floatingLabelText="To"
            value={to}
            onChange={(e, concernTo) => {
              toUpdate(concernTo);
            }}
          />
          <ListItem
            primaryText="View Chart"
            primaryTogglesNestedList
            nestedItems={[
              <ResponsiveContainer
                render={({ width }) => (
                  <div width={width} height={width / 1.5}>
                    <Donut
                      data={incidents || []}
                      shouldShowLoadingState={!incidents}
                      colorSchema={colorSchema}
                      width={width}
                      height={width / 1.5}
                      externalRadius={width / 3}
                      internalRadius={width / 6}
                      isAnimated={false}
                      customMouseOver={data =>
                        this.setState({ highlightedCareType: data.data.id })
                      }
                      customMouseOut={() =>
                        this.setState({ highlightedCareType: 99999 })
                      }
                    />
                    <Legend
                      data={incidents || []}
                      colorSchema={colorSchema}
                      numberFormat="d"
                      margin={{ top: 10, bottom: 10, left: 0, right: 30 }}
                      width={width}
                      height={incidents ? incidents.length * 33 : 33}
                      highlightEntryById={this.state.highlightedCareType}
                    />
                  </div>
                )}
              />,
            ]}
          />
          {incidents.length ? (
            incidents.map(i => (
              <ListItem
                secondaryText={`${i.name} (${i.quantity})`}
                key={i.id}
                primaryTogglesNestedList
                nestedItems={i.notes.map(n => (
                  <NoteCard
                    key={n._id}
                    note={n}
                    caseLoad={n.case}
                    household={n.household}
                    readOnly
                    goToPlacement
                  />
                ))}
              />
            ))
          ) : (
            <ListItem
              disabled
              style={{ backgroundColor: 'white' }}
              primaryText="No Incidents"
            />
          )}
        </div>
      </div>
    ) : (
      <div className="dash-col">
        <ListItem
          disabled
          style={{ backgroundColor: 'white' }}
          primaryText="No Incidents"
        />
      </div>
    );
  }
}

export default Incidents;
