import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroller';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { Tabs, Tab } from 'material-ui/Tabs';
import { ListItem } from 'material-ui/List';

import SearchBox from '../../../components/SearchBox';
import PersonCard from '../../../components/PersonCard';

import { changeTab, clearSearch, searchCarers } from '../CarerActions';

class CarerList extends Component {
  constructor(props) {
    super(props);
    this.doSearch = debounce(this.doSearch.bind(this), 800);
  }

  fetchMore() {
    const { count, start, tab, loading, dispatch, text } = this.props;
    if (!loading) {
      const newStart = start + count;
      dispatch(searchCarers(text, newStart, count, tab));
    }
  }

  doSearch(val) {
    const { count, tab, loading, dispatch } = this.props;
    if (!loading) {
      dispatch(searchCarers(val, 0, count, tab));
    }
  }

  handleTabOnActive(tab, val) {
    const { dispatch } = this.props;
    dispatch(changeTab(tab));
    const { count, loading } = this.props;
    if (!loading) {
      dispatch(searchCarers(val, 0, count, tab, 'tabChanged'));
    }
  }

  handleSearchButtonOnClick(val) {
    this.doSearch(val);
  }

  handleSearchBoxOnChange(val) {
    this.doSearch(val);
  }

  handleClearButtonOnClick() {
    this.props.dispatch(clearSearch());
    this.doSearch(null);
  }

  render() {
    const {
      tab,
      search,
      carers,
      start,
      hasMore,
      error,
      /* location, */
    } = this.props;
    const list = search.text ? search.result : carers;
    let hint = '';
    // switch (location.pathname) {
    //   case '/carer':
    //     hint = 'Search carers';
    //     break;
    //   case '/prospect':
    //     hint = 'Search prospects';
    //     break;
    //   default:
    //     hint = 'Search';
    // }
    switch (tab) {
      case 'open':
        hint = 'Search open carers';
        break;
      case 'closed':
        hint = 'Search closed/inactive carers';
        break;
      case 2:
        hint = 'Search kinship carers';
        break;
      default:
        hint = 'Search carers';
    }

    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Carers - HEART1869</title>
          <meta name="description" content="Kennerley HEART1869 Carers" />
        </Helmet>
        <div className="content">
          <div className="list-tabs" style={{ overflowY: 'scroll' }}>
            <Tabs
              style={{
                maxWidth: '900px',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingBottom: '5px',
              }}
              value={tab}
            >
              <Tab
                label="Active"
                value="open"
                onActive={() => {
                  this.handleTabOnActive('open', search.text);
                }}
              />
              <Tab
                label="Inactive"
                value="closed"
                onActive={() => {
                  this.handleTabOnActive('closed', search.text);
                }}
              />
              <Tab
                label="Kinship"
                value={2}
                onActive={() => {
                  this.handleTabOnActive(2, search.text);
                }}
              />
              <Tab
                label="All"
                value={1}
                onActive={() => {
                  this.handleTabOnActive(1, search.text);
                }}
              />
            </Tabs>
          </div>
          <div className="list-head">
            <SearchBox
              hintText={hint}
              searchText={search.text}
              onClickSearch={val => {
                this.handleSearchButtonOnClick(val);
              }}
              onChangeSearch={val => {
                this.handleSearchBoxOnChange(val);
              }}
              onClickClear={() => {
                this.handleClearButtonOnClick();
              }}
            />
          </div>
          <div className="list-body">
            <InfiniteScroll
              className="list-section"
              useWindow={false}
              pageStart={start}
              loadMore={() => this.fetchMore(start)}
              hasMore={!error && hasMore}
              initialLoad
            >
              {!isEmpty(list) ? (
                list.map(c => <PersonCard person={c} rel="carer" key={c._id} />)
              ) : (
                <ListItem disabled>
                  {error
                    ? 'An error occurred, please refresh the app.'
                    : 'No results.'}
                </ListItem>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

CarerList.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tab: state.carer.tab,
  search: state.carer.search,
  carers: state.carer.list,
  start: state.carer.info.start,
  count: state.carer.info.count,
  loading: state.carer.info.loading,
  hasMore: state.carer.info.hasMore,
  error: state.carer.info.error,
});

export default connect(mapStateToProps)(CarerList);
