import React, { Component } from 'react';

import flow from 'lodash/fp/flow';
import each from 'lodash/each';
import filter from 'lodash/fp/filter';

import config from '../config';

import TextField from './TextField';
import SelectField from './SelectField';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import Checkbox from './Checkbox';
import Address from './Address';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }
  getInitialState() {
    const { model, object } = this.props;
    const state = { object: {} };
    each(model, m => {
      state.object[m.field] = object[m.field] || '';
      if (state.object[m.field] === '' && m.multiSelect)
        state.object[m.field] = [];
      if (state.object[m.field] === '' && m.type === 'check')
        state.object[m.field] = false;
    });
    return state;
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ object: props.object });
  }
  renderField(param) {
    const { object } = this.state;
    const { create, readOnly, carer, youngPerson, caseLoad } = this.props;
    const value = object[param.field];
    const onUpdated = newVal => {
      this.setState({
        object: {
          ...object,
          [param.field]: newVal,
        },
      });
      this.props.onUpdated({ [param.field]: newVal });
    };
    const common = {
      param,
      key: param.field,
      value,
      onUpdated,
      create,
      readOnly,
    };
    switch (param.type) {
      case 'text':
      case 'number':
      case 'tel':
      case 'email':
        return <TextField {...common} />;
      case 'select':
        return <SelectField {...common} half={param.half} />;
      case 'date':
        return (
          <DatePicker
            {...common}
            carer={carer}
            youngPerson={youngPerson}
            caseLoad={caseLoad}
          />
        );
      case 'check':
        return <Checkbox {...common} />;
      case 'time':
        return <TimePicker {...common} />;
      case 'address':
        return (
          <Address
            param={param}
            key={param.field}
            value={value}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
              config.gmApiKey
            }&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '400px' }} />}
            onUpdated={onUpdated}
            create={create}
            readOnly={readOnly}
          />
        );
      default:
        return null;
    }
  }
  render() {
    const { section, edit, model } = this.props;
    const fields = flow(
      filter(p => !section || p.section === section),
      filter(p => !edit || p.editable)
    )(model);
    return <form>{fields.map(p => this.renderField(p))}</form>;
  }
}

export default Form;
