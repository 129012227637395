import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import uuid from 'uuid/v4';

import find from 'lodash/find';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import IconButton from 'material-ui/IconButton';
import CircularProgress from 'material-ui/CircularProgress';
import { List, ListItem } from 'material-ui/List';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { initializeIcons } from '@uifabric/icons';

import Dialog from './Dialog';
import Form from './Form';
import DatePicker from './DatePicker';
import DeletePrompt from './DeletePrompt';

import { icons, colours } from '../theme';
import config from '../config';
import { uploadFile, getRecordAttachments, deleteFile } from '../util/UtilAPI';
import bytesToSize from '../util/bytesToSize';
import AttachmentCategories from './AttachmentCategories';

import './Attachment.css';

const attachmentModel = [
  {
    field: 'dateRecorded',
    name: 'Date Recorded',
    editable: true,
    required: true,
    type: 'date',
  },
  {
    field: 'category',
    name: 'Category',
    editable: true,
    required: true,
    type: 'select',
    options: AttachmentCategories,
  },
  {
    field: 'description',
    name: 'Description',
    editable: true,
    type: 'text',
    multiLine: true,
  },
];

const fileTypeMap = [
  /* Media */
  { type: 'image', icon: 'FileImage' },
  { type: 'video', icon: 'Video' },
  /* Documents */
  { type: 'application/pdf', icon: 'PDF' },
  { type: 'application/msword', icon: 'WordDocument' },
  { type: 'application/vnd.ms-word', icon: 'WordDocument' },
  { type: 'application/vnd.oasis.opendocument.text', icon: 'WordDocument' },
  {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml',
    icon: 'WordDocument',
  },
  { type: 'application/vnd.ms-excel', icon: 'ExcelDocument' },
  {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml',
    icon: 'ExcelDocument',
  },
  {
    type: 'application/vnd.oasis.opendocument.spreadsheet',
    icon: 'ExcelDocument',
  },
  { type: 'application/vnd.ms-powerpoint', icon: 'PowerPointDocument' },
  {
    type: 'application/vnd.openxmlformats-officedocument.presentationml',
    icon: 'PowerPointDocument',
  },
  {
    type: 'application/vnd.oasis.opendocument.presentation',
    icon: 'PowerPointDocument',
  },
  { type: 'text/plain', icon: 'TextDocument' },
  { type: 'text/html', icon: 'TextDocument' },
  /* Archives */
  { type: 'application/gzip', icon: 'ZipFolder' },
  { type: 'application/zip', icon: 'ZipFolder' },
  { type: 'application/x-zip-compressed', icon: 'ZipFolder' },
];

class Attachment extends Component {
  static getIconName(attachment) {
    let mapIcon;
    let iconName;
    try {
      mapIcon = find(
        fileTypeMap,
        o =>
          (attachment.type || attachment.toUpload.type).indexOf(o.type) !== -1
      );
      iconName = mapIcon ? mapIcon.icon : 'Document';
    } catch (e) {
      iconName = 'Document';
    }
    return iconName;
  }
  constructor(props) {
    super(props);
    const attFrom = new Date();
    attFrom.setFullYear(attFrom.getFullYear() - 1);
    this.state = {
      category: props.category,
      files: props.record ? '' : [],
      toUpload: '',
      description: '',
      attFrom: null,
      attTo: null,
      loading: false,
      error: false,
    };
    this.onFileDrop = this.onFileDrop.bind(this);
    initializeIcons(undefined, { disableWarnings: true });
  }
  onFileDrop(files) {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const dateRecorded = new Date(file.lastModifiedDate);
      dateRecorded.setHours(0, 0, 0, 0);
      this.setState({
        toUpload: file,
        dateRecorded,
      });
    };
  }
  onClose() {
    this.setState({
      toUpload: '',
      description: '',
      dateRecorded: '',
      category: '',
    });
  }
  onSubmit() {
    const { record, recordType, newRecord, onFileSubmit } = this.props;
    const { toUpload, description, dateRecorded, category } = this.state;
    const path = `${recordType.toLowerCase()}/${record}/${uuid()}/${
      toUpload.name
    }`;
    if (newRecord) {
      onFileSubmit({
        toUpload,
        path,
        description,
        recordType,
        dateRecorded,
        category,
      });
      this.onClose();
    } else {
      this.setState({ loading: true, error: false });
      uploadFile(
        toUpload,
        path,
        description,
        recordType,
        record,
        dateRecorded,
        category
      ).then(
        () => {
          getRecordAttachments(record).then(recFiles => {
            this.setState({ files: recFiles, loading: false });
            this.onClose();
          });
        },
        () => this.setState({ error: true, loading: false })
      );
    }
  }
  onDelete(event, file) {
    event.preventDefault();
    this.setState({ toDelete: file });
  }
  renderFile(file, admin) {
    const { description, dateRecorded, category } = file || this.state;
    const link = {};
    let primaryText = `${(file.toUpload || file).name} (${bytesToSize(
      (file.toUpload || file).size
    )})`;
    let secondaryText = `${moment((file.toUpload || file).lastModified).format(
      'DD/MM/YY HH:mm:ss'
    )}${category ? ` - ${category}` : ''}${
      description ? ` - ${description}` : ''
    }`;
    if (file._id) {
      secondaryText = `${moment(dateRecorded).format('DD/MM/YY')}${
        category ? ` - ${category}` : ''
      }${description ? ` - ${description}` : ''}`;
      link.containerElement = (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          href={`${config.server}/file/${file.path}`}
          target="_blank"
          rel="noopener noreferrer"
          title={secondaryText}
        />
      );
      primaryText = `${file.name} (${bytesToSize(file.size)})`;
    }
    return (
      <ListItem
        leftIcon={
          <FontIcon
            iconName={Attachment.getIconName(file)}
            className="file-icon"
          />
        }
        primaryText={primaryText}
        secondaryText={secondaryText}
        key={file._id || primaryText}
        {...link}
        rightIconButton={
          admin && (
            <IconButton onClick={e => this.onDelete(e, file.path)}>
              <icons.DeleteIcon color={colours.kRed} key={file._id} />
            </IconButton>
          )
        }
        disabled={!file._id}
      />
    );
  }
  renderAddAttachment(toUpload) {
    const { description, dateRecorded, category, loading, error } = this.state;
    const object = { description, dateRecorded, category };
    return (
      <Dialog
        open={!!toUpload}
        onRequestClose={() => this.onClose()}
        title="Add Attachment"
        modal
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <FlatButton label="Cancel" primary onClick={() => this.onClose()} />,
          <RaisedButton
            label="Submit"
            primary
            disabled={loading || !category}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() => this.onSubmit()}
          />,
        ]}
      >
        <List>
          <Dropzone
            onDrop={newFiles => this.onFileDrop(newFiles)}
            className="dropZone"
            activeClassName="dropZoneActive"
          >
            {this.renderFile(toUpload)}
          </Dropzone>
        </List>
        <Form
          model={attachmentModel}
          object={object}
          onUpdated={val => this.setState(val)}
        />
      </Dialog>
    );
  }
  render() {
    const { toUpload, files, attFrom, attTo, toDelete } = this.state;
    const { filesToUpload, admin, record, readOnly } = this.props;
    if (!files) {
      getRecordAttachments(record).then(recFiles => {
        this.setState({ files: recFiles });
      });
      return null;
    }
    const filtered = filter(
      !isEmpty(filesToUpload) ? filesToUpload : files,
      f => {
        const dateRecorded = f.dateRecorded
          ? new Date(f.dateRecorded)
          : new Date(f.created);
        return (
          (!attFrom || dateRecorded >= attFrom) &&
          (!attTo || dateRecorded <= attTo)
        );
      }
    );
    return (
      <div className="attachment">
        {toUpload ? this.renderAddAttachment(toUpload) : null}
        <Dropzone
          onDrop={newFiles => this.onFileDrop(newFiles)}
          className="dropZone"
          activeClassName="dropZoneActive"
          disabledClassName="dropZonedDisabled"
          disabled={!!toUpload}
        >
          {!readOnly && (
            <ListItem
              leftIcon={<icons.AttachmentIcon color={colours.skyBlue} />}
              primaryText="Add Attachment"
            />
          )}
        </Dropzone>
        <ListItem disabled secondaryText={`Total: ${filtered.length}`} />
        <DatePicker
          expandable
          half
          param={{
            field: 'attFrom',
            name: 'Filter Attachments From',
            editable: true,
          }}
          value={attFrom}
          onUpdated={attFrom => this.setState({ attFrom })}
        />
        <DatePicker
          expandable
          half
          param={{ field: 'attTo', name: 'To', editable: true }}
          value={attTo}
          onUpdated={attTo => this.setState({ attTo })}
        />
        {filtered.map(f => this.renderFile(f, admin))}
        <DeletePrompt
          closeDialog={() => this.setState({ toDelete: '' })}
          open={!!toDelete}
          delete={() =>
            deleteFile(toDelete).then(() => {
              getRecordAttachments(record).then(recFiles => {
                this.setState({ files: recFiles, toDelete: '' });
              });
            })
          }
        />
      </div>
    );
  }
}

export default Attachment;
