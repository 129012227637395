import io from 'socket.io-client';

import config from '../config';

let connecting;
let token;

export function getSocket(newToken) {
  if (newToken) token = newToken;
  if (!connecting) {
    connecting = new Promise((resolve, reject) => {
      const url = new URL(config.server);
      const socket = io(url.origin, {
        path: `${url.pathname.replace(/\/$/, '')}/socket.io`,
        transports: ['polling', 'websocket'],
        autoConnect: true,
        rememberUpgrade: true,
        withCredentials: true,
        auth: {
          token,
        },
      });
      const firstConnect = () => {
        resolve(socket);
        socket.removeListener('connect', firstConnect);
      };
      socket.on('connect', firstConnect);
    });
  }
  return connecting;
}

export function reInitSocket(newToken) {
  getSocket(newToken).then(socket => {
    const onDisconnect = () =>
      setTimeout(() => {
        socket.connect();
        socket.removeListener('disconnect', onDisconnect);
      }, 3000);

    socket.on('disconnect', onDisconnect);
    socket.disconnect();
  });
}

export function emit(event, data, token) {
  getSocket().then(socket => socket.emit(event, data));
}

export function on(event, handler, token) {
  getSocket(token).then(socket => socket.on(event, handler));
}

export function off(event, listener, token) {
  getSocket().then(socket => socket.removeListener(event, listener));
}
