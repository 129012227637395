const multiSelect = true;
const multiLine = true;
const editable = true;

export default [
  {
    field: 'careType',
    name: 'Type of Care',
    type: 'select',
    options: [
      'Foster Care',
      'Respite Care',
      'Community Respite Care',
      'Family Connections Program',
    ],
    section: 'main',
    multiSelect,
    editable,
  },
  {
    field: 'kinship',
    name: 'Kinship',
    type: 'check',
    section: 'main',
    editable,
  },
  {
    field: 'servicesProvided',
    name: 'Services Provided',
    type: 'select',
    useKeywords: true,
    multiSelect,
    section: 'main',
    editable,
  },
  {
    field: 'fosterCapacity',
    name: 'Foster Capacity',
    type: 'number',
    section: 'main',
    editable,
  },
  {
    field: 'respiteCapacity',
    name: 'Respite Capacity',
    type: 'number',
    section: 'main',
    editable,
  },
  {
    field: 'restrictions',
    name: 'Placement Restrictions and Conditions',
    type: 'text',
    section: 'main',
    editable,
    multiLine,
  },
];
