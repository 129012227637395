import callApi, { callDownloadApi } from '../../util/apiCaller';

export function getStaff() {
  return callApi('staff');
}

export function fetchStaff(id) {
  return callApi(`staff/${id}`);
}

export function fetchMe() {
  return callApi('staff/me');
}

export function updateMe(body) {
  return callApi('staff/me', 'POST', body);
}

export function updateStaff(id, body) {
  return callApi(`staff/${id}`, 'POST', body);
}

export function searchStaff(text) {
  return callApi('staff/search', 'POST', { text });
}

export function getCoordinators() {
  return callApi('staff/coordinators');
}

export function exportStaff(_id, body) {
  return callDownloadApi(`staff/${_id}/export`, 'POST', body);
}
