import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import extend from 'lodash/extend';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import isDate from 'lodash/isDate';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

import trainingModel from '../TrainingModel';
import { updateTraining, deleteTraining } from '../TrainingActions';
import { fetchCarer } from '../../Carer/CarerActions';
import { fetchProspect } from '../../Carer/ProspectActions';

import Form from '../../../components/Form';
import Dialog from '../../../components/Dialog';
import DeletePrompt from '../../../components/DeletePrompt';

import { colours } from '../../../theme';

class TrainingView extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }
  // eslint-disable-next-line class-methods-use-this
  getInitialState() {
    const training = {};
    each(trainingModel, c => {
      training[c.field] = '';
      if (c.multiSelect) training[c.field] = [];
    });

    return {
      training,
      valid: false,
      stepIndex: 0,
      loading: false,
      error: false,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ training: { ...props.training } });
  }
  onChange(val, object) {
    this.setState({ [object]: extend(this.state[object], val) });
    this.validate();
  }

  onDialogClose(action) {
    const { parentType, dispatch, closeDialog } = this.props;
    const { training } = this.state;
    if (action === 'save') {
      this.setState({ loading: true, error: false });
      dispatch(updateTraining(parentType, training)).then(
        newTraining => {
          if (newTraining) {
            this.setState({ training: {}, valid: false });
            if (parentType === 'Carer') {
              dispatch(fetchCarer(training.assessed));
            } else if (parentType === 'Prospect') {
              dispatch(fetchProspect(training.assessed));
            }
            closeDialog();
          } else this.setState({ loading: false, error: true });
        },
        () => this.setState({ loading: false, error: true })
      );
    } else if (action === 'delete') {
      if (parentType === 'Carer') {
        dispatch(fetchCarer(training.assessed));
      } else if (parentType === 'Prospect') {
        dispatch(fetchProspect(training.assessed));
      }
      closeDialog();
    } else {
      closeDialog();
    }
  }
  deleteTraining() {
    const { dispatch, parentType } = this.props;
    return dispatch(deleteTraining(parentType, this.state.training._id)).then(
      () => this.onDialogClose('delete')
    );
  }
  validate() {
    const { training, stepIndex } = this.state;
    let valid = true;
    if (stepIndex === 0) {
      each(trainingModel, c => {
        if (c.required && valid) {
          valid = !isEmpty(training[c.field]) || isDate(training[c.field]);
          if (training.module === 'Other' && isEmpty(training.moduleOther))
            valid = false;
        }
      });
    }
    this.setState({
      training,
      valid,
    });
  }

  render() {
    const {
      valid,
      doNotCheckSemiRequired,
      training,
      showDelPro,
      error,
      loading,
    } = this.state;
    const { open, user } = this.props;
    const style = {
      marginLeft: 12,
    };
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title="Training"
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <small
            style={{
              color: !doNotCheckSemiRequired ? colours.kRed : colours.kGrey,
            }}
          >
            Module, Start Date required
          </small>,
          <FlatButton
            label="Cancel"
            primary
            style={style}
            onClick={() => this.onDialogClose()}
          />,
          (user.roles.includes('admin') ||
            user.roles.includes('moderator')) && (
            <FlatButton
              label="Delete"
              primary
              style={{
                ...style,
                backgroundColor: colours.kRed,
                color: 'white',
              }}
              onClick={() => this.setState({ showDelPro: true })}
            />
          ),
          <RaisedButton
            label="Save"
            primary
            disabled={loading || !valid}
            style={style}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() => this.onDialogClose(isEmpty(training) ? '' : 'save')}
          />,
        ]}
      >
        <span />
        <Form
          create
          model={trainingModel}
          object={training}
          section="main"
          onUpdated={val => this.onChange(val, 'training')}
        />
        <DeletePrompt
          closeDialog={() => this.setState({ showDelPro: false })}
          open={!!showDelPro}
          delete={() => this.deleteTraining()}
        />
      </Dialog>
    );
  }
}

TrainingView.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ user: state.user.profile });

export default connect(mapStateToProps)(TrainingView);
