import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import extend from 'lodash/extend';
import debounce from 'lodash/debounce';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import isDate from 'lodash/isDate';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import { List, ListItem } from 'material-ui/List';

import { submitYoungPerson } from '../YoungPersonActions';
import contactModel from '../../Contact/ContactModel';
import Dialog from '../../../components/Dialog';
import ContactCard from '../../Contact/components/ContactCard';
import Form from '../../../components/Form';
import { findContacts } from '../../Contact/ContactAPI';

import { colours } from '../../../theme';

class YoungPersonAdd extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.findExistingContacts = debounce(
      this.findExistingContacts.bind(this),
      800
    );
  }
  // eslint-disable-next-line class-methods-use-this
  getInitialState() {
    const contact = { userType: 'young' };
    each(contactModel, c => {
      contact[c.field] = '';
      if (c.multiSelect) contact[c.field] = [];
      if (c.field === 'gender') contact[c.field] = 'Not Specified';
      if (c.type === 'address') contact[c.field] = {};
    });
    return {
      contact,
      sections: {},
      stepIndex: 0,
      redirect: '',
      suggestions: [],
      doNotCheckSemiRequired: false,
      valid: false,
      loading: false,
      error: false,
    };
  }
  onChange(val, object) {
    this.setState({ [object]: extend(this.state[object], val) });
    if (object === 'contact') {
      this.findExistingContacts();
    }
    this.validate();
  }
  onDialogClose() {
    this.setState(this.getInitialState());
    this.props.closeDialog();
  }
  getStepContent() {
    const { suggestions, contact } = this.state;
    return (
      <div>
        <List>
          <ListItem
            style={{
              backgroundColor: !isEmpty(suggestions) && colours.paleLimeGreen,
            }}
            primaryTogglesNestedList
            nestedItems={suggestions.map(s => (
              <ListItem
                key={s._id}
                nestedItems={[
                  <ListItem key={`${s._id}-card`}>
                    <ContactCard
                      contact={s}
                      readOnly
                      redirectTo={`/young/${s.youngPerson}`}
                    />
                  </ListItem>,
                ]}
              >
                {s.fullName}
              </ListItem>
            ))}
          >
            Suggestions{!isEmpty(suggestions) ? ` (${suggestions.length})` : ''}
          </ListItem>
        </List>
        <Form
          create
          model={contactModel}
          object={contact}
          onUpdated={val => this.onChange(val, 'contact')}
          youngPerson
        />
      </div>
    );
  }
  validate() {
    const { stepIndex, contact } = this.state;
    let valid = true;
    const doNotCheckSemiRequired = true; // change to false if need restrictions
    if (stepIndex === 0) {
      each(contactModel, c => {
        // if (c.semiRequired && !doNotCheckSemiRequired) {
        //   if (c.type === 'address' && !isEmpty(contact[c.field])) {
        //     const address = contact[c.field];
        //     doNotCheckSemiRequired = address.street_number && address.route && address.locality && address.administrative_area_level_1 && address.country && address.postal_code;
        //   } else doNotCheckSemiRequired = !isEmpty(contact[c.field]) || isDate(contact[c.field]);
        // }
        if (c.required && valid) {
          valid = !isEmpty(contact[c.field]) || isDate(contact[c.field]);
          if (c.type === 'address' && valid) {
            const address = contact[c.field];
            valid =
              address.street_number &&
              address.route &&
              address.locality &&
              address.administrative_area_level_1 &&
              address.country &&
              address.postal_code;
          }
        }
      });
      if (!doNotCheckSemiRequired) valid = false;
    }
    this.setState({ valid, doNotCheckSemiRequired });
  }
  findExistingContacts() {
    const { givenName, surname, aka, email } = this.state.contact;
    const contact = {};
    if (givenName) contact.givenName = givenName;
    if (surname) contact.surname = surname;
    if (aka) contact.aka = aka;
    if (email) contact.email = email;
    if (!isEmpty(contact)) {
      findContacts(contact, 'young').then(suggestions => {
        this.setState({ suggestions });
      });
    }
  }
  handleNext(lastPage) {
    const { stepIndex, contact } = this.state;
    if (stepIndex === lastPage) {
      this.setState({ loading: true, error: false });
      this.props.dispatch(submitYoungPerson({ contact })).then(
        newYP => {
          if (newYP) {
            this.setState({
              redirect: <Redirect to={`/young/${newYP._id}`} />,
            });
            this.onDialogClose();
          } else this.setState({ loading: false, error: true });
        },
        () => this.setState({ loading: false, error: true })
      );
    } else {
      this.setState({ stepIndex: stepIndex + 1 }, () => this.validate());
    }
  }
  handlePrev() {
    const { stepIndex } = this.state;
    if (stepIndex === 0) {
      this.onDialogClose();
      return;
    }
    this.setState({ stepIndex: stepIndex - 1 });
  }
  render() {
    const {
      stepIndex,
      valid,
      /* doNotCheckSemiRequired , */ redirect,
      error,
      loading,
    } = this.state;
    if (redirect) return redirect;
    const lastPage = 0;
    const { open } = this.props;
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title="Add Young Person"
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <FlatButton
            label={stepIndex === 0 ? 'Cancel' : 'Back'}
            primary
            onClick={() => this.handlePrev()}
          />,
          <RaisedButton
            label={stepIndex === lastPage ? 'Submit' : 'Next'}
            primary
            disabled={loading || !valid}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() => this.handleNext(lastPage)}
          />,
        ]}
      >
        {this.getStepContent()}
      </Dialog>
    );
  }
}

YoungPersonAdd.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(YoungPersonAdd);
