export default [
  {
    field: 'dateStart',
    name: 'From',
    type: 'date',
    required: true,
    editable: true,
    section: 'date',
  },
  {
    field: 'dateEnd',
    name: 'To',
    type: 'date',
    required: true,
    editable: true,
    section: 'date',
  },
  {
    field: 'alternate',
    name: 'Other Arrangement',
    type: 'text',
    required: false,
    editable: true,
    section: 'alternate',
    multiLine: true,
  },
];
