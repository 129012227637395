import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import extend from 'lodash/extend';
import debounce from 'lodash/debounce';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import isDate from 'lodash/isDate';
import filter from 'lodash/filter';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import { List, ListItem } from 'material-ui/List';
import { Step, Stepper, StepLabel } from 'material-ui/Stepper';

import config from '../../../config';

import Dialog from '../../../components/Dialog';
import contactModel, { otherContactModel } from '../../Contact/ContactModel';
import { findContacts } from '../../Contact/ContactAPI';
import ContactCard from '../../Contact/components/ContactCard';
import Form from '../../../components/Form';
import referralModel from '../ReferralModel';
import PersonCard from '../../../components/PersonCard';
import { submitReferral } from '../ReferralActions';

import { colours, icons } from '../../../theme';
import './ReferralAdd.css';
import { StepButton } from 'material-ui';

class ReferralAdd extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
    this.findExistingContacts = debounce(
      this.findExistingContacts.bind(this),
      800
    );
  }
  getInitialState() {
    const { youngPerson } = this.props;
    const contact = { userType: 'young' };
    each(contactModel, c => {
      contact[c.field] = '';
      if (c.multiSelect) contact[c.field] = [];
      if (c.field === 'gender') contact[c.field] = 'Not Specified';
      if (c.type === 'address') contact[c.field] = {};
    });
    const primaryContact = { userType: 'primaryContact' };
    each(otherContactModel, c => {
      primaryContact[c.field] = '';
      if (c.multiSelect) primaryContact[c.field] = [];
      if (c.field === 'gender') primaryContact[c.field] = 'Not Specified';
      if (c.type === 'address') primaryContact[c.field] = {};
    });
    const emergencyContact = { userType: 'emergencyContact' };
    each(otherContactModel, c => {
      emergencyContact[c.field] = '';
      if (c.multiSelect) emergencyContact[c.field] = [];
      if (c.field === 'gender') emergencyContact[c.field] = 'Not Specified';
      if (c.type === 'address') emergencyContact[c.field] = {};
    });
    const referral = { closed: false, source: '', placementType: '' };
    return {
      contact,
      primaryContact: [primaryContact],
      emergencyContact: [emergencyContact],
      youngPerson: youngPerson || '',
      primaryPerson: [],
      emergencyPerson: [],
      referral,
      referralType: 'Internal',
      sections: {},
      stepIndex: 0,
      redirect: '',
      suggestionsYP: [],
      suggestionsPP: [],
      suggestionsEP: [],
      doNotCheckSemiRequired: false,
      valid: !!youngPerson,
      currentPP: 0,
      currentEP: 0,
      loading: false,
      error: false,
    };
  }
  onChange(val, object, index) {
    let referralType = '';

    if (val.servicesProvided) {
      config.internalType.map(t => {
        if (val.servicesProvided === t) referralType = 'Internal';
        return null;
      });
      config.externalType.map(t => {
        if (val.servicesProvided === t) referralType = 'External';
        return null;
      });
      config.communityType.map(t => {
        if (val.servicesProvided === t) referralType = 'Community';
        return null;
      });
      config.familyType.map(t => {
        if (val.servicesProvided === t) referralType = 'Family';
        return null;
      });
      this.setState({ referralType });
    }

    const valToAdd = val;
    if (val.legalOrderStatus === 'Not Applicable')
      val.placementType = 'Community Respite Care';
    if (
      val.placementType &&
      val.placementType !== 'Community Respite Care' &&
      this.state.referral.legalOrderStatus === 'Not Applicable'
    )
      val.legalOrderStatus = '';
    if (object === 'primaryContact' || object === 'emergencyContact') {
      const newObject = this.state[object];
      newObject[index] = extend(newObject[index], valToAdd);
      this.setState({ [object]: newObject });
    } else {
      this.setState({ [object]: extend(this.state[object], valToAdd) });
    }
    if (
      object === 'contact' ||
      object === 'primaryContact' ||
      object === 'emergencyContact'
    ) {
      this.findExistingContacts(object, index);
    }
    this.validate();
  }
  onDialogClose() {
    this.setState(this.getInitialState());
    this.props.closeDialog();
  }
  getStepContent() {
    const {
      suggestionsYP,
      suggestionsPP,
      suggestionsEP,
      stepIndex,
      contact,
      primaryContact,
      emergencyContact,
      referral,
      youngPerson,
      primaryPerson,
      emergencyPerson,
      currentEP,
      currentPP,
    } = this.state;
    switch (stepIndex) {
      case 1:
        if (youngPerson) {
          return (
            <div>
              <ListItem
                disabled
                rightIconButton={
                  <RaisedButton
                    label="Remove"
                    className="add-unselect-person"
                    icon={<icons.CancelIcon />}
                    onClick={() =>
                      this.setState({ youngPerson: '' }, () => this.validate())
                    }
                    backgroundColor={colours.kRed}
                    labelColor="#fff"
                  />
                }
              >
                Selected Young Person
              </ListItem>
              <PersonCard
                person={youngPerson}
                rel="young"
                key={youngPerson.fullName}
                selected
              />
            </div>
          );
        }
        return (
          <div>
            {!isEmpty(suggestionsYP) && (
              <List>
                <ListItem
                  style={{
                    backgroundColor:
                      !isEmpty(suggestionsYP) && colours.paleLimeGreen,
                  }}
                  primaryTogglesNestedList
                  nestedItems={suggestionsYP.map(
                    s =>
                      s && (
                        <ListItem
                          key={s._id}
                          primaryTogglesNestedList
                          nestedItems={[
                            <ListItem disabled key={`${s._id}-card`}>
                              <ContactCard
                                contact={s}
                                readOnly
                                redirectTo={`/young/${s.youngPerson}`}
                                select={() =>
                                  this.setState(
                                    {
                                      youngPerson: {
                                        _id: s.youngPerson,
                                        contact: s,
                                      },
                                    },
                                    () => this.validate()
                                  )
                                }
                              />
                            </ListItem>,
                          ]}
                        >
                          {s.fullName}
                        </ListItem>
                      )
                  )}
                >
                  {`Suggestions (${suggestionsYP.length})`}
                </ListItem>
              </List>
            )}
            <Form
              create
              model={contactModel}
              object={contact}
              onUpdated={val => this.onChange(val, 'contact')}
              youngPerson
            />
          </div>
        );
      case 0:
        return (
          <div>
            <Form
              create
              model={
                referral.placementType === 'Community Respite Care'
                  ? filter(referralModel, r => r.field !== 'legalOrderStatus')
                  : referralModel
              }
              object={referral}
              section="request"
              onUpdated={val => this.onChange(val, 'referral')}
            />
          </div>
        );
      case 2:
        return (
          <div>
            {primaryContact.length > 1 && (
              <Stepper activeStep={currentPP}>
                {primaryContact.map((pC, index) => (
                  <Step
                    key={index}
                    active={index === currentPP}
                    disabled={false}
                  >
                    <StepButton
                      onClick={() => this.setState({ currentPP: index })}
                    >
                      {pC
                        ? `${pC.givenName || 'Person'} ${pC.surname}`
                        : `${primaryPerson[index].contact.givenName} ${
                            primaryPerson[index].contact.surname
                          }`}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            )}
            {primaryPerson[currentPP] ? (
              <div>
                <ListItem
                  disabled
                  rightIconButton={
                    <RaisedButton
                      label="Remove"
                      className="add-unselect-person"
                      icon={<icons.CancelIcon />}
                      onClick={() => {
                        const newPP = primaryPerson;
                        newPP[currentPP] = null;
                        const newPC = primaryContact;
                        newPC[currentPP] = { userType: 'primaryContact' };
                        each(otherContactModel, c => {
                          newPC[currentPP][c.field] = '';
                          if (c.multiSelect) newPC[currentPP][c.field] = [];
                          if (c.field === 'gender')
                            newPC[currentPP][c.field] = 'Not Specified';
                          if (c.type === 'address')
                            newPC[currentPP][c.field] = {};
                        });
                        this.setState(
                          { primaryPerson: newPP, primaryContact: newPC },
                          () => this.validate()
                        );
                      }}
                      backgroundColor={colours.kRed}
                      labelColor="#fff"
                    />
                  }
                >
                  Selected Primary Contact
                </ListItem>
                <PersonCard
                  person={primaryPerson[currentPP]}
                  rel="primary"
                  key={primaryPerson[currentPP].fullName}
                  selected
                />
              </div>
            ) : (
              <div>
                {!isEmpty(suggestionsPP) && (
                  <List>
                    <ListItem
                      style={{
                        backgroundColor:
                          !isEmpty(suggestionsPP) && colours.paleLimeGreen,
                      }}
                      primaryTogglesNestedList
                      nestedItems={suggestionsPP.map(
                        s =>
                          s && (
                            <ListItem
                              key={s._id}
                              primaryTogglesNestedList
                              nestedItems={[
                                <ListItem disabled key={`${s._id}-card`}>
                                  <ContactCard
                                    contact={s}
                                    readOnly
                                    select={() => {
                                      const newPP = primaryPerson;
                                      newPP[currentPP] = {
                                        _id: s.primaryPerson,
                                        contact: s,
                                      };
                                      const newPC = primaryContact;
                                      newPC[currentPP] = null;
                                      this.setState(
                                        {
                                          primaryPerson: newPP,
                                          primaryContact: newPC,
                                        },
                                        () => this.validate()
                                      );
                                    }}
                                  />
                                </ListItem>,
                              ]}
                            >
                              {s.fullName}
                            </ListItem>
                          )
                      )}
                    >
                      {`Suggestions (${suggestionsPP.length})`}
                    </ListItem>
                  </List>
                )}
                <Form
                  create
                  model={otherContactModel}
                  object={primaryContact[currentPP]}
                  onUpdated={val =>
                    this.onChange(val, 'primaryContact', currentPP)
                  }
                />
              </div>
            )}
          </div>
        );
      case 3:
        return (
          <div>
            {emergencyContact.length > 1 && (
              <Stepper activeStep={currentEP}>
                {emergencyContact.map((eC, index) => (
                  <Step
                    key={index}
                    active={index === currentEP}
                    disabled={false}
                  >
                    <StepButton
                      onClick={() => this.setState({ currentEP: index })}
                    >
                      {eC
                        ? `${eC.givenName || 'Person'} ${eC.surname}`
                        : `${emergencyPerson[index].contact.givenName} ${
                            emergencyPerson[index].contact.surname
                          }`}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            )}
            {emergencyPerson[currentEP] ? (
              <div>
                <ListItem
                  disabled
                  rightIconButton={
                    <RaisedButton
                      label="Remove"
                      className="add-unselect-person"
                      icon={<icons.CancelIcon />}
                      onClick={() => {
                        const newEP = emergencyPerson;
                        newEP[currentEP] = null;
                        const newEC = emergencyContact;
                        newEC[currentEP] = { userType: 'emergencyContact' };
                        each(otherContactModel, c => {
                          newEC[currentEP][c.field] = '';
                          if (c.multiSelect) newEC[currentEP][c.field] = [];
                          if (c.field === 'gender')
                            newEC[currentEP][c.field] = 'Not Specified';
                          if (c.type === 'address')
                            newEC[currentEP][c.field] = {};
                        });
                        this.setState(
                          { emergencyPerson: newEP, emergencyContact: newEC },
                          () => this.validate()
                        );
                      }}
                      backgroundColor={colours.kRed}
                      labelColor="#fff"
                    />
                  }
                >
                  Selected Emergency Contact
                </ListItem>
                <PersonCard
                  person={emergencyPerson[currentEP]}
                  rel="emergencyPerson"
                  key={emergencyPerson[currentEP].fullName}
                  selected
                />
              </div>
            ) : (
              <div>
                {!isEmpty(suggestionsEP) && (
                  <List>
                    <ListItem
                      style={{
                        backgroundColor:
                          !isEmpty(suggestionsEP) && colours.paleLimeGreen,
                      }}
                      primaryTogglesNestedList
                      nestedItems={suggestionsEP.map(
                        s =>
                          s && (
                            <ListItem
                              key={s._id}
                              primaryTogglesNestedList
                              nestedItems={[
                                <ListItem disabled key={`${s._id}-card`}>
                                  <ContactCard
                                    contact={s}
                                    readOnly
                                    select={() => {
                                      const newEP = emergencyPerson;
                                      newEP[currentEP] = {
                                        _id: s.emergencyPerson,
                                        contact: s,
                                      };
                                      const newEC = emergencyContact;
                                      newEC[currentEP] = null;
                                      this.setState(
                                        {
                                          emergencyPerson: newEP,
                                          emergencyContact: newEC,
                                        },
                                        () => this.validate()
                                      );
                                    }}
                                  />
                                </ListItem>,
                              ]}
                            >
                              {s.fullName}
                            </ListItem>
                          )
                      )}
                    >
                      {`Suggestions (${suggestionsEP.length})`}
                    </ListItem>
                  </List>
                )}
                <Form
                  create
                  model={otherContactModel}
                  object={emergencyContact[currentEP]}
                  onUpdated={val =>
                    this.onChange(val, 'emergencyContact', currentEP)
                  }
                />
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  }
  findExistingContacts(object, index) {
    const objectFull =
      index >= 0 ? this.state[object][index] : this.state[object];
    const { givenName, surname, aka, email } = objectFull;
    const contact = {};
    if (givenName) contact.givenName = givenName;
    if (surname) contact.surname = surname;
    if (aka) contact.aka = aka;
    if (email) contact.email = email;
    if (!isEmpty(contact)) {
      if (object === 'primaryContact') {
        findContacts(contact, 'primaryContact').then(suggestionsPP => {
          this.setState({ suggestionsPP });
        });
      } else if (object === 'emergencyContact') {
        findContacts(contact, 'emergencyContact').then(suggestionsEP => {
          this.setState({ suggestionsEP });
        });
      } else {
        findContacts(contact, 'young').then(suggestionsYP => {
          this.setState({ suggestionsYP });
        });
      }
    }
  }
  validate() {
    const {
      stepIndex,
      contact,
      primaryContact,
      emergencyContact,
      primaryPerson,
      emergencyPerson,
      referral,
      youngPerson,
    } = this.state;
    let valid = true;
    const doNotCheckSemiRequired = true; // change to false if need restrictions
    switch (stepIndex) {
      case 1:
        if (youngPerson) break;
        each(contactModel, c => {
          // if (c.semiRequired && !doNotCheckSemiRequired) {
          //   if (c.type === 'address' && !isEmpty(contact[c.field])) {
          //     const address = contact[c.field];
          //     doNotCheckSemiRequired =
          //       address.street_number &&
          //       address.route &&
          //       address.locality &&
          //       address.administrative_area_level_1 &&
          //       address.country &&
          //       address.postal_code;
          //   } else
          //     doNotCheckSemiRequired =
          //       !isEmpty(contact[c.field]) || isDate(contact[c.field]);
          // }
          if (c.required && valid) {
            valid = !isEmpty(contact[c.field]) || isDate(contact[c.field]);
            if (c.type === 'address' && valid) {
              const address = contact[c.field];
              valid =
                address.street_number &&
                address.route &&
                address.locality &&
                address.administrative_area_level_1 &&
                address.country &&
                address.postal_code;
            }
          }
        });
        if (!doNotCheckSemiRequired) valid = false;
        break;
      case 0:
        if (!referral.source || !referral.placementType) valid = false;
        break;
      case 2:
        each(primaryContact, (pC, index) => {
          if (!primaryPerson[index])
            each(otherContactModel, c => {
              if (c.required && valid) {
                valid = !isEmpty(pC[c.field]) || isDate(pC[c.field]);
                if (c.type === 'address' && valid) {
                  const address = pC[c.field];
                  valid =
                    address.street_number &&
                    address.route &&
                    address.locality &&
                    address.administrative_area_level_1 &&
                    address.country &&
                    address.postal_code;
                }
              }
            });
        });
        break;
      case 3:
        each(emergencyContact, (eC, index) => {
          if (!emergencyPerson[index])
            each(otherContactModel, c => {
              if (c.required && valid) {
                valid = !isEmpty(eC[c.field]) || isDate(eC[c.field]);
                if (c.type === 'address' && valid) {
                  const address = eC[c.field];
                  valid =
                    address.street_number &&
                    address.route &&
                    address.locality &&
                    address.administrative_area_level_1 &&
                    address.country &&
                    address.postal_code;
                }
              }
            });
        });
        break;
      default:
    }
    this.setState({ valid, doNotCheckSemiRequired });
  }
  handleNext(lastPage) {
    const {
      stepIndex,
      contact,
      primaryContact,
      emergencyContact,
      referral,
      referralType,
      youngPerson,
      primaryPerson,
      emergencyPerson,
    } = this.state;

    if (stepIndex === lastPage) {
      this.setState({ loading: true, error: false });
      const newReferral = { referral, referralType };

      if (youngPerson) newReferral.referral.youngPerson = youngPerson._id;
      else newReferral.contact = contact;

      if (referral.placementType === 'Community Respite Care') {
        newReferral.referral.legalOrderStatus = 'Not Applicable';
      }

      if (
        referralType === 'Community' ||
        referralType === 'External' ||
        referralType === 'Family'
      ) {
        newReferral.primaryPerson = primaryPerson.filter(p => !!p);
        newReferral.primaryContact = primaryContact.filter(p => !!p);

        newReferral.emergencyPerson = emergencyPerson.filter(e => !!e);
        newReferral.emergencyContact = emergencyContact.filter(p => !!p);
      }

      this.props.dispatch(submitReferral(newReferral)).then(
        newRef => {
          if (newRef) {
            this.setState({
              redirect: <Redirect to={`/referral/${newRef._id}`} />,
            });
            this.onDialogClose();
          } else this.setState({ loading: false, error: true });
        },
        () => this.setState({ loading: false, error: true })
      );
    } else {
      this.setState({ stepIndex: stepIndex + 1 }, () => this.validate());
    }
  }
  handlePrev() {
    const { stepIndex } = this.state;
    if (stepIndex === 0) {
      this.onDialogClose();
      return;
    }
    this.setState({ stepIndex: stepIndex - 1 }, () => this.validate());
  }
  render() {
    const {
      stepIndex,
      valid,
      /* doNotCheckSemiRequired, youngPerson, */
      redirect,
      referralType,
      error,
      loading,
    } = this.state;
    if (redirect) return redirect;
    const { open } = this.props;
    const lastPage = referralType !== 'Internal' ? 3 : 1;
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title="Add Referral"
        actions={[
          stepIndex >= 2 && (
            <FlatButton
              label={`Add another ${
                stepIndex === 2 ? 'Parent/Guardian' : 'Emergency Contact'
              }`}
              disabled={!valid}
              onClick={() => {
                const indexType = stepIndex === 2 ? 'currentPP' : 'currentEP';
                const object =
                  stepIndex === 2 ? 'primaryContact' : 'emergencyContact';
                const newIndex = this.state[object].length;
                const newObject = this.state[object];
                newObject[newIndex] = { userType: object };
                each(otherContactModel, c => {
                  newObject[newIndex][c.field] = '';
                  if (c.multiSelect) newObject[newIndex][c.field] = [];
                  if (c.field === 'gender')
                    newObject[newIndex][c.field] = 'Not Specified';
                  if (c.type === 'address') newObject[newIndex][c.field] = {};
                });
                this.setState(
                  { [indexType]: newIndex, [object]: newObject },
                  () => this.validate()
                );
              }}
            />
          ),
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <FlatButton
            label={stepIndex === 0 ? 'Cancel' : 'Back'}
            primary
            onClick={() => this.handlePrev()}
          />,
          <RaisedButton
            label={stepIndex === lastPage ? 'Submit' : 'Next'}
            primary
            disabled={loading || !valid}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() => this.handleNext(lastPage)}
          />,
        ]}
      >
        <Stepper activeStep={stepIndex}>
          <Step>
            <StepLabel>Referral</StepLabel>
          </Step>
          <Step>
            <StepLabel>Young Person</StepLabel>
          </Step>
          {referralType !== 'Internal' && (
            <Step>
              <StepLabel>Parent/Guardian</StepLabel>
            </Step>
          )}
          {referralType !== 'Internal' && (
            <Step>
              <StepLabel>Emergency Contact</StepLabel>
            </Step>
          )}
        </Stepper>
        {this.getStepContent()}
      </Dialog>
    );
  }
}

ReferralAdd.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ReferralAdd);
