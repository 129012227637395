import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { Tabs, Tab } from 'material-ui/Tabs';
import { ListItem } from 'material-ui/List';

import SearchBox from '../../../components/SearchBox';
import PersonCard from '../../../components/PersonCard';

import {
  changeTab,
  clearSearch,
  searchMember,
} from '../OtherIndividualActions';

class MemberList extends Component {
  constructor(props) {
    super(props);
    this.doSearch = debounce(this.doSearch.bind(this), 800);
  }
  fetchMore() {
    const { count, start, tab, loading, dispatch, text } = this.props;
    if (!loading) {
      const newStart = start + count;
      dispatch(searchMember(text, newStart, count, tab));
    }
  }

  doSearch(val) {
    const { count, tab, loading, dispatch } = this.props;
    if (!loading) {
      dispatch(searchMember(val, 0, count, tab));
    }
  }

  handleTabOnActive(tab, val) {
    const { dispatch } = this.props;
    dispatch(changeTab(tab));
    const { count, loading } = this.props;
    if (!loading) {
      dispatch(searchMember(val, 0, count, tab, 'tabChanged'));
    }
  }

  handleSearchButtonOnClick(val) {
    this.doSearch(val);
  }

  handleSearchBoxOnChange(val) {
    this.doSearch(val);
  }

  handleClearButtonOnClick() {
    this.props.dispatch(clearSearch());
    this.doSearch(null);
  }

  render() {
    const { tab, search, members, start, hasMore, error } = this.props;
    const list = search.text ? search.result : members;
    let hint = '';
    switch (tab) {
      case 'open':
        hint = 'Search open household members';
        break;
      case 'closed':
        hint = 'Search closed/inactive household members';
        break;
      case 5:
        hint = 'Search external household members';
        break;
      case 4:
        hint = 'Search family household members';
        break;
      case 3:
        hint = 'Search community household members';
        break;
      case 2:
        hint = 'Search kennerley household members';
        break;
      default:
        hint = 'Search household members';
    }
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Household Members - HEART1869</title>
          <meta
            name="description"
            content="Kennerley HEART1869 Household Members"
          />
        </Helmet>
        <div className="content">
          <div className="list-tabs" style={{ overflowY: 'scroll' }}>
            <Tabs
              style={{
                maxWidth: '900px',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingBottom: '5px',
              }}
              value={tab}
            >
              <Tab
                label="Active"
                value="open"
                onActive={() => {
                  this.handleTabOnActive('open', search.text);
                }}
              />
              <Tab
                label="Inactive"
                value="closed"
                onActive={() => {
                  this.handleTabOnActive('closed', search.text);
                }}
              />
              <Tab
                label="Kennerley"
                value={2}
                onActive={() => {
                  this.handleTabOnActive(2, search.text);
                }}
              />
              <Tab
                label="Community"
                value={3}
                onActive={() => {
                  this.handleTabOnActive(3, search.text);
                }}
              />
              <Tab
                label="FCP"
                value={4}
                onActive={() => {
                  this.handleTabOnActive(4, search.text);
                }}
              />
              <Tab
                label="External"
                value={5}
                onActive={() => {
                  this.handleTabOnActive(5, search.text);
                }}
              />
              <Tab
                label="All"
                value={1}
                onActive={() => {
                  this.handleTabOnActive(1, search.text);
                }}
              />
            </Tabs>
          </div>
          <div className="list-head">
            <SearchBox
              hintText={hint}
              searchText={search.text}
              onClickSearch={val => {
                this.handleSearchButtonOnClick(val);
              }}
              onChangeSearch={val => {
                this.handleSearchBoxOnChange(val);
              }}
              onClickClear={() => {
                this.handleClearButtonOnClick();
              }}
            />
          </div>
          <div className="list-body" style={{ height: '87.05%' }}>
            <InfiniteScroll
              className="list-section"
              useWindow={false}
              pageStart={start}
              loadMore={() => this.fetchMore(start)}
              hasMore={!error && hasMore}
              initialLoad
            >
              {!isEmpty(list) ? (
                list.map(m => (
                  <PersonCard
                    person={m}
                    rel="other"
                    key={m._id}
                    householdLink={
                      <Link to={`/household/${m.household?._id}`} />
                    }
                    caller="member"
                  />
                ))
              ) : (
                <ListItem disabled>
                  {error
                    ? 'An error occurred, please refresh the app.'
                    : 'No results.'}
                </ListItem>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

MemberList.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tab: state.member.tab,
  search: state.member.search,
  members: state.member.list,
  start: state.member.info.start,
  count: state.member.info.count,
  loading: state.member.info.loading,
  hasMore: state.member.info.hasMore,
  error: state.member.info.error,
});

export default connect(mapStateToProps)(MemberList);
