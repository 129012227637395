import * as actions from './AppActions';

const initialState = {
  message: '',
  messageid: 0,
  loading: false,
  keywords: '',
  route: '',
  recent: [],
  reportProgress: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REPORT_PROGRESS:
      if (action.percent === 100) {
        return {
          ...state,
          reportProgress: 0,
          message: 'Reports successfully updated.',
          messageid: state.messageid + 1,
          messageDuration: 10000,
        };
      }
      return { ...state, reportProgress: action.percent };
    case actions.NOTIFICATION:
      return {
        ...state,
        message: action.message,
        messageid: state.messageid + 1,
        messageDuration: action.messageDuration,
        messageAction: action.messageAction,
        messageOnActionClick: action.messageOnActionClick,
      };
    case actions.ROUTE_CHANGED:
      return {
        ...state,
        route: action.path,
      };
    case actions.UPDATE_RECENT:
      if (action.initial) {
        return { ...state, recent: action.path };
      }
      const recent = [...state.recent];
      const alreadyRecent = recent.findIndex(
        r => r.id === action.path.id && r.type === action.path.type
      );
      if (alreadyRecent > -1) {
        recent.splice(alreadyRecent, 1);
      } else if (recent.length >= 5) {
        recent.pop();
      }
      recent.unshift(action.path);
      localStorage.setItem('recent', JSON.stringify(recent));
      return { ...state, recent };
    case actions.PARAMS:
      return {
        ...state,
        ...action.params,
      };
    case actions.LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case actions.ADD_KEYWORDS:
      return {
        ...state,
        keywords: action.keywords,
      };
    case 'user/LOGOUT':
      return initialState;
    default:
      return state;
  }
};
