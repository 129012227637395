import React, { Component } from 'react';
import { ListItem } from 'material-ui/List';

import PersonCard from '../../../components/PersonCard';
import HouseholdCard from '../../Household/components/HouseholdCard';

class AttachmentMissing extends Component {
  render() {
    const { households, youngPeople } = this.props;
    return !households || !youngPeople ? (
      <div className="dash-col">
        <div>
          {households
            ? households.map(h => (
                <HouseholdCard caller="dashboard" key={h._id} household={h} />
              ))
            : ''}
          {youngPeople
            ? youngPeople.map(y => (
                <PersonCard key={y._id} person={y} rel="young" />
              ))
            : ''}
        </div>
      </div>
    ) : (
      <div className="dash-col">
        <ListItem
          disabled
          style={{ backgroundColor: 'white' }}
          primaryText="No Reviews Due"
        />
      </div>
    );
  }
}

export default AttachmentMissing;
