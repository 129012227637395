import React, { Component } from 'react';
import MTextField from 'material-ui/TextField';

import { colours } from '../theme';

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { param, value, onUpdated, create, readOnly, onKeyDown } = this.props;
    let floatingLabelText = param.name;
    if (create) {
      if (param.required) {
        floatingLabelText = `${param.name} (required)`;
      }
    }
    return (
      <MTextField
        id={param.field}
        fullWidth
        floatingLabelText={floatingLabelText}
        floatingLabelStyle={{
          color: create && param.required && !value && colours.kRed,
        }}
        multiLine={param.multiLine}
        rows={param.multiLine ? 2 : 1}
        type={param.type}
        value={value || ''}
        onChange={(e, val) => onUpdated(val)}
        disabled={readOnly || (!create && !param.editable)}
        underlineDisabledStyle={{ borderBottom: '1px solid #e0e0e0' }}
        onKeyDown={onKeyDown || null}
      />
    );
  }
}

export default TextField;
