import * as TrainingAPI from './TrainingAPI';

export const ADD_CARER_TRAINING = 'carer/ADD_CARER_TRAINING';
export const UPDATE_CARER_TRAINING = 'carer/UPDATE_CARER_TRAINING';
export const DELETE_CARER_TRAINING = 'carer/DELETE_CARER_TRAINING';

export const ADD_PROSPECT_TRAINING = 'prospect/ADD_PROSPECT_TRAINING';
export const UPDATE_PROSPECT_TRAINING = 'prospect/UPDATE_PROSPECT_TRAINING';
export const DELETE_PROSPECT_TRAINING = 'prospect/DELETE_PROSPECT_TRAINING';

export function _addTraining(parentType, parentId, training) {
  return {
    type:
      parentType.toLowerCase() === 'carer'
        ? ADD_CARER_TRAINING
        : ADD_PROSPECT_TRAINING,
    parentId,
    training,
  };
}

export function _updateTraining(parentType, training) {
  return {
    type:
      parentType.toLowerCase() === 'carer'
        ? UPDATE_CARER_TRAINING
        : UPDATE_PROSPECT_TRAINING,
    training,
  };
}

export function _deleteTraining(parentType, training) {
  return {
    type:
      parentType.toLowerCase() === 'carer'
        ? DELETE_CARER_TRAINING
        : DELETE_PROSPECT_TRAINING,
    training,
  };
}

export function createTraining(parentType, parentId, training) {
  return dispatch =>
    TrainingAPI.createTraining(parentType, parentId, training).then(
      newTraining => {
        dispatch(_addTraining(parentType, parentId, newTraining));
        return newTraining;
      }
    );
}

export function updateTraining(parentType, training) {
  return dispatch =>
    TrainingAPI.updateTraining(training).then(() => {
      dispatch(_updateTraining(parentType, training));
      return training;
    });
}

export function deleteTraining(parentType, training) {
  return dispatch =>
    TrainingAPI.deleteTraining(training).then(() => {
      dispatch(_deleteTraining(parentType, training));
      return;
    });
}
