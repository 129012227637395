import React, { Component } from 'react';
import config from '../../../config';

class AuthError extends Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    let error = '';
    const { pathname } = window.location;
    if (pathname === '/login/access_denied') error = 'Access Denied';
    if (pathname === '/login/team_not_found') error = 'Team Not Found';
    if (pathname === '/login/no_team_provided') error = 'No Team Provided';
    if (pathname === '/login/provider_not_found') error = 'Provider Not Found';
    return (
      <div className="container">
        <div className="content">
          <div className="list-head">
            <div
              className="list-search"
              style={{
                minHeight: '100px',
                margin: 'auto',
                width: '50%',
                textAlign: 'center',
              }}
            >
              {error}
              <a
                style={{ display: 'block' }}
                href={`${config.server}/auth/discover/kennerley`}
              >
                Login again?
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthError;
