import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';

import * as StaffActions from '../StaffActions';

import GlobalLoading from '../../../components/GlobalLoading';
import BackLink from '../../../components/BackLink';
import deepCompare from '../../../util/deepCompare';

import '../../../styles/PrintPreview.css';
import '../../../styles/PrintPDF.css';

import qs from 'query-string';

class StaffDetail extends Component {
  constructor(props) {
    super(props);

    const query = qs.parse(props.location.search);

    this.state = {
      user: {},
      staff: {},
      contact: {},
      printContact: query.contact !== '0',
      printRegulation: query.regulation !== '0',
      printCurrentPlacements: query.currentPlacements !== '0',
      printArchivedPlacements: query.archivedPlacements !== '0',
      printAssignedHouseholds: query.assignedHouseholds !== '0',
      pdf: query.do === 'pdf',
    };

    props.dispatch(StaffActions.fetchStaff(props.match.params.id));
  }

  shouldComponentUpdate(props, state) {
    const { userStaff, staff, contact } = this.props;
    return (
      !deepCompare(state, this.state) ||
      !deepCompare(props.userStaff, userStaff) ||
      !deepCompare(props.staff, staff) ||
      !deepCompare(props.contact, contact)
    );
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { match } = this.props;
    if (props.match.params.id !== match.params.id) {
      this.setState({ staff: {}, contact: {} });
      props.dispatch(StaffActions.fetchStaff(props.match.params.id));
    } else {
      this.setState({ staff: props.staff, contact: props.contact });
    }

    this.setState({ staffId: match.params.id });
  }

  renderPrintHeader_PP(bla) {
    const { pdf, contact } = this.state;
    return (
      <table className={!pdf ? 'printPreviewHeader' : 'printPDFHeader'}>
        <tbody>
          <tr>
            <td colSpan={1}>STAFF</td>
            <td colSpan={1}>{contact && contact.fullName}</td>
            <td colSpan={1}>HEART1869&nbps;</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderContactDetails_PP(bla) {
    const { pdf, contact } = this.state;
    return (
      contact && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Contact Details</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldValue" colSpan={3}>
                {contact.givenName + ' ' + contact.surname}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Also Knows As
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.aka}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Gender
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.gender}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Date of Birth
              </td>
              <td className="fieldValue" colSpan={1}>
                {moment(contact.birthDate).format('DD MMM YYYY')}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Email
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.email}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Mobile Number
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.mobile}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Phone Number
              </td>
              <td className="fieldValue" colSpan={1}>
                {contact.phone}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Residential Address
              </td>
              <td className="fieldValue" colSpan={3}>
                {!!contact.residentialAddress &&
                  contact.residentialAddress.administrative_area_level_1 +
                    ', ' +
                    contact.residentialAddress.country}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderRegulation_PP(bla) {
    const { pdf, staff } = this.state;
    return (
      staff && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Regulation</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={3}>
                Working with Vulnerable People Check
              </td>
              <td className="fieldTitle" colSpan={1}>
                Expiry of WWVP Check
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldValue" colSpan={3}>
                {staff.wwvpCheck}
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!staff.wwvpcExpiry &&
                  moment(staff.wwvpcExpiry).format('DD MMM YYYY')}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                License Number
              </td>
              <td className="fieldValue" colSpan={1}>
                {staff.licence}
              </td>
              <td className="fieldTitle" colSpan={1}>
                License Expiry
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!staff.licenceExpiry &&
                  moment(staff.licenceExpiry).format('DD MMM YYYY')}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Police Check Received
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!staff.pcResultsReceived &&
                  moment(staff.pcResultsReceived).format('DD MMM YYYY')}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Next PC Due
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!staff.pcReviewDate &&
                  moment(staff.pcReviewDate).format('DD MMM YYYY')}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Police Check Results
              </td>
              <td className="fieldValue" colSpan={3}>
                {staff.pcResults}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Child Safety Check Sent
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!staff.cscSent && moment(staff.cscSent).format('DD MMM YYYY')}
              </td>
              <td className="fieldTitle" colSpan={1}>
                Child Safety Check Received
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!staff.cscResultsReceived &&
                  moment(staff.cscResultsReceived).format('DD MMM YYYY')}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={3}>
                Child Safety Check Results
              </td>

              <td className="fieldTitle" colSpan={1}>
                Commenced Employment
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldValue" colSpan={3}>
                {staff.cscResults}
              </td>
              <td className="fieldValue" colSpan={1}>
                {!!staff.commencedEmployment &&
                  moment(staff.commencedEmployment).format('DD MMM YYYY')}
              </td>
            </tr>
          </tbody>
        </table>
      )
    );
  }

  renderPlacements_PP(status) {
    const { pdf, staff } = this.state;
    if (!staff.caseLoad) return;
    const cases =
      status === 'current'
        ? staff.caseLoad.filter(
            c => !c.leftCareDate || moment(c.leftCareDate).isAfter(moment())
          )
        : staff.caseLoad.filter(
            c => c.leftCareDate && moment(c.leftCareDate).isBefore(moment())
          );
    return (
      !isEmpty(cases) && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={2}>
                {status === 'current'
                  ? 'Current Placements'
                  : 'Archived Placements'}
              </td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Placement
              </td>
              <td className="fieldTitle" colSpan={1}>
                Detail
              </td>
            </tr>
            {cases.map(c => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {`Placement for ${c.youngPerson.contact.fullName}`}
                </td>
                <td className="fieldValue" colSpan={1}>
                  {`${
                    c.household ? c.household.name : 'Moving On Program'
                  } from ${moment(c.kennerleyEntryDate || c.created).format(
                    'D MMMM YYYY'
                  )}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  renderAssignedHouseholds_PP() {
    const { pdf, staff } = this.state;
    const households = staff.households;
    return (
      !isEmpty(households) && (
        <table className={!pdf ? 'printPreviewTable' : 'printPDFTable'}>
          <tbody>
            <tr
              className={
                !pdf ? 'printPreviewTableHeaderRow' : 'printPDFTableHeaderRow'
              }
            >
              <td colSpan={4}>Assigned Households</td>
            </tr>

            <tr
              className={
                !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
              }
            >
              <td className="fieldTitle" colSpan={1}>
                Name
              </td>
              <td className="fieldTitle" colSpan={3}>
                Address
              </td>
            </tr>
            {households.map(h => (
              <tr
                className={
                  !pdf ? 'printPreviewTableBodyRow' : 'printPDFTableBodyRow'
                }
              >
                <td className="fieldValue" colSpan={1}>
                  {h.name}
                </td>
                <td className="fieldValue" colSpan={3}>
                  {!!h.address && h.address.street_number
                    ? h.address.street_number
                    : ''}
                  {!!h.address && h.address.route ? ', ' + h.address.route : ''}
                  {!!h.address && h.address.postal_town
                    ? ', ' + h.address.postal_town
                    : ''}
                  {!!h.address && h.address.administrative_area_level_2
                    ? ', ' + h.address.administrative_area_level_2
                    : ''}
                  {!!h.address && h.address.administrative_area_level_1
                    ? ', ' + h.address.administrative_area_level_1
                    : ''}
                  {!!h.address && h.address.postal_code
                    ? ', ' + h.address.postal_code
                    : ''}
                  {!!h.address && h.address.country
                    ? ', ' + h.address.country
                    : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  }

  render() {
    const { pdf, staff, contact } = this.state;
    // let readOnly = true;
    const { user } = this.props;
    if (isEmpty(staff) || isEmpty(user.roles)) return <GlobalLoading />;
    if (staff.deleted)
      return (
        <div className="container">
          <div className="content">
            <div className="list-head">
              <div
                className="list-search"
                style={{
                  minHeight: '100px',
                  margin: 'auto',
                  width: '50%',
                  textAlign: 'center',
                }}
              >
                This staff can not be found or has been deleted.
                <BackLink />
              </div>
            </div>
          </div>
        </div>
      );

    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {contact ? contact.fullName : 'Young Person'} - HEART1869
          </title>
          <meta
            name="description"
            content="Kennerley HEART1869 Young Person Detail"
          />
        </Helmet>
        <div className="content">
          <div className="content-body">
            <div className={!pdf ? 'printPreview' : 'printPDF'}>
              {this.renderPrintHeader_PP()}
              {this.state.printContact && this.renderContactDetails_PP()}
              {this.state.printRegulation && this.renderRegulation_PP()}
              {this.state.printCurrentPlacements &&
                this.renderPlacements_PP('current')}
              {this.state.printArchivedPlacements &&
                this.renderPlacements_PP('archived')}
              {this.state.printAssignedHouseholds &&
                this.renderAssignedHouseholds_PP()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
StaffDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.profile,
  staff: state.staff.detail,
  contact: state.staff.detail.contact,
  userStaff: state.user.profile.staff,
});

export default connect(mapStateToProps)(StaffDetail);
