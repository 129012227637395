import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { SpeedDial, BubbleList, BubbleListItem } from 'react-speed-dial';

import moment from 'moment';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import { Card, CardTitle } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';

import staffModel from '../StaffModel';
import Form from '../../../components/Form';
import Attachment from '../../../components/Attachment';

import * as StaffActions from '../StaffActions';

import PlacementCard from '../../Placement/components/PlacementCard';
import ContactCard from '../../Contact/components/ContactCard';
import GlobalLoading from '../../../components/GlobalLoading';
import GlobalError from '../../../components/GlobalError';
import { authenticate } from '../../User/UserActions';
import HouseholdCard from '../../Household/components/HouseholdCard';

import PrintOptions from '../components/PrintOptions';

import ObjectLogs from '../../Log/components/ObjectLogs';
import { getObjectLogs } from '../../Log/LogAPI';
import { updateRecent } from '../../App/AppActions';

import { colours, icons } from '../../../theme';
import deepCompare from '../../../util/deepCompare';

class StaffDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: {},
      contact: {},
      attachmentsExpanded: false,
      currentPlacementsExpanded: true,
      currentHouseholdsExpanded: true,
      error: false,
    };
    props
      .dispatch(StaffActions.fetchStaff(props.match.params.id))
      .catch(() => this.setState({ error: true }));
    this.onChange = debounce(this.onChange.bind(this), 800);
    props
      .dispatch(StaffActions.fetchStaff(props.match.params.id))
      .then(({ staff }) => {
        if (staff.contact && staff.contact.fullName) {
          props.dispatch(
            updateRecent({
              type: 'staff',
              id: props.match.params.id,
              title: staff.contact.fullName,
            })
          );
        }
      });
  }
  shouldComponentUpdate(props, state) {
    const { userStaff, staff, contact } = this.props;
    return (
      !deepCompare(state, this.state) ||
      !deepCompare(props.userStaff, userStaff) ||
      !deepCompare(props.staff, staff) ||
      !deepCompare(props.contact, contact)
    );
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { match } = this.props;
    if (props.match.params.id !== match.params.id) {
      this.setState({ staff: {}, contact: {} });
      props.dispatch(StaffActions.fetchStaff(props.match.params.id));
    } else {
      this.setState({ staff: props.staff, contact: props.contact });
    }

    this.setState({ staffId: match.params.id });
  }
  onChange(val) {
    const { match, dispatch, userStaff } = this.props;
    const { id } = match.params;
    const key = Object.keys(val).join();
    const prevVal = { [key]: this.state.staff[key] };
    dispatch(StaffActions.modifyStaff(id, val, prevVal)).then(() => {
      if (id === userStaff._id) {
        dispatch(authenticate());
      }
    });
  }
  getLogs() {
    const { staff, contact } = this.props;
    getObjectLogs(staff._id, contact._id).then(logs => this.setState({ logs }));
  }
  renderRegulation() {
    return (
      <Card className="content-box">
        <CardTitle subtitle="Regulation" />
        <Form
          model={staffModel}
          section="regulation"
          object={this.state.staff}
          onUpdated={val => this.onChange(val)}
        />
      </Card>
    );
  }
  renderPlacements() {
    const {
      staff,
      currentPlacementsExpanded,
      archivedPlacementsExpanded,
    } = this.state;
    const { caseLoad } = staff;
    const currentPlacements = caseLoad.filter(
      c => !c.leftCareDate || moment(c.leftCareDate).isAfter(moment())
    );
    const archivedPlacements = caseLoad.filter(
      c => c.leftCareDate && moment(c.leftCareDate).isBefore(moment())
    );
    return [
      <Card
        className="content-box"
        key="current"
        expanded={currentPlacementsExpanded}
        onExpandChange={() =>
          this.setState({
            currentPlacementsExpanded: !currentPlacementsExpanded,
          })
        }
      >
        <CardTitle
          subtitle="Current Placements"
          actAsExpander
          showExpandableButton
        />
        {currentPlacements.map(c => (
          <PlacementCard key={c._id} caseLoad={c} expandable />
        ))}
      </Card>,
      <Card
        className="content-box"
        key="archived"
        expanded={archivedPlacementsExpanded}
        onExpandChange={() =>
          this.setState({
            archivedPlacementsExpanded: !archivedPlacementsExpanded,
          })
        }
      >
        <CardTitle
          subtitle="Archived Placements"
          actAsExpander
          showExpandableButton
        />
        {archivedPlacements.map(c => (
          <PlacementCard key={c._id} caseLoad={c} expandable />
        ))}
      </Card>,
    ];
  }
  renderHouseholds() {
    const { households } = this.state.staff;
    const {
      currentHouseholdsExpanded,
      archivedHouseholdsExpanded,
    } = this.state;
    const activeHouseholds = households.filter(
      h =>
        (!h.primaryCarer.dateClosed && !h.primaryCarer.dateInactive) ||
        (moment(h.primaryCarer.dateClosed).isAfter(moment()) &&
          moment(h.primaryCarer.dateInactive).isAfter(moment()))
    );
    const inactiveHouseholds = households.filter(
      h =>
        (h.primaryCarer.dateClosed &&
          moment(h.primaryCarer.dateClosed).isBefore(moment())) ||
        (h.primaryCarer.dateInactive &&
          moment(h.primaryCarer.dateInactive).isBefore(moment()))
    );
    return [
      <Card
        className="content-box"
        key="current-household"
        expanded={currentHouseholdsExpanded}
        onExpandChange={() =>
          this.setState({
            currentHouseholdsExpanded: !currentHouseholdsExpanded,
          })
        }
      >
        <CardTitle
          subtitle="Active Households"
          actAsExpander
          showExpandableButton
        />
        {activeHouseholds.map(h => <HouseholdCard household={h} expandable />)}
      </Card>,
      <Card
        className="content-box"
        key="archived-household"
        expanded={archivedHouseholdsExpanded}
        onExpandChange={() =>
          this.setState({
            archivedHouseholdsExpanded: !archivedHouseholdsExpanded,
          })
        }
      >
        <CardTitle
          subtitle="Inactive Households"
          actAsExpander
          showExpandableButton
        />
        {inactiveHouseholds.map(h => (
          <HouseholdCard household={h} expandable />
        ))}
      </Card>,
    ];
  }
  renderAttachments() {
    const { attachmentsExpanded, staff } = this.state;
    return (
      <Card
        className="content-box"
        expanded={attachmentsExpanded}
        onExpandChange={() =>
          this.setState({ attachmentsExpanded: !attachmentsExpanded })
        }
      >
        <CardTitle subtitle="Attachments" actAsExpander showExpandableButton />
        <Attachment
          admin={this.props.userStaff.admin}
          record={staff._id}
          recordType="Staff"
          expandable
        />
      </Card>
    );
  }
  renderLogs() {
    const { logs, logExpanded } = this.state;
    if (isEmpty(logs) && logExpanded) this.getLogs();
    return (
      <Card
        className="content-box"
        expanded={logExpanded}
        onExpandChange={() => this.setState({ logExpanded: !logExpanded })}
      >
        <CardTitle subtitle="Logs" actAsExpander showExpandableButton />
        {!isEmpty(logs) && <ObjectLogs logs={logs} expandable />}
      </Card>
    );
  }
  render() {
    const {
      contact,
      staff,
      speedDialOpen,
      showPrintOptions,
      error,
    } = this.state;
    const readOnly = false;
    if (error) return <GlobalError />;
    if (isEmpty(staff)) return <GlobalLoading />;
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Staff - HEART1869</title>
          <meta name="description" content="Kennerley HEART1869 Carer Detail" />
        </Helmet>
        <div className="content">
          <div className="content-body">
            <div className="content-column">
              <Card className="content-box">
                <ContactCard contact={contact} staff />
              </Card>
              {this.renderPlacements()}
              {this.renderHouseholds()}
            </div>
            <div className="content-column">
              {this.renderRegulation()}
              {this.renderAttachments()}
              {this.renderLogs()}
            </div>
          </div>
        </div>
        {!readOnly && (
          <SpeedDial
            styleBackdrop={{ opacity: 0.01 }}
            className="speed-dial"
            isOpen={speedDialOpen}
            onChange={change => this.setState({ speedDialOpen: change.isOpen })}
          >
            <BubbleList className="bubble-list">
              {false && (
                <BubbleListItem
                  primaryText="Print"
                  rightAvatar={
                    <Avatar
                      backgroundColor={colours.limeGreen}
                      color={colours.skyBlue}
                      icon={<icons.PrintIcon />}
                    />
                  }
                  onClick={() => {
                    this.setState({ speedDialOpen: false });
                    window.open(
                      '/printpreview' + window.location.pathname,
                      '_blank',
                      'menubar=0,location=0',
                      false
                    );
                  }}
                />
              )}
              <BubbleListItem
                primaryText="Print"
                rightAvatar={
                  <Avatar
                    backgroundColor={colours.limeGreen}
                    color={colours.skyBlue}
                    icon={<icons.PrintIcon />}
                  />
                }
                onClick={() =>
                  this.setState({
                    speedDialOpen: false,
                    showPrintOptions: true,
                  })
                }
              />
            </BubbleList>
          </SpeedDial>
        )}
        <PrintOptions
          closeDialog={() => this.setState({ showPrintOptions: false })}
          open={showPrintOptions}
          staff={staff}
        />
      </div>
    );
  }
}
StaffDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  staff: state.staff.detail,
  contact: state.staff.detail.contact,
  userStaff: state.user.profile.staff,
});

export default connect(mapStateToProps)(StaffDetail);
