import callApi from '../../util/apiCaller';

export function searchMember(text, start, count, type) {
  return callApi(`household/member?start=${start}&count=${count}`, 'POST', {
    text,
    type,
  });
}

export function archiveMember(household, member, archive = true) {
  return callApi(
    `household/${household}/members/${member}?archive=${archive}`,
    'PUT'
  );
}
