import { withCredentials } from '../../util/apiCaller';
import { reInitSocket } from '../../util/socket';

export const LOGIN = 'user/LOGIN';
export const LOGOUT = 'user/LOGOUT';
export const ROOM = 'user/CHAT_ROOM';
export const ONLINE_USERS = 'live/ONLINE_USERS';

function login(profile) {
  return {
    type: LOGIN,
    profile,
  };
}

export function logout() {
  withCredentials('user/logout');
  localStorage.removeItem('token');
  return {
    type: LOGOUT,
  };
}

export function authenticate(andSetupSocket = true) {
  return dispatch =>
    withCredentials('user/me').then(profile => {
      if (profile.roles.includes('admin')) withCredentials('contact/admin');
      if (andSetupSocket) {
        const token = localStorage.getItem('token');
        reInitSocket(token);
      }
      return dispatch(login(profile));
    });
}
