import callApi from '../../util/apiCaller';

export function getTraining(id) {
  return callApi(`training/carer/${id}`);
}

export function createTraining(parent, id, training) {
  return callApi(`training/add/${parent}/${id}`, 'PUT', training);
}

export function updateTraining(training) {
  return callApi(`training/${training._id}`, 'POST', training);
}

export function deleteTraining(training) {
  return callApi(`training/${training}`, 'DELETE');
}
