import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Provider } from 'react-redux';

import startsWith from 'lodash/startsWith';

import config from './config';
import store from './store';
import AppModule from './modules/App/App';

import Routes from './Routes';
import { Theme } from './theme';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialised: false,
    };
  }
  componentDidMount() {
    fetch('/config.json')
      .then(r => r.json())
      .then(appConfig => this.start(appConfig));
  }
  start(appConfig) {
    const { core, reports, ...kennerleyApp } = appConfig;
    window.kennerleyApp = kennerleyApp;

    if (core) {
      window.env = {
        auth: core,
        kibana: reports,
      };
    }

    const isOauthCallback =
      startsWith(
        window.location.href,
        `${config.client}/auth/msgraph/callback`
      ) ||
      startsWith(
        window.location.href,
        `${
          config.client
        }/auth/msgraph/5c33270b-9c94-4a6e-98f5-f401272c7bf8/callback`
      );
    if (!isOauthCallback && !localStorage.getItem('token')) {
      window.location.href = `${config.server}/auth/discover/kennerley`;
    }
    this.setState({ initialised: true });
  }
  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider muiTheme={Theme}>
          <BrowserRouter>
            {this.state.initialised && (
              <AppModule>
                <Routes />
              </AppModule>
            )}
          </BrowserRouter>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
