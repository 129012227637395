import callApi, { callDownloadApi } from '../../util/apiCaller';

export function searchCarers(text, start, count, type) {
  return callApi(`carer?start=${start}&count=${count}`, 'POST', {
    text,
    type,
  });
}

export function fetchCarer(_id) {
  return callApi(`carer/${_id}`);
}

export function updateCarer(_id, body) {
  return callApi(`carer/${_id}`, 'POST', body);
}

export function addCarer(body) {
  return callApi('carer', 'PUT', body);
}

export function getCarerNotes(id) {
  return callApi(`carer/${id}/note`);
}

export function filterCarerNotes(id, body) {
  return callApi(`carer/${id}/note`, 'POST', body);
}

export function deleteCarer(_id) {
  return callApi(`carer/${_id}`, 'DELETE');
}

export function exportCarer(_id, body) {
  return callDownloadApi(`carer/${_id}/export`, 'POST', body);
}
