import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import GlobalLoading from '../../../components/GlobalLoading';

import callApi from '../../../util/apiCaller';
import { authenticate } from '../UserActions';

class OauthCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'init',
    };
  }
  UNSAFE_componentWillMount() {
    const { location, match, dispatch } = this.props;
    const params = qs.parse(location.search);
    const { provider, externalId } = match.params;
    if (params.code) {
      callApi(
        `user/auth/${provider}${externalId ? `/${externalId}` : ''}${
          location.search
        }`
      ).then(auth => {
        if (auth && auth.token) {
          localStorage.setItem('token', auth.token);
          dispatch(authenticate());
          this.setState({ stage: 'authenticated' });
        } else {
          this.setState({ stage: 'error' });
        }
      });
    }
  }
  render() {
    const redirect = sessionStorage.getItem('launch_uri') || '/home';
    switch (this.state.stage) {
      default:
      case 'init':
        return <GlobalLoading />;
      case 'authenticated':
        return <Redirect to={redirect} />;
      case 'error':
        return <Redirect to="/login/access_denied" />;
    }
  }
}

OauthCallback.isPublic = true;

OauthCallback.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(OauthCallback);
