import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import bowser from 'bowser';

import isEmpty from 'lodash/isEmpty';
import startsWith from 'lodash/startsWith';

import Snackbar from 'material-ui/Snackbar';

import Header from './components/Header';
import Drawer from './components/Drawer';
import LoadingIndicator from './components/LoadingIndicator';

import {
  fetchKeywords,
  routeChanged,
  updateRecent,
  onRemoteAction,
} from './AppActions';
import { authenticate } from '../User/UserActions';
import { on } from '../../util/socket';

import './App.css';

const isMobile = () => bowser.mobile;
const isTablet = () => bowser.tablet;
const isTouchDevice = () => bowser.mobile || bowser.tablet;

class App extends Component {
  // eslint-disable-next-line no-undef
  static childContextTypes = {
    isMobile: PropTypes.func.isRequired,
    isTablet: PropTypes.func.isRequired,
    isTouchDevice: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: !isMobile(),
      snackbarOpen: false,
      loading: false,
    };
    this.loading = this.loading.bind(this);
  }
  getChildContext() {
    return {
      history: this.props.history,
      isMobile,
      isTablet,
      isTouchDevice,
    };
  }
  componentDidMount() {
    let recent = localStorage.getItem('recent');
    if (recent) {
      recent = JSON.parse(recent);
      this.props.dispatch(updateRecent(recent, true));
    }
  }
  UNSAFE_componentWillMount() {
    const hasToken = !!localStorage.getItem('token');
    const { user, dispatch, location } = this.props;
    if (hasToken && !user._id) {
      dispatch(authenticate()).then(() =>
        on(
          'action',
          (data = {}) => onRemoteAction(data, dispatch),
          localStorage.getItem('token')
        )
      );
    }
    this.onRouteChange(location.pathname);
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { messageid, loading } = this.props;
    const hasToken = !!localStorage.getItem('token');
    if (hasToken) {
      if (isEmpty(props.user)) {
        props.dispatch(authenticate());
        return;
      }
    }
    if (!props.keywords) {
      props.dispatch(fetchKeywords());
    }
    if (props.message && props.messageid !== messageid) {
      if (this.state.snackbarOpen) {
        this.setState({ snackbarOpen: false });
        setTimeout(() => {
          this.setState({ snackbarOpen: true });
        }, 500);
      } else {
        this.setState({ snackbarOpen: true });
      }
    }
    if (props.loading !== loading) {
      this.loading(props.loading);
    }
  }
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      this.onRouteChange(location.pathname);
    }
  }
  onRouteChange(path) {
    this.props.dispatch(routeChanged(path));
  }
  loading(status) {
    if (status) {
      this.setState({ loading: true });
    } else if (this.state.loading) {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 250);
    }
  }
  render() {
    const {
      children,
      message,
      messageDuration,
      dispatch,
      messageOnActionClick,
      messageAction,
      location,
      keywords,
      user,
    } = this.props;
    const { drawerOpen, snackbarOpen, loading } = this.state;
    const printpreview = startsWith(location.pathname, '/printpreview');
    const mainClass = printpreview
      ? 'aap-main'
      : drawerOpen && (!isMobile() || isTablet())
        ? 'app-main drawer-open'
        : 'app-main';

    return (
      <div className={printpreview ? 'app-root-printpreview' : 'app-root'}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>HEART1869</title>
          <meta name="description" content="Kennerley HEART1869" />
        </Helmet>
        {!printpreview && (
          <Header
            menuToggle={() => this.setState({ drawerOpen: !drawerOpen })}
          />
        )}
        {!printpreview && (
          <Drawer
            open={drawerOpen}
            location={location}
            onRequestChange={() => this.setState({ drawerOpen: !drawerOpen })}
          />
        )}
        <div className={mainClass}>
          {(isEmpty(user) || keywords) && children}
        </div>
        <Snackbar
          open={snackbarOpen}
          message={`${message}` || ''}
          autoHideDuration={messageDuration}
          onRequestClose={() => this.setState({ snackbarOpen: false })}
          onActionClick={() => {
            dispatch(messageOnActionClick);
            this.setState({ snackbarOpen: false });
          }}
          action={messageAction || ''}
        />
        <LoadingIndicator loading={loading} />
      </div>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

App.childContextTypes = {
  history: PropTypes.object.isRequired,
  isMobile: PropTypes.func.isRequired,
  isTablet: PropTypes.func.isRequired,
  isTouchDevice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  keywords: state.app.keywords,
  user: state.user.profile,
  message: state.app.message,
  messageid: state.app.messageid,
  messageDuration: state.app.messageDuration,
  messageAction: state.app.messageAction,
  messageOnActionClick: state.app.messageOnActionClick,
  loading:
    state.carer.info.loading ||
    state.prospect.info.loading ||
    state.accessdb.info.loading ||
    state.cases.info.loading ||
    state.household.info.loading ||
    state.referral.info.loading ||
    state.young.info.loading ||
    state.staff.loading ||
    state.app.loading,
  carer: state.carer.detail,
  prospect: state.prospect.detail,
  young: state.young.detail,
  referral: state.referral.detail,
  household: state.household.detail,
  case: state.cases.detail,
  staff: state.staff.detail,
});

export default withRouter(connect(mapStateToProps)(App));
