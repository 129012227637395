import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MDialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import { icons } from '../theme';

import './Dialog.css';

class Dialog extends Component {
  render() {
    const {
      open,
      onRequestClose,
      actions,
      title,
      modal,
      children,
      style,
    } = this.props;
    return (
      <MDialog
        open={open}
        onRequestClose={onRequestClose}
        title={
          <div className="clamped-dialog-title">
            <IconButton
              className="clamped-dialog-close"
              onClick={onRequestClose}
            >
              <icons.CloseIcon />
            </IconButton>
            {title}
          </div>
        }
        autoScrollBodyContent
        actions={actions}
        modal={modal}
        contentStyle={style}
        style={{ position: 'absolute', top: '50px' }}
        className={`clamped-dialog${this.context.isMobile() ? '-mobile' : ''}`}
      >
        {children}
      </MDialog>
    );
  }
}

Dialog.contextTypes = {
  isMobile: PropTypes.func.isRequired,
};

export default Dialog;
