import React, { Component } from 'react';
import moment from 'moment';

import { ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar';

import ViewAndWishDetail from './ViewAndWishDetail';

import { colours, icons } from '../../../theme';

class ViewAndWishCard extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }
  render() {
    const { viewandwish, readOnly } = this.props;
    const { open } = this.state;
    const statusColour = colours.skyBlue;
    return (
      <div className="person-card">
        <ListItem
          primaryText={viewandwish.title}
          secondaryText={moment(viewandwish.dateRecorded).format('D MMMM YYYY')}
          leftAvatar={
            <Avatar
              icon={<icons.FavoriteIcon />}
              backgroundColor={colours.kGrey}
            />
          }
          style={{ borderLeft: `5px solid ${statusColour}` }}
          onClick={() => this.setState({ open: !open })}
          rightIcon={
            viewandwish.hasAtt ? (
              <icons.AttachmentIcon color={colours.darkBlack} />
            ) : (
              <span />
            )
          }
        />
        <ViewAndWishDetail
          readOnly={readOnly}
          open={open}
          closeDialog={() => this.setState({ open: !open })}
          viewandwish={viewandwish}
        />
      </div>
    );
  }
}

export default ViewAndWishCard;
