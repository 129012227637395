import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

import Dialog from './Dialog';

import { colours, icons } from '../theme';

class DeletePrompt extends Component {
  constructor() {
    super();
    this.state = { redirect: '', loading: false, error: false };
  }
  onDialogClose(toDelete) {
    const { parentRoute, closeDialog } = this.props;
    if (toDelete) {
      this.setState({ loading: true, error: false });
      this.props.delete().then(
        () => {
          if (parentRoute)
            this.setState({
              redirect: <Redirect to={parentRoute} />,
              loading: false,
            });
          closeDialog();
        },
        () => this.setState({ loading: false, error: true })
      );
    } else {
      closeDialog();
    }
  }
  render() {
    const { open, extra } = this.props;
    const { error, loading } = this.state;
    if (this.state.redirect) return this.state.redirect;
    const style = {
      marginLeft: 12,
    };
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title="Delete Confirm"
        modal
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <FlatButton
            style={style}
            label="Cancel"
            primary
            onClick={() => this.onDialogClose()}
          />,
          <RaisedButton
            style={style}
            label="Delete"
            buttonStyle={{ backgroundColor: colours.kRed }}
            labelStyle={{ color: 'white' }}
            disabled={loading}
            icon={
              loading ? (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              ) : (
                <icons.DeleteIcon />
              )
            }
            onClick={() => this.onDialogClose(true)}
          />,
        ]}
      >
        <div>Are you sure you want to delete?</div>
        <div>{extra}</div>
      </Dialog>
    );
  }
}

export default DeletePrompt;
