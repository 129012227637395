import callApi from './apiCaller';

import config from '../config';

export function deleteFile(path) {
  return callApi(`file/remove?file=${path}`, 'DELETE');
}

export function getRecordAttachments(record) {
  return callApi(`file/record/${record}`);
}

export function uploadFile(
  file,
  path,
  description,
  recordType,
  record,
  dateRecorded,
  category
) {
  const data = new FormData();
  data.append('file', file);
  data.append('path', path);
  data.append('dateRecorded', dateRecorded);
  data.append('category', category);
  data.append('description', description);
  data.append('recordType', recordType);
  data.append('record', record);

  return fetch(`${config.server}/file`, {
    method: 'POST',
    body: data,
    headers: {
      token: localStorage.getItem('token'),
    },
  }).then(
    response => {
      if (!response.ok) {
        return Promise.reject();
      }
      return response.json();
    },
    () => {
      localStorage.removeItem('token');
      return Promise.reject();
    }
  );
}
