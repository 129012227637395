const editable = true;

export default [
  {
    field: 'wwvpCheck',
    name: 'Working with Vulnerable People Check',
    type: 'text',
    section: 'regulation',
    editable,
  },
  {
    field: 'wwvpcExpiry',
    name: 'Expiry of WWVP Check',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'scocExpiry',
    name: 'Expiry of Safeguarding Children Online Certificate',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'licence',
    name: 'Licence Number',
    type: 'text',
    section: 'regulation',
    editable,
  },
  {
    field: 'licenceExpiry',
    name: 'Licence Expiry',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'pcResults',
    name: 'Police Check Results',
    type: 'text',
    section: 'regulation',
    editable,
  },
  {
    field: 'pcResultsReceived',
    name: 'PC Results Received',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'pcReviewDate',
    name: 'Next PC Due',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'cscSent',
    name: 'Child Safety Check Sent',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'cscResults',
    name: 'CSC Results',
    type: 'text',
    section: 'regulation',
    editable,
  },
  {
    field: 'cscResultsReceived',
    name: 'CSC Results Received',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'commencedEmployment',
    name: 'Commenced Employment',
    type: 'date',
    section: 'regulation',
    editable,
  },
];
