import React, { Component } from 'react';
import moment from 'moment';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';

import SelectField from '../../../components/SelectField';

import './Logs.css';

const param = {
  field: 'filter',
  name: 'Filter Logs',
  type: 'select',
  options: ['Read', 'Updated', 'Created'],
  multiSelect: true,
  editable: true,
};

class ObjectLogs extends Component {
  constructor(props) {
    super(props);
    const filters = ['Updated', 'Created'];
    const logs = filter(
      props.logs,
      l => findIndex(filters, f => f === l.action) >= 0
    );
    this.state = { filters, logs };
  }
  changeFilters(filters) {
    const logs = filter(
      this.props.logs,
      l => findIndex(filters, f => f === l.action) >= 0
    );
    this.setState({ filters, logs });
  }
  render() {
    const { filters, logs } = this.state;
    return (
      <div>
        <SelectField
          param={param}
          value={filters}
          onUpdated={filters => this.changeFilters(filters)}
          expandable
        />
        {logs.map(l => (
          <div className="log" key={l._id}>
            <div className="log-section-small">
              {moment(l.created).format('DD/MM/YY HH:mm')}
            </div>
            <div className="log-section-big">
              {l.action}
              {l.change ? `: ${l.change}` : ''}
            </div>
            <div className="log-section-small">{l.user.fullName}</div>
          </div>
        ))}
      </div>
    );
  }
}

export default ObjectLogs;
