import React, { Component } from 'react';

import IconButton from 'material-ui/IconButton';
import TextField from 'material-ui/TextField';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';

import { colours, icons } from '../theme';

const styles = {
  searchIcon: { color: colours.kGrey },
  clearIcon: { color: colours.kRed },
  searchColumn1: { padding: 0, width: 48 },
  searchColumn2: { padding: 0, width: 'auto' },
  searchColumn3: { padding: 0, width: 48 },
  clearButtonOn: { display: 'block' },
  clearButtonOff: { display: 'none' },
};

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: props.searchText,
      showClear: !!props.searchText,
    };
  }

  handleSearchButtonOnClick() {
    this.props.onClickSearch(this.state.searchText);
  }

  handleSearchBoxOnChange(value) {
    this.setState({
      searchText: value,
      showClear: !!value,
    });

    this.props.onChangeSearch(value);
  }

  handleClearButtonOnClick() {
    this.setState({
      searchText: '',
      showClear: false,
    });

    this.props.onClickClear();
  }

  render() {
    const { searchText, showClear } = this.state;
    return (
      <div>
        <Table className="list-search" selectable={false}>
          <TableBody displayRowCheckbox={false}>
            <TableRow selectable={false}>
              <TableRowColumn style={styles.searchColumn1}>
                <IconButton
                  iconStyle={styles.searchIcon}
                  onClick={() => this.handleSearchButtonOnClick()}
                >
                  <icons.SearchIcon />
                </IconButton>
              </TableRowColumn>
              <TableRowColumn style={styles.searchColumn2}>
                <TextField
                  hintText={this.props.hintText}
                  fullWidth
                  value={searchText}
                  onChange={(e, value) => this.handleSearchBoxOnChange(value)}
                />
              </TableRowColumn>
              <TableRowColumn style={styles.searchColumn3}>
                <IconButton
                  disabled={!showClear}
                  iconStyle={styles.clearIcon}
                  onClick={() => this.handleClearButtonOnClick()}
                >
                  <icons.ClearIcon />
                </IconButton>
              </TableRowColumn>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default SearchBox;
