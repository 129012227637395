const editable = true;

export default [
  {
    field: 'household',
    name: 'My Details',
    type: 'check',
    default: true,
    section: 'options',
    editable: false,
  },
  {
    field: 'otherHouseholdMembers',
    name: 'Other Household Members',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'carers',
    name: 'Carers',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'coordinator',
    name: 'Coordinator',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'placements',
    name: 'Placements',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'accommodation',
    name: 'Accommodation',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'notes',
    name: 'Notes',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'attachments',
    name: 'Attachments',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
];
