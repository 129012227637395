import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import each from 'lodash/each';
// import isEmpty from 'lodash/isEmpty';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import MCheckbox from 'material-ui/Toggle';

import Dialog from '../../../components/Dialog';
import Form from '../../../components/Form';
import PrintOptionsModel from '../PrintOptionsModel';

import { colours } from '../../../theme';

import * as StaffAPI from '../StaffAPI';
import bytesToSize from '../../../util/bytesToSize';

class PrintOptions extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }
  getInitialState() {
    const { staff } = this.props;
    return {
      staff,
      selectAll: false,
      zipUrl: null,
      loading: false,
      error: false,
    };
  }
  onSelectAll(val) {
    const state = {};

    each(PrintOptionsModel, n => {
      state[n.field] = val;
    });

    this.setState({ selectAll: val, ...state }, () => this.validate());
  }
  onChange(val) {
    const state = { selectAll: false, ...this.state, ...val };

    state.selectAll = PrintOptionsModel.every(n => state[n.field]);

    this.setState({ ...state }, () => this.validate());
  }
  // eslint-disable-next-line class-methods-use-this
  onPreview() {
    const { state } = this;
    let query = '';

    each(PrintOptionsModel, n => {
      query += !state[n.field] ? `&${n.field}=0` : '';
    });

    window.open(
      '/printpreview' + window.location.pathname + '?' + query,
      '_blank',
      'menubar=0,location=0',
      false
    );
  }
  onSubmit() {
    const { state } = this;
    const { staff } = this.props;
    const body = {
      source: 'staff',
      id: staff._id,
    };

    each(PrintOptionsModel, n => {
      body[n.field] = state[n.field];
    });

    this.setState({ loading: true, error: false }, () => {
      StaffAPI.exportStaff(staff._id, body).then(
        zip => {
          this.setState(
            { zipUrl: window.URL.createObjectURL(zip), zipSize: zip.size },
            () => {
              this.setState({ loading: false });
            }
          );
        },
        () => this.setState({ error: true, loading: false })
      );
    });
  }
  onDialogClose() {
    this.setState(this.getInitialState());
    this.onSelectAll(false);
    this.props.closeDialog();
  }
  validate() {
    let valid = false;
    const { state } = this;
    each(PrintOptionsModel, n => {
      valid = state[n.field] ? true : valid;
    });
    this.setState({ valid });
  }
  render() {
    const { valid, selectAll, zipUrl, loading, error, zipSize } = this.state;
    const { open } = this.props;
    const style = {
      marginLeft: 12,
    };

    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title="Print Options"
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <FlatButton
            label="Cancel"
            primary
            style={style}
            onClick={() => this.onDialogClose()}
          />,
          (loading || zipUrl) && (
            <RaisedButton
              label={
                zipSize
                  ? `Download Zip (${bytesToSize(zipSize)})`
                  : 'Download Zip'
              }
              prim
              primary
              style={style}
              disabled={!zipUrl}
              href={zipUrl}
              icon={
                loading && (
                  <CircularProgress
                    size={20}
                    thickness={2}
                    className="progress"
                  />
                )
              }
              target="_blank"
              onClick={() => this.onDialogClose()}
            />
          ),
          !loading &&
            !zipUrl && (
              <RaisedButton
                label="Preview"
                primary
                style={style}
                disabled={!valid}
                onClick={() => this.onPreview()}
              />
            ),
          !loading &&
            !zipUrl && (
              <RaisedButton
                label="Print"
                primary
                style={style}
                disabled={!valid}
                onClick={() => this.onSubmit()}
              />
            ),
        ]}
      >
        <MCheckbox
          label={selectAll ? 'Deselect All' : 'Select All'}
          labelStyle={{ color: colours.skyBlue }}
          style={{ margin: '32px 0 16px 0' }}
          toggled={selectAll}
          onToggle={(e, val) => this.onSelectAll(val)}
        />
        <Form
          create
          model={PrintOptionsModel}
          object={this.state}
          section="options"
          onUpdated={val => this.onChange(val)}
        />
      </Dialog>
    );
  }
}

PrintOptions.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(PrintOptions);
