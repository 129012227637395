import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import extend from 'lodash/extend';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
// import isDate from 'lodash/isDate';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import moment from 'moment';

import accommodationModel from '../AccommodationModel';
import {
  updateAccomodation,
  deleteAccommodation,
  fetchPlacement,
} from '../PlacementActions';

import Form from '../../../components/Form';
import Dialog from '../../../components/Dialog';

import { colours } from '../../../theme';

class AccommodationView extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }
  // eslint-disable-next-line class-methods-use-this
  getInitialState() {
    const accommodation = {};
    each(accommodationModel, a => {
      accommodation[a.field] = '';
      if (a.multiSelect) accommodation[a.field] = [];
    });

    return {
      accommodation,
      valid: false,
      stepIndex: 0,
      loading: false,
      error: false,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ accommodation: { ...props.accommodation } });
  }
  onChange(val, object) {
    this.setState({ [object]: extend(this.state[object], val) }, () =>
      this.validate()
    );
  }

  onDialogClose(action) {
    const { closeDialog, dispatch } = this.props;
    if (action === 'save') {
      this.setState({ loading: true, error: false });
      dispatch(updateAccomodation(this.state.accommodation)).then(
        newAccommodation => {
          if (newAccommodation) {
            this.setState({ accommodation: {}, valid: false, loading: false });
            closeDialog();
          } else this.setState({ loading: false, error: true });
        },
        () => this.setState({ loading: false, error: true })
      );
    } else {
      this.setState({ accommodation: {}, valid: false });
      closeDialog();
    }
  }
  handleDelete() {
    const { dispatch } = this.props;
    const { accommodation } = this.state;
    this.setState({ loading: true, error: false });
    dispatch(deleteAccommodation(accommodation._id)).then(
      () => {
        dispatch(fetchPlacement(accommodation.case)).then(() => {
          this.setState({ loading: false });
          this.onDialogClose();
        });
      },
      () => this.setState({ loading: false, error: true })
    );
  }

  validate() {
    const { accommodation /*, stepIndex*/ } = this.state;
    const { caseLoad } = this.props;
    let valid = true;
    let prevkey = -1;

    each(caseLoad.accommodation, (value, key) => {
      if (value._id === accommodation._id) {
        if (key < caseLoad.accommodation.length - 1) {
          prevkey = key + 1;
          this.setState({
            prevAccommodation: caseLoad.accommodation[key + 1],
          });
        }
      }
    });

    if (prevkey === -1) {
      if (
        !accommodation.dateStart ||
        (accommodation.dateEnd &&
          !moment(accommodation.dateEnd).isAfter(
            moment(accommodation.dateStart)
          ))
      ) {
        valid = false;
      }
    } else if (prevkey < 1) {
      if (
        (accommodation.dateStart &&
          !moment(accommodation.dateStart).isAfter(
            moment(caseLoad.accommodation[prevkey].dateEnd)
          )) ||
        (accommodation.dateEnd &&
          (!moment(accommodation.dateEnd).isAfter(
            moment(accommodation.dateStart)
          ) ||
            !moment(accommodation.dateEnd).isAfter(
              moment(caseLoad.accommodation[prevkey].dateStart)
            ) ||
            !moment(accommodation.dateEnd).isAfter(
              moment(caseLoad.accommodation[prevkey].dateEnd)
            )))
      ) {
        valid = false;
      }
    }

    if (
      valid &&
      moment(accommodation.dateStart).isSame(moment(accommodation.dateStart)) &&
      (moment(accommodation.dateEnd).isSame(moment(accommodation.dateEnd)) ||
        accommodation.dateEnd === this.props.accommodation.dateEnd) &&
      accommodation.alternate === this.props.accommodation.alternate
    ) {
      valid = false;
    }

    return this.setState({
      accommodation,
      valid,
    });
  }
  // COMBAK: LIMIT TO LAST AVAILABLE DATE? i.e. dateStart of previous accom or later?
  render() {
    const style = {
      marginLeft: 12,
    };
    const { valid, accommodation, loading, error } = this.state;
    const { open, readOnly } = this.props;
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title={
          accommodation && accommodation.household
            ? `${accommodation.household.name} - Accommodation`
            : `${accommodation.alternate}`
        }
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <FlatButton
            label="Delete"
            primary
            style={
              readOnly || accommodation.locked
                ? { display: 'none' }
                : { ...style, backgroundColor: colours.kRed, color: 'white' }
            }
            disabled={loading}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() => this.handleDelete()}
          />,
          <FlatButton
            label="Cancel"
            primary
            style={style}
            onClick={() => this.onDialogClose()}
          />,
          <RaisedButton
            label="Save"
            primary
            style={style}
            disabled={loading || !valid}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() =>
              this.onDialogClose(isEmpty(accommodation) ? '' : 'save')
            }
          />,
        ]}
      >
        <span />
        <Form
          model={accommodationModel}
          object={accommodation}
          section="date"
          onUpdated={val => this.onChange(val, 'accommodation')}
        />
        {accommodation && accommodation.alternate ? (
          <Form
            model={accommodationModel}
            object={accommodation}
            section="alternate"
            onUpdated={val => this.onChange(val, 'accommodation')}
          />
        ) : null}
      </Dialog>
    );
  }
}

AccommodationView.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(AccommodationView);
