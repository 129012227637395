const editable = true;

export default [
  {
    field: 'dateRecorded',
    name: 'Date Recorded',
    type: 'date',
    section: 'info',
    required: true,
    editable,
  },
  {
    field: 'title',
    name: 'Title',
    type: 'text',
    section: 'info',
    required: true,
    editable,
  },
  {
    field: 'detail',
    name: 'Detail',
    type: 'text',
    section: 'content',
    required: true,
    editable,
    multiLine: true,
  },
];
