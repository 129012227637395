import get from 'lodash/get';

export default new class {
  authApi = 'https://dev-server.isw.net.au'; // eslint-disable-line no-undef

  client = get(window, 'location.origin', 'https://dev-client.isw.net.au'); // eslint-disable-line no-undef
  gmApiKey = 'AIzaSyDWjL2MEdIp92PiV0SNyaezpO9UxTHcRpM'; // eslint-disable-line no-undef

  kibana = 'https://dev-kibana.isw.net.au'; // eslint-disable-line no-undef

  get server() {
    return get(window, 'env.auth', this.authApi);
  }

  get reports() {
    return get(window, 'env.kibana', this.kibana);
  }

  communityType = ['Community Respite', 'NDIS']; // eslint-disable-line no-undef
  /* eslint-disable-next-line no-undef */
  internalType = [
    'Primary Placement',
    'Emergency Placement CSS',
    'KCH Respite',
  ]; // eslint-disable-line no-undef
  externalType = ['External Respite']; // eslint-disable-line no-undef
  familyType = ['Supervised Family Visit']; // eslint-disable-line no-undef
}();
