import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import extend from 'lodash/extend';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import isDate from 'lodash/isDate';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import { Step, Stepper, StepLabel } from 'material-ui/Stepper';

import trainingModel from '../TrainingModel';
import { createTraining } from '../TrainingActions';
import { fetchCarer } from '../../Carer/CarerActions';
import { fetchProspect } from '../../Carer/ProspectActions';

import Form from '../../../components/Form';
import Dialog from '../../../components/Dialog';

import { colours } from '../../../theme';

class TrainingAdd extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }
  // eslint-disable-next-line class-methods-use-this
  getInitialState() {
    const training = {};
    each(trainingModel, c => {
      training[c.field] = '';
      if (c.multiSelect) training[c.field] = [];
    });

    return {
      training,
      valid: false,
      stepIndex: 0,
      loading: false,
      error: false,
    };
  }
  onChange(val, object) {
    this.setState({ [object]: extend(this.state[object], val) });
    this.validate();
  }

  onDialogClose(action) {
    const { dispatch, parentType, parentId, closeDialog } = this.props;
    if (action === 'submit') {
      this.setState({ loading: true, error: false });
      dispatch(createTraining(parentType, parentId, this.state.training)).then(
        newTraining => {
          if (newTraining) {
            this.setState({ training: {}, valid: false });
            if (parentType === 'Carer') {
              dispatch(fetchCarer(parentId));
            } else if (parentType === 'Prospect') {
              dispatch(fetchProspect(parentId));
            }
            closeDialog();
          } else this.setState({ loading: false, error: true });
        },
        () => this.setState({ loading: false, error: true })
      );
    } else {
      closeDialog();
    }
  }

  validate() {
    const { training, stepIndex } = this.state;
    let valid = true;
    if (stepIndex === 0) {
      each(trainingModel, c => {
        if (c.required && valid) {
          valid = !isEmpty(training[c.field]) || isDate(training[c.field]);
          if (training.module === 'Other' && isEmpty(training.moduleOther))
            valid = false;
        }
      });
    }
    this.setState({
      training,
      valid,
    });
  }

  render() {
    const {
      stepIndex,
      valid,
      redirect,
      doNotCheckSemiRequired,
      training,
      error,
      loading,
    } = this.state;
    const { open } = this.props;
    if (redirect) return redirect;
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title="Add Training"
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <small
            style={{
              color: !doNotCheckSemiRequired ? colours.kRed : colours.kGrey,
            }}
          >
            Module, Start Date required
          </small>,
          <FlatButton
            label="Cancel"
            primary
            onClick={() => this.onDialogClose()}
          />,
          <RaisedButton
            label="Submit"
            primary
            disabled={loading || !valid}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() =>
              this.onDialogClose(
                isEmpty(this.props.training) ? 'submit' : 'save'
              )
            }
          />,
        ]}
      >
        <Stepper activeStep={stepIndex}>
          <Step>
            <StepLabel>Training</StepLabel>
          </Step>
        </Stepper>
        <Form
          create
          model={trainingModel}
          object={training}
          section="main"
          onUpdated={val => this.onChange(val, 'training')}
        />
      </Dialog>
    );
  }
}

TrainingAdd.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(TrainingAdd);
