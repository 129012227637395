import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import AppBar from 'material-ui/AppBar';
import Avatar from 'material-ui/Avatar';
import IconButton from 'material-ui/IconButton';

import { colours, icons } from '../../../theme';

function getIcon(pathname) {
  const i = {
    home: <icons.DashboardIcon />,
    carer: <icons.CarerIcon />,
    young: <icons.YoungPersonIcon />,
    case: <icons.PlacementIcon />,
    household: <icons.HomeIcon />,
    staff: <icons.StaffIcon />,
    prospect: <icons.ProspectIcon />,
    referral: <icons.ReferralIcon />,
    settings: <icons.SettingsIcon />,
  };
  const splitPathname = pathname.split(/\//);
  const section = splitPathname[1];
  // const id = splitPathname[2]; // TODO use with profile images later...
  return i[section] || <icons.DashboardIcon />;
}

class Header extends Component {
  render() {
    const { isMobile } = this.context;
    return (
      <AppBar
        iconElementLeft={
          <div>
            <IconButton
              className="header-icons"
              onClick={() => this.props.menuToggle()}
            >
              <icons.MenuIcon color="white" />
            </IconButton>
          </div>
        }
        title={
          <span>
            <NavLink to="/">
              <span
                className={isMobile() ? 'header-title-only' : 'header-title'}
              >
                HEART1869
              </span>
            </NavLink>
            {!isMobile() && (
              <img
                src="../../../left.png"
                alt=""
                height="56px"
                style={{ marginLeft: '28px' }}
              />
            )}
            {!isMobile() && (
              <img
                src="../../../right.png"
                alt=""
                height="56px"
                style={{ float: 'right' }}
              />
            )}
          </span>
        }
        iconStyleLeft={{ cursor: 'pointer' }}
        iconElementRight={
          isMobile() ? (
            <Avatar
              icon={getIcon(window.location.pathname)}
              backgroundColor={colours.steelBlue}
              color="#fff"
            />
          ) : (
            <span />
          )
        }
        className="app-header"
      />
    );
  }
}

Header.contextTypes = {
  isMobile: PropTypes.func.isRequired,
};

export default Header;
