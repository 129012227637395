import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import extend from 'lodash/extend';
import isDate from 'lodash/isDate';
import debounce from 'lodash/debounce';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import { List, ListItem } from 'material-ui/List';

import Checkbox from '../../../components/Checkbox';
import contactModel, {
  contactOptDOBModel,
  doctorModel,
} from '../../Contact/ContactModel';
import otherIndividualModel from '../OtherIndividualModel';
import Dialog from '../../../components/Dialog';
import ContactCard from '../../Contact/components/ContactCard';
import PersonCard from '../../../components/PersonCard';
import Form from '../../../components/Form';
import { createMember } from '../../Household/HouseholdActions';
import {
  createParent,
  createSibling,
  createDoctor,
} from '../../YoungPerson/YoungPersonActions';
import { findContacts } from '../../Contact/ContactAPI';

import { colours, icons } from '../../../theme';
import { stubFalse } from 'lodash';

class AddPerson extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.findExistingContacts = debounce(
      this.findExistingContacts.bind(this),
      800
    );
  }
  getInitialState(props) {
    const { address } = this.props;
    const contact = { userType: 'other' };
    let model = contactModel;
    if (props) {
      switch (props.type) {
        case 'doctor':
          model = doctorModel;
          break;
        case 'parent':
        case 'sibling':
        case 'emergency':
          model = contactOptDOBModel;
          break;
        default:
      }
    }
    each(model, c => {
      contact[c.field] = '';
      if (c.multiSelect) contact[c.field] = [];
      if (c.field === 'gender') contact[c.field] = 'Not Specified';
      if (c.type === 'address') {
        if (address) {
          contact[c.field] = address;
        } else {
          contact[c.field] = {};
        }
      }
    });
    return {
      model,
      contact,
      sections: {},
      stepIndex: 0,
      redirect: '',
      suggestions: [],
      doNotCheckSemiRequired: !!address,
      valid: false,
      selectedYP: '',
      loading: false,
      error: stubFalse,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.type !== this.props.type)
      this.setState(this.getInitialState(props), () => this.validate());
  }
  onChange(val, object) {
    this.setState({ [object]: extend(this.state[object], val) });
    if (object === 'contact' && this.state.contact.userType === 'young') {
      this.findExistingContacts();
    }
    this.validate();
  }
  onDialogClose() {
    this.setState(this.getInitialState());
    this.props.closeDialog();
  }
  getStepContent() {
    const { type } = this.props;
    const { suggestions, contact, selectedYP, model } = this.state;
    const isYoung = contact.userType === 'young';
    if (selectedYP) {
      return (
        <div>
          {type === 'sibling' ? (
            <Checkbox
              param={{
                field: 'isYoung',
                name: 'Sibling In Care?',
                editable: true,
                required: true,
              }}
              value={isYoung}
              onUpdated={val => {
                const state = {
                  model: isYoung ? contactOptDOBModel : contactModel,
                  contact: {
                    ...contact,
                    userType: isYoung ? 'other' : 'young',
                  },
                };
                if (!isYoung) this.findExistingContacts();
                else {
                  state.selectedYP = '';
                }
                this.setState(state, () => this.validate());
              }}
            />
          ) : null}
          <ListItem
            disabled
            rightIconButton={
              <RaisedButton
                label="Remove"
                className="add-unselect-person"
                icon={<icons.CancelIcon />}
                onClick={() =>
                  this.setState({ selectedYP: '' }, () => this.validate())
                }
                backgroundColor={colours.kRed}
                labelColor="#fff"
              />
            }
          >
            Selected Young Person
          </ListItem>
          <PersonCard
            person={selectedYP}
            rel="young"
            key={selectedYP.fullName}
            selected
          />
        </div>
      );
    }
    return (
      <div>
        <List>
          {type === 'sibling' ? (
            <Checkbox
              param={{
                field: 'isYoung',
                name: 'Sibling In Care?',
                editable: true,
                required: true,
              }}
              value={isYoung}
              onUpdated={val => {
                if (!isYoung) this.findExistingContacts();
                this.setState(
                  {
                    model: isYoung ? contactOptDOBModel : contactModel,
                    contact: {
                      ...contact,
                      userType: isYoung ? 'other' : 'young',
                    },
                  },
                  () => this.validate()
                );
              }}
            />
          ) : null}
          {isYoung && (
            <ListItem
              style={{
                backgroundColor: !isEmpty(suggestions) && colours.paleLimeGreen,
              }}
              primaryTogglesNestedList
              nestedItems={suggestions.map(s => (
                <ListItem
                  key={s._id}
                  primaryTogglesNestedList
                  nestedItems={[
                    <ListItem key={`${s._id}-card`} disabled>
                      <ContactCard
                        contact={s}
                        readOnly
                        select={() =>
                          this.setState(
                            {
                              selectedYP: {
                                _id: s.youngPerson,
                                contact: s,
                              },
                            },
                            () => this.validate()
                          )
                        }
                      />
                    </ListItem>,
                  ]}
                >
                  {s.fullName}
                </ListItem>
              ))}
            >
              Suggestions{!isEmpty(suggestions)
                ? ` (${suggestions.length})`
                : ''}
            </ListItem>
          )}
        </List>
        <Form
          create
          model={model}
          object={contact}
          onUpdated={val => this.onChange(val, 'contact')}
        />
        <Form
          create
          model={otherIndividualModel}
          section="description"
          object={this.state}
          onUpdated={val => this.setState(val)}
        />
      </div>
    );
  }
  validate() {
    const { stepIndex, contact, selectedYP, model } = this.state;
    let valid = true;
    let doNotCheckSemiRequired = true;
    if (stepIndex === 0) {
      if (!selectedYP) {
        each(model, c => {
          if (c.semiRequired && !doNotCheckSemiRequired) {
            if (c.type === 'address' && !isEmpty(contact[c.field])) {
              const address = contact[c.field];
              doNotCheckSemiRequired =
                address.street_number &&
                address.route &&
                address.locality &&
                address.administrative_area_level_1 &&
                address.country &&
                address.postal_code;
            } else
              doNotCheckSemiRequired =
                !isEmpty(contact[c.field]) || isDate(contact[c.field]);
          }
          if (c.required && valid) {
            valid = !isEmpty(contact[c.field]) || isDate(contact[c.field]);
            if (c.type === 'address' && valid) {
              const address = contact[c.field];
              valid =
                address.street_number &&
                address.route &&
                address.locality &&
                address.administrative_area_level_1 &&
                address.country &&
                address.postal_code;
            }
          }
        });
        if (!doNotCheckSemiRequired) valid = false;
      }
    }
    this.setState({ valid, doNotCheckSemiRequired });
  }
  findExistingContacts() {
    const { givenName, surname, aka, email } = this.state.contact;
    const contact = {};
    if (givenName) contact.givenName = givenName;
    if (surname) contact.surname = surname;
    if (aka) contact.aka = aka;
    if (email) contact.email = email;
    if (!isEmpty(contact)) {
      findContacts(contact, 'young').then(suggestions => {
        this.setState({ suggestions });
      });
    }
  }
  handleNext(lastPage) {
    const { stepIndex, contact, selectedYP, description } = this.state;
    const { type, youngPerson, household } = this.props;
    if (stepIndex === lastPage) {
      this.setState({ loading: true, error: false });
      switch (type) {
        case 'doctor':
          this.props
            .dispatch(createDoctor(youngPerson._id, { contact, description }))
            .then(
              () => this.onDialogClose(),
              () => this.setState({ loading: false, error: true })
            );
          break;
        case 'parent':
          this.props
            .dispatch(createParent(youngPerson._id, { contact, description }))
            .then(
              () => this.onDialogClose(),
              () => this.setState({ loading: false, error: true })
            );
          break;
        case 'sibling':
          const body = {};
          if (selectedYP) {
            body.youngPerson = selectedYP._id;
            body.contact = selectedYP.contact;
          } else {
            body.contact = contact;
            body.description = description;
          }
          this.props
            .dispatch(createSibling(youngPerson._id, body))
            .then(
              () => this.onDialogClose(),
              () => this.setState({ loading: false, error: true })
            );
          break;
        default:
          this.props
            .dispatch(createMember(household._id, { contact, description }))
            .then(
              () => this.onDialogClose(),
              () => this.setState({ loading: false, error: true })
            );
      }
    } else {
      this.setState({ stepIndex: stepIndex + 1 }, () => this.validate());
    }
  }
  handlePrev() {
    const { stepIndex } = this.state;
    if (stepIndex === 0) {
      this.onDialogClose();
      return;
    }
    this.setState({ stepIndex: stepIndex - 1 });
  }
  render() {
    const {
      stepIndex,
      valid,
      doNotCheckSemiRequired,
      loading,
      error,
    } = this.state;
    const lastPage = 0;
    const { open, type } = this.props;
    let title = '';
    switch (type) {
      case 'doctor':
        title = 'Doctor';
        break;
      case 'parent':
        title = 'Parent / Guardian';
        break;
      case 'sibling':
        title = 'Sibling';
        break;
      case 'member':
      default:
        title = 'Household Member';
        break;
    }
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title={`Add ${title}`}
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <small
            style={{
              color: !doNotCheckSemiRequired ? colours.kRed : colours.kGrey,
            }}
          >
            Email, Mobile, Phone or Residential Address required
          </small>,
          <FlatButton
            label={stepIndex === 0 ? 'Cancel' : 'Back'}
            primary
            onClick={() => this.handlePrev()}
          />,
          <RaisedButton
            label={stepIndex === lastPage ? 'Submit' : 'Next'}
            primary
            disabled={loading || !valid}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() => this.handleNext(lastPage)}
          />,
        ]}
      >
        {this.getStepContent()}
      </Dialog>
    );
  }
}

AddPerson.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(AddPerson);
