import React, { Component } from 'react';
import moment from 'moment';

import extend from 'lodash/extend';
import orderBy from 'lodash/orderBy';
import each from 'lodash/each';
import filter from 'lodash/filter';

import { List, ListItem } from 'material-ui/List';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

import Dialog from '../../../components/Dialog';
import Form from '../../../components/Form';
import DeletePrompt from '../../../components/DeletePrompt';
import PaymentsModel from '../PaymentsModel';
import deepCompare from '../../../util/deepCompare';

// import { createPayment } from

import { colours, icons } from '../../../theme';

class PaymentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      edit: false,
      currentPayment: {},
      currentPaymentsList: props.payments,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (!deepCompare(props.payments, this.props.payments)) {
      this.setState({ currentPaymentsList: props.payments });
    }
  }
  onDialogClose() {
    this.setState({ edit: false, open: false, currentPayment: {} });
  }
  onChange(val) {
    this.setState({ currentPayment: extend(this.state.currentPayment, val) });
  }
  onSubmit() {
    const { currentPaymentsList, currentPayment } = this.state;
    const payments = orderBy(
      [...currentPaymentsList, currentPayment],
      p => moment(p.dateStart).toISOString(),
      ['desc']
    );
    each(payments, (pay, key) => {
      const nextPayment = payments[key - 1];
      if (
        key > 0 &&
        (!pay.dateEnd ||
          moment(pay.dateEnd).toISOString() >
            moment(nextPayment.dateStart).toISOString())
      ) {
        pay.dateEnd = nextPayment.dateStart;
      }
    });
    this.props.onChange({ payments });
    this.onDialogClose();
  }
  onDelete() {
    const { currentPaymentsList } = this.state;
    this.props.onChange({ payments: currentPaymentsList });
    this.onDialogClose();
    return Promise.resolve();
  }
  renderDialog() {
    const { open, currentPayment, edit, showDelPro } = this.state;
    const style = {
      marginLeft: 12,
    };
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title="Add Payment Classification"
        actions={[
          !this.props.readOnly &&
            edit && (
              <FlatButton
                label="Delete"
                primary
                style={{
                  ...style,
                  backgroundColor: colours.kRed,
                  color: 'white',
                }}
                onClick={() => this.setState({ showDelPro: true })}
              />
            ),
          <FlatButton
            label="Cancel"
            primary
            onClick={() => this.onDialogClose()}
            style={style}
          />,
          <RaisedButton
            label="Submit"
            primary
            disabled={
              !currentPayment.classification || !currentPayment.dateStart
            }
            style={style}
            onClick={() => this.onSubmit()}
          />,
        ]}
      >
        <Form
          create
          model={PaymentsModel}
          object={currentPayment}
          onUpdated={val => this.onChange(val)}
        />
        <DeletePrompt
          closeDialog={() => this.setState({ showDelPro: false })}
          open={!!showDelPro}
          delete={() => this.onDelete()}
        />
      </Dialog>
    );
  }
  render() {
    const { payments, readOnly } = this.props;
    return (
      <div>
        <List>
          {!readOnly && (
            <ListItem
              primaryText="Add Payment Classification"
              rightIcon={<icons.AddIcon color={colours.skyBlue} />}
              onClick={() => this.setState({ open: true })}
            />
          )}
          {payments.map(payment => {
            const { _id, classification, dateStart, dateEnd } = payment;
            const start = moment(dateStart).format('DD/MM/YY');
            const end = dateEnd
              ? moment(dateEnd).format('DD/MM/YY')
              : 'present';
            return (
              <ListItem
                key={_id}
                primaryText={classification}
                secondaryText={`${start} - ${end}`}
                disabled={readOnly}
                onClick={() => {
                  this.setState({
                    edit: true,
                    open: true,
                    currentPayment: { ...payment },
                    currentPaymentsList: filter(
                      this.state.currentPaymentsList,
                      p => !deepCompare(p, payment)
                    ),
                  });
                }}
              />
            );
          })}
        </List>
        {this.renderDialog()}
      </div>
    );
  }
}

export default PaymentsList;
