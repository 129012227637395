import React, { Component } from 'react';
import { withScriptjs } from 'react-google-maps';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';

import each from 'lodash/each';

import MTextField from 'material-ui/TextField';

import { colours } from '../theme';

class Address extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    const refs = {};
    const defaultValue = [];
    if (value.street_number && value.route) {
      defaultValue.push(
        `${value.subpremise ? `${value.subpremise}/` : ''}${
          value.street_number
        } ${value.route}`
      );
    }
    if (value.locality) {
      defaultValue.push(value.locality);
    }
    if (value.administrative_area_level_1) {
      defaultValue.push(value.administrative_area_level_1);
    }
    if (value.country) {
      defaultValue.push(value.country);
    }
    if (value.postal_code) {
      defaultValue.push(value.postal_code);
    }
    this.state = {
      defaultValue,
      onSearchBoxMounted: ref => {
        refs.searchBox = ref;
      },
      onPlacesChanged: () => {
        const { address_components } = refs.searchBox.getPlaces()[0];
        const address = {};
        each(address_components, a => {
          address[a.types[0]] = a.long_name;
        });
        props.onUpdated(address);
      },
    };
  }
  validAddress() {
    const { value } = this.props;
    return (
      value.street_number &&
      value.route &&
      value.locality &&
      value.administrative_area_level_1 &&
      value.country &&
      value.postal_code
    );
  }
  render() {
    const { param, create, readOnly } = this.props;
    let floatingLabelText = param.name;
    if (create) {
      if (param.required) {
        floatingLabelText = `${param.name} (required)`;
      }
    }
    const { defaultValue, onPlacesChanged, onSearchBoxMounted } = this.state;
    return (
      <StandaloneSearchBox
        onPlacesChanged={onPlacesChanged}
        ref={onSearchBoxMounted}
      >
        <MTextField
          floatingLabelText={floatingLabelText}
          floatingLabelStyle={{
            color:
              create && param.required && !this.validAddress() && colours.kRed,
          }}
          placeholder=""
          defaultValue={defaultValue.join(', ')}
          fullWidth
          disabled={readOnly || (!create && !param.editable)}
          underlineDisabledStyle={{ borderBottom: '1px solid #e0e0e0' }}
          // onChange={(event, value) =>  { // TODO need to find out why this was added
          //   this.setState({ searchText: value});
          //   this.props.onUpdated(value);
          // }}
        />
      </StandaloneSearchBox>
    );
  }
}

export default withScriptjs(Address);
