import keys from 'lodash/keys';
import find from 'lodash/find';

import StaffModel from './StaffModel';

import * as StaffAPI from './StaffAPI';
import * as PlacementAPI from '../Placement/PlacementAPI';

import { notification } from '../App/AppActions';

export const DISPLAY_STAFF = 'staff/DISPLAY_STAFF';
export const UPDATE_STAFF = 'staff/UPDATE_STAFF';
export const GET_CASES = 'staff/GET_CASES';
export const LOADING_STAFF = 'staff/LOADING_STAFF';
export const STAFF_ERROR = 'staff/STAFF_ERROR';

export function getStaff(staff) {
  return {
    type: DISPLAY_STAFF,
    staff,
  };
}

export function updateStaff(staff, body, detail) {
  return {
    type: UPDATE_STAFF,
    staff,
    body,
    detail,
  };
}

export function getPlacements(cases) {
  return {
    type: GET_CASES,
    cases,
  };
}

export function loadStaff(loading) {
  return {
    type: LOADING_STAFF,
    loading,
  };
}

export function setError(error = false) {
  return {
    type: STAFF_ERROR,
    error,
  };
}

export function fetchStaff(_id) {
  return dispatch => {
    if (!_id) {
      return StaffAPI.fetchMe().then(
        staff => dispatch(getStaff(staff)),
        () => Promise.reject()
      );
    }
    return StaffAPI.fetchStaff(_id).then(
      staff => dispatch(getStaff(staff)),
      () => Promise.reject()
    );
  };
}

export function modifyStaff(_id, body, prevVal, undo = false, detail = true) {
  return dispatch => {
    if (!_id) {
      return StaffAPI.updateMe(body).then(() => {
        dispatch(updateStaff(_id, body, detail));
        if (!undo) {
          dispatch(
            notification(
              `${find(StaffModel, { field: keys(body)[0] }).name} updated.`,
              'UNDO',
              modifyStaff(_id, prevVal, '', true)
            )
          );
        } else {
          dispatch(
            notification(
              `Changes to ${
                find(StaffModel, { field: keys(body)[0] }).name
              } undone.`
            )
          );
        }
      });
    }
    return StaffAPI.updateStaff(_id, body).then(() => {
      dispatch(updateStaff(_id, body, detail));
      if (!undo) {
        dispatch(
          notification(
            `${find(StaffModel, { field: keys(body)[0] }).name} updated.`,
            'UNDO',
            modifyStaff(_id, prevVal, '', true)
          )
        );
      } else {
        dispatch(
          notification(
            `Changes to ${
              find(StaffModel, { field: keys(body)[0] }).name
            } undone.`
          )
        );
      }
    });
  };
}

export function fetchStaffPlacements(_id) {
  return dispatch =>
    PlacementAPI.getStaffPlacements(_id).then(cases => {
      dispatch(getPlacements(cases));
      return cases;
    });
}
