import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import moment from 'moment';

import Dialog from '../../../components/Dialog';
import Form from '../../../components/Form';
import accommodationModel from '../AccommodationModel';
import { getAvailableHouseholds } from '../../Household/HouseholdAPI';
import HouseholdCard from '../../Household/components/HouseholdCard';
import { fetchPlacement } from '../PlacementActions';
import { createAccommodation } from '../PlacementAPI';

import truncate from '../../../util/truncate';

class AccommodationAdd extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }
  getInitialState() {
    const { caseLoad } = this.props;
    return {
      dateStart: '',
      dateEnd: '',
      youngPerson: caseLoad.youngPerson._id,
      case: caseLoad._id,
      valid: false,
      households: [],
      household: {},
      accType: 'household',
      alternate: '',
      loading: false,
      error: false,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { /*servicesProvided, */ careType } = this.props.caseLoad;
    if (props.open && isEmpty(this.state.households)) {
      //getAvailableHouseholds({servicesProvided, placementType: careType}).then(households => {
      getAvailableHouseholds({
        placementType: careType,
        accommodation: true,
      }).then(households => {
        this.setState({ households });
      });
    }
  }
  onChange(val) {
    this.setState(val, () => this.validate());
  }
  onDialogClose() {
    this.setState(this.getInitialState());
    this.props.closeDialog();
  }
  onSubmit() {
    const { caseLoad, dispatch } = this.props;
    this.setState({ loading: true, error: false });
    const {
      youngPerson,
      dateStart,
      dateEnd,
      household,
      alternate,
      accType,
    } = this.state;
    const body = {
      youngPerson,
      dateStart,
      dateEnd,
    };
    if (accType === 'household') body.household = household._id;
    if (accType === 'alternate') body.alternate = alternate;
    createAccommodation(caseLoad._id, body).then(
      () => {
        dispatch(fetchPlacement(caseLoad._id));
        this.setState({ loading: false });
        this.onDialogClose();
      },
      () => this.setState({ loading: false, error: true })
    );
  }
  validate() {
    let valid = true;
    const { dateStart, dateEnd, household, alternate, accType } = this.state;
    const { caseLoad } = this.props;
    const { accommodation } = caseLoad;
    if (!dateStart) {
      valid = false;
    } else if (accType === 'household' && isEmpty(household)) {
      valid = false;
    } else if (accType === 'alternate' && isEmpty(alternate)) {
      valid = false;
    } else if (caseLoad.accommodation) {
      if (
        moment(dateStart).isBefore(
          moment(accommodation[accommodation.length - 1].dateStart)
        )
      ) {
        valid = false;
      }
    } else if (dateEnd && moment(dateStart).isAfter(moment(dateEnd)))
      valid = false;
    this.setState({ valid });
  }
  render() {
    // COMBAK: LIMIT TO LAST AVAILABLE DATE? i.e. dateStart of previous accom or later?
    const {
      valid,
      households,
      household,
      accType,
      alternate,
      loading,
      error,
    } = this.state;
    const { open } = this.props;
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title="Add Respite or Other Arrangements"
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <small>
            Selected:{' '}
            {household.name || accType === 'alternate'
              ? truncate(alternate, 33, true) || 'None'
              : 'None'}
          </small>,
          <FlatButton
            label="Cancel"
            primary
            onClick={() => this.onDialogClose()}
          />,
          <RaisedButton
            label="Submit"
            disabled={loading || !valid}
            primary
            onClick={() => this.onSubmit()}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
          />,
        ]}
      >
        <Form
          create
          model={accommodationModel}
          object={this.state}
          section="date"
          onUpdated={val => this.onChange(val)}
        />
        <RadioButtonGroup
          name="Accommodation Arrangement"
          onChange={(e, accType) =>
            this.setState({ accType }, () => this.validate())
          }
          valueSelected={accType}
          style={{ padding: '10px 0' }}
        >
          <RadioButton
            value="household"
            label="Respite"
            style={{ display: 'inline-block', width: '50%' }}
          />
          <RadioButton
            value="alternate"
            label="Alternate"
            style={{ display: 'inline-block', width: '50%' }}
          />
        </RadioButtonGroup>
        {accType === 'household' ? (
          households.map(h => (
            <HouseholdCard
              household={h}
              key={h._id}
              selected={household._id === h._id}
              attach={() => {
                this.setState({ household: h }, () => this.validate());
              }}
            />
          ))
        ) : (
          <Form
            create
            model={accommodationModel}
            object={this.state}
            section="alternate"
            onUpdated={val => this.onChange(val)}
          />
        )}
      </Dialog>
    );
  }
}

AccommodationAdd.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(AccommodationAdd);
