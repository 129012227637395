import unionBy from 'lodash/unionBy';
import extend from 'lodash/extend';
import filter from 'lodash/filter';
// import isEmpty from 'lodash/isEmpty';

import * as actions from './YoungPersonActions';
import { ROUTE_CHANGED } from '../App/AppActions';
import { UPDATE_YOUNG_PERSON_CONTACT } from '../Contact/ContactActions';

const initialState = {
  list: [],
  detail: {},
  tab: 'open',
  search: {
    text: '',
    result: [],
  },
  info: {
    loading: false,
    start: -30,
    count: 30,
    hasMore: true,
    error: false,
  },
};

export default (state = initialState, action) => {
  const _youngPeople =
    action.start === 0 || action.tabChanged
      ? action.youngPeople
      : unionBy(state.list, action.youngPeople, '_id');

  const _list = state.list;

  if (action.type === ROUTE_CHANGED) {
    return { ...state, detail: {} };
  }
  switch (action.type) {
    case actions.YOUNG_PEOPLE_ERROR:
      return {
        ...state,
        info: { ...state.info, loading: false, error: action.error },
      };
    case actions.CHANGE_TAB:
      return {
        ...state,
        list: [],
        search: {
          text: '',
          result: [],
        },
        tab: action.tab,
      };
    case actions.CLEAR_SEARCH:
      return {
        ...state,
        search: {
          text: '',
          result: [],
        },
      };
    case actions.RENDER_YOUNG_PEOPLE:
      return {
        ...state,
        list: action.text ? _list : _youngPeople,
        search: {
          text: action.text,
          result: !action.text ? state.search.result : action.youngPeople,
        },
        info: {
          ...state.info,
          loading: false,
          hasMore: action.youngPeople.length === state.info.count,
        },
      };
    case actions.LOADING_YOUNG_PEOPLE:
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
          start: action.start,
        },
      };
    case actions.DISPLAY_YOUNG_PERSON:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.youngPerson,
        },
      };
    case actions.UPDATE_YOUNG_PERSON:
      if (action.detail) {
        return {
          ...state,
          list: state.list.map(l => {
            if (l._id === action.youngPerson) {
              return { ...l, ...action.body };
            }
            return l;
          }),
          detail: extend(state.detail, action.body),
        };
      }
      return state;
    case UPDATE_YOUNG_PERSON_CONTACT:
      if (action.detail) {
        return {
          ...state,
          detail: {
            ...state.detail,
            contact: extend(state.detail.contact, action.body),
          },
        };
      }
      return state;
    case actions.GET_REFERRALS:
      return {
        ...state,
        detail: {
          ...state.detail,
          referrals: action.referrals,
        },
      };
    case actions.ADD_DOCTOR:
      return {
        ...state,
        detail: {
          ...state.detail,
          doctors: unionBy(state.detail.doctors, [action.doctor], '_id'),
        },
      };
    case actions.ADD_PARENT:
      return {
        ...state,
        detail: {
          ...state.detail,
          parents: unionBy(state.detail.parents, [action.parent], '_id'),
        },
      };
    case actions.ADD_SIBLING:
      return {
        ...state,
        detail: {
          ...state.detail,
          siblings:
            action.sibling.contact.userType === 'other'
              ? unionBy(state.detail.siblings, [action.sibling], '_id')
              : state.detail.siblings,
          siblingsInCare:
            action.sibling.contact.userType === 'young'
              ? unionBy(state.detail.siblingsInCare, [action.sibling], '_id')
              : state.detail.siblingsInCare,
        },
      };
    case actions.UPDATE_DOCTOR:
      return {
        ...state,
        detail: {
          ...state.detail,
          doctors: state.detail.doctors.map(o => {
            if (action.doctor !== o._id) return o;
            const newDoctor = extend(o, action.body);
            newDoctor.contact = extend(newDoctor.contact, action.contact);
            return newDoctor;
          }),
        },
      };
    case actions.UPDATE_PARENT:
      return {
        ...state,
        detail: {
          ...state.detail,
          parents: state.detail.parents.map(o => {
            if (action.parent !== o._id) return o;
            const newParent = extend(o, action.body);
            newParent.contact = extend(newParent.contact, action.contact);
            return newParent;
          }),
        },
      };
    case actions.UPDATE_SIBLING:
      return {
        ...state,
        detail: {
          ...state.detail,
          siblings: state.detail.siblings.map(o => {
            if (action.sibling !== o._id) return o;
            const newSibling = extend(o, action.body);
            newSibling.contact = extend(newSibling.contact, action.contact);
            return newSibling;
          }),
        },
      };
    case actions.DELETE_YOUNG_PERSON:
      return {
        ...state,
        list: [...filter(state.list, l => l._id !== action.youngPerson)],
        search: {
          ...state.search,
          result: [
            ...filter(state.search.result, r => r._id !== action.youngPerson),
          ],
        },
      };
    case actions.GET_NOTES:
      return {
        ...state,
        detail: {
          ...state.detail,
          notes: action.notes,
        },
      };
    case actions.ADD_VIEW_AND_WISH:
      return {
        ...state,
        detail: {
          ...state.detail,
          viewsandwishes: unionBy(
            [action._vW],
            state.detail.viewsandwishes,
            '_id'
          ),
        },
      };
    case actions.UPDATE_VIEW_AND_WISH:
      return {
        ...state,
        viewandwishDetail: {
          ...state.viewandwishDetail,
          ...action.body,
        },
      };
    case actions.GET_VIEW_AND_WISH:
      return {
        ...state,
        viewandwishDetail: action._vW,
      };
    case actions.GET_VIEWS_AND_WISHES:
      return {
        ...state,
        detail: {
          ...state.detail,
          viewsandwishes: action.vWs,
        },
      };
    case actions.DELETE_VIEW_AND_WISH:
      return {
        ...state,
        detail: {
          ...state.detail,
          viewsandwishes: [
            ...filter(
              state.detail.viewsandwishes,
              _vw => _vw._id !== action.vW
            ),
          ],
        },
      };
    case actions.EXIT_VIEW_AND_WISH:
      return {
        ...state,
        viewandwishDetail: {},
      };

    default:
      return state;
  }
};
