const multiLine = true;
const required = true;

export default [
  {
    field: 'module',
    name: 'Module',
    type: 'select',
    options: [
      'Initial Assessment',
      'Preservice Training Day 1',
      'Preservice Training Day 2',
      'Preservice Training Day 3',
      'Kinship Assessment A',
      'Kinship Assessment B',
      'Kinship Assessment C',
      'ACF',
      'Safeguarding Children Level One',
      'Other',
    ],
    section: 'main',
    required,
  },
  {
    field: 'moduleOther',
    name: 'Other Module',
    type: 'text',
    section: 'main',
  },
  {
    field: 'startDate',
    name: 'Training Started',
    type: 'date',
    section: 'main',
    required,
  },
  {
    field: 'endDate',
    name: 'Training Completed',
    type: 'date',
    section: 'main',
  },
  {
    field: 'comments',
    name: 'Comments',
    type: 'text',
    multiLine,
    section: 'main',
  },
];
