import unionBy from 'lodash/unionBy';
import filter from 'lodash/filter';

import * as actions from './CarerActions';

import { UPDATE_CARER_CONTACT } from '../Contact/ContactActions';
import { ROUTE_CHANGED } from '../App/AppActions';

const initialState = {
  list: [],
  detail: {},
  tab: 'open',
  search: {
    text: '',
    result: [],
  },
  info: {
    loading: false,
    start: -30,
    count: 30,
    hasMore: true,
    error: false,
  },
};

export default (state = initialState, action) => {
  const _carers =
    action.start === 0 || action.tabChanged
      ? action.carers
      : unionBy(state.list, action.carers, '_id');

  const _list = state.list;

  if (action.type === ROUTE_CHANGED) {
    return { ...state, detail: {} };
  }
  switch (action.type) {
    case actions.CARERS_ERROR:
      return {
        ...state,
        info: { ...state.info, loading: false, error: action.error },
      };
    case actions.LOADING_CARERS:
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
          start: action.start,
        },
      };
    case actions.RENDER_CARERS:
      return {
        ...state,
        list: action.text ? _list : _carers,
        search: {
          text: action.text,
          result: !action.text ? state.search.result : action.carers,
        },
        info: {
          ...state.info,
          loading: false,
          hasMore: action.carers.length === state.info.count,
        },
      };
    case actions.CHANGE_TAB:
      return {
        ...state,
        list: [],
        search: {
          text: '',
          result: [],
        },
        tab: action.tab,
      };
    case actions.CLEAR_SEARCH:
      return {
        ...state,
        search: {
          text: '',
          result: [],
        },
      };
    case actions.DISPLAY_CARER:
      return {
        ...state,
        detail: action.carer,
      };
    case actions.UPDATE_CARER:
      if (action.detail) {
        return {
          ...state,
          list: state.list.map(l => {
            if (l._id === action.carer) {
              return { ...l, ...action.body };
            }
            return l;
          }),
          detail: {
            ...state.detail,
            ...action.body,
          },
        };
      }
      return state;
    case UPDATE_CARER_CONTACT:
      if (action.detail) {
        const household = { ...state.detail.household };
        if (action.body.givenName || action.body.surname) {
          const givenName =
            action.body.givenName || state.detail.contact.givenName;
          const surname = action.body.surname || state.detail.contact.surname;
          household.name = `${givenName} ${surname} Household`;
        }
        return {
          ...state,
          detail: {
            ...state.detail,
            household,
            contact: {
              ...state.detail.contact,
              ...action.body,
            },
          },
        };
      }
      return state;
    case actions.DELETE_CARER:
      return {
        ...state,
        list: [...filter(state.list, l => l._id !== action.carer)],
        search: {
          ...state.search,
          result: [...filter(state.search.result, r => r._id !== action.carer)],
        },
      };
    case actions.GET_NOTES:
      return {
        ...state,
        detail: {
          ...state.detail,
          notes: action.notes,
        },
      };
    default:
      return state;
  }
};
