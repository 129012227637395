import React from 'react';
import ReactDOM from 'react-dom';
import qs from 'query-string';
import './styles/index.css';
import './styles/common.css';
import App from './App';
import { unregister } from './registerServiceWorker';

const { pathname, hash } = window.location;
const ignorePaths = ['', '/', '/login', '/home'];
if (ignorePaths.indexOf(pathname) === -1 && pathname.substr(0, 5) !== '/auth') {
  sessionStorage.setItem('launch_uri', pathname + hash);
}

const { search } = window.location;
const query = qs.parse(search);

const { redirectTo } = query;
if (redirectTo) {
  sessionStorage.setItem('launch_uri', redirectTo);
}

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
