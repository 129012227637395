import callApi from '../../util/apiCaller';

export function searchPlacements(text, start, count, type) {
  return callApi(`case?start=${start}&count=${count}`, 'POST', { text, type });
}

export function fetchPlacement(_id) {
  return callApi(`case/${_id}`);
}

export function updatePlacement(_id, body) {
  return callApi(`case/${_id}`, 'POST', body);
}

export function addPlacement(body) {
  return callApi('case', 'PUT', body);
}

export function getAccommodation(id) {
  return callApi(`case/${id}/accommodation`);
}

export function createAccommodation(id, body) {
  return callApi(`case/${id}/accommodation`, 'PUT', body);
}

export function updateAccomodation(id, body) {
  return callApi(`case/accommodation/${id}`, 'POST', body);
}

export function createPlacementNote(id, body) {
  return callApi(`case/${id}/note`, 'PUT', body);
}

export function getNotes(id) {
  return callApi(`case/${id}/note`);
}

export function filterPlacementNotes(id, body) {
  return callApi(`case/${id}/note`, 'POST', body);
}

export function getNote(caseId, noteId) {
  return callApi(`case/${caseId}/note/${noteId}`);
}

export function updateNote(caseId, noteId, body) {
  return callApi(`case/${caseId}/note/${noteId}`, 'POST', body);
}

export function assignCoordinator(id, body) {
  return callApi(`case/${id}/coordinator`, 'POST', body);
}

export function filterAccommodation(id, body) {
  return callApi(`case/${id}/accommodation`, 'POST', body);
}

export function deleteAccommodation(id) {
  return callApi(`case/accommodation/${id}`, 'DELETE');
}

export function deletePlacement(_id) {
  return callApi(`case/${_id}`, 'DELETE');
}

export function deleteNote(_id, noteId) {
  return callApi(`case/${_id}/note/${noteId}`, 'DELETE');
}

export function getStaffPlacements(_id) {
  return callApi(`case/staff/${_id}`);
}
