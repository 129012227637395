import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import find from 'lodash/find';
import get from 'lodash/get';

import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import Avatar from 'material-ui/Avatar';
import MenuItem from 'material-ui/MenuItem';
import { List, ListItem } from 'material-ui/List';

import Dialog from '../../../components/Dialog';
import Form from '../../../components/Form';
import SelectField from '../../../components/SelectField';

import {
  updateNote,
  fetchNote,
  closeNote,
  deleteNote,
} from '../PlacementActions';
import {
  fetchHouseholdNote,
  closeHouseholdNote,
  updateHouseholdNote,
  deleteHouseholdNote,
} from '../../Household/HouseholdActions';
import noteModel from '../NoteModel';
import GlobalLoading from '../../../components/GlobalLoading';
import Attachment from '../../../components/Attachment';
import DeletePrompt from '../../../components/DeletePrompt';

import { icons, colours } from '../../../theme';

class NoteDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { note: '' };
    this.model = [];
    this.onChange = debounce(this.onChange.bind(this), 800);
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { dispatch } = this.props;
    if (!isEmpty(props.noteDetail)) {
      if (props.noteDetail.communication === 'Home Visit') {
        this.model = noteModel.map(m => {
          if (['homeVisitYear', 'homeVisitMonth'].includes(m.field)) {
            return { ...m, editable: true };
          }
          return m;
        });
      } else {
        this.model = noteModel;
      }
      this.setState({ note: props.noteDetail });
    } else if (props.open && !this.state.note) {
      if (props.caseLoad)
        dispatch(
          fetchNote(
            get(props.caseLoad, '_id') || props.caseLoad,
            props.note._id
          )
        );
      if (props.household)
        dispatch(
          fetchHouseholdNote(
            get(props.household, '_id') || props.household,
            props.note._id
          )
        );
    } else if (!props.open) this.setState({ note: '' });
  }
  onChange(val) {
    const { household, dispatch } = this.props;
    const { note } = this.state;
    const noteId = note._id;
    const caseId = note.case;
    const householdId = note.household;
    const key = Object.keys(val).join();
    const prevVal = { [key]: note[key] };
    let updateVal = val;
    if (key === 'communication') {
      const homeVisit = {
        homeVisitYear: note.homeVisitYear,
        homeVisitMonth: note.homeVisitMonth,
      };
      if (val.communication === 'Home Visit') {
        this.model = noteModel.map(m => {
          if (['homeVisitYear', 'homeVisitMonth'].includes(m.field)) {
            if (!homeVisit[m.field]) homeVisit[m.field] = m.default;
            return { ...m, editable: true };
          }
          return m;
        });
      } else {
        homeVisit.homeVisitMonth = '';
        homeVisit.homeVisitYear = null;
        this.model = [...noteModel];
      }
      updateVal = { ...homeVisit, ...val };
    }
    if (household)
      dispatch(updateHouseholdNote(householdId, noteId, updateVal, prevVal));
    else dispatch(updateNote(caseId, noteId, updateVal, prevVal));
  }
  onDialogClose() {
    const { dispatch, household, closeDialog } = this.props;
    dispatch(household ? closeHouseholdNote() : closeNote());
    closeDialog();
  }
  deleteNote() {
    const { note } = this.state;
    const { dispatch, household } = this.props;
    return dispatch(
      household
        ? deleteHouseholdNote(note.household, note._id)
        : deleteNote(note.case, note._id)
    ).then(() => this.onDialogClose());
  }
  renderAccom() {
    const { accommodation } = this.state.note;
    if (!accommodation) return null;
    return (
      <List>
        <ListItem
          leftAvatar={<Avatar icon={<icons.HomeIcon />} />}
          primaryText={
            accommodation.household
              ? accommodation.household.name
              : accommodation.alternate
          }
          secondaryText={`${moment(accommodation.dateStart).format(
            'D MMMM YYYY'
          )} - ${
            accommodation.dateEnd
              ? moment(accommodation.dateEnd).format('D MMMM YYYY')
              : 'Present'
          }`}
          key={accommodation._id}
          disabled
        />
      </List>
    );
  }
  renderPeople() {
    const { staff, youngPerson, carers, otherIndividuals } = this.state.note;
    const people = compact([
      staff,
      youngPerson,
      ...carers,
      ...otherIndividuals,
    ]);
    return (
      <List>
        <ListItem
          disabled
          primaryText={people.map(p => p.contact.fullName).join(', ')}
        />
      </List>
    );
  }
  renderPeople2() {
    const {
      staff,
      youngPerson,
      carers,
      otherIndividuals,
      otherYoungPeople,
    } = this.state.note;
    const people = compact([
      staff,
      youngPerson,
      ...otherYoungPeople,
      ...carers,
      ...otherIndividuals,
    ]);
    return (
      <SelectField
        create
        param={{
          field: 'people',
          name: 'People',
          editiable: true,
          required: true,
          multiLine: true,
          multiSelect: true,
        }}
        value={people}
        options={people.map(p => (
          <MenuItem
            key={p._id}
            value={p}
            primaryText={p.contact.fullName}
            checked={!!find(people, select => select._id === p._id)}
            insetChildren
          />
        ))}
        onUpdated={val => val}
      />
    );
  }
  render() {
    const { open, readOnly, goToPlacement, caseLoad, user } = this.props;
    const { note, showDelPro } = this.state;
    let dialogBody = '';
    let title = 'Loading...';
    if (note && note.protected) {
      title = 'Permission Denied';
      dialogBody = (
        <div style={{ paddingTop: '15px' }}>
          You do not have the access rights required for this sensitive note.
        </div>
      );
    } else if (note) {
      title = `${note.title} (${moment(note.dateRecorded).format(
        'D MMMM YYYY'
      )})`;
      dialogBody = (
        <div>
          <Form
            caseLoad={caseLoad}
            edit
            model={this.model}
            object={note}
            section="info"
            onUpdated={val => this.onChange(val)}
            readOnly={readOnly || note.protected}
          />
          <Form
            edit
            model={this.model}
            object={note}
            section="content"
            onUpdated={val => this.onChange(val)}
            readOnly={readOnly || note.protected}
          />
          <Attachment
            admin={
              user.roles.includes('admin') || user.roles.includes('moderator')
            }
            readOnly={readOnly}
            record={note._id}
            recordType="Note"
          />
          {this.renderPeople2()}
          {this.renderAccom()}
        </div>
      );
    }
    const style = {
      marginLeft: 12,
    };
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title={title}
        style={{
          width: '90%',
          maxWidth: '1200px',
        }}
        actions={[
          !note && <GlobalLoading isDialog />,
          goToPlacement && (
            <Link
              to={
                note.household
                  ? `/household/${note.household}`
                  : `/case/${caseLoad || note.case}`
              }
            >
              <FlatButton
                label={`View ${note.household ? 'Household' : 'Placement'}`}
                primary
                style={style}
                onClick={() => this.onDialogClose()}
              />
            </Link>
          ),
          (user.roles.includes('admin') || user.roles.includes('moderator')) &&
            note &&
            !goToPlacement && (
              <FlatButton
                label="Delete"
                primary
                style={{
                  ...style,
                  backgroundColor: colours.kRed,
                  color: 'white',
                }}
                onClick={() => this.setState({ showDelPro: true })}
              />
            ),
          <RaisedButton
            label="Close"
            primary
            style={style}
            onClick={() => this.onDialogClose()}
          />,
        ]}
      >
        {note ? dialogBody : ''}
        <DeletePrompt
          closeDialog={() => this.setState({ showDelPro: false })}
          open={!!showDelPro}
          delete={() => this.deleteNote()}
        />
      </Dialog>
    );
  }
}

NoteDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  noteDetail: props.household
    ? state.household.noteDetail
    : state.cases.noteDetail,
  user: state.user.profile,
});

export default connect(mapStateToProps)(NoteDetail);
