import React, { Component } from 'react';

import './Title.css';

class Title extends Component {
  render() {
    const { title } = this.props;
    return (
      <div className="contact-card">
        <div>
          <h3>{title}</h3>
        </div>
      </div>
    );
  }
}

export default Title;
