const editable = true;

export default [
  {
    field: 'contact',
    name: 'Contact Details',
    type: 'check',
    default: true,
    section: 'options',
    editable: false,
  },
  {
    field: 'regulation',
    name: 'Regulation',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'currentPlacements',
    name: 'Current Placements',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'archivedPlacements',
    name: 'Archived Placements',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'assignedHouseholds',
    name: 'Assigned Households',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'attachments',
    name: 'Attachments',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
];
