const editable = true;
const required = true;
const multiLine = true;

export default [
  {
    field: 'source',
    name: 'Source of Referral',
    type: 'select',
    useKeywords: true,
    section: 'request',
    required,
    editable,
  },
  {
    field: 'placementType',
    name: 'Placement Type',
    type: 'select',
    options: [
      'Foster Care',
      'Respite Care',
      'Community Respite Care',
      'Moving On Program',
      'Family Connections Program',
    ],
    section: 'request',
    required,
    editable,
  },
  {
    field: 'servicesProvided',
    name: 'Services Requested',
    type: 'select',
    useKeywords: true,
    multiSelect: false,
    section: 'request',
    editable,
  },
  {
    field: 'proposedStart',
    name: 'Proposed Start Date',
    type: 'date',
    section: 'request',
    editable,
  },
  {
    field: 'proposedEnd',
    name: 'Proposed End Date',
    type: 'date',
    section: 'request',
    editable,
  },
  {
    field: 'closed',
    name: 'Closed',
    type: 'check',
    section: 'closed',
    editable,
  },
  {
    field: 'closedDate',
    name: 'Closed Date',
    type: 'date',
    section: 'closed',
    editable,
  },
  {
    field: 'closedReason',
    name: 'Reason for Closure',
    type: 'select',
    options: [
      'Unable to find Placement',
      'Placement Identified and Approved By Kennerley',
      'Placement Identified and CSS Refusal',
      'Placement Identified by CSS',
      'Calendar Month Closure',
    ],
    section: 'closed',
    editable,
  },
  {
    field: 'notPlacingReasons',
    name: 'Reasons for Not Placing',
    type: 'select',
    useKeywords: true,
    section: 'closed',
    editable,
  },
  {
    field: 'legalOrderStatus',
    name: 'Legal Order Status',
    type: 'select',
    editable,
    section: 'request',
    options: [
      'Custody',
      'Custody - Protective Supervision ',
      'Guardianship (Long Term)',
      'Guardianship (Short Term)',
      'Third Party Guardianship',
      'FVAO',
      'FCA Parenting Order',
      'Subpoena',
      'Warrant',
      'Not Applicable',
    ],
  },
  {
    field: 'comments',
    name: 'Additional Comments',
    type: 'text',
    default: '',
    section: 'request',
    editable,
    multiLine,
  },
];
