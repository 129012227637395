import * as actions from './UserActions';

const initialState = {
  profile: {},
  chatRoom: '',
  onlineUsers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN:
      return {
        ...state,
        profile: action.profile,
      };
    case actions.LOGOUT:
      return initialState;
    case actions.ROOM:
      return {
        ...state,
        chatRoom: action.room,
      };
    case actions.ONLINE_USERS:
      return {
        ...state,
        onlineUsers: action.users,
      };
    default:
      return state;
  }
};
