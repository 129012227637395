import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import extend from 'lodash/extend';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import isDate from 'lodash/isDate';
import get from 'lodash/get';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

import Form from '../../../components/Form';
import Dialog from '../../../components/Dialog';

import OtherIndividualModel from '../OtherIndividualModel';
import ContactModel, {
  contactOptDOBModel,
  doctorModel,
} from '../../Contact/ContactModel';
import {
  editHouseholdMember,
  removeHouseholdMember,
} from '../../Household/HouseholdActions';
import {
  editDoctor,
  editParent,
  editSibling,
} from '../../YoungPerson/YoungPersonActions';
import DeletePrompt from '../../../components/DeletePrompt';

import { colours } from '../../../theme';

class EditPerson extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }
  getInitialState(props) {
    const { address } = this.props;
    const other = {};
    const contact = {};
    each(OtherIndividualModel, c => {
      other[c.field] = '';
      if (c.multiSelect) other[c.field] = [];
    });
    let model = ContactModel;
    if (props) {
      switch (props.type || get(props, 'other.otherType')) {
        case 'doctor':
          model = doctorModel;
          break;
        case 'parent':
        case 'sibling':
        case 'emergency':
        case 'emergencyContact':
        case 'primaryContact':
        default:
          model = contactOptDOBModel;
          break;
      }
    }

    each(model, c => {
      contact[c.field] = '';
      if (c.multiSelect) contact[c.field] = [];
      if (c.field === 'gender') contact[c.field] = 'Not Specified';
      if (c.type === 'address') {
        if (address) {
          contact[c.field] = address;
        } else {
          contact[c.field] = {};
        }
      }
    });
    return {
      model,
      other,
      contact,
      valid: false,
      stepIndex: 0,
      loading: false,
      error: false,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.other) {
      this.setState(this.getInitialState(props), () =>
        this.setState({
          other: { ...props.other },
          contact: { ...props.other.contact },
        })
      );
    }
  }
  onChange(val, object) {
    this.setState({ [object]: extend(this.state[object], val) }, () =>
      this.validate()
    );
  }
  onDialogClose(save, del) {
    const { household, youngPerson, closeDialog, dispatch } = this.props;
    if (save) {
      this.setState({ loading: true, error: false });
      const other = { _id: this.state.other._id };
      const contact = { _id: this.state.contact._id };
      each(this.state.model, c => {
        contact[c.field] = this.state.contact[c.field];
      });
      each(OtherIndividualModel, o => {
        other[o.field] = this.state.other[o.field];
      });
      if (household) {
        dispatch(
          editHouseholdMember(household._id, other._id, { ...other, contact })
        ).then(
          () => {
            this.setState(this.getInitialState());
            closeDialog();
          },
          () => this.setState({ loading: false, error: true })
        );
      }
      if (youngPerson) {
        let promise = '';
        switch (other.otherType) {
          case 'doctor':
            promise = editDoctor(youngPerson._id, other._id, {
              ...other,
              contact,
            });
            break;
          case 'sibling':
            promise = editSibling(youngPerson._id, other._id, {
              ...other,
              contact,
            });
            break;
          case 'parent':
          default:
            promise = editParent(youngPerson._id, other._id, {
              ...other,
              contact,
            });
            break;
        }
        dispatch(promise).then(
          () => {
            this.setState(this.getInitialState());
            closeDialog();
          },
          () => this.setState({ loading: false, error: true })
        );
      }
    } else if (del && household) {
      this.setState({ loading: true, error: false });
      return dispatch(
        removeHouseholdMember(household._id, this.state.other._id)
      ).then(
        () => {
          this.setState(this.getInitialState());
          closeDialog();
        },
        () => this.setState({ loading: false, error: true })
      );
    } else {
      this.setState(this.getInitialState());
      closeDialog();
    }
  }
  validate() {
    const { other, contact, model } = this.state;
    let valid = true;
    let changed = false;
    each(model, c => {
      if (c.required && valid) {
        valid = !isEmpty(
          other.contact[c.field] || isDate(other.contact[c.field])
        );
        if (c.type === 'address' && valid) {
          const address = other.contac[c.field];
          valid =
            address.street_number &&
            address.route &&
            address.locality &&
            address.administrative_area_level_1 &&
            address.country &&
            address.postal_code;
        }
      }
      if (!changed)
        changed =
          contact[c.field] !== other.contact[c.field] ||
          (contact[c.field] === '' && other.contact[c.field] === undefined);
    });
    if (!changed && other.description) changed = true;
    const birthDate = new Date(contact.birthDate || Date.now());
    birthDate.setFullYear(birthDate.getFullYear() + 16);
    if (!changed && other.otherType === 'member' && birthDate < Date.now()) {
      each(OtherIndividualModel, o => {
        if (!changed)
          changed =
            other[o.field] !== this.props.other[o.field] &&
            !(other[o.field] === '' && other[o.field] === undefined);
      });
    }
    this.setState({ valid: valid && changed });
  }
  render() {
    const {
      valid,
      other,
      contact,
      model,
      showDelPro,
      loading,
      error,
    } = this.state;
    const { open, title, user } = this.props;
    const style = {
      marginLeft: 12,
    };
    let checksRequiredFrom = false;
    if (other && other.otherType === 'member') {
      const birthDate = new Date(contact.birthDate || Date.now());
      birthDate.setFullYear(birthDate.getFullYear() + 16);
      checksRequiredFrom = birthDate < Date.now();
    }
    return (
      <Dialog
        open={open}
        onRequestClose={() => this.onDialogClose()}
        title={title}
        actions={[
          error && (
            <small className="errorText">
              An error occurred, please try again.
            </small>
          ),
          <FlatButton
            label="Cancel"
            style={style}
            primary
            onClick={() => this.onDialogClose()}
          />,
          (user.roles.includes('admin') || user.roles.includes('moderator')) &&
          other.otherType === 'member' ? (
            <FlatButton
              label="Delete"
              primary
              style={{
                ...style,
                backgroundColor: colours.kRed,
                color: 'white',
              }}
              disabled={loading}
              icon={
                loading && (
                  <CircularProgress
                    size={20}
                    thickness={2}
                    className="progress"
                  />
                )
              }
              onClick={() => this.setState({ showDelPro: true })}
            />
          ) : null,
          <RaisedButton
            label="Save"
            style={style}
            primary
            disabled={loading || !valid}
            icon={
              loading && (
                <CircularProgress
                  size={20}
                  thickness={2}
                  className="progress"
                />
              )
            }
            onClick={() => this.onDialogClose(isEmpty(other) ? '' : 'save')}
          />,
        ]}
      >
        <span />
        <Form
          create
          model={model}
          object={contact}
          onUpdated={val => this.onChange(val, 'contact')}
        />
        <Form
          create
          model={OtherIndividualModel}
          object={other}
          section="description"
          onUpdated={val => this.onChange(val, 'other')}
        />
        {checksRequiredFrom && (
          <Form
            create
            model={OtherIndividualModel}
            object={other}
            section="regulation"
            onUpdated={val => this.onChange(val, 'other')}
          />
        )}
        <DeletePrompt
          closeDialog={() => this.setState({ showDelPro: false })}
          open={!!showDelPro}
          delete={() => this.onDialogClose(null, true)}
        />
      </Dialog>
    );
  }
}

EditPerson.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ user: state.user.profile });

export default connect(mapStateToProps)(EditPerson);
