import React, { Component } from 'react';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import MSelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { colours } from '../theme';

class SelectField extends Component {
  renderMenuItems(options) {
    const { param, value, search } = this.props;
    const { useKeywords, field, multiSelect, required } = param;
    const keywords = this.props.keywords.find(key => key.field === field);
    const items = (
      (useKeywords && keywords ? keywords.values : options) || []
    ).map(o => (
      <MenuItem
        key={o}
        value={o}
        primaryText={o}
        checked={multiSelect && !!value && value.includes(o)}
        insetChildren={multiSelect}
      />
    ));
    if (search || !required || multiSelect) {
      items.unshift(<MenuItem key="Blank" value="" />);
    }
    return items;
  }
  render() {
    const {
      param,
      value,
      onUpdated,
      create,
      readOnly,
      search,
      options,
    } = this.props;
    let floatingLabelText = param.name;
    if (create) {
      if (param.required) {
        floatingLabelText = `${param.name} (required)`;
      }
    } // TODO textfield for other
    return (
      <MSelectField
        id={param.field}
        fullWidth
        floatingLabelText={floatingLabelText}
        floatingLabelStyle={{
          color: create && param.required && !value && colours.kRed,
        }}
        type={param.type}
        multiple={param.multiSelect}
        value={
          !search &&
          param.default &&
          isEmpty(value) &&
          typeof value !== 'number'
            ? param.default
            : value
        }
        onChange={(e, index, val) => onUpdated(val)}
        disabled={readOnly || (!create && !param.editable)}
        underlineDisabledStyle={{ borderBottom: '1px solid #e0e0e0' }}
      >
        {options || this.renderMenuItems(param.options)}
      </MSelectField>
    );
  }
}

function mapStateToProps(state) {
  return {
    keywords: state.app.keywords,
  };
}

export default connect(mapStateToProps)(SelectField);
