import keys from 'lodash/keys';
import find from 'lodash/find';

import CarerModel from './CarerModel';

import * as CarerAPI from './CarerAPI';

import { notification } from '../App/AppActions';

export const RENDER_CARERS = 'carer/RENDER_CARERS';
export const LOADING_CARERS = 'carer/LOADING_CARERS';
export const DISPLAY_CARER = 'carer/DISPLAY_CARER';
export const UPDATE_CARER = 'carer/UPDATE_CARER';
export const CHANGE_TAB = 'carer/CHANGE_TAB';
export const CLEAR_SEARCH = 'carer/CLEAR_SEARCH';
export const DELETE_CARER = 'carer/DELETE_CARER';
export const GET_NOTES = 'carer/GET_NOTES';
export const CARERS_ERROR = 'carer/CARERS_ERROR';

export function changeTab(tab) {
  return {
    type: CHANGE_TAB,
    tab,
  };
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

export function renderCarers(text, tab, tabChanged, carers) {
  return {
    type: RENDER_CARERS,
    text,
    tab,
    tabChanged,
    carers,
  };
}

export function getCarer(carer) {
  return {
    type: DISPLAY_CARER,
    carer,
  };
}

export function updateCarer(carer, body, detail) {
  return {
    type: UPDATE_CARER,
    carer,
    body,
    detail,
  };
}

export function loadMore(start) {
  return {
    type: LOADING_CARERS,
    start,
  };
}

export function _deleteCarer(carer) {
  return {
    type: DELETE_CARER,
    carer,
  };
}

export function getCarerNotes(notes) {
  return {
    type: GET_NOTES,
    notes,
  };
}

export function setError(error = false) {
  return {
    type: CARERS_ERROR,
    error,
  };
}

export function searchCarers(text, start, count, type, tabChanged) {
  return dispatch => {
    dispatch(loadMore(start));
    return CarerAPI.searchCarers(text, start, count, type).then(
      carers => {
        dispatch(renderCarers(text, type, tabChanged, carers));
      },
      err => dispatch(setError(err || true))
    );
  };
}

export function fetchCarer(_id) {
  return dispatch =>
    CarerAPI.fetchCarer(_id).then(
      carer => {
        if (carer) {
          return dispatch(getCarer(carer));
        }
        return dispatch(getCarer({ deleted: true }));
      },
      () => Promise.reject()
    );
}

export function modifyCarer(_id, body, prevVal, undo = false, detail = true) {
  return dispatch =>
    CarerAPI.updateCarer(_id, body).then(() => {
      dispatch(updateCarer(_id, body, detail));
      if (!undo) {
        dispatch(
          notification(
            `${find(CarerModel, { field: keys(body)[0] }).name} updated.`,
            'UNDO',
            modifyCarer(_id, prevVal, '', true)
          )
        );
      } else {
        dispatch(
          notification(
            `Changes to ${
              find(CarerModel, { field: keys(body)[0] }).name
            } undone.`
          )
        );
      }
    });
}

export function submitCarer(body) {
  return dispatch =>
    CarerAPI.addCarer(body).then(carer => {
      dispatch(getCarer(carer));
      return carer;
    });
}

export function deleteCarer(carer) {
  return dispatch =>
    CarerAPI.deleteCarer(carer).then(() => dispatch(_deleteCarer(carer)));
}

export function fetchCarerNotes(_id) {
  return dispatch =>
    CarerAPI.getCarerNotes(_id).then(notes => {
      dispatch(getCarerNotes(notes));
      return notes;
    });
}
