import * as OtherIndividualAPI from './OtherIndividualAPI';

export const RENDER_MEMBERS = 'member/RENDER_MEMBERS';
export const LOADING_MEMBERS = 'member/LOADING_MEMBERS';
export const CHANGE_TAB = 'member/CHANGE_TAB';
export const CLEAR_SEARCH = 'member/CLEAR_SEARCH';
export const MEMBERS_ERROR = 'member/MEMBERS_ERROR';

export function changeTab(tab) {
  return {
    type: CHANGE_TAB,
    tab,
  };
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

export function renderMember(text, tab, tabChanged, members) {
  return {
    type: RENDER_MEMBERS,
    text,
    tab,
    tabChanged,
    members,
  };
}

export function loadMore(start) {
  return {
    type: LOADING_MEMBERS,
    start,
  };
}

export function setError(error = false) {
  return {
    type: MEMBERS_ERROR,
    error,
  };
}

export function searchMember(text, start, count, type, tabChanged) {
  return dispatch => {
    dispatch(loadMore(start));
    return OtherIndividualAPI.searchMember(text, start, count, type).then(
      members => {
        dispatch(renderMember(text, type, tabChanged, members));
      },
      err => dispatch(setError(err || true))
    );
  };
}
