import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { SpeedDial, BubbleList, BubbleListItem } from 'react-speed-dial';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import each from 'lodash/each';
import uniqBy from 'lodash/uniqBy';
import pickBy from 'lodash/pickBy';

import { Card, CardTitle } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import RaisedButton from 'material-ui/RaisedButton';
import { List, ListItem } from 'material-ui/List';

import {
  fetchProspect,
  modifyProspect,
  createProspectHousehold,
  deleteProspect,
} from '../ProspectActions';
import { updateRecent } from '../../App/AppActions';
import { filterProspectNotes, getProspectNotes } from '../ProspectAPI';
import TrainingAdd from '../../Training/components/TrainingAdd';
import TrainingView from '../../Training/components/TrainingView';
import TrainingCard from '../../Training/components/TrainingCard';

import ObjectLogs from '../../Log/components/ObjectLogs';
import { getObjectLogs } from '../../Log/LogAPI';
import SelectField from '../../../components/SelectField';
import TextField from '../../../components/TextField';
import noteModel from '../../Placement/NoteModel';

import carerModel from '../CarerModel';
import PlacementCard from '../../Placement/components/PlacementCard';
import NoteCard from '../../Placement/components/NoteCard';
import Form from '../../../components/Form';
import PersonCard from '../../../components/PersonCard';
import Attachment from '../../../components/Attachment';
import Dialog from '../../../components/Dialog';
import GlobalLoading from '../../../components/GlobalLoading';
import GlobalError from '../../../components/GlobalError';
import ContactCard from '../../Contact/components/ContactCard';
import HouseholdCard from '../../Household/components/HouseholdCard';
import BackLink from '../../../components/BackLink';
import DeletePrompt from '../../../components/DeletePrompt';

import { colours, icons } from '../../../theme';
import deepCompare from '../../../util/deepCompare';

class ProspectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachmentsExpanded: false,
      currentPlacementsExpanded: true,
      regulationExpanded: true,
      trainingExpanded: false,
      supportExpanded: false,
      enquiryExpanded: true,
      detailsExpanded: true,
      logExpanded: false,
      prospect: {},
      contact: {},
      training: {},
      logs: [],
      speedDialOpen: false,
      showTrainingAdd: false,
      showTrainingView: false,
      approvalErrorOpen: false,
      approvalErrorText: [],
      notes: '',
      search: {},
      showDelPro: false,
      error: false,
    };
    this.onChange = debounce(this.onChange.bind(this), 800);
    this.doSearch = debounce(this.doSearch.bind(this), 800);
    props
      .dispatch(fetchProspect(props.match.params.id))
      .then(({ prospect }) => {
        if (prospect.contact && prospect.contact.fullName) {
          props.dispatch(
            updateRecent({
              type: 'prospect',
              id: props.match.params.id,
              title: prospect.contact.fullName,
            })
          );
        }
      })
      .catch(() => this.setState({ error: true }));
  }
  shouldComponentUpdate(props, state) {
    const { user, prospect, contact, household } = this.props;
    return (
      !deepCompare(state, this.state) ||
      !deepCompare(props.user, user) ||
      !deepCompare(props.prospect, prospect) ||
      !deepCompare(props.contact, contact) ||
      !deepCompare(props.household, household)
    );
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ prospect: props.prospect, contact: props.contact });
  }
  doSearch() {
    // NOTE: EXCLUDE SENSITIVE???
    const search = pickBy(this.state.search);
    if (isEmpty(search)) this.setState({ searchResults: '' });
    else
      filterProspectNotes(this.props.match.params.id, search).then(
        searchResults => this.setState({ searchResults })
      );
  }
  onChange(val) {
    const { prospect } = this.state;
    const { match, dispatch } = this.props;
    const { id } = match.params;
    const key = Object.keys(val).join();
    const prevVal = { [key]: prospect[key] };
    const body = val;
    if (key === 'status') {
      if (
        (val.status === 'Withdrawn' ||
          val.status === 'Not Suitable' ||
          val.status === 'Transferred' ||
          val.status === 'Deregistered' ||
          val.status === 'Closed') &&
        !val.dateClosed
      ) {
        body.dateClosed = new Date().setHours(0, 0, 0, 0);
        prevVal.dateClosed = prospect.dateClosed;
        body.dateInactive = '';
        prevVal.dateInactive = prospect.dateInactive;
      }
      if (
        val.status === 'Waiting' ||
        val.status === 'Allocated Assessor' ||
        val.status === 'Commenced Training'
      ) {
        body.dateClosed = '';
        prevVal.dateClosed = prospect.dateClosed;
        body.dateInactive = '';
        prevVal.dateInactive = prospect.dateInactive;
        body.approvalDate = '';
        prevVal.approvalDate = prospect.approvalDate;
      }
      if (val.status === 'Temporarily Inactive') {
        body.dateClosed = '';
        prevVal.dateClosed = prospect.dateClosed;
        body.dateInactive = new Date().setHours(0, 0, 0, 0);
        prevVal.dateInactive = prospect.dateInactive;
      }
    }
    if (
      (key === 'status' && val.status === 'Approved') ||
      key === 'approvalDate'
    ) {
      const readyForApproval = this.validateApproval();
      if (readyForApproval) {
        body.dateClosed = '';
        body.dateInactive = '';
        body.approvalDate =
          val.approvalDate ||
          prospect.approvalDate ||
          new Date().setHours(0, 0, 0, 0);
        dispatch(modifyProspect(id, body, prevVal));
      } else {
        this.setState({ ...prevVal, approvalErrorOpen: true });
      }
    } else dispatch(modifyProspect(id, body, prevVal));
  }
  onSelectTraining(t) {
    this.setState({
      training: t,
      showTrainingView: true,
    });
  }
  getLogs() {
    const { prospect, contact } = this.props;
    getObjectLogs(prospect._id, contact._id).then(logs =>
      this.setState({ logs })
    );
  }
  validateApproval() {
    const { prospect, contact } = this.props;
    let { household } = this.props;
    if (!household) household = {};
    const { kinship } = household;
    const { training } = prospect;
    let readyForApproval = false;
    let trainingComplete = false;
    let ia = false;
    let ptd1 = false;
    let ptd2 = false;
    let ptd3 = false;
    let kaA = false;
    const capacity = household.fosterCapacity || household.respiteCapacity;
    if (
      !isEmpty(household) &&
      household.careType.length === 1 &&
      household.careType.includes('Family Connections Program')
    ) {
      if (!!contact.birthDate && capacity) readyForApproval = true;
      if (!readyForApproval) {
        const approvalErrorText = [];
        if (!contact.birthDate) {
          approvalErrorText.push('Date of Birth');
        }
        if (!capacity) approvalErrorText.push('Capacity (Household)');
        this.setState({ approvalErrorText });
      }
    } else {
      each(training, t => {
        switch (t.module) {
          case 'Preservice Training Day 1':
            ptd1 = !!t.endDate;
            break;
          case 'Preservice Training Day 2':
            ptd2 = !!t.endDate;
            break;
          case 'Preservice Training Day 3':
            ptd3 = !!t.endDate;
            break;
          case 'Initial Assessment':
            ia = !!t.endDate;
            break;
          case 'Kinship Assessment A':
            kaA = !!t.endDate;
            break;
          default:
        }
      });
      trainingComplete =
        (kinship && kaA) || (!kinship && ptd1 && ptd2 && ptd3 && ia);
      const regulationOfCare =
        prospect.wwvpCheck &&
        prospect.pcResults &&
        prospect.pcResultsReceived &&
        prospect.cscResults &&
        prospect.cscResultsReceived;

      if (
        !!contact.birthDate &&
        !!household &&
        !isEmpty(household.careType) &&
        capacity &&
        trainingComplete &&
        regulationOfCare
      )
        readyForApproval = true;
      if (!readyForApproval) {
        const approvalErrorText = [];
        if (!contact.birthDate) {
          approvalErrorText.push('Date of Birth');
        }
        if (isEmpty(household)) {
          approvalErrorText.push('Household');
        } else {
          if (isEmpty(household.careType))
            approvalErrorText.push('Care Type (Household)');
          if (!capacity) approvalErrorText.push('Capacity (Household)');
        }
        if (kinship) {
          if (!kaA) approvalErrorText.push('Kinship Assessment A (Completed)');
        } else {
          if (!ia) approvalErrorText.push('Initial Assessment (Completed)');
          if (!ptd1)
            approvalErrorText.push('Preservice Training Day 1 (Completed)');
          if (!ptd2)
            approvalErrorText.push('Preservice Training Day 2 (Completed)');
          if (!ptd3)
            approvalErrorText.push('Preservice Training Day 3 (Completed)');
        }
        if (!prospect.wwvpCheck)
          approvalErrorText.push('Working with Vulnerable People Check');
        if (!prospect.pcResults) approvalErrorText.push('Police Check Results');
        if (!prospect.pcResultsReceived)
          approvalErrorText.push('PC Results Received');
        if (!prospect.cscResults) approvalErrorText.push('CSC Results');
        if (!prospect.cscResultsReceived)
          approvalErrorText.push('CSC Results Received');
        this.setState({ approvalErrorText });
      }
    }
    return readyForApproval;
  }
  renderNoteSearch(num) {
    const { search } = this.state;
    return (
      <List expandable>
        <ListItem
          primaryTogglesNestedList
          primaryText={`Search Notes (${num})`}
          rightIcon={<icons.SearchIcon color={colours.skyBlue} />}
          nestedItems={[
            <ListItem disabled key="searchbody">
              <TextField
                param={{ field: 'text', name: 'Search', editable: true }}
                value={search.text}
                onUpdated={newVal => {
                  this.setState({ search: { ...search, text: newVal } });
                  this.doSearch();
                }}
              />
              {noteModel.map(n => {
                if (!n.filterable) return null;
                return (
                  <SelectField
                    search
                    key={n.field}
                    param={n}
                    value={search[n.field]}
                    onUpdated={newVal => {
                      this.setState({
                        search: { ...search, [n.field]: newVal },
                      });
                      this.doSearch();
                    }}
                  />
                );
              })}
            </ListItem>,
          ]}
        />
      </List>
    );
  }
  renderNotes(readOnly) {
    const { searchResults, notes, notesExpanded } = this.state;
    const displayNotes = searchResults || notes || [];
    if (!notes && notesExpanded)
      getProspectNotes(this.props.prospect._id).then(notes =>
        this.setState({ notes })
      );
    return (
      <Card
        className="content-box"
        expanded={notesExpanded}
        onExpandChange={() => this.setState({ notesExpanded: !notesExpanded })}
      >
        <CardTitle subtitle="Notes" actAsExpander showExpandableButton />
        {this.renderNoteSearch(displayNotes ? displayNotes.length : null)}
        {displayNotes &&
          displayNotes.map(n => (
            <NoteCard
              readOnly
              key={n._id}
              note={n}
              caseLoad={n.case}
              household={n.household}
              expandable
              goToPlacement
            />
          ))}
      </Card>
    );
  }
  renderFamily() {
    const { household } = this.props;
    return (
      <Card className="content-box">
        <CardTitle subtitle="My Family" />
        {!isEmpty(household) && <HouseholdCard household={household} />}
      </Card>
    );
  }
  renderPreviousHousehold() {
    const { previousHouseholds } = this.props.prospect;
    return previousHouseholds.length > 0 ? (
      <Card className="content-box">
        <CardTitle subtitle="My Previous Households" />
        {previousHouseholds.map(pH => <HouseholdCard household={pH} />)}
      </Card>
    ) : null;
  }
  renderDetails(readOnly) {
    const { detailsExpanded, prospect } = this.state;
    return (
      <Card
        className="content-box"
        expanded={detailsExpanded}
        onExpandChange={() =>
          this.setState({ detailsExpanded: !detailsExpanded })
        }
      >
        <CardTitle subtitle="My Details" actAsExpander showExpandableButton />
        <Form
          model={carerModel}
          readOnly={readOnly}
          section="main"
          expandable
          object={prospect}
          onUpdated={val => this.onChange(val)}
        />
      </Card>
    );
  }
  renderSupport() {
    const { household } = this.props;
    const { supportExpanded } = this.state;
    let staff = [];
    if (!isEmpty(household))
      staff = uniqBy(household.cases.map(c => c.staff), '_id');
    return (
      <Card
        className="content-box"
        expanded={supportExpanded}
        onExpandChange={() =>
          this.setState({ supportExpanded: !supportExpanded })
        }
      >
        <CardTitle subtitle="My Support" actAsExpander showExpandableButton />
        {staff.map(s => <PersonCard person={s} rel="staff" key={s._id} />)}
      </Card>
    );
  }
  renderTraining(readOnly) {
    const { training } = this.props.prospect;
    const { trainingExpanded } = this.state;
    return (
      <Card
        className="content-box"
        expanded={trainingExpanded}
        onExpandChange={() =>
          this.setState({ trainingExpanded: !trainingExpanded })
        }
      >
        <CardTitle
          subtitle="My Assessments and Training"
          actAsExpander
          showExpandableButton
        />
        {training.map(t => (
          <TrainingCard
            readOnly={readOnly}
            training={t}
            key={t._id}
            expandable
            showDialog={() => this.onSelectTraining(t)}
          />
        ))}
      </Card>
    );
  }
  renderRegulation(readOnly) {
    const { regulationExpanded, prospect } = this.state;
    return (
      <Card
        className="content-box"
        expanded={regulationExpanded}
        onExpandChange={() =>
          this.setState({ regulationExpanded: !regulationExpanded })
        }
      >
        <CardTitle
          subtitle="Regulation of Care"
          actAsExpander
          showExpandableButton
        />
        <Form
          model={carerModel}
          readOnly={readOnly}
          section="regulation"
          expandable
          object={prospect}
          onUpdated={val => this.onChange(val)}
        />
      </Card>
    );
  }
  renderInitialEnquiry(readOnly) {
    const { enquiryExpanded, prospect } = this.state;
    return (
      <Card
        className="content-box"
        expanded={enquiryExpanded}
        onExpandChange={() =>
          this.setState({ enquiryExpanded: !enquiryExpanded })
        }
      >
        <CardTitle
          subtitle="Initial Enquiry"
          actAsExpander
          showExpandableButton
        />
        <Form
          model={carerModel}
          readOnly={readOnly}
          section="enquiry"
          expandable
          object={prospect}
          onUpdated={val => this.onChange(val)}
        />
      </Card>
    );
  }
  renderLogs() {
    const { logs, logExpanded } = this.state;
    if (isEmpty(logs) && logExpanded) this.getLogs();
    return (
      <Card
        className="content-box"
        expanded={logExpanded}
        onExpandChange={() => this.setState({ logExpanded: !logExpanded })}
      >
        <CardTitle subtitle="Logs" actAsExpander showExpandableButton />
        {!isEmpty(logs) && <ObjectLogs logs={logs} expandable />}
      </Card>
    );
  }
  renderAttachments(readOnly) {
    const { user } = this.props;
    const { attachmentsExpanded, prospect } = this.state;
    return (
      <Card
        className="content-box"
        expanded={attachmentsExpanded}
        onExpandChange={() =>
          this.setState({ attachmentsExpanded: !attachmentsExpanded })
        }
      >
        <CardTitle subtitle="Attachments" actAsExpander showExpandableButton />
        <Attachment
          admin={
            user.roles.includes('admin') || user.roles.includes('moderator')
          }
          readOnly={readOnly}
          record={prospect._id}
          recordType="Carer"
          expandable
        />
      </Card>
    );
  }
  renderPlacements() {
    const { household } = this.props;
    const {
      currentPlacementsExpanded,
      archivedPlacementsExpanded,
    } = this.state;
    let cases = [];
    if (!isEmpty(household)) {
      cases = household.cases;
    }
    const currentPlacements = cases.filter(
      c => !c.leftCareDate || moment(c.leftCareDate).isAfter(moment())
    );
    const archivedPlacements = cases.filter(
      c => c.leftCareDate && moment(c.leftCareDate).isBefore(moment())
    );
    return [
      <Card
        className="content-box"
        key="current"
        expanded={currentPlacementsExpanded}
        onExpandChange={() =>
          this.setState({
            currentPlacementsExpanded: !currentPlacementsExpanded,
          })
        }
      >
        <CardTitle
          subtitle="My Placements"
          actAsExpander
          showExpandableButton
        />
        {currentPlacements.map(c => (
          <PlacementCard key={c._id} caseLoad={c} expandable />
        ))}
      </Card>,
      <Card
        className="content-box"
        key="archived"
        expanded={archivedPlacementsExpanded}
        onExpandChange={() =>
          this.setState({
            archivedPlacementsExpanded: !archivedPlacementsExpanded,
          })
        }
      >
        <CardTitle
          subtitle="Archived Placements"
          actAsExpander
          showExpandableButton
        />
        {archivedPlacements.map(c => (
          <PlacementCard key={c._id} caseLoad={c} expandable />
        ))}
      </Card>,
    ];
  }
  render() {
    const {
      prospect,
      contact,
      approvalErrorOpen,
      speedDialOpen,
      showTrainingAdd,
      training,
      showTrainingView,
      approvalErrorText,
      showDelPro,
      error,
    } = this.state;
    const { dispatch, user, household, match } = this.props;
    let readOnly = true;
    if (error) return <GlobalError />;
    if (isEmpty(prospect) || isEmpty(user.roles)) return <GlobalLoading />;
    if (
      user.roles &&
      !user.roles.includes('admin') &&
      !user.roles.includes('moderator')
    ) {
      // const carers = [];
      // each(user.staff.caseLoad, (c) => {
      //   carers.push(c.household.primaryCarer);
      //   carers.push(c.household.secondaryCarer);
      // });
      // if (carers.includes(carer._id)) readOnly = false;
      readOnly = false;
    } else readOnly = false;
    if (prospect.deleted)
      return (
        <div className="container">
          <div className="content">
            <div>
              <div
                className="list-search"
                style={{
                  margin: 'auto',
                  width: '50%',
                  textAlign: 'center',
                  padding: '5px',
                }}
              >
                This prospect can not be found or has been deleted.
                <BackLink />
              </div>
            </div>
          </div>
        </div>
      );
    const deletable =
      user.roles.includes('admin') || user.roles.includes('moderator');
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{contact.fullName} - HEART1869</title>
          <meta
            name="description"
            content="Kennerley HEART1869 Prospect Detail"
          />
        </Helmet>
        <div className="content">
          <div className="content-body">
            <div className="content-column">
              <Card className="content-box">
                <ContactCard
                  contact={{ ...contact, userType: 'prospect' }}
                  readOnly={readOnly}
                />
              </Card>
              {this.renderDetails(readOnly)}
              {this.renderFamily(readOnly)}
              {this.renderPlacements(readOnly)}
              {this.renderNotes()}
              {this.renderSupport(readOnly)}
            </div>
            <div className="content-column">
              {this.renderTraining(readOnly)}
              {this.renderRegulation(readOnly)}
              {this.renderInitialEnquiry(readOnly)}
              {this.renderAttachments(readOnly)}
              {this.renderLogs()}
            </div>
          </div>
          <SpeedDial
            styleBackdrop={{ opacity: 0.01 }}
            className="speed-dial"
            isOpen={speedDialOpen}
            onChange={change => this.setState({ speedDialOpen: change.isOpen })}
            floatingActionButtonProps={
              isEmpty(household) ? { backgroundColor: colours.kRed } : {}
            }
          >
            <BubbleList className="bubble-list">
              {isEmpty(household) && (
                <BubbleListItem
                  primaryText="Household"
                  rightAvatar={
                    <Avatar
                      backgroundColor={colours.kRed}
                      color="white"
                      icon={<icons.HomeIcon />}
                    />
                  }
                  onClick={() => {
                    dispatch(createProspectHousehold(prospect._id)).then(() =>
                      this.setState({ speedDialOpen: false })
                    );
                  }}
                />
              )}
              <BubbleListItem
                primaryText="Training"
                rightAvatar={
                  <Avatar
                    backgroundColor={colours.limeGreen}
                    color={colours.skyBlue}
                    icon={<icons.TrainingIcon />}
                  />
                }
                onClick={() => {
                  this.setState({
                    showTrainingAdd: true,
                    speedDialOpen: false,
                  });
                }}
              />
              {deletable && (
                <BubbleListItem
                  primaryText="Delete"
                  rightAvatar={
                    <Avatar
                      backgroundColor={colours.kRed}
                      color="white"
                      icon={<icons.DeleteIcon />}
                    />
                  }
                  onClick={() => {
                    this.setState({ speedDialOpen: false, showDelPro: true });
                  }}
                />
              )}
            </BubbleList>
          </SpeedDial>
          <TrainingAdd
            parentType="Prospect"
            parentId={match.params.id}
            closeDialog={() => this.setState({ showTrainingAdd: false })}
            open={showTrainingAdd}
          />
          <TrainingView
            parentType="Prospect"
            training={training}
            closeDialog={() => this.setState({ showTrainingView: false })}
            open={showTrainingView}
          />
        </div>
        <Dialog
          open={approvalErrorOpen}
          onRequestClose={() => this.setState({ approvalErrorOpen: false })}
          modal
          title="Unable to Approve"
          actions={[
            <RaisedButton
              label="OK"
              primary
              onClick={() => this.setState({ approvalErrorOpen: false })}
            />,
          ]}
        >
          <div className="error-dialog">
            <div className="missing-text">
              {prospect.contact.givenName} needs the following before they can
              become approved -{' '}
            </div>
            {approvalErrorText.map(t => (
              <div key={t} className="missing-text">
                {t}
              </div>
            ))}
          </div>
        </Dialog>
        <DeletePrompt
          closeDialog={() => this.setState({ showDelPro: false })}
          open={showDelPro}
          delete={() => dispatch(deleteProspect(prospect._id))}
          parentRoute={'/prospect'}
        />
      </div>
    );
  }
}

ProspectDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.profile,
  prospect: state.prospect.detail,
  contact: state.prospect.detail.contact,
  household: state.prospect.detail.household,
});

export default connect(mapStateToProps)(ProspectDetail);
