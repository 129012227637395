const editable = true;
const required = true;
const semiRequired = true;

export default [
  {
    field: 'givenName',
    name: 'Given Name',
    type: 'text',
    default: '',
    editable,
    required,
  },
  {
    field: 'surname',
    name: 'Surname',
    type: 'text',
    default: '',
    editable,
    required,
  },
  {
    field: 'aka',
    name: 'Also Known As',
    type: 'text',
    default: '',
    editable,
  },
  {
    field: 'gender',
    name: 'Gender',
    type: 'select',
    default: 'Not Specified',
    editable,
    useKeywords: true,
    required,
  },
  {
    field: 'birthDate',
    name: 'Date of Birth',
    type: 'date',
    default: '',
    editable,
    required,
  },
  {
    field: 'email',
    name: 'Email Address',
    type: 'text',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'mobile',
    name: 'Mobile Number',
    type: 'text',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'phone',
    name: 'Phone Number',
    type: 'text',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'residentialAddress',
    name: 'Residential Address',
    type: 'address',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'postalAddress',
    name: 'Postal Address',
    type: 'address',
    default: '',
    editable,
  },
];

export const contactOptDOBModel = [
  {
    field: 'givenName',
    name: 'Given Name',
    type: 'text',
    default: '',
    editable,
    required,
  },
  {
    field: 'surname',
    name: 'Surname',
    type: 'text',
    default: '',
    editable,
    required,
  },
  {
    field: 'aka',
    name: 'Also Known As',
    type: 'text',
    default: '',
    editable,
  },
  {
    field: 'gender',
    name: 'Gender',
    type: 'select',
    default: 'Not Specified',
    editable,
    useKeywords: true,
    required,
  },
  {
    field: 'birthDate',
    name: 'Date of Birth',
    type: 'date',
    default: '',
    editable,
  },
  {
    field: 'email',
    name: 'Email Address',
    type: 'text',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'mobile',
    name: 'Mobile Number',
    type: 'text',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'phone',
    name: 'Phone Number',
    type: 'text',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'residentialAddress',
    name: 'Residential Address',
    type: 'address',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'postalAddress',
    name: 'Postal Address',
    type: 'address',
    default: '',
    editable,
  },
];

export const doctorModel = [
  {
    field: 'givenName',
    name: 'Given Name',
    type: 'text',
    default: '',
    editable,
    required,
  },
  {
    field: 'surname',
    name: 'Surname',
    type: 'text',
    default: '',
    editable,
    required,
  },
  {
    field: 'aka',
    name: 'Also Known As',
    type: 'text',
    default: '',
    editable,
  },
  {
    field: 'gender',
    name: 'Gender',
    type: 'select',
    default: 'Not Specified',
    editable,
    useKeywords: true,
  },
  {
    field: 'birthDate',
    name: 'Date of Birth',
    type: 'date',
    default: '',
    editable,
  },
  {
    field: 'email',
    name: 'Email Address',
    type: 'text',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'mobile',
    name: 'Mobile Number',
    type: 'text',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'phone',
    name: 'Phone Number',
    type: 'text',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'residentialAddress',
    name: 'Practice Address',
    type: 'address',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'postalAddress',
    name: 'Postal Address',
    default: '',
    type: 'address',
    editable,
  },
];

export const otherContactModel = [
  {
    field: 'givenName',
    name: 'Given Name',
    type: 'text',
    default: '',
    editable,
    required,
  },
  {
    field: 'surname',
    name: 'Surname',
    type: 'text',
    default: '',
    editable,
    required,
  },
  {
    field: 'aka',
    name: 'Also Known As',
    type: 'text',
    default: '',
    editable,
  },
  {
    field: 'gender',
    name: 'Gender',
    type: 'select',
    default: 'Not Specified',
    editable,
    useKeywords: true,
  },
  {
    field: 'birthDate',
    name: 'Date of Birth',
    type: 'date',
    default: '',
    editable,
  },
  {
    field: 'email',
    name: 'Email Address',
    type: 'text',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'mobile',
    name: 'Mobile Number',
    type: 'text',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'phone',
    name: 'Phone Number',
    type: 'text',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'residentialAddress',
    name: 'Residential Address',
    type: 'address',
    default: '',
    editable,
    semiRequired,
  },
  {
    field: 'postalAddress',
    name: 'Postal Address',
    type: 'address',
    default: '',
    editable,
  },
];
