import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// import each from 'lodash/each';

import { ListItem } from 'material-ui/List';
// import Avatar from 'material-ui/Avatar';

import { colours, icons } from '../../../theme';

class ClientCard extends Component {
  render() {
    const { client, rel } = this.props;

    const iconCommons = {
      className: 'person-card-back',
      color: colours.kGrey,
    };

    const iconsWithAttributes = {
      client: <icons.PersonIcon {...iconCommons} />,
    };

    return (
      <div className="person-card">
        {iconsWithAttributes[rel]}
        <ListItem
          primaryText={`${client.onames} ${client.sname}`}
          onClick={() => this.props.onClickClient(client)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.profile,
});

export default connect(mapStateToProps)(ClientCard);
