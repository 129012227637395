import { combineReducers } from 'redux';

import app from './modules/App/AppReducer';
import user from './modules/User/UserReducer';
import carer from './modules/Carer/CarerReducer';
import prospect from './modules/Carer/ProspectReducer';
import young from './modules/YoungPerson/YoungPersonReducer';
import referral from './modules/Referral/ReferralReducer';
import household from './modules/Household/HouseholdReducer';
import cases from './modules/Placement/PlacementReducer';
import staff from './modules/Staff/StaffReducer';
import member from './modules/OtherIndividual/OtherIndividualReducer';

import accessdb from './modules/AccessDB/AccessDBReducer';

export default combineReducers({
  app,
  user,
  carer,
  prospect,
  young,
  referral,
  household,
  cases,
  staff,
  accessdb,
  member,
});
