import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { SpeedDial, BubbleList, BubbleListItem } from 'react-speed-dial';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import pickBy from 'lodash/pickBy';
import each from 'lodash/each';
import filter from 'lodash/filter';

import { Card, CardTitle } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import { List, ListItem } from 'material-ui/List';

import ObjectLogs from '../../Log/components/ObjectLogs';
import { getObjectLogs } from '../../Log/LogAPI';

import noteModel from '../NoteModel';

import {
  fetchPlacement,
  fetchPlacementNotes,
  modifyPlacement,
  deletePlacement,
} from '../PlacementActions';
import { filterPlacementNotes } from '../PlacementAPI';
import { updateRecent } from '../../App/AppActions';
import caseModel from '../PlacementModel';
import Form from '../../../components/Form';
import DatePicker from '../../../components/DatePicker';
import GlobalLoading from '../../../components/GlobalLoading';
import GlobalError from '../../../components/GlobalError';
import AccommodationCard from '../components/AccommodationCard';
import AccommodationAdd from '../components/AccommodationAdd';
import AccommodationView from '../components/AccommodationView';
import NoteAdd from '../components/NoteAdd';
import NoteCard from '../components/NoteCard';
import SelectField from '../../../components/SelectField';
import TextField from '../../../components/TextField';
import PersonCard from '../../../components/PersonCard';
import HouseholdCard from '../../Household/components/HouseholdCard';
import Assign from '../../Staff/components/Assign';
import BackLink from '../../../components/BackLink';
import DeletePrompt from '../../../components/DeletePrompt';
import Title from '../../../components/Title';

import { colours, icons } from '../../../theme';
import deepCompare from '../../../util/deepCompare';

function getFYStart() {
  const curDate = new Date();
  if (curDate.getMonth() < 6)
    curDate.setFullYear(curDate.getFullYear() - 1, 6, 1);
  else curDate.setFullYear(curDate.getFullYear(), 6, 1);
  return curDate;
}

function getFYEnd() {
  const curDate = new Date();
  if (curDate.getMonth() >= 6)
    curDate.setFullYear(curDate.getFullYear() + 1, 5, 30);
  else curDate.setFullYear(curDate.getFullYear(), 5, 30);
  return curDate;
}

class PlacementDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsExpanded: true,
      accommodationExpanded: true,
      notesExpanded: true,
      logExpanded: false,
      speedDialOpen: false,
      showAccAdd: false,
      showAccView: false,
      showNoteAdd: false,
      showAssignCoord: false,
      search: {
        text: '',
        category: '',
        communication: '',
        incidentReporting: '',
      },
      searchResults: '',
      accFrom: getFYStart(),
      accTo: getFYEnd(),
      showDelPro: false,
      error: false,
    };
    this.doSearch = debounce(this.doSearch.bind(this), 800);
    this.onChange = debounce(this.onChange.bind(this), 800);
    props
      .dispatch(fetchPlacement(this.props.match.params.id))
      .then(({ caseLoad }) => {
        if (caseLoad.youngPerson && caseLoad.youngPerson.contact.fullName) {
          props.dispatch(
            updateRecent({
              type: 'case',
              id: props.match.params.id,
              title: `Placement: ${caseLoad.youngPerson.contact.fullName}`,
            })
          );
        }
      })
      .catch(() => this.setState({ error: true }));
  }
  shouldComponentUpdate(props, state) {
    const { user, caseLoad, notes } = this.props;
    return (
      !deepCompare(state, this.state) ||
      !deepCompare(props.user, user) ||
      !deepCompare(props.caseLoad, caseLoad) ||
      !deepCompare(props.notes, notes)
    );
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { match } = this.props;
    if (!isEmpty(props.caseLoad) && !props.notes) {
      props.dispatch(fetchPlacementNotes(match.params.id));
    }
    if (props.notes) {
      this.setState({ notes: props.notes });
    }

    if (!isEmpty(props.caseLoad)) {
      this.setState({ caseLoad: props.caseLoad });
    }

    this.setState({ caseId: match.params.id });
  }

  onChange(val) {
    const { match, dispatch } = this.props;
    const { id } = match.params;
    const key = Object.keys(val).join();
    const prevVal = { [key]: this.state.caseLoad[key] };
    dispatch(modifyPlacement(id, val, prevVal));
  }
  onSelectAccommodation(a) {
    this.setState({
      accommodation: a,
      showAccView: true,
    });
  }
  getLogs() {
    getObjectLogs(this.props.caseLoad).then(logs => this.setState({ logs }));
  }
  doSearch() {
    // NOTE: EXCLUDE SENSITIVE???
    const search = pickBy(this.state.search);
    if (isEmpty(search)) this.setState({ searchResults: '' });
    else
      filterPlacementNotes(this.props.match.params.id, search).then(
        searchResults => this.setState({ searchResults })
      );
  }
  renderNoteSearch(num) {
    const { search } = this.state;
    return (
      <List expandable>
        <ListItem
          primaryTogglesNestedList
          primaryText={`Search Notes (${num})`}
          rightIcon={<icons.SearchIcon color={colours.skyBlue} />}
          nestedItems={[
            <ListItem disabled key="searchbody">
              <TextField
                param={{ field: 'text', name: 'Search', editable: true }}
                value={search.text}
                onUpdated={newVal => {
                  this.setState({ search: { ...search, text: newVal } });
                  this.doSearch();
                }}
              />
              {noteModel.map(n => {
                if (!n.filterable) return null;
                return (
                  <SelectField
                    search
                    key={n.field}
                    param={n}
                    value={search[n.field]}
                    onUpdated={newVal => {
                      this.setState({
                        search: { ...search, [n.field]: newVal },
                      });
                      this.doSearch();
                    }}
                  />
                );
              })}
            </ListItem>,
          ]}
        />
      </List>
    );
  }
  renderNotes(readOnly) {
    const { searchResults, notes, caseLoad, notesExpanded } = this.state;
    const displayNotes = searchResults || notes;
    return (
      <Card
        className="content-box"
        expanded={notesExpanded}
        onExpandChange={() => this.setState({ notesExpanded: !notesExpanded })}
      >
        <CardTitle subtitle="Notes" actAsExpander showExpandableButton />
        {this.renderNoteSearch(displayNotes ? displayNotes.length : null)}
        {displayNotes &&
          displayNotes.map(n => (
            <NoteCard
              readOnly={readOnly}
              key={n._id}
              note={n}
              caseLoad={caseLoad}
              expandable
            />
          ))}
      </Card>
    );
  }
  renderDetails(readOnly) {
    const { detailsExpanded, caseLoad } = this.state;
    return (
      <Card
        className="content-box"
        expanded={detailsExpanded}
        onExpandChange={() =>
          this.setState({ detailsExpanded: !detailsExpanded })
        }
      >
        <CardTitle
          subtitle="Placement Details"
          actAsExpander
          showExpandableButton
        />
        <Form
          readOnly={readOnly}
          model={caseModel}
          section="detail"
          expandable
          object={caseLoad}
          onUpdated={val => this.onChange(val)}
        />
      </Card>
    );
  }
  renderLogs() {
    const { logs, logExpanded } = this.state;
    if (isEmpty(logs) && logExpanded) this.getLogs();
    return (
      <Card
        className="content-box"
        expanded={logExpanded}
        onExpandChange={() => this.setState({ logExpanded: !logExpanded })}
      >
        <CardTitle subtitle="Logs" actAsExpander showExpandableButton />
        {!isEmpty(logs) && <ObjectLogs logs={logs} expandable />}
      </Card>
    );
  }
  renderCarers() {
    const { caseLoad } = this.props;
    if (!caseLoad.household) return null;
    const { primaryCarer, secondaryCarer } = caseLoad.household;
    return (
      <Card className="content-box">
        <CardTitle subtitle="Carers" />
        {primaryCarer && (
          <PersonCard
            person={primaryCarer}
            rel={primaryCarer.approvalDate ? 'carer' : 'prospect'}
            key={primaryCarer._id}
            title="Primary Carer"
          />
        )}
        {secondaryCarer && (
          <PersonCard
            person={secondaryCarer}
            rel={secondaryCarer.approvalDate ? 'carer' : 'prospect'}
            key={secondaryCarer._id}
            title="Secondary Carer"
          />
        )}
      </Card>
    );
  }
  renderHousehold() {
    const { household } = this.props.caseLoad;
    return household ? (
      <Card className="content-box">
        <CardTitle subtitle="Primary Household" />
        {household && <HouseholdCard household={household} />}
      </Card>
    ) : null;
  }
  renderParentsGuardians(readOnly) {
    const { parents } = this.state.caseLoad.youngPerson;
    return (
      <Card className="content-box">
        <CardTitle subtitle="Parents/Guardians" />
        {parents &&
          parents.map(p => (
            <PersonCard
              person={p}
              rel="other"
              key={p._id}
              title="Parent/Guardian"
            />
          ))}
      </Card>
    );
  }
  renderEmergencyContacts(readOnly) {
    const { emergencyContacts } = this.state.caseLoad.youngPerson;
    return (
      <Card className="content-box">
        <CardTitle subtitle="Emergency Contacts" />
        {emergencyContacts &&
          emergencyContacts.map(p => (
            <PersonCard
              person={p}
              rel="other"
              key={p._id}
              title="Emergency Contact"
            />
          ))}
      </Card>
    );
  }
  renderYP() {
    const { youngPerson } = this.state.caseLoad;
    return (
      <Card className="content-box">
        <CardTitle subtitle="Young Person" />
        <PersonCard person={youngPerson} rel="young" key={youngPerson._id} />
      </Card>
    );
  }
  renderStaff() {
    const { staff } = this.state.caseLoad;
    return (
      <Card className="content-box">
        <CardTitle subtitle="Coordinator" />
        <PersonCard person={staff} rel="staff" key={staff._id} />
      </Card>
    );
  }
  renderRespite(readOnly) {
    const { accFrom, accTo, caseLoad, accommodationExpanded } = this.state;
    const { accommodation, household, placementLength } = caseLoad;
    const respite = filter(
      accommodation,
      a =>
        !a.locked &&
        (!a.household ||
          (a.household &&
            a.household._id !== (household || {})._id &&
            new Date(a.dateEnd) >= accFrom &&
            new Date(a.dateStart) <= accTo))
    );
    let respiteTotal = 0;
    each(respite, r => {
      if (r.household)
        respiteTotal += moment(r.dateEnd).diff(moment(r.dateStart), 'days');
    });
    return (
      <Card
        className="content-box"
        expanded={accommodationExpanded}
        onExpandChange={() =>
          this.setState({
            accommodationExpanded: !accommodationExpanded,
          })
        }
      >
        <CardTitle
          subtitle="Respite and Other Accommodation"
          actAsExpander
          showExpandableButton
        />
        <DatePicker
          expandable
          half
          param={{ field: 'accFrom', name: 'From', editable: true }}
          value={accFrom}
          onUpdated={accFrom => this.setState({ accFrom })}
        />
        <DatePicker
          expandable
          half
          param={{ field: 'accTo', name: 'To', editable: true }}
          value={accTo}
          onUpdated={accTo => this.setState({ accTo })}
        />
        {caseLoad.caseType === 'External' ||
        caseLoad.caseType === 'Community' ||
        caseLoad.caseType === 'Family' ? (
          <ListItem
            disabled
            expandable
            primaryText={`Nights in Respite for period: ${respiteTotal}`}
          />
        ) : (
          <ListItem
            disabled
            expandable
            primaryText={`Total Days in Care: ${placementLength}`}
            secondaryText={`Nights in Respite for period: ${respiteTotal}`}
          />
        )}
        {respite.map(a => (
          <AccommodationCard
            readOnly={readOnly}
            accommodation={a}
            key={a._id}
            expandable
            showDialog={
              !readOnly ? () => this.onSelectAccommodation(a) : () => ({})
            }
          />
        ))}
      </Card>
    );
  }
  render() {
    const {
      caseLoad,
      showAccAdd,
      showAccView,
      showNoteAdd,
      notes,
      showAssignCoord,
      speedDialOpen,
      accommodation,
      showDelPro,
      error,
    } = this.state;
    const { user, dispatch } = this.props;
    if (error) return <GlobalError />;
    if (isEmpty(caseLoad) || !notes || isEmpty(user.roles))
      return <GlobalLoading />;
    if (caseLoad.deleted)
      return (
        <div className="container">
          <div className="content">
            <div>
              <div
                className="list-search"
                style={{
                  margin: 'auto',
                  width: '50%',
                  textAlign: 'center',
                  padding: '5px',
                }}
              >
                This placement can not be found or has been deleted.
                <BackLink />
              </div>
            </div>
          </div>
        </div>
      );
    let readOnly = true;
    if (
      !user.roles.includes('admin') &&
      !user.roles.includes('moderator') &&
      !user.roles.includes('coordinator')
    ) {
      // const cases = user.staff.caseLoad.map(c => c._id);
      // if (cases.includes(caseLoad._id)) readOnly = false;
      readOnly = false;
    } else readOnly = false;
    let canAddNote = false;
    if (user.roles.includes('student')) {
      canAddNote = true;
    }
    const deletable =
      user.roles.includes('admin') || user.roles.includes('moderator');
    const externalOrCommunityOrFamily =
      caseLoad.caseType === 'External' ||
      caseLoad.caseType === 'Community' ||
      caseLoad.caseType === 'Family';
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Placement - HEART1869</title>
          <meta
            name="description"
            content="Kennerley HEART1869 Placement Detail"
          />
        </Helmet>
        <div className="content">
          <div className="content-body">
            <div className="content-column">
              <Card className="content-box">
                <Title
                  title={`Placement for ${
                    caseLoad.youngPerson.contact.fullName
                  }`}
                />
              </Card>
              {this.renderYP()}
              {this.renderCarers()}
              {!externalOrCommunityOrFamily ? this.renderHousehold() : null}
              {externalOrCommunityOrFamily
                ? this.renderParentsGuardians(true)
                : null}
              {externalOrCommunityOrFamily
                ? this.renderEmergencyContacts(true)
                : null}
              {this.renderStaff()}
              {this.renderRespite(readOnly)}
            </div>
            <div className="content-column">
              {this.renderDetails(readOnly)}
              {this.renderNotes(readOnly)}
              {this.renderLogs()}
            </div>
          </div>
          {!readOnly && (
            <SpeedDial
              styleBackdrop={{ opacity: 0.01 }}
              className="speed-dial"
              isOpen={speedDialOpen}
              onChange={change =>
                this.setState({ speedDialOpen: change.isOpen })
              }
            >
              <BubbleList className="bubble-list">
                <BubbleListItem
                  primaryText="Note"
                  rightAvatar={
                    <Avatar
                      backgroundColor={colours.limeGreen}
                      color={colours.skyBlue}
                      icon={<icons.NoteIcon />}
                    />
                  }
                  onClick={() =>
                    this.setState({ speedDialOpen: false, showNoteAdd: true })
                  }
                />
                {(user.roles.includes('admin') ||
                  user.roles.includes('moderator') ||
                  user.roles.includes('coordinator')) && (
                  <BubbleListItem
                    primaryText="Respite or Other"
                    rightAvatar={
                      <Avatar
                        backgroundColor={colours.limeGreen}
                        color={colours.skyBlue}
                        icon={<icons.AccommodationIcon />}
                      />
                    }
                    onClick={() =>
                      this.setState({ speedDialOpen: false, showAccAdd: true })
                    }
                  />
                )}
                {deletable ? (
                  <BubbleListItem
                    primaryText="Assign Coordinator"
                    rightAvatar={
                      <Avatar
                        backgroundColor={colours.limeGreen}
                        color={colours.skyBlue}
                        icon={<icons.AssignCoordinatorIcon />}
                      />
                    }
                    onClick={() =>
                      this.setState({
                        speedDialOpen: false,
                        showAssignCoord: true,
                      })
                    }
                  />
                ) : null}
                {deletable && (
                  <BubbleListItem
                    primaryText="Delete"
                    rightAvatar={
                      <Avatar
                        backgroundColor={colours.kRed}
                        color="white"
                        icon={<icons.DeleteIcon />}
                      />
                    }
                    onClick={() => {
                      this.setState({ speedDialOpen: false, showDelPro: true });
                    }}
                  />
                )}
              </BubbleList>
            </SpeedDial>
          )}
          {readOnly &&
            canAddNote && (
              <SpeedDial
                styleBackdrop={{ opacity: 0.01 }}
                className="speed-dial"
                isOpen={speedDialOpen}
                onChange={change =>
                  this.setState({ speedDialOpen: change.isOpen })
                }
              >
                <BubbleList className="bubble-list">
                  <BubbleListItem
                    primaryText="Note"
                    rightAvatar={
                      <Avatar
                        backgroundColor={colours.limeGreen}
                        color={colours.skyBlue}
                        icon={<icons.NoteIcon />}
                      />
                    }
                    onClick={() =>
                      this.setState({ speedDialOpen: false, showNoteAdd: true })
                    }
                  />
                </BubbleList>
              </SpeedDial>
            )}
          <Assign
            closeDialog={() => this.setState({ showAssignCoord: false })}
            open={showAssignCoord}
            caseLoad={caseLoad}
            prevStaff={caseLoad.staff}
            dispatch={dispatch}
          />
          <AccommodationAdd
            closeDialog={() => this.setState({ showAccAdd: false })}
            open={showAccAdd}
            caseLoad={caseLoad}
          />
          <AccommodationView
            closeDialog={() => this.setState({ showAccView: false })}
            open={showAccView}
            caseLoad={caseLoad}
            accommodation={accommodation}
            readOnly={readOnly}
          />
          <NoteAdd
            closeDialog={() => this.setState({ showNoteAdd: false })}
            open={showNoteAdd}
            caseLoad={caseLoad}
            accommodation={caseLoad.accommodation}
            youngPerson={caseLoad.youngPerson}
            {...(caseLoad.household
              ? {
                  primaryCarer: caseLoad.household.primaryCarer,
                  secondaryCarer: caseLoad.household.secondaryCarer,
                  householdMembers: caseLoad.household.otherIndividuals,
                }
              : {})}
            staff={user.staff}
          />
          <DeletePrompt
            closeDialog={() => this.setState({ showDelPro: false })}
            open={showDelPro}
            delete={() => dispatch(deletePlacement(caseLoad._id))}
            parentRoute={'/case'}
          />
        </div>
      </div>
    );
  }
}

PlacementDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  caseLoad: state.cases.detail,
  notes: state.cases.detail.notes,
  user: state.user.profile,
});

export default connect(mapStateToProps)(PlacementDetail);
