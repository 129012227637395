import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { SpeedDial, BubbleList, BubbleListItem } from 'react-speed-dial';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import pickBy from 'lodash/pickBy';
import uniqBy from 'lodash/uniqBy';

import { Card, CardTitle } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import { List, ListItem } from 'material-ui/List';

import {
  fetchYoungPerson,
  modifyYoungPerson,
  deleteYoungPerson,
} from '../YoungPersonActions';
import { filterYPNotes, getYPNotes } from '../YoungPersonAPI';
import { updateRecent } from '../../App/AppActions';
import PersonAdd from '../../OtherIndividual/components/AddPerson';
import PersonCard from '../../../components/PersonCard';

import PrintOptions from '../components/PrintOptions';
import ViewAndWishAsdd from '../components/ViewAndWishAdd';

import ObjectLogs from '../../Log/components/ObjectLogs';
import { getObjectLogs } from '../../Log/LogAPI';

import SelectField from '../../../components/SelectField';
import TextField from '../../../components/TextField';
import noteModel from '../../Placement/NoteModel';

import PaymentsList from '../components/PaymentsList';

import AccommodationCard from '../../Placement/components/AccommodationCard';
import NoteCard from '../../Placement/components/NoteCard';
import ViewAndWishCard from '../components/ViewAndWishCard';
import PlacementCard from '../../Placement/components/PlacementCard';
import youngPersonModel from '../YoungPersonModel';
import Form from '../../../components/Form';
import Attachment from '../../../components/Attachment';
import BackLink from '../../../components/BackLink';
import GlobalLoading from '../../../components/GlobalLoading';
import GlobalError from '../../../components/GlobalError';
import DeletePrompt from '../../../components/DeletePrompt';
import ContactCard from '../../Contact/components/ContactCard';
import PersonEdit from '../../OtherIndividual/components/EditPerson';
import ReferralCard from '../../Referral/components/ReferralCard';
import ReferralAdd from '../../Referral/components/ReferralAdd';
import { colours, icons } from '../../../theme';
import deepCompare from '../../../util/deepCompare';

class YoungPersonDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddPerson: false,
      attachmentsExpanded: false,
      currentPlacementsExpanded: true,
      careExpanded: true,
      paymentsExpanded: false,
      personalExpanded: true,
      educationExpanded: false,
      medicalExpanded: false,
      logExpanded: false,
      youngPerson: {},
      contact: {},
      referrals: [],
      viewsandwishes: [],
      logs: [],
      notes: '',
      search: {},
      speedDialOpen: false,
      showPersonEdit: false,
      showRefAdd: false,
      showDelPro: false,
      showPrintOptions: false,
      showViewAndWishAdd: false,
      error: false,
    };
    this.onChange = debounce(this.onChange.bind(this), 800);
    this.doSearch = debounce(this.doSearch.bind(this), 800);
    props
      .dispatch(fetchYoungPerson(props.match.params.id))
      .then(({ youngPerson }) => {
        if (youngPerson.contact && youngPerson.contact.fullName) {
          props.dispatch(
            updateRecent({
              type: 'young',
              id: props.match.params.id,
              title: youngPerson.contact.fullName,
            })
          );
        }
      })
      .catch(() => this.setState({ error: true }));
  }
  shouldComponentUpdate(props, state) {
    const {
      user,
      youngPerson,
      payments,
      contact,
      referrals,
      viewsandwishes,
    } = this.props;
    return (
      !deepCompare(state, this.state) ||
      !deepCompare(props.user, user) ||
      !deepCompare(props.youngPerson, youngPerson) ||
      !deepCompare(props.payments, payments) ||
      !deepCompare(props.contact, contact) ||
      !deepCompare(props.referrals, referrals) ||
      !deepCompare(props.viewsandwishes, viewsandwishes)
    );
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setState({ youngPerson: {}, contact: {} });
      props.dispatch(fetchYoungPerson(props.match.params.id));
    } else
      this.setState({
        youngPerson: props.youngPerson,
        payments: props.payments,
        contact: props.contact,
        referrals: props.referrals,
        viewsandwishes: props.viewsandwishes,
      });
  }
  doSearch() {
    // NOTE: EXCLUDE SENSITIVE???
    const search = pickBy(this.state.search);
    if (isEmpty(search)) this.setState({ searchResults: '' });
    else
      filterYPNotes(this.props.match.params.id, search).then(searchResults =>
        this.setState({ searchResults })
      );
  }
  onChange(val) {
    const { match, dispatch } = this.props;
    const { id } = match.params;
    const key = Object.keys(val).join();
    const prevVal = { [key]: this.state.youngPerson[key] };
    dispatch(modifyYoungPerson(id, val, prevVal));
  }
  getLogs() {
    const { youngPerson, contact } = this.props;
    getObjectLogs(youngPerson._id, contact._id).then(logs =>
      this.setState({ logs })
    );
  }
  selectPerson(person) {
    this.setState({
      person,
      showPersonEdit: true,
    });
  }
  renderMedicalDetails(readOnly) {
    const { youngPerson, medicalExpanded } = this.state;
    const { doctors } = youngPerson;
    return (
      <Card
        className="content-box"
        expanded={medicalExpanded}
        onExpandChange={() =>
          this.setState({ medicalExpanded: !medicalExpanded })
        }
      >
        <CardTitle
          subtitle="Medical Details"
          actAsExpander
          showExpandableButton
        />
        <Form
          readOnly={readOnly}
          model={youngPersonModel}
          section="medical"
          expandable
          object={youngPerson}
          onUpdated={val => this.onChange(val)}
        />
        <CardTitle subtitle="Doctors" expandable />
        {isEmpty(doctors)
          ? ''
          : doctors.map(o => (
              <PersonCard
                readOnly={readOnly}
                person={o}
                rel="other"
                key={o._id}
                expandable
                select={() => this.selectPerson(o)}
              />
            ))}
      </Card>
    );
  }
  renderCareDetails(readOnly) {
    const { careExpanded, youngPerson } = this.state;
    return (
      <Card
        className="content-box"
        expanded={careExpanded}
        onExpandChange={() => this.setState({ careExpanded: !careExpanded })}
      >
        <CardTitle subtitle="Care Details" actAsExpander showExpandableButton />
        <Form
          readOnly={readOnly}
          model={youngPersonModel}
          section="care"
          expandable
          object={youngPerson}
          onUpdated={val => this.onChange(val)}
        />
      </Card>
    );
  }
  renderPersonalDetails(readOnly) {
    const { personalExpanded, youngPerson } = this.state;
    return (
      <Card
        className="content-box"
        expanded={personalExpanded}
        onExpandChange={() =>
          this.setState({ personalExpanded: !personalExpanded })
        }
      >
        <CardTitle
          subtitle="Personal Details"
          actAsExpander
          showExpandableButton
        />
        <Form
          readOnly={readOnly}
          model={youngPersonModel}
          section="personal"
          expandable
          object={youngPerson}
          onUpdated={val => this.onChange(val)}
        />
      </Card>
    );
  }
  renderEducationDetails(readOnly) {
    const { educationExpanded, youngPerson } = this.state;
    return (
      <Card
        className="content-box"
        expanded={educationExpanded}
        onExpandChange={() =>
          this.setState({ educationExpanded: !educationExpanded })
        }
      >
        <CardTitle
          subtitle="Education Details"
          actAsExpander
          showExpandableButton
        />
        <Form
          readOnly={readOnly}
          model={youngPersonModel}
          section="education"
          expandable
          object={youngPerson}
          onUpdated={val => this.onChange(val)}
        />
      </Card>
    );
  }
  renderParentDetails(readOnly) {
    const { parents } = this.state.youngPerson;
    return (
      <Card className="content-box">
        <CardTitle
          subtitle="Parents/Guardians"
          actAsExpander
          showExpandableButton
        />
        {isEmpty(parents)
          ? ''
          : uniqBy(parents, '_id').map(o => (
              <PersonCard
                readOnly={readOnly}
                person={o}
                rel="other"
                key={o._id}
                expandable
                select={() => this.selectPerson(o)}
              />
            ))}
      </Card>
    );
  }
  renderEmergencyContacts(readOnly) {
    const { emergencyContacts } = this.state.youngPerson;
    return (
      <Card className="content-box">
        <CardTitle
          subtitle="Emergency Contacts"
          actAsExpander
          showExpandableButton
        />
        {isEmpty(emergencyContacts)
          ? ''
          : uniqBy(emergencyContacts, '_id').map(o => (
              <PersonCard
                readOnly={readOnly}
                person={o}
                rel="other"
                key={o._id}
                expandable
                select={() => this.selectPerson(o)}
              />
            ))}
      </Card>
    );
  }

  renderSiblingDetails(readOnly) {
    const { siblings, siblingsInCare } = this.state.youngPerson;
    return (
      <Card className="content-box">
        <CardTitle subtitle="Siblings" actAsExpander showExpandableButton />
        {isEmpty(siblingsInCare)
          ? ''
          : uniqBy(siblingsInCare, '_id').map(o => (
              <PersonCard person={o} rel="young" key={o._id} expandable />
            ))}
        {isEmpty(siblings)
          ? ''
          : uniqBy(siblings, '_id').map(o => (
              <PersonCard
                readOnly={readOnly}
                person={o}
                rel="other"
                key={o._id}
                expandable
                select={() => this.selectPerson(o)}
              />
            ))}
      </Card>
    );
  }
  renderPayments(readOnly) {
    const { paymentsExpanded, payments } = this.state;
    return (
      <Card
        className="content-box"
        expanded={paymentsExpanded}
        onExpandChange={() =>
          this.setState({ paymentsExpanded: !paymentsExpanded })
        }
      >
        <CardTitle
          subtitle="Payment Classifications"
          actAsExpander
          showExpandableButton
        />
        <PaymentsList
          expandable
          payments={payments}
          readOnly={readOnly}
          onChange={this.onChange}
        />
      </Card>
    );
  }
  renderLogs() {
    const { logs, logExpanded } = this.state;
    if (isEmpty(logs) && logExpanded) this.getLogs();
    return (
      <Card
        className="content-box"
        expanded={logExpanded}
        onExpandChange={() => this.setState({ logExpanded: !logExpanded })}
      >
        <CardTitle subtitle="Logs" actAsExpander showExpandableButton />
        {!isEmpty(logs) && <ObjectLogs logs={logs} expandable />}
      </Card>
    );
  }
  renderAttachments(readOnly) {
    const { user } = this.props;
    const { attachmentsExpanded, youngPerson } = this.state;
    return (
      <Card
        className="content-box"
        expanded={attachmentsExpanded}
        onExpandChange={() =>
          this.setState({ attachmentsExpanded: !attachmentsExpanded })
        }
      >
        <CardTitle subtitle="Attachments" actAsExpander showExpandableButton />
        <Attachment
          admin={
            user.roles.includes('admin') || user.roles.includes('moderator')
          }
          readOnly={readOnly}
          record={youngPerson._id}
          recordType="YoungPerson"
          expandable
        />
      </Card>
    );
  }
  renderNoteSearch(num) {
    const { search } = this.state;
    return (
      <List expandable>
        <ListItem
          primaryTogglesNestedList
          primaryText={`Search Notes (${num})`}
          rightIcon={<icons.SearchIcon color={colours.skyBlue} />}
          nestedItems={[
            <ListItem disabled key="searchbody">
              <TextField
                param={{ field: 'text', name: 'Search', editable: true }}
                value={search.text}
                onUpdated={newVal => {
                  this.setState({ search: { ...search, text: newVal } });
                  this.doSearch();
                }}
              />
              {noteModel.map(n => {
                if (!n.filterable) return null;
                return (
                  <SelectField
                    search
                    key={n.field}
                    param={n}
                    value={search[n.field]}
                    onUpdated={newVal => {
                      this.setState({
                        search: { ...search, [n.field]: newVal },
                      });
                      this.doSearch();
                    }}
                  />
                );
              })}
            </ListItem>,
          ]}
        />
      </List>
    );
  }
  renderNotes(readOnly) {
    const { searchResults, notes, notesExpanded } = this.state;
    const displayNotes = searchResults || notes || [];
    if (!notes && notesExpanded)
      getYPNotes(this.props.youngPerson._id).then(notes =>
        this.setState({ notes })
      );
    return (
      <Card
        className="content-box"
        expanded={notesExpanded}
        onExpandChange={() => this.setState({ notesExpanded: !notesExpanded })}
      >
        <CardTitle subtitle="Notes" actAsExpander showExpandableButton />
        {this.renderNoteSearch(displayNotes ? displayNotes.length : null)}
        {displayNotes &&
          displayNotes.map(n => (
            <NoteCard
              readOnly
              key={n._id}
              note={n}
              caseLoad={n.case}
              expandable
              goToPlacement
            />
          ))}
      </Card>
    );
  }
  renderAccommodation() {
    const { youngPerson, accommodationExpanded } = this.state;
    const { accommodation } = youngPerson;
    return (
      <Card
        className="content-box"
        expanded={accommodationExpanded}
        onExpandChange={() =>
          this.setState({
            accommodationExpanded: !accommodationExpanded,
          })
        }
      >
        <CardTitle
          subtitle="Accommodation"
          actAsExpander
          showExpandableButton
        />
        {accommodation.map(a => (
          <AccommodationCard
            readOnly
            accommodation={a}
            expandable
            key={a._id}
          />
        ))}
      </Card>
    );
  }
  renderPlacements() {
    const {
      currentPlacementsExpanded,
      archivedPlacementsExpanded,
      youngPerson: { cases },
    } = this.state;
    const currentPlacements = cases.filter(
      c => !c.leftCareDate || moment(c.leftCareDate).isAfter(moment())
    );
    const archivedPlacements = cases.filter(
      c => c.leftCareDate && moment(c.leftCareDate).isBefore(moment())
    );
    return [
      <Card
        className="content-box"
        key="current"
        expanded={currentPlacementsExpanded}
        onExpandChange={() =>
          this.setState({
            currentPlacementsExpanded: !currentPlacementsExpanded,
          })
        }
      >
        <CardTitle
          subtitle="My Placements"
          actAsExpander
          showExpandableButton
        />
        {currentPlacements.map(c => (
          <PlacementCard key={c._id} caseLoad={c} expandable />
        ))}
      </Card>,
      <Card
        className="content-box"
        key="archived"
        expanded={archivedPlacementsExpanded}
        onExpandChange={() =>
          this.setState({
            archivedPlacementsExpanded: !archivedPlacementsExpanded,
          })
        }
      >
        <CardTitle
          subtitle="Archived Placements"
          actAsExpander
          showExpandableButton
        />
        {archivedPlacements.map(c => (
          <PlacementCard key={c._id} caseLoad={c} expandable />
        ))}
      </Card>,
    ];
  }
  renderReferrals() {
    const { referrals } = this.state.youngPerson;
    return (
      <Card className="content-box">
        <CardTitle subtitle="Referrals" />
        {referrals.map(r => (
          <ReferralCard referral={r} rel="referral" key={r._id} />
        ))}
      </Card>
    );
  }

  renderViewsAndWishes(readOnly) {
    const { viewsandwishes, vwsExpanded } = this.state;
    return (
      <Card
        className="content-box"
        expanded={vwsExpanded}
        onExpandChange={() => this.setState({ vwsExpanded: !vwsExpanded })}
      >
        <CardTitle
          subtitle="Views & Wishes"
          actAsExpander
          showExpandableButton
        />
        {viewsandwishes.map(vw => (
          <ViewAndWishCard
            readOnly
            key={vw._id}
            viewandwish={vw}
            caseLoad={vw.case}
            expandable
            goToPlacement
          />
        ))}
      </Card>
    );
  }

  render() {
    const {
      youngPerson,
      contact,
      speedDialOpen,
      showAddPerson,
      addType,
      showPersonEdit,
      person,
      showRefAdd,
      showPrintOptions,
      showViewAndWishAdd,
      showDelPro,
      error,
    } = this.state;
    let readOnly = true;
    const { user, dispatch } = this.props;
    if (error) return <GlobalError />;
    if (isEmpty(youngPerson) || isEmpty(user.roles)) return <GlobalLoading />;
    if (youngPerson.deleted)
      return (
        <div className="container">
          <div className="content">
            <div>
              <div
                className="list-search"
                style={{
                  margin: 'auto',
                  width: '50%',
                  textAlign: 'center',
                  padding: '5px',
                }}
              >
                This young person can not be found or has been deleted.
                <BackLink />
              </div>
            </div>
          </div>
        </div>
      );
    if (!user.roles.includes('admin') && !user.roles.includes('moderator')) {
      // const yPs = user.staff.caseLoad.map(c => c.youngPerson._id);
      // if (yPs.includes(youngPerson._id)) readOnly = false;
      readOnly = false;
    } else readOnly = false;
    const deletable =
      user.roles.includes('admin') || user.roles.includes('moderator');
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {contact ? contact.fullName : 'Young Person'} - HEART1869
          </title>
          <meta
            name="description"
            content="Kennerley HEART1869 Young Person Detail"
          />
        </Helmet>
        <div className="content">
          <div className="content-body">
            <div className="content-column">
              <Card className="content-box">
                <ContactCard contact={contact} readOnly={readOnly} />
              </Card>
              {this.renderPersonalDetails(readOnly)}
              {this.renderEducationDetails(readOnly)}
              {this.renderMedicalDetails(readOnly)}
              {this.renderParentDetails(readOnly)}
              {this.renderEmergencyContacts(readOnly)}
              {this.renderSiblingDetails(readOnly)}
              {this.renderReferrals()}
              {this.renderViewsAndWishes(readOnly)}
            </div>
            <div className="content-column">
              {this.renderCareDetails(readOnly)}
              {this.renderPayments(readOnly)}
              {this.renderPlacements()}
              {this.renderNotes()}
              {this.renderAttachments(readOnly)}
              {this.renderAccommodation()}
              {this.renderLogs()}
            </div>
          </div>
          <SpeedDial
            styleBackdrop={{ opacity: 0.01 }}
            className="speed-dial"
            isOpen={speedDialOpen}
            onChange={change => this.setState({ speedDialOpen: change.isOpen })}
          >
            <BubbleList className="bubble-list">
              {false && (
                <BubbleListItem
                  primaryText="Print"
                  rightAvatar={
                    <Avatar
                      backgroundColor={colours.limeGreen}
                      color={colours.skyBlue}
                      icon={<icons.PrintIcon />}
                    />
                  }
                  onClick={() => {
                    this.setState({ speedDialOpen: false });
                    window.open(
                      '/printpreview' + window.location.pathname,
                      '_blank',
                      'menubar=0,location=0',
                      false
                    );
                  }}
                />
              )}
              <BubbleListItem
                primaryText="Print"
                rightAvatar={
                  <Avatar
                    backgroundColor={colours.limeGreen}
                    color={colours.skyBlue}
                    icon={<icons.PrintIcon />}
                  />
                }
                onClick={() =>
                  this.setState({
                    speedDialOpen: false,
                    showPrintOptions: true,
                  })
                }
              />
              <BubbleListItem
                primaryText="Views & Wishes"
                rightAvatar={
                  <Avatar
                    backgroundColor={colours.limeGreen}
                    color={colours.skyBlue}
                    icon={<icons.FavoriteIcon />}
                  />
                }
                onClick={() =>
                  this.setState({
                    speedDialOpen: false,
                    showViewAndWishAdd: true,
                  })
                }
              />
              <BubbleListItem
                primaryText="Referral"
                rightAvatar={
                  <Avatar
                    backgroundColor={colours.limeGreen}
                    color={colours.skyBlue}
                    icon={<icons.ReferralIcon />}
                  />
                }
                onClick={() => {
                  this.setState({ speedDialOpen: false, showRefAdd: true });
                }}
              />
              <BubbleListItem
                primaryText="Parent / Guardian"
                rightAvatar={
                  <Avatar
                    backgroundColor={colours.limeGreen}
                    color={colours.skyBlue}
                    icon={<icons.ParentIcon />}
                  />
                }
                onClick={() => {
                  this.setState({
                    speedDialOpen: false,
                    showAddPerson: true,
                    addType: 'parent',
                  });
                }}
              />
              <BubbleListItem
                primaryText="Sibling"
                rightAvatar={
                  <Avatar
                    backgroundColor={colours.limeGreen}
                    color={colours.skyBlue}
                    icon={<icons.SiblingIcon />}
                  />
                }
                onClick={() => {
                  this.setState({
                    speedDialOpen: false,
                    showAddPerson: true,
                    addType: 'sibling',
                  });
                }}
              />
              <BubbleListItem
                primaryText="Doctor"
                rightAvatar={
                  <Avatar
                    backgroundColor={colours.limeGreen}
                    color={colours.skyBlue}
                    icon={<icons.MedicalIcon />}
                  />
                }
                onClick={() => {
                  this.setState({
                    speedDialOpen: false,
                    showAddPerson: true,
                    addType: 'doctor',
                  });
                }}
              />
              {deletable && (
                <BubbleListItem
                  primaryText="Delete"
                  rightAvatar={
                    <Avatar
                      backgroundColor={colours.kRed}
                      color="white"
                      icon={<icons.DeleteIcon />}
                    />
                  }
                  onClick={() => {
                    this.setState({ speedDialOpen: false, showDelPro: true });
                  }}
                />
              )}
            </BubbleList>
          </SpeedDial>
          <PersonAdd
            closeDialog={() => this.setState({ showAddPerson: false })}
            open={showAddPerson}
            type={addType}
            youngPerson={youngPerson}
          />
          <PersonEdit
            closeDialog={() =>
              this.setState({ showPersonEdit: false, person: '' })
            }
            open={showPersonEdit}
            other={person}
            title={`Edit ${person ? person.contact.fullName : ''}`}
            youngPerson={youngPerson}
          />
          <ReferralAdd
            closeDialog={() => this.setState({ showRefAdd: false })}
            open={showRefAdd}
            youngPerson={youngPerson}
          />
          <PrintOptions
            closeDialog={() => this.setState({ showPrintOptions: false })}
            open={showPrintOptions}
            youngPerson={youngPerson}
          />
          <ViewAndWishAsdd
            closeDialog={() => this.setState({ showViewAndWishAdd: false })}
            open={showViewAndWishAdd}
            youngPerson={youngPerson}
          />
          <DeletePrompt
            closeDialog={() => this.setState({ showDelPro: false })}
            open={showDelPro}
            delete={() => dispatch(deleteYoungPerson(youngPerson._id))}
            parentRoute={'/young'}
          />
        </div>
      </div>
    );
  }
}

YoungPersonDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  youngPerson: state.young.detail,
  payments: state.young.detail.payments,
  contact: state.young.detail.contact,
  referrals: state.young.detail.referrals,
  viewsandwishes: state.young.detail.viewsandwishes,
  user: state.user.profile,
});

export default connect(mapStateToProps)(YoungPersonDetail);
