/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';

class BackLink extends Component {
  // eslint-disable-next-line no-undef
  static contextTypes = {
    router: () => null,
  };
  render() {
    return (
      <a
        style={{ display: 'block', cursor: 'pointer' }}
        onClick={this.context.router.history.goBack}
      >
        Go back
      </a>
    );
  }
}

export default BackLink;
