import unionBy from 'lodash/unionBy';
import extend from 'lodash/extend';
import filter from 'lodash/filter';

import * as actions from './HouseholdActions';
import { ROUTE_CHANGED } from '../App/AppActions';
import { isEmpty } from 'lodash';

const initialState = {
  list: [],
  detail: {},
  noteDetail: {},
  tab: 'open',
  search: {
    text: '',
    result: [],
  },
  info: {
    loading: false,
    start: -30,
    count: 30,
    hasMore: true,
    error: false,
  },
};

export default (state = initialState, action) => {
  const _households =
    action.start === 0 || action.tabChanged
      ? action.households
      : unionBy(state.list, action.households, '_id');

  const _list = state.list;

  if (action.type === ROUTE_CHANGED) {
    return { ...state, detail: {} };
  }
  switch (action.type) {
    case actions.HOUSEHOLDS_ERROR:
      return {
        ...state,
        info: { ...state.info, loading: false, error: action.error },
      };
    case actions.LOADING_HOUSEHOLDS:
      return {
        ...state,
        info: { ...state.info, loading: true, start: action.start },
      };
    case actions.RENDER_HOUSEHOLDS:
      return {
        ...state,
        list: action.text ? _list : _households,
        search: {
          text: action.text,
          result: !action.text ? state.search.result : action.households,
        },
        info: {
          ...state.info,
          loading: false,
          hasMore: action.households.length === state.info.count,
        },
      };
    case actions.CHANGE_TAB:
      return {
        ...state,
        list: [],
        search: { text: '', result: [] },
        tab: action.tab,
      };
    case actions.CLEAR_SEARCH:
      return { ...state, search: { text: '', result: [] } };
    case actions.DISPLAY_HOUSEHOLD:
      return { ...state, detail: action.household };
    case actions.ADD_HOUSEHOLD_MEMBER:
      return {
        ...state,
        detail: {
          ...state.detail,
          otherIndividuals: unionBy(
            state.detail.otherIndividuals,
            [action.member],
            '_id'
          ),
        },
      };
    case actions.EDIT_HOUSEHOLD_MEMBER:
      return {
        ...state,
        detail: {
          ...state.detail,
          otherIndividuals: state.detail.otherIndividuals.map(o => {
            if (action.member !== o._id) return o;
            const newMember = extend(o, action.updatedMember);
            //newMember.contact = extend(newMember.contact, action.contact);
            newMember.contact = action.contact;
            return newMember;
          }),
        },
      };
    case actions.DELETE_HOUSEHOLD_MEMBER:
      return {
        ...state,
        detail: {
          ...state.detail,
          otherIndividuals: state.detail.otherIndividuals.filter(
            o => action.member !== o._id
          ),
        },
      };
    case actions.ADD_HOUSEHOLD_SECONDARY:
      return {
        ...state,
        detail: { ...state.detail, secondaryCarer: action.secondaryCarer },
      };
    case actions.UPDATE_HOUSEHOLD:
      if (action.detail) {
        return {
          ...state,
          list: state.list.map(l => {
            if (l._id === action.household) {
              return { ...l, ...action.body };
            }
            return l;
          }),
          detail: { ...state.detail, ...action.body },
        };
      }
      return state;
    case actions.REMOVE_CARER:
      if (action.removePrimary) {
        return {
          ...state,
          detail: {
            ...state.detail,
            primaryCarer: state.detail.secondaryCarer,
            name: `${state.detail.secondaryCarer.contact.fullName} Household`,
            secondaryCarer: null,
            previousCarers: [
              ...state.detail.previousCarers,
              state.detail.primaryCarer,
            ],
          },
        };
      }
      return {
        ...state,
        detail: {
          ...state.detail,
          secondaryCarer: null,
          previousCarers: [
            ...state.detail.previousCarers,
            state.detail.secondaryCarer,
          ],
        },
      };
    case actions.REMOVE_MEMBER:
      if (action.archive) {
        const member = state.detail.otherIndividuals.find(
          oi => oi._id === action.member
        );
        return {
          ...state,
          detail: {
            ...state.detail,
            otherIndividuals: state.detail.otherIndividuals.filter(
              oi => oi._id !== action.member
            ),
            previousOtherIndividuals: [
              ...state.detail.previousOtherIndividuals,
              member,
            ],
          },
        };
      }
      const member = state.detail.previousOtherIndividuals.find(
        oi => oi._id === action.member
      );
      return {
        ...state,
        detail: {
          ...state.detail,
          previousOtherIndividuals: state.detail.previousOtherIndividuals.filter(
            oi => oi._id !== action.member
          ),
          otherIndividuals: [...state.detail.otherIndividuals, member],
        },
      };
    case actions.DELETE_HOUSEHOLD:
      return {
        ...state,
        list: [...filter(state.list, l => l._id !== action.household)],
        search: {
          ...state.search,
          result: [
            ...filter(state.search.result, r => r._id !== action.household),
          ],
        },
      };
    default:
      return state;
    case actions.UPDATE_NOTE:
      return { ...state, noteDetail: extend(state.noteDetail, action.body) };
    case actions.ADD_NOTE:
      if (!isEmpty(state.detail)) {
        return {
          ...state,
          detail: {
            ...state.detail,
            notes: [action.note, ...state.detail.notes],
          },
        };
      }
      return state;
    case actions.GET_NOTES:
      return { ...state, detail: { ...state.detail, notes: action.notes } };
    case actions.GET_NOTE:
      return { ...state, noteDetail: action.note };
    case actions.EXIT_NOTE:
      return { ...state, noteDetail: {} };
  }
};
