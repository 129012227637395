import keys from 'lodash/keys';
import find from 'lodash/find';

import PlacementModel from './PlacementModel';
import NoteModel from './NoteModel';

import * as PlacementAPI from './PlacementAPI';

import { notification } from '../App/AppActions';

export const RENDER_CASES = 'case/RENDER_CASES';
export const LOADING_CASES = 'case/LOADING_CASES';
export const DISPLAY_CASE = 'case/DISPLAY_CASE';
export const UPDATE_CASE = 'case/UPDATE_CASE';
export const CHANGE_TAB = 'case/CHANGE_TAB';
export const CLEAR_SEARCH = 'case/CLEAR_SEARCH';
export const ADD_NOTE = 'case/ADD_NOTE';
export const GET_NOTES = 'case/GET_NOTES';
export const GET_NOTE = 'case/GET_NOTE';
export const UPDATE_NOTE = 'case/UPDATE_NOTE';
export const EXIT_NOTE = 'case/EXIT_NOTE';
export const UPDATE_ACCOMMODATION = 'case/UPDATE_ACCOMMODATION';
export const DELETE_CASE = 'case/DELETE_CASE';
export const CASES_ERROR = 'case/CASES_ERROR';

export function changeTab(tab) {
  return {
    type: CHANGE_TAB,
    tab,
  };
}

export function exitNote() {
  return { type: EXIT_NOTE };
}

export function modifyNote(noteId, body) {
  return {
    type: UPDATE_NOTE,
    noteId,
    body,
  };
}

export function getNotes(notes) {
  return {
    type: GET_NOTES,
    notes,
  };
}

export function getNote(note) {
  return {
    type: GET_NOTE,
    note,
  };
}

export function addNote(note) {
  return {
    type: ADD_NOTE,
    note,
  };
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

export function renderPlacements(text, tab, tabChanged, cases) {
  return {
    type: RENDER_CASES,
    text,
    tab,
    tabChanged,
    cases,
  };
}

export function getPlacement(caseLoad) {
  return {
    type: DISPLAY_CASE,
    caseLoad,
  };
}

export function updatePlacement(caseLoad, body, detail) {
  return {
    type: UPDATE_CASE,
    caseLoad,
    body,
    detail,
  };
}

export function loadMore(start) {
  return {
    type: LOADING_CASES,
    start,
  };
}

export function _updateAccomodation(accId, accommodation) {
  return {
    type: UPDATE_ACCOMMODATION,
    accId,
    accommodation,
  };
}

export function _deletePlacement(caseLoad) {
  return {
    type: DELETE_CASE,
    caseLoad,
  };
}

export function setError(error = false) {
  return {
    type: CASES_ERROR,
    error,
  };
}

export function searchPlacements(text, start, count, type, tabChanged) {
  return dispatch => {
    dispatch(loadMore(start));
    return PlacementAPI.searchPlacements(text, start, count, type).then(
      cases => {
        dispatch(renderPlacements(text, type, tabChanged, cases));
      },
      err => dispatch(setError(err || true))
    );
  };
}

export function fetchPlacement(_id) {
  return dispatch =>
    PlacementAPI.fetchPlacement(_id).then(
      caseLoad => {
        if (caseLoad) {
          return PlacementAPI.getAccommodation(_id).then(accommodation => {
            const caseWithAcc = { ...caseLoad, accommodation };
            return dispatch(getPlacement(caseWithAcc));
          });
        }
        return dispatch(getPlacement({ deleted: true }));
      },
      () => Promise.reject()
    );
}

export function modifyPlacement(
  _id,
  body,
  prevVal,
  undo = false,
  detail = true
) {
  return dispatch =>
    PlacementAPI.updatePlacement(_id, body).then(() => {
      dispatch(fetchPlacement(_id));
      if (!undo) {
        dispatch(
          notification(
            `${find(PlacementModel, { field: keys(body)[0] }).name} updated.`,
            'UNDO',
            modifyPlacement(_id, prevVal, '', true)
          )
        );
      } else {
        dispatch(
          notification(
            `Changes to ${
              find(PlacementModel, { field: keys(body)[0] }).name
            } undone.`
          )
        );
      }
    });
}

export function changeCoordinator(id, body, staff) {
  return dispatch =>
    PlacementAPI.assignCoordinator(id, body).then(() => {
      dispatch(updatePlacement(id, { staff }, true));
      dispatch(notification('Coordinator updated'));
    });
}

export function submitPlacement(body) {
  return dispatch =>
    PlacementAPI.addPlacement(body).then(caseLoad => {
      dispatch(getPlacement(caseLoad));
      return caseLoad;
    });
}

export function fetchPlacementNotes(_id) {
  return dispatch =>
    PlacementAPI.getNotes(_id).then(notes => {
      dispatch(getNotes(notes));
      return notes;
    });
}

export function addPlacementNote(_id, body) {
  return dispatch =>
    PlacementAPI.createPlacementNote(_id, body).then(note => {
      dispatch(addNote(note));
      dispatch(notification('Note added.'));
      return note;
    });
}

export function fetchNote(caseId, noteId) {
  return dispatch =>
    PlacementAPI.getNote(caseId, noteId).then(note => {
      dispatch(getNote(note));
    });
}

export function updateNote(caseId, noteId, body, prevVal, undo = false) {
  return dispatch =>
    PlacementAPI.updateNote(caseId, noteId, body).then(() => {
      dispatch(modifyNote(noteId, body));
      dispatch(fetchPlacementNotes(caseId));
      if (!undo) {
        dispatch(
          notification(
            `${find(NoteModel, { field: keys(body)[0] }).name} updated.`,
            'UNDO',
            updateNote(caseId, noteId, prevVal, '', true)
          )
        );
      } else {
        dispatch(
          notification(
            `Changes to ${
              find(NoteModel, { field: keys(body)[0] }).name
            } undone.`
          )
        );
      }
    });
}

export function deleteNote(caseId, noteId) {
  return dispatch =>
    PlacementAPI.deleteNote(caseId, noteId).then(() => {
      dispatch(fetchPlacementNotes(caseId));
    });
}

export function closeNote() {
  return dispatch => {
    dispatch(exitNote());
  };
}

export function updateAccomodation(accommodation) {
  return dispatch =>
    PlacementAPI.updateAccomodation(accommodation._id, accommodation).then(
      () => {
        dispatch(_updateAccomodation(accommodation._id, accommodation));
        return accommodation;
      }
    );
}

export function deleteAccommodation(accommodation) {
  return dispatch => PlacementAPI.deleteAccommodation(accommodation);
}

export function deletePlacement(caseLoad) {
  return dispatch =>
    PlacementAPI.deletePlacement(caseLoad).then(() =>
      dispatch(_deletePlacement(caseLoad))
    );
}
