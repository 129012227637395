import callApi from '../../util/apiCaller';

export function addReadLog(_id, recordType) {
  return callApi(`log?recordType=${recordType}`, 'PUT', { _id });
}

export function getObjectLogs(_id, contact) {
  return callApi('log', 'POST', { _id, contact });
}

export function getAllLogs(start) {
  return callApi(`log?start=${start}`);
}
