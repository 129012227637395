import React, { Component } from 'react';
import MCheckbox from 'material-ui/Toggle';

import { colours } from '../theme';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { param, value, onUpdated, readOnly, create } = this.props;
    return (
      <MCheckbox
        id={param.field}
        label={param.name}
        style={{ margin: '32px 0 16px 0' }}
        labelStyle={{ color: param.required ? colours.kRed : '' }}
        thumbSwitchedStyle={
          param.required ? { backgroundColor: colours.kRed } : {}
        }
        trackSwitchedStyle={
          param.required ? { backgroundColor: '#F8B6AF' } : {}
        }
        toggled={value}
        onToggle={(e, val) => onUpdated(val)}
        disabled={readOnly || (!create && !param.editable)}
      />
    );
  }
}

export default Checkbox;
