import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { getAllLogs } from '../LogAPI';

class AllLogs extends Component {
  static getLink(l) {
    // eslint-disable-line react/sort-comp
    let link = '';
    let recordType = l.recordType.toLowerCase();
    if (recordType === 'youngperson') recordType = 'young';
    if (recordType === 'contact') link = `${l.record.userType}/${l.record._id}`;
    else link = `${recordType}/${l.record._id}`;
    return link;
  }
  static getRecordName(l) {
    // eslint-disable-line react/sort-comp
    if (l.record.name) return l.record.name;
    if (l.record.contact) return l.record.contact.fullName;
    if (l.record.fullName) return l.record.fullName;
    if (l.record.youngPerson) {
      let type = l.recordType;
      if (type === 'Case') type = 'Placement';
      return `${l.recordType} for ${l.record.youngPerson.contact.fullName}`;
    }
    return l._id;
  }
  constructor() {
    super();
    this.state = {
      logs: [],
      start: -50,
      loading: false,
      hasMore: true,
    };
  }
  fetchMore() {
    const { start, loading, logs } = this.state;
    if (!loading) {
      const newStart = start + 50;
      getAllLogs(newStart).then(newLogs => {
        this.setState({
          logs: [...logs, ...newLogs],
          hasMore: newLogs.length === 50,
          start: newStart,
        });
      });
    }
  }
  render() {
    const { start, logs, hasMore } = this.state;
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Logs - HEART1869</title>
          <meta name="description" content="Kennerley HEART1869 Logs" />
        </Helmet>
        <div className="content">
          <div className="log-list-body">
            <InfiniteScroll
              className="log-list-section"
              useWindow={false}
              pageStart={start}
              loadMore={() => this.fetchMore(start)}
              hasMore={hasMore}
              initialLoad
            >
              <div className="log-column-names">
                <div className="log-section-small-all">Date</div>
                <div className="log-section-big-all">Action</div>
                <div className="log-section-small-all">User</div>
                <div className="log-section-small-all">Record</div>
              </div>
              {logs.map(l => (
                <Link
                  to={AllLogs.getLink(l)}
                  className={`log ${l.record.deleted ? 'disabled-link' : ''}`}
                  key={l._id}
                >
                  <div className="log-section-small-all">
                    {moment(l.created).format('DD/MM/YY HH:mm')}
                  </div>
                  <div className="log-section-big-all">
                    {l.action}
                    {l.change ? `: ${l.change}` : ''}
                  </div>
                  <div className="log-section-small-all">{l.user.fullName}</div>
                  <div className="log-section-small-all">
                    {AllLogs.getRecordName(l)}
                  </div>
                </Link>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

export default AllLogs;
