const clearable = true;
const editable = true;
const multiLine = true;

export default [
  {
    field: 'careType',
    name: 'Care Type',
    type: 'select',
    options: [
      'Foster Care',
      'Respite Care',
      'Community Respite Care',
      'Moving On Program',
      'Family Connections Program',
    ],
    section: 'detail',
  },
  {
    field: 'servicesProvided',
    name: 'Services Requested',
    type: 'select',
    useKeywords: true,
    multiSelect: false,
    section: 'detail',
    editable,
  },
  {
    field: 'kennerleyEntryDate',
    name: 'Date Entered Care with Kennerley',
    type: 'date',
    default: '',
    section: 'detail',
    editable,
  },
  {
    field: 'leftCareDate',
    name: 'Date Left Care',
    type: 'date',
    default: '',
    section: 'detail',
    editable,
    clearable,
  },
  {
    field: 'leftCareReason',
    name: 'Reason Left Care',
    type: 'select',
    useKeywords: true,
    default: '',
    section: 'detail',
    editable,
  },
  {
    field: 'placementBreakdown',
    name: 'Placement Breakdown',
    type: 'check',
    default: '',
    section: 'detail',
    editable,
  },
  {
    field: 'information',
    name: 'General Information',
    type: 'text',
    multiLine,
    editable,
    section: 'detail',
  },
];
