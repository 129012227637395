const editable = true;
const multiLine = true;

export default [
  {
    field: 'leftCareDate',
    name: 'Left Care Date',
    type: 'date',
    default: '',
    section: 'care',
    editable,
  },
  {
    field: 'legalOrderStatus',
    name: 'Legal Order Status',
    type: 'select',
    editable,
    section: 'care',
    options: [
      'Custody',
      'Custody - Protective Supervision ',
      'Guardianship (Long Term)',
      'Guardianship (Short Term)',
      'Third Party Guardianship',
      'FVAO',
      'FCA Parenting Order',
      'Subpoena',
      'Warrant',
      'Not Applicable',
    ],
  },
  {
    field: 'wrapAroundServices',
    name: 'Wraparound Services',
    type: 'text',
    default: '',
    section: 'care',
    editable,
  },
  {
    field: 'csoName',
    name: 'Child Safety Officer',
    type: 'text',
    section: 'care',
    editable,
  },
  {
    field: 'cscEmail',
    name: 'CSO Email',
    type: 'text',
    section: 'care',
    editable,
  },
  {
    field: 'csoPhone',
    name: 'CSO Phone',
    type: 'text',
    section: 'care',
    editable,
  },
  {
    field: 'healthConcerns',
    name: 'Health Concerns',
    type: 'text',
    default: '',
    section: 'medical',
    editable,
  },
  {
    field: 'severity',
    name: 'Health Severity',
    type: 'select',
    useKeywords: true,
    section: 'medical',
    editable,
  },
  {
    field: 'econtactName',
    name: 'Emergency Contact',
    type: 'text',
    editable,
    section: 'medical',
  },
  {
    field: 'econtactEmail',
    name: 'Emergency Email',
    type: 'text',
    editable,
    section: 'medical',
  },
  {
    field: 'econtactPhone',
    name: 'Emergency Phone',
    type: 'text',
    editable,
    section: 'medical',
  },
  {
    field: 'mop',
    name: 'Moving On Program',
    type: 'check',
    default: '',
    section: 'care',
    editable,
  },
  {
    field: 'goals',
    name: 'Goals',
    type: 'text',
    default: '',
    section: 'care',
    editable,
    multiLine,
  },
  {
    field: 'comments',
    name: 'Additional Comments',
    type: 'text',
    default: '',
    section: 'care',
    editable,
    multiLine,
  },
  {
    field: 'birthCountry',
    name: 'Country of Birth',
    type: 'text',
    default: '',
    section: 'personal',
    editable,
  },
  {
    field: 'homeLanguage',
    name: 'Main Language Spoken at Home',
    type: 'text',
    default: '',
    section: 'personal',
    editable,
  },
  {
    field: 'aboriginal',
    name: 'First Nations Connection',
    type: 'select',
    useKeywords: true,
    default: 'Unknown',
    section: 'personal',
    editable,
  },
  {
    field: 'cald',
    name: 'Culturally and Linguistically Diverse Background',
    type: 'check',
    default: '',
    section: 'personal',
    editable,
  },
  {
    field: 'religion',
    name: 'Religion',
    type: 'text',
    default: '',
    section: 'personal',
    editable,
  },
  {
    field: 'ethnicity',
    name: 'Ethnicity',
    type: 'text',
    default: '',
    section: 'personal',
    editable,
  },
  {
    field: 'school',
    name: 'School',
    type: 'select',
    useKeywords: true,
    default: '',
    section: 'education',
    editable,
  },
  {
    field: 'apprenticeship',
    name: 'Undertaking Apprenticeship',
    type: 'check',
    default: false,
    section: 'education',
    editable,
  },
  {
    field: 'tertiary',
    name: 'Undertaking Tertiary Education',
    type: 'select',
    useKeywords: true,
    section: 'education',
    editable,
  },
  {
    field: 'educationComments',
    name: 'Education Comments',
    type: 'text',
    default: '',
    section: 'education',
    editable,
    multiLine,
  },
  {
    field: 'employer',
    name: 'Employer',
    type: 'text',
    default: '',
    section: 'personal',
    editable,
  },
  {
    field: 'employerPhone',
    name: 'Employer Phone',
    type: 'text',
    default: '',
    section: 'personal',
    editable,
  },
  {
    field: 'payments',
    name: 'Payment Classifications',
  },
];
