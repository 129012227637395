import unionBy from 'lodash/unionBy';

import * as actions from './OtherIndividualActions';
import { ROUTE_CHANGED } from '../App/AppActions';

const initialState = {
  list: [],
  detail: {},
  tab: 'open',
  search: {
    text: '',
    result: [],
  },
  info: {
    loading: false,
    start: -30,
    count: 30,
    hasMore: true,
    error: false,
  },
};

export default (state = initialState, action) => {
  const _members =
    action.start === 0 || action.tabChanged
      ? action.members
      : unionBy(state.list, action.members, '_id');

  const _list = state.list;

  if (action.type === ROUTE_CHANGED) {
    return { ...state, detail: {} };
  }
  switch (action.type) {
    case actions.MEMBERS_ERROR:
      return {
        ...state,
        info: { ...state.info, loading: false, error: action.error },
      };
    case actions.LOADING_MEMBERS:
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
          start: action.start,
        },
      };
    case actions.RENDER_MEMBERS:
      return {
        ...state,
        list: action.text ? _list : _members,
        search: {
          text: action.text,
          result: !action.text ? state.search.result : action.members,
        },
        info: {
          ...state.info,
          loading: false,
          hasMore: action.members.length === state.info.count,
        },
      };
    case actions.CHANGE_TAB:
      return {
        ...state,
        list: [],
        search: {
          text: '',
          result: [],
        },
        tab: action.tab,
      };
    case actions.CLEAR_SEARCH:
      return {
        ...state,
        search: {
          text: '',
          result: [],
        },
      };
    default:
      return state;
  }
};
