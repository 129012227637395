const editable = true;

export default [
  {
    field: 'contact',
    name: 'Contact Details',
    type: 'check',
    default: true,
    section: 'options',
    editable: false,
  },
  {
    field: 'personal',
    name: 'Personal Details',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'education',
    name: 'Education Details',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'medical',
    name: 'Medical Details',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'parents',
    name: 'Parents / Guardians',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'emergency',
    name: 'Emergency Details',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'siblings',
    name: 'Siblings',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'care',
    name: 'Care Details',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'payment',
    name: 'Payment Classifications',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'referrals',
    name: 'Referrals',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'cases',
    name: 'Placements',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'accommodation',
    name: 'Accommodation',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'viewsandwishes',
    name: 'Views & Wishes',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
  {
    field: 'attachments',
    name: 'Attachments',
    type: 'check',
    default: '',
    section: 'options',
    editable,
  },
];
